import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { ApiService, Competitor, CompetitorService, OrganizationUUID } from '@core/api';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-input-competitor',
  templateUrl: './input-competitor.component.html',
  styleUrls: ['./input-competitor.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputCompetitorComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class InputCompetitorComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Competitor[] | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-competitor';
  describedBy = '';
  stateChanges = new Subject<void>();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  competitors: Competitor[];
  inputCtrl = new UntypedFormControl();

  icCancel = Icon.IC_TWOTONE_CANCEL;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() tabIndex;
  @Input() ignore: Competitor[];

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = value;

    if (value) {
      this.inputCtrl.enable();
    } else {
      this.inputCtrl.disable();
    }

    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Competitor[] | null { return this._value; }
  set value(value: Competitor[] | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Competitor[] = [];

  @HostBinding('id') id = `input-competitor-${InputCompetitorComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private competitorService: CompetitorService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      filter(value => !!value && typeof value === 'string'),
      debounceTime(200),
      tap(() => this.loading = true),
      switchMap(value => this.competitorService.search({
        filter: {
          ownerId: OrganizationUUID.NETLOG,
          competitorName: value
        },
        pageSize: 100
      })),
      map(response => this.filterCompetitorResult(response.data.results)),
      tap(() => this.loading = false)
    ).subscribe((competitors) => this.competitors = competitors);
  }

  private filterCompetitorResult(list: Competitor[]): Competitor[] {
    let exists = this.value;

    if (this.ignore) {
      exists = [...exists, ...this.ignore];
    }

    return list.filter(competitor => !exists.some(selected => selected.competitorId === competitor.competitorId));
  }

  reset() {
    this.input.nativeElement.value = '';
    this.inputCtrl.setValue(null);
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = [...this.value, event.option.value];
    this.reset();
  }

  onAddToken(event: MatChipInputEvent) {
    if (!this.matAutocomplete.isOpen) {
      event.input.value = '';
      this.inputCtrl.setValue(null);
    }
  }

  onRemoveChip(competitor: Competitor) {
    const index = this.value.indexOf(competitor);

    if (index >= 0) {
      this.value.splice(index, 1);
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Competitor[]) {
    if (value instanceof Array) {
      this.value = value;
    }
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
