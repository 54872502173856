import moment from 'moment';
import { PopoverRef } from 'src/app/shared/components/popover/popover-ref';
import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Notification, NotificationSearchRequest, NotificationService, User } from '@core/api';
import { NotificationDataSource } from '@core/api/notification/notification.data-source';
import { GetNotification, getUser, UpdateNotificationCount } from '@core/store';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { stagger40ms } from '@shared/animations';
import { trackById } from '@shared/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import { NgFor } from '@angular/common';

@Component({
  selector: 'net-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss'],
  animations: [
    stagger40ms,
  ],
  standalone: true,
  imports: [
    SharedModule,
    NgFor,
  ]
})

export class ToolbarNotificationsDropdownComponent implements OnInit, OnDestroy {
  pageSize = 10;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: NotificationDataSource;
  user: User;
  initiated = false;
  notifications: Notification[];

  icArrows = Icon.IC_BASELINE_KEYBOARD_ARROW_RIGHT;
  icSettings = Icon.IC_TWOTONE_SETTINGS;
  icChevronRight = Icon.IC_TWOTONE_CHEVRON_RIGHT;
  icClearAll = Icon.IC_TWOTONE_CLEAR_ALL;
  icNotificationsOff = Icon.IC_TWOTONE_NOTIFICATIONS_OFF;
  icInfo = Icon.MDI_INFORMATION_VARIANT;
  loading = true;
  trackById = trackById;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private popoverRef: PopoverRef<ToolbarNotificationsDropdownComponent>,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);
  }

  ngOnInit() {
    const request: NotificationSearchRequest = {
      filter: { sendedUserId: this.user.userId, isReaded: false },
      orderBy: 'createdOn',
      page: 1,
      pageSize: 10,
      orderType: 'DESC'
    };
    this.notificationService.search(request, true).subscribe(response => {
      this.notifications = response.data.results.filter(notification => !notification.isReaded);
      this.notifications.forEach(not => {
        if (moment().diff(new Date(not.createdOn)) > 0) {
          not.createdBefore = moment(new Date(not.createdOn), 'YYYYMMDD').fromNow();
        } else {
          not.createdBefore = moment(moment()).fromNow();
        }
      }
      );
    }).add(() => {
      setTimeout(() => {
        this.loading = false;
      }, 10);
    });
  }

  makeRead(notification: Notification) {
    const indexN = this.notifications.findIndex(x => x.notificationId === notification.notificationId);
    this.notifications.splice(indexN, 1);
    this.notificationService.read(notification?.notificationId, '').subscribe(res => {
      this.store.dispatch(new GetNotification());
    });
  }

  makeAllRead() {
    this.notificationService.read('', this.user.userId)
      .subscribe();
    this.store.dispatch(new UpdateNotificationCount(0));
    this.notifications = null;
  }

  navigateToNotifications() {
    this.router.navigateByUrl('/notification').then(() => this.popoverRef.close());
  }

  // Mecburiyetten board için else if ekledim. Baştan yazılıp bir standart belirlenmeli
  goToLink(url: string, notification: Notification) {
    const routeUrl = url?.split('/')[0] ?? '';
    const paramValue = url?.split('/')[1] ?? '';

    if (url?.includes('tablefilter')) {
      this.onClickTableFilterNotification(url);
      return;
    }

    if (routeUrl === 'assignment') {

      this.onClickAssignmentNotification(url, paramValue, routeUrl, notification);

    } else if ('board' === routeUrl) {

      this.onClickBoardNotification(url, paramValue, notification);

    } else if (routeUrl === 'custom-dashboard') {

      this.onClickCustomDashboardNotification(url, paramValue, notification);

    } else if (routeUrl === 'opportunities') {

      this.onClickOpportunitiesNotification(url, paramValue, notification);

    }
    else if (routeUrl === 'leads') {

      this.onClickAccountNotification(url, paramValue, notification);

    } else {
      this.router.navigate(['../' + url]).catch(err => {
        this.navigateError(notification, routeUrl);
      }).then(() => this.popoverRef.close());
    }
  }

  onClickTableFilterNotification(url: string) {
    const _url = url.split('?')[0];
    const queryParams = { tableFilter: url.split('tablefilter=')[1] };

    this.toSpecificRoute(_url, queryParams);
    this.popoverRef.close();
  }

  onClickAssignmentNotification(url: string, paramValue: string, routeUrl: string, notification: Notification) {
    const queryParams: Params = { id: paramValue };
    this.router.navigate(['../' + routeUrl], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    }).catch(err => {
      this.navigateError(notification, routeUrl);
    }).then(() => this.popoverRef.close());
  }

  onClickBoardNotification(url: string, routeUrl: string, notification: Notification) {
    const cardId = url.split('?cardId=')[1];
    const queryParams: Params = { cardId };
    const _url = cardId ? url.split('?')[0] : url;

    this.router.navigateByUrl('/board', { state: { showLoading: true } }).catch(() => this.navigateError(notification, routeUrl))
      .then((isRedirect) => {

        if (isRedirect) {

          setTimeout(() => {
            this.router.navigate([_url], { queryParams }).finally(() => this.popoverRef.close());
          }, 1000);

        }
      });
  }

  onClickCustomDashboardNotification(url: string, routeUrl: string, notification: Notification) {
    const queryParams: Params = { fromNotification: true };
    this.router.navigate(['../' + url], {
      relativeTo: this.route,
      queryParams
    }).catch(err => {
      this.navigateError(notification, routeUrl);
    }).then(() => this.popoverRef.close());
  }

  onClickOpportunitiesNotification(url: string, routeUrl: string, notification: Notification) {
    let queryParams: Params;
    if ((notification.notificationText.includes('mentioned'))) {
      queryParams = { fromNotification: true };
    }
    this.router.navigate(['../' + url], {
      relativeTo: this.route,
      queryParams
    }).catch(err => {
      this.navigateError(notification, routeUrl);
    }).then(() => this.popoverRef.close());
  }

  onClickAccountNotification(url: string, routeUrl: string, notification: Notification) {
    let queryParams: Params;
    if ((notification.notificationText.includes('mentioned'))) {
      queryParams = { fromNotification: true };
    }
    this.router.navigate(['../' + url], {
      relativeTo: this.route,
      queryParams
    }).catch(err => {
      this.navigateError(notification, routeUrl);
    }).then(() => this.popoverRef.close());
  }

  navigateError(notification: Notification, routeUrl: string) {
    const snackBarRef = this._snackBar.open('');
    snackBarRef.dismiss();
  }

  toSpecificRoute(routeUrl, queryParams) {
    this.router.navigate(['../' + routeUrl], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {
  }
}
