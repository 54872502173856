import { StaticFieldService } from '@core/api/static-field/static-field.service';
import { TableColumn } from '../interfaces/table-column.interface';
import { map } from 'rxjs/operators';

export function updateWithStaticFields<T>(
    staticFieldService: StaticFieldService,
    entityName: string,
    columns: TableColumn<T>[]
): Promise<void> {
    return new Promise((resolve) => {
        staticFieldService.search({
            filter: {
                entityName
            }
        }).pipe(map(response => response.data.results))
            .subscribe((staticFields) => {

                staticFields.forEach(staticField => {
                    const column = columns.find(c => c.staticFieldEnum === staticField.entityFieldId);

                    if (!column) {
                        return;
                    }

                    column.staticFieldActiveFlag = staticField.activeFlag;
                    column.staticFieldListFlag = staticField.listFlag;
                });

                resolve();
            });
    });
}
