@if(options.raised) {

<button mat-button [type]="options.type" [color]="options.buttonColor" [class.active]="options.active"
  [class.fullWidth]="options.fullWidth" mat-raised-button [class.mat-mdc-stroked-button]="options.stroked"
  [class.mat-mdc-flat-button]="options.flat" [class.mat-mdc-fab]="options.fab" [ngClass]="options.customClass"
  [disabled]="options.active || options.disabled">

  <ng-container *ngTemplateOutlet="spinnerButtonBody"></ng-container>

</button>

} @else if (options.stroked) {

<button mat-button [type]="options.type" [color]="options.buttonColor" [class.active]="options.active"
  [class.fullWidth]="options.fullWidth" mat-stroked-button [class.mat-mdc-flat-button]="options.flat"
  [class.mat-mdc-fab]="options.fab" [ngClass]="options.customClass" [disabled]="options.active || options.disabled">

  <ng-container *ngTemplateOutlet="spinnerButtonBody"></ng-container>

</button>

}@else if (options.flat) {

<button mat-button [type]="options.type" [color]="options.buttonColor" [class.active]="options.active"
  [class.fullWidth]="options.fullWidth" mat-flat-button [ngClass]="options.customClass"
  [disabled]="options.active || options.disabled">

  <ng-container *ngTemplateOutlet="spinnerButtonBody"></ng-container>

</button>

}@else if (options.fab) {

<button mat-button [type]="options.type" [color]="options.buttonColor" [class.active]="options.active"
  [class.fullWidth]="options.fullWidth" mat-fab [ngClass]="options.customClass"
  [disabled]="options.active || options.disabled">

  <ng-container *ngTemplateOutlet="spinnerButtonBody"></ng-container>

</button>

}


<ng-template #spinnerButtonBody>

  @if(options.buttonIcon.tailwindIcon ) {

  <net-iconify-icon class="mat-button-icon" [class.mr-2]="!!options.text"
    [class.is-mat-icon]="!options.buttonIcon.fontSet" [ngClass]="options.buttonIcon.customClass"
    [class.active]="options.active && !options.disabled" [icon]="options.buttonIcon.tailwindIcon"
    [color]="options.buttonIcon.color" size="18" />

  }


  @if(!options.fab) {

  <span class="button-text" [class.active]="options.active && !options.disabled">
    {{ options.text }}
  </span>

  }

  @if(options.fab && options.icon ) {

  <net-iconify-icon [color]="options.buttonIcon.color" [class.mr-2]="!!options.text" [icon]="options.icon.tailwindIcon"
    size="18" />

  }

  @if(options.active && !options.disabled) {
  <mat-spinner class="spinner" [diameter]="options.fab ? 58 : options.spinnerSize" [color]="options.spinnerColor"
    [mode]="options.mode" [value]="options.value" [class.active]="options.active && !options.disabled">
  </mat-spinner>

  }

</ng-template>