import { Component, DoCheck, ElementRef, HostBinding, Input, OnChanges, OnDestroy, Optional, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { SelectTime } from './select-time.component.model';
import { SharedModule } from '@shared/modules';

/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
  selector: 'net-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectTimeComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectTimeComponent implements OnChanges, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SelectTime | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-time';
  describedBy = '';
  stateChanges = new Subject<void>();

  selectCtrl = new UntypedFormControl();

  options: SelectTime[];

  @Input() tabIndex;
  @Input() start = 7;
  @Input() end = 22;
  @Input() min: SelectTime;
  @Input() max: SelectTime;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SelectTime | null { return this.selectCtrl.value; }
  set value(value: SelectTime | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-time-${SelectTimeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Generate time options
    this.options = this.generateSelectTimes();
  }

  private generateSelectTimes(): SelectTime[] {
    const options: SelectTime[] = [];

    for (let hour = this.start; hour <= this.end; hour++) {
      if (this.min && hour < this.min.hour) {
        continue;
      }

      if (this.max && hour > this.max.hour) {
        continue;
      }

      if (this.min && this.min.hour !== this.start && hour === this.min.hour && new Date().getMinutes() > 30) {
        continue;
      }

      if (!this.min || this.min?.hour === this.start || ((this.min && hour !== this.min.hour) || 0 === this.min.hour)) {
        options.push({
          hour,
          minute: 0
        });
      }

      options.push({
        hour,
        minute: 30
      });
    }

    return options;
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: SelectTime, two: SelectTime) => one && two && one.hour === two.hour && one.minute === two.minute;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SelectTime) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.min && changes.min.currentValue) || (changes.max && changes.max.currentValue) ||
      (changes.start && changes.start.currentValue) || (changes.end && changes.end.currentValue)) {
      this.options = this.generateSelectTimes();
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
