import { AbstractControl, ValidationErrors } from '@angular/forms';
import cron from 'cron-validate';

export class CronValidator {
  static validator(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }

    const isCronFormat = cronValidate(control.value);

    let errorMsg = '';

    if (isCronFormat.isError()) {
      const seperator = ' (Input cron';

      const formattedErrors = isCronFormat.getError().map((error) => {
        if (error.startsWith('Element')) {
          error = error.split(seperator)[0];

          const arr = error.split(' ');
          arr[0] = arr[0].replace('Element', 'String');
          arr[1] += `'`;

          return arr.join(' ');
        } else if (error.startsWith('Number')) {
          error = error.split(seperator)[0];

          const arr = error.split(' ');
          arr[1] = `'${arr[1]}'`;

          return arr.join(' ') + '.';
        }

        return error.split(seperator)[0];
      });

      errorMsg = formattedErrors.join('\n');
    }

    return isCronFormat.isError() ? { invalidCron: errorMsg } : null;
  }
}

const cronValidate = (str: string) => {
  return cron(str, {
    // preset: 'npm-node-cron',
    // preset: 'aws-cloud-watch',
    // preset: 'default',
    preset: 'npm-cron-schedule',
    override: {
      // useAliases: true,
      useSeconds: false,
      useYears: false,
      useLastDayOfMonth: true,
      useLastDayOfWeek: true,
      useNthWeekdayOfMonth: true,
      useNearestWeekday: true
      // mustHaveBlankDayField: false
    },
  });
};
