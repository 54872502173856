import { MatCustomDataAccessor } from './mat-data-accesssor';
import { replaceTurkishCharacters } from './replace-turkish-characters';

export const FILTER_DELIMITER = '◬';

/**
 * Replicates default filter predicate from MatTableDataSource
 * Supports data accessor and locale
 * @TODO remove any cast for toLocaleLowerCase after TS supports locale
 */
export function matFilterPredicate(data, filter: string, dataAccessor: MatCustomDataAccessor, locale?: string): boolean {
  if (locale === undefined) {
    locale = 'TR';
  }

  // Transform the data into a lowercase string of all property values.
  const dataStr = (Object.keys(data).reduce((currentTerm: string, key: string) => {
    // Use an obscure Unicode character to delimit the words in the concatenated string.
    // This avoids matches where the values of two columns combined will match the user's query
    // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
    // that has a very low chance of being typed in by somebody in a text field. This one in
    // particular is "White up-pointing triangle with dot" from
    // https://en.wikipedia.org/wiki/List_of_Unicode_characters
    return currentTerm + dataAccessor(data, key) + FILTER_DELIMITER;
  }, '') as any);

  const replaced = replaceTurkishCharacters(dataStr).toLocaleUpperCase();

  // Transform the filter by converting it to lowercase and removing whitespace.
  const transformedFilter = replaceTurkishCharacters(filter.trim()).toLocaleUpperCase();

  return replaced.indexOf(transformedFilter) !== -1;
}
