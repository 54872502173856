<div fxLayout="row" fxLayoutAlign="start center" class="mb-0" mat-dialog-title>
  <span class="text-sm">{{'TOOLBAR.WHICH_SALES_ORGANIZATION_WOULD_YOU_LIKE_TO_SEE_OF_THE_CUSTOMER'
    | translate}}
  </span>
  <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
    <net-iconify-icon [icon]="icClose"></net-iconify-icon>
  </button>
</div>
<mat-dialog-content class="pb-4" fxLayout="column">
  <h4 class="font-medium mt-4">{{'GENERAL.SALES_ORGANIZATIONS' | translate}}</h4>
  <div *ngFor="let account of accounts" class="border border-solid border-gray-400 rounded-lg mt-2 hover:bg-hover">
    <button mat-stroked-button class="w-full text-left	flex flex-row items-center justify-start"
      (click)="onSelectContactWithAccount(account.accountId)" type="button">
      <net-iconify-icon class="text-hint" [icon]="icKeyboardArrowRight"></net-iconify-icon>
      <span class="account-alert-title">{{ account.salesOrganization.name }}</span>
    </button>
  </div>
</mat-dialog-content>