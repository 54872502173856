import { finalize, map } from 'rxjs/operators';

import { AccountDocumentService, DocumentDetail, SystemUserService, User } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { of } from 'rxjs';
import { DocumentSourceType } from './document-source.enum';
import { TranslateService } from '@ngx-translate/core';

export class DocumentDataSource extends ApiDataSource<DocumentDetail> {

  public $createBys = this.systemUserService.userSearch({
    filter: {
      salesOrganizationIds: this.user.salesOrganizations.map(item => item)
    }
  }).pipe(
    map(response => response.data.results),
    map(statuses => statuses.map(item => {
      return {
        key: item.systemUserId,
        value: `${item.firstName} ${item.lastName}`
      };
    }))
  );

  public $sources = of([
    { key: DocumentSourceType.CUSTOMER, value: this.translate.instant('GENERAL.CUSTOMER') },
    { key: DocumentSourceType.CARD, value: this.translate.instant('GENERAL.CARD') },
    { key: DocumentSourceType.OPPORTUNITY, value: this.translate.instant('GENERAL.OPPORTUNITY') },
    { key: DocumentSourceType.ACTIVITY, value: this.translate.instant('GENERAL.ACTIVITY') },
    { key: DocumentSourceType.ASSIGNMENT, value: this.translate.instant('GENERAL.ASSIGNMENT') }
  ].sort((a, b) => (
    a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase() ? -1 : 1
  )));

  constructor(
    private translate: TranslateService,
    private accountDocumentService: AccountDocumentService,
    private systemUserService: SystemUserService,
    private user: User,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.accountDocumentService
      .additionalDocumentsList(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);
        this.rowCount$.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
