import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  Board, BoardInfo, BoardInfoAdditionalData, Card, BoardResponse,
  PagedResponse, Response, SocketSearchRequest,
  CardPaginationType, BoardGroupType, BoardListenerType
} from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';
import * as signalR from '@microsoft/signalr';
import { BoardHubRequest } from './board-info-signal-r.model';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomConsole } from 'src/app/shared/utils/custom-console';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BoardResolverService } from 'src/app/board/board-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class BoardInfoSignalRService {

  console = new CustomConsole();

  private hubConnection: signalR.HubConnection;

  private filterSubject = new BehaviorSubject<SocketSearchRequest>(null);
  filter$ = this.filterSubject.asObservable();

  public loadingSubject = new ReplaySubject<boolean>();
  public loading$ = this.loadingSubject.asObservable();

  public connectionSubject = new ReplaySubject<boolean>();
  public connection$ = this.connectionSubject.asObservable();

  public panelLoadingSubject = new ReplaySubject<boolean>();
  public panelLoading$ = this.panelLoadingSubject.asObservable();

  public openedCardIdsSubject = new BehaviorSubject<string[]>(null);
  public openedCardIds$ = this.openedCardIdsSubject.asObservable();

  private groupName: string;
  private request: SocketSearchRequest;
  joinedGroupList: string[] = [];

  public startConnection = (boardId: string, isJoinGroup: boolean = true) => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      // tslint:disable-next-line:max-line-length
      .withUrl(`${environment.socketUrl}/boardhub?x-client=${environment.socketTenant}&boardId=${boardId}`, { accessTokenFactory: async () => { await this.getToken(); return AuthService.getToken(); }, withCredentials: true, transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true })
      .withAutomaticReconnect([500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    // this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;
    this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60;

    this.hubConnection.onclose((err) => {
      if (err) {
        if (err.stack.includes('WebSocket closed with status code: 1006 ()')) { setTimeout(() => { location.reload(); }, 1000); }
        setTimeout(() => {
          this.hubConnection.start()
            .then(() => this.console.info('Websocket Connection Established'))
            .catch(error => this.console.error('SignalR Connection Error: ', error));
        }, 500);
      }
    });

    this.hubConnection.onreconnected(() => {
      if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        this.console.info('Websocket Reconnected');
        this.joinGroupForReconnect();
      }
    });

    this.hubConnection
      .start()
      .then(() => {
        // Board değişikliklerini almak için gruba dahil olma
        if (this.hubConnection.state === signalR.HubConnectionState.Connected && isJoinGroup) {
          this.hubConnection.invoke(BoardGroupType.JoinGroup, BoardGroupType.Board, boardId).finally(() => {
            this.listenJoinGroup();
            this.listenUser();
            this.console.info('Connection started');
            this.connectionSubject.next(true);
            this.loadingSubject.next(false);
          });
        }
      })
      .catch(err => this.console.info('Error while starting connection: ' + err));
  }

  public invokeBoardData = (request: BoardHubRequest) => {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.invoke('boardinfodata', request);
    }
  }

  public invokeData = (url: string, request) => {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.console.info('url', url);
      this.console.info('request', request);
      this.hubConnection.invoke(url, request);
    }
  }

  constructor(
    private boardResolverService: BoardResolverService,
    private toastService: ToastService,
    private translate: TranslateService,
    private auth: AuthService
  ) { }

  //#region Loading Board Infos
  // Invoke BoardInfo
  public invokeBoardInfos = (viewName, request) => {
    this.request = request;

    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.console.info('viewName', viewName);
      this.console.info('request', request);
      return this.hubConnection.invoke(viewName, request);
    }
  }

  // Get BoardInfo
  public addBoardInfoDataListener = (groupName: string) => {

    this.groupName = groupName;

    this.hubConnection.on(groupName, (response) => {

      this.console.info(groupName, response);

      this.loadingSubject.next(false);

      if (groupName === BoardGroupType.Kanban) {
        this.boardResolverService.addSectionsToScrum(response.data, this.request);
      } else {
        this.boardResolverService.addBoardInfosToLists(response.data, this.request);
      }

      this.hubConnection.off(groupName);
    });
  }
  //#endregion

  //#region Loading Card
  // invoke Cards
  public invokeCards = (request: SocketSearchRequest) => {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.console.info(CardPaginationType.GetCards, request);
      return this.hubConnection.invoke(CardPaginationType.GetCards, request);
    }
  }

  // get Cards
  public addCards = () => {
    this.hubConnection.on(CardPaginationType.CardPagination, (response: PagedResponse<Card>) => {
      this.console.info(CardPaginationType.CardPagination, response);
      this.loadingSubject.next(false);
      this.boardResolverService.addCardsToScrumSections(response.data.results);
      this.hubConnection.off(CardPaginationType.CardPagination);
    });
  }
  //#endregion

  //#region List Cards
  // invoke List Cards
  public invokeListCards = (request: SocketSearchRequest, type: string) => {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.request = request;
      this.console.info(type, request);
      this.hubConnection.invoke(type, request);
    }
  }

  // get List Cards
  public listCardsListener = (type: string, additionalData?: BoardInfoAdditionalData) => {
    this.hubConnection.on(type, (response: Response<BoardInfo[]>) => {
      this.console.info(type, response);
      this.loadingSubject.next(false);
      this.boardResolverService.addBoardInfosToLists(response.data, this.request, additionalData);
      this.hubConnection.off(type);
    });
  }
  //#endregion

  //#region Board Listeners
  public boardInfoDataListener = () => {
    this.hubConnection.on(BoardListenerType.BoardInfo, (response: Response<BoardResponse<BoardInfo>[]>) => {
      if (!response.success) {
        this.toastService.error(this.translate.instant('ResultMessage.' + response.message));
        return;
      }

      this.console.info('boardInfoDataListener', response.data);
      this.loadingSubject.next(false);
      this.boardResolverService.updateBoardInfoData(response.data, this.hubConnection, this.groupName, this.request);
    });
  }

  public cardDataListener = () => {
    this.hubConnection.on(BoardListenerType.Card, (response: Response<BoardResponse<Card>[]>) => {
      if (!response.success) {
        this.toastService.error(this.translate.instant('ResultMessage.' + response.message));
        return;
      }

      this.console.info('cardDataListener', response.data);
      this.loadingSubject.next(false);
      this.boardResolverService.updateCardData(response.data, this.request, this.groupName, this.hubConnection);

    });
  }

  public boardDataListener = () => {
    this.hubConnection.on(BoardListenerType.Board, (response: Response<BoardResponse<Board>[]>) => {
      if (!response.success) {
        this.toastService.error(this.translate.instant('ResultMessage.' + response.message));
        return;
      }

      this.console.info('boardDataListener', response.data);
      this.loadingSubject.next(false);
      this.boardResolverService.updateBoardData(response.data);
    });
  }
  //#endregion

  listenJoinGroup() {
    this.hubConnection.on(BoardGroupType.JoinGroup, (response: string) => {
      this.joinedGroupList.push(response);
    });
  }

  listenUser() {
    this.hubConnection.on(BoardGroupType.UserComment, (response: Response<BoardResponse<Card>[]>) => {

      this.console.info('userCommentDataListener', response.data);
      this.loadingSubject.next(false);
      this.boardResolverService.updateCardData(response.data, this.request, BoardGroupType.UserComment);
    });
  }

  joinGroupForReconnect() {
    this.hubConnection.invoke(BoardGroupType.JoinGroupForReconnect, this.joinedGroupList);
  }

  clearBoardInfoData() {
    this.boardResolverService.clearBoardInfo();
  }

  public getHubConnection(): signalR.HubConnection {
    return this.hubConnection;
  }

  getToken(): Promise<void> {
    return new Promise((resolve) => {
      this.auth.refreshToken().pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                this.auth.logout(true, true);
                break;
            }
          }
          return throwError(error);
        })
      ).subscribe(() => resolve());
    });
  }

  setGroupName(groupName: string) {
    this.groupName = groupName;
  }

  public disconnectBoardDataListener = () => {
    if (!this.hubConnection) {
      return;
    }

    this.hubConnection.stop();
    this.filterSubject.next(null);
    this.loadingSubject.next(false);
    this.panelLoadingSubject.next(false);
    this.connectionSubject.next(false);
  }

}
