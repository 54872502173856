<mat-select [placeholder]="placeholder" [tabIndex]="tabIndex" [formControl]="selectCtrl"
  (selectionChange)="onSelectionChange()" panelClass="custom-panel" disableOptionCentering multiple>
  <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length>1">
    {{'GENERAL.VALUE_USERS_SELECTED' | translate: {value: selectCtrl.value.length} }}
  </mat-select-trigger>
  <div class="mat-option-button">
    <button mat-raised-button (click)="selectAll()">
      {{'GENERAL.SELECT_ALL' | translate}}
    </button>
    <button mat-raised-button (click)="deselectAll()">
      {{'GENERAL.REMOVE_ALL' | translate}}
    </button>
  </div>

  @for (systemUser of systemUsers; track $index) {
  <mat-option [disabled]="disabled || defaultSelectedUserIds?.includes(systemUser?.systemUserId)"
    [value]="systemUser">{{
    systemUser.firstName }} {{ systemUser.lastName }}
  </mat-option>
  }
</mat-select>

@if (loading === true) {
<mat-progress-spinner diameter="16" mode="indeterminate"></mat-progress-spinner>
}