import { DynamicField, DynamicFieldPicklist } from '@core/api';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class InputDynamicFieldValuesValidator {
  static picklistValue(field: DynamicField): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (field.picklistValues && control.value) {
        const values = Array.isArray(control.value) ? control.value : [control.value];

        const isValid = values.every(value => {
          const picklistValue = field.picklistValues.find(item => item.dynamicFieldPickListId === value);

          // Return required when picklist value is not active
          if (picklistValue && !picklistValue.activeFlag) {
            return false;
          }

          return true;
        });

        if (!isValid) {
          return { required: true };
        }
      }

      return null;
    };
  }

  static picklistDisabledValue(field: DynamicField): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (field.selectedDynamicFieldPicklistValues && control.value) {

        if (field.selectedDynamicFieldPicklistValues) {
          const isDisabledElement = field.selectedDynamicFieldPicklistValues?.some(item =>
            !item.conditionVisibility &&
            InputDynamicFieldValuesValidator.isDisabledPicklistValues(item, field)
          );

          if (isDisabledElement) {
            return { required: true };
          }

          return null;
        }
      }
      return null;
    };
  }

  private static isDisabledPicklistValues(value: DynamicFieldPicklist, field: DynamicField): boolean {
    return !!field.selectedDynamicFieldPicklistValues.find(selected => selected.dynamicFieldPickListId === value.dynamicFieldPickListId);
  }
}
