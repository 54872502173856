export enum AccountType {
    BUSINESS = 'c0971b35-8b5b-4b43-b8a3-2f17a1eee3d3',
    CONSUMER = 'f0556438-005b-4f6e-bdf1-1df1e64416b2'
}

export enum AccountTypeNumber {
    BUSINESS = 0,
    CONSUMER = 1
}
export enum AccountTypeString {
    BUSINESS = 'Business',
    CONSUMER = 'Consumer'
}
