import * as moment from 'moment';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import Swal from 'sweetalert2';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectorRef, Component, DestroyRef, EventEmitter,
  Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, inject
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Account,
  AccountTypeString,
  API_DATE_FORMAT,
  Contact,
  CurrencyExchangeService,
  CurrencyService,
  CurrencyType,
  DynamicField,
  DynamicFieldEntityAsString,
  DynamicFieldEntityId,
  DynamicFieldValue,
  FinancialInquiry,
  FinancialInquiryService,
  Guid,
  Opportunity,
  OpportunityCopySummaryService,
  OpportunityExpectedDateHistory,
  OpportunityExpectedDateHistoryService,
  OpportunityInfo,
  OpportunityLostReasonType,
  OpportunityMailRequest,
  OpportunityParameterService,
  OpportunityProcessTypeEnum,
  OpportunityRequest,
  OpportunityService as opportunityService,
  OpportunityStatus,
  OpportunityStatusType,
  OpportunityTypeOption,
  Organization,
  OrganizationMainServiceServiceRequest,
  OrganizationMainServiceServiceService,
  processDynamicFieldValues,
  RevenueType,
  RevenueTypeEnum,
  RevenueTypeService,
  SalesOrganizationAdvancedSettingType,
  SalesOrganizationSettingInfoValue,
  StaticFieldService,
  SystemUserStructure,
  TransactionCurrency,
  TransactionCurrencyExchange,
  User
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { getSalesOrganizationSettingValue, getUser, hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import {
  FinancialInquiryFormDialogComponent
} from '../financial-inquiry-form-dialog/financial-inquiry-form-dialog.component';
import { FinancialInquiryFormDialog } from '../financial-inquiry-form-dialog/financial-inquiry-form-dialog.model';
import { OpportunitySendingMailDialogComponent } from './opportunity-sending-mail/opportunity-sending-mail-dialog.component';
import { OpportunityService } from '../input-opportunity-services/input-opportunity-services.component.model';
import { InputOpportunityServiceValidators } from '../input-opportunity-services/input-opportunity-services.validators';
import { InputParticipantMode } from '../input-participant/input-participant.component.enum';
import { Participants } from '../input-participant/input-participant.component.model';
import { map } from 'rxjs/operators';
import { OpportunityParameter } from '@core/api/opportunity-parameter/opportunity-parameter.model';
import { AuthService } from '@core/auth/auth.service';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { StaticField, StaticFieldFilterRequest } from '@core/api/static-field/static-field.model';
import { StaticFieldEnum } from '@core/api/static-field/static-field.enum';
import { ValidateParticipant } from '../input-participant/input-participant.validator';
import { SystemUserAdapter } from '@core/api/api.adapter';
import { scrollToError } from '../../utils/scroll-to-error';
import { ToastService } from 'src/app/shared/services/toast.service';
import { opportunityCopySummary } from '@shared/utils';
import { SharedModule } from '@shared/modules';
import { Icon } from '@shared/enums';
import { InputOpportunityServicesComponent } from '../input-opportunity-services/input-opportunity-services.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputParticipantComponent } from '../input-participant/input-participant.component';
import { SelectOpportunityTypeComponent } from '../select-opportunity-type/select-opportunity-type.component';
import { InputDynamicFieldValuesComponent } from '../input-dynamic-field-values/input-dynamic-field-values.component';
import { SelectCurrencyComponent } from '../select-currency/select-currency.component';
import { CurrencyReplacePipe } from '@shared/pipes';
import { SelectOpportunityStatusComponent } from '../select-opportunity-status/select-opportunity-status.component';
import { isEqual, cloneDeep } from 'lodash';
import { SelectAccountComponent } from '../select-account/select-account.component';
import { InputSliderComponent } from '../input-slider/input-slider.component';

@Component({
  selector: 'net-opportunity-form[account]',
  templateUrl: './opportunity-form.component.html',
  styleUrls: ['./opportunity-form.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    InputOpportunityServicesComponent,
    InputParticipantComponent,
    SelectOpportunityTypeComponent,
    InputDynamicFieldValuesComponent,
    SelectCurrencyComponent,
    SelectOpportunityStatusComponent,
    CurrencyReplacePipe,
    SelectAccountComponent,
    InputSliderComponent
  ],
  providers: [provideNgxMask(), NgxMaskPipe]
})
export class OpportunityFormComponent implements OnInit, AfterViewInit, OnDestroy {

  icSave = Icon.IC_SAVE;
  icClose = Icon.IC_TWOTONE_CLOSE;
  icList = Icon.SOLID_LIST_ALT;
  icEye = Icon.IC_REMOVE_RED_EYE;
  icInfo = Icon.IC_INFO_OUTLINE;
  icWarning = Icon.MDI_ALERT_CIRCLE;
  icDownload = Icon.MDI_DOWNLOAD;
  icPdfOutLine = Icon.MDI_FILE_PDF_OUTLINE;
  icPdf = Icon.IC_TWOTONE_PICTURE_AS_PDF;
  icCopy = Icon.AKAR_ICONS_COPY;
  icTextSummaryLtr = Icon.OOUI_SUMMARY_LTR;
  icDelete = Icon.IC_TWOTONE_DELETE;

  guid = Guid;

  @Input() loading = false;
  @Input() account: Account = null;
  @Input() isAccountEnabled: boolean;
  @Input() contact: Contact = null;

  @Output() save = new EventEmitter<OpportunityRequest>();
  @Output() isContract = new EventEmitter<boolean>();
  @Output() emitFinancialInquiry = new EventEmitter<boolean>();
  @Output() emitMailRequest = new EventEmitter<OpportunityMailRequest>();
  @Output() emitDelete = new EventEmitter<string>();

  @Input()
  set formChanged(value: boolean) {
    this._formChanged = value;
  }
  get formChanged(): boolean {
    return this._formChanged;
  }

  @Output() isFormChange = new EventEmitter<boolean>();
  initialFormValue: any;
  isInitialized = false;
  private _formChanged = false;

  form: UntypedFormGroup;
  terminationForm: UntypedFormGroup;
  lostInformationForm: UntypedFormGroup;
  expectedDateForm: UntypedFormGroup = this.formBuilder.group({
    expectedDate: [null, Validators.required],
  });
  reasonFormFields = false;
  isPage = false;
  isDialog = false;
  hasContractUpdatePermission = true;
  hasOpportunityUpdatePermission = false;
  hasOpportunityUpdateAllPermission = false;
  hasOpportunityOfferPdfPermission = false;
  hasOpportunityCopyPermission = false;
  hasOpportunityCopySummaryPermission = false;
  hasGeneralConditionsPermission = false;
  hasContractAdmin = false;
  isSalesman = false;
  isFinanceUser = false;
  hasOpportunityDeletePermission = false;
  openExpectedStartDialog = false;
  today = moment();
  max = moment().endOf('year').add(1, 'year');
  hasOfferPdf: boolean | string | number | SalesOrganizationSettingInfoValue[];
  hasCopySummaryUsage: boolean | string | number | SalesOrganizationSettingInfoValue[];
  hasGeneralConditions: boolean | string | number | SalesOrganizationSettingInfoValue[];
  hasSendingMailWithOpportunity: boolean | string | number | SalesOrganizationSettingInfoValue[];
  currency = CurrencyType;
  opportunityStatus = OpportunityStatusType;
  isOpportunityStatusLost = false;
  lostReasonTypes: OpportunityLostReasonType[] = [];
  participantMode = InputParticipantMode;
  opportunityTypeOption = OpportunityTypeOption;
  opportunityExpectedDateHistory: OpportunityExpectedDateHistory[];
  opportunityProceses = OpportunityProcessTypeEnum;
  opportunityProcess: OpportunityProcessTypeEnum;
  opportunityCurrencyExchange: TransactionCurrencyExchange;
  defaultCurrency: TransactionCurrency;
  selectOpportunityTypeFilter = null;
  activeUser: User;
  organizations: Organization[];
  organizationMainServiceService: any[];
  staticFields: StaticField[] = [];
  staticFieldEnum = StaticFieldEnum;
  revenueTypes: RevenueType[] = [];
  selectedServiceIds: string[] = [];
  openSeparator = false;

  offerPdfAction: 'save' | 'download' | 'preview';
  opportunityParameterId: string;
  hasFinancialInquiry = false;
  financialInquiryLoading = false;
  actionLoading = false;
  serviceLoading = false;
  DynamicFieldEntityId = DynamicFieldEntityId;
  DynamicFieldEntityAsString = DynamicFieldEntityAsString;
  opportunityParameters: OpportunityParameter[];
  dynamicFieldMode: 'add' | 'edit';
  dynamicFields: DynamicField[];
  templateStorage: {
    templateId: string;
    template: string;
  }[] = [];
  AccountTypeString = AccountTypeString;

  clearExpectedStartMonthValidation = false;

  dialogRef: MatDialogRef<any>;

  translations: { [key: string]: string };

  @ViewChild('terminationDialog', { static: true }) terminationDialog: TemplateRef<HTMLElement>;
  @ViewChild('lostInformationDialog', { static: true }) lostInformationDialog: TemplateRef<HTMLElement>;
  @ViewChild('expectedDateDialog', { static: true }) expectedDateDialog: TemplateRef<HTMLElement>;

  private readonly ngxMaskPipe = inject(NgxMaskPipe)
  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private store: Store,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private currencyService: CurrencyService,
    private translate: TranslateService,
    private currencyExchangeService: CurrencyExchangeService,
    private financialInquiryService: FinancialInquiryService,
    private organizationMainServiceServiceService: OrganizationMainServiceServiceService,
    private opportunityExpectedDateHistoryService: OpportunityExpectedDateHistoryService,
    private opportunityParameterService: OpportunityParameterService,
    private opportunityCopySummaryService: OpportunityCopySummaryService,
    private authService: AuthService,
    private router: Router,
    private oppService: opportunityService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private revenueTypeService: RevenueTypeService,
    private staticFieldService: StaticFieldService
  ) {

    this.getRevenueTypes();
    // Create contact form
    this.form = this.formBuilder.group({
      name: [null, Validators.maxLength(200)],
      account: [this.account, Validators.required],
      participants: [null],
      organization: [{ value: null, disabled: true }],
      opportunityType: [null, Validators.required],
      opportunityStatus: [null],
      currency: [null],
      probabilityRate: [50],
      expectedStartMonth: [null, Validators.required],
      expectedRevenue: [{ value: null, disabled: true }],
      totalMonthlyRevenue: [{ value: null, disabled: true }],
      totalForOnceRevenue: [{ value: null, disabled: true }],
      services: [null, { validators: [InputOpportunityServiceValidators.required] }],
      description: [null],
      dynamicFieldValues: [null],
      useGeneralConditions: [true],
      template: [null],
      generalConditionsTemplate: [null]
    }, { updateOn: 'change' });

    // Create termination form
    this.terminationForm = this.formBuilder.group({
      reason: [null, Validators.required],
      status: [null, Validators.required],
      lostReasonType: [null, Validators.required],
      failureDescription: [null, Validators.required],
    });

    // Create lostInformation form
    this.lostInformationForm = this.formBuilder.group({
      lostReasonType: [null, Validators.required],
      failureDescription: [null, Validators.maxLength(180)]
    });

    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async user => {
      this.activeUser = user;

      await this.getStaticFields();
    });

    combineLatest([
      this.store.select(hasUserPermission(Permission.ACCOUNT_FILTER_SALES_ORGANIZATION)),
      this.store.select(hasUserPermission(Permission.FINANCIAL_INQUIRY_INSERT)),
      this.store.select(hasUserPermission(Permission.FINANCIAL_INQUIRY_UPDATE)),
      this.store.select(hasUserPermission(Permission.CONTRACT_UPDATE)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_UPDATE)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_UPDATE_ALL)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_OFFER_PDF)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_COPY)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_COPY_SUMMARY)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_EDIT_GENERAL_CONDITIONS)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_DELETE))
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      ([perm1, perm2, perm3, perm4, perm5, perm6, perm7, perm8, perm9, perm10, perm11]) => {
        this.hasContractAdmin = perm1;
        this.isSalesman = perm2;
        this.isFinanceUser = perm3;
        this.hasContractUpdatePermission = perm4;
        this.hasOpportunityUpdatePermission = perm5;
        this.hasOpportunityUpdateAllPermission = perm6;
        this.hasOpportunityOfferPdfPermission = perm7;
        this.hasOpportunityCopyPermission = perm8;
        this.hasOpportunityCopySummaryPermission = perm9;
        this.hasGeneralConditionsPermission = perm10;
        this.hasOpportunityDeletePermission = perm11;
      }
    );

    this.form.get('opportunityType').valueChanges.subscribe(value => {
      const opportunityStatus = this.form.get('opportunityStatus');
      const account = this.form.get('account').value ?? this.account;

      if (account) {
        if (value && value === OpportunityTypeOption.VOLUME_DEVELOPMENT || this.opportunityProceses.BASIC === this.opportunityProcess) {
          this.syncOrganizationMainServiceService();
          opportunityStatus.setValidators(Validators.required);
        } else {
          this.syncOrganizationMainServiceService();
          opportunityStatus.clearValidators();
          opportunityStatus.updateValueAndValidity();
        }
      }

    });

    this.expectedDateForm.get('expectedDate').valueChanges.pipe(
      debounceTime(300),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => {
      if (moment(value) > moment(this.today).add(1, 'years')) {
        this.expectedDateWarning().then((result) => {
          if (!result.value) {
            this.expectedDateForm.get('expectedDate').patchValue(this.opportunity ? this.opportunity.expectedStartMonth : this.today,
              { emitEvent: false });
          }
        });
      }
    });

    this.form.get('expectedStartMonth').valueChanges.subscribe(value => {
      if (moment(value) > moment(this.today).add(1, 'years') && this.openExpectedStartDialog) {
        this.expectedDateWarning().then((result) => {
          if (!result.value) {
            this.form.get('expectedStartMonth').patchValue(this.opportunity ? this.opportunity.expectedStartMonth : this.today,
              { emitEvent: false });
          }
        });
      }
      if (this.opportunity && !this.openExpectedStartDialog) {
        this.openExpectedStartDialog = true;
      }
      this.getCurrencyExchange(this.form.get('currency').value);
    });

    this.form.get('currency').valueChanges.subscribe((currency: TransactionCurrency) => {
      this.getCurrencyExchange(currency);
    });

    const templateForm = this.form.get('template');
    this.form.get('useGeneralConditions').valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((booleanValue: boolean) => {
      if (booleanValue) {
        templateForm.clearValidators();
      } else {
        templateForm.setValidators(Validators.required);
      }

      templateForm.updateValueAndValidity();
    });

    this.form.get('generalConditionsTemplate').valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((template: string) => {
      const chosenTemplate = this.form.get('template').value;
      const cachedTemplateFound = this.templateStorage.find(item => item.templateId === chosenTemplate);

      if (cachedTemplateFound) {
        cachedTemplateFound.template = template;
      }
    });

    this.currencyService.getDefaultCurrency().subscribe(currency => this.defaultCurrency = currency.data);

    this.translate.get(['OPPORTUNITY', 'OpportunityType', 'TransactionCurrency', 'RevenueType', 'GENERAL']).subscribe(translations => {
      this.translations = {
        ...translations.OPPORTUNITY,
        ...translations.OpportunityType,
        ...translations.TransactionCurrency,
        ...translations.RevenueType,
        ...translations.GENERAL
      };
    });
  }

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  private _opportunity: Opportunity = undefined;

  get opportunity(): Opportunity {
    return this._opportunity;
  }

  @Input()
  set opportunity(opportunity: Opportunity) {
    this._opportunity = opportunity;
    this.openExpectedStartDialog = !opportunity;
    if (opportunity) {
      this.dynamicFieldMode = 'edit';
      const participants: Participants = {
        contacts: opportunity.opportunityContacts.map(item => item.contact),
        systemUsers: opportunity.opportunitySystemUsers.filter(item => !!item.systemUser).map(item => item.systemUser)
      };

      const services: OpportunityService[] = opportunity.opportunityInfos.map(info => {
        return {
          opportunityInfoId: info.opportunityInfoId,
          metric: info.metric,
          revenue: info.revenue,
          serviceId: info.serviceId,
          revenueTypeId: info.revenueTypeId,
          unit: info.serviceUnit,
          organizationMainServiceServiceId: info.organizationMainServiceServiceId,
          detail: info.detail,
          unitPrice: info.unitPrice
        };
      });
      this.form.patchValue({
        participants,
        services,
        name: opportunity.name,
        organization: opportunity.organization.organizationId,
        currency: opportunity.transactionCurrency,
        probabilityRate: opportunity.probabilityRate,
        expectedRevenue: opportunity.expectedRevenue,
        totalMonthlyRevenue: opportunity.totalMonthlyRevenue,
        totalForOnceRevenue: opportunity.totalForOnceRevenue,
        description: opportunity.description,
        opportunityType: opportunity.opportunityTypeId,
        opportunityStatus: opportunity.opportunityStatusId,
        dynamicFieldValues: opportunity.dynamicFieldValues?.filter(field => !field.arrayFlag) ?? [],
        useGeneralConditions: opportunity.customConditions ? false : true,
        template: this.extractOpportunityParameterId(opportunity.customConditions, 'getValue'),
        generalConditionsTemplate: this.extractOpportunityParameterId(opportunity.customConditions, 'removeValue')
      });

      this.getSelectedServiceId();

      this.form.patchValue({
        expectedStartMonth: opportunity.expectedStartMonth ?? this.today
      }, { emitEvent: false });

      this.opportunityProcess = this.getOpportunityProcess();
      if (opportunity.opportunityTypeId !== OpportunityTypeOption.VOLUME_DEVELOPMENT) {
        if (this.opportunityStatus.ACTIVE === opportunity.opportunityStatusId && this.opportunityProceses.DETAILED === this.opportunityProcess) {
          this.isContract.emit(true);
        }

        if (opportunity.opportunityId !== Guid.EMPTY && ((this.opportunityStatus.CREATED !== opportunity?.opportunityStatusId
          && this.opportunityProceses.DETAILED === this.opportunityProcess) ||
          (this.opportunity?.createdUser?.userId !== this.activeUser?.userId && !this.hasOpportunityUpdateAllPermission))) {
          this.form.disable();
        } else {
          this.form.enable({ emitEvent: true, onlySelf: true });
        }
      } else {
        if (this.opportunity?.createdUser?.userId !== this.activeUser?.userId && !this.hasOpportunityUpdateAllPermission) {
          this.form.disable();
        }
      }

      if (opportunity.account) {
        this.form.get('account').setValue(opportunity.account);
        this.form.get('account').disable();

        combineLatest([
          this.store.select(getSalesOrganizationSettingValue(
            SalesOrganizationAdvancedSettingType.OPPORTUNITY_COPY_SUMMARY_USAGE, opportunity.account.salesOrganizationId
          )),
          this.store.select(getSalesOrganizationSettingValue(
            SalesOrganizationAdvancedSettingType.OPPORTUNITY_GENERAL_CONDITIONS, opportunity.account.salesOrganizationId
          ))
        ]).pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(([copySummaryUsage, generalConds]) => {
            this.hasCopySummaryUsage = copySummaryUsage;
            this.hasGeneralConditions = generalConds;
          });
      }

      if (this.opportunityProcess === this.opportunityProceses.BASIC && this.isPage) {
        if (this.hasOpportunityOfferPdfPermission) {
          this.getSalesOrganizationSetting();
        }
      }
    } else {
      this.form.get('expectedStartMonth').patchValue(this.today);
      this.dynamicFieldMode = 'add';

      this.getSelectedServiceId();

    }

    if (opportunity && opportunity.opportunityId === Guid.EMPTY) {
      this._opportunity = null;
      this.dynamicFieldMode = 'edit';
    }
  }

  private _systemUserStructure: SystemUserStructure = undefined;

  @Input()
  get systemUserStructure(): SystemUserStructure {
    return this._systemUserStructure;
  }

  set systemUserStructure(systemUserStructure: SystemUserStructure) {
    this._systemUserStructure = systemUserStructure;
  }

  getCurrencyExchange(currency: TransactionCurrency) {
    if (currency && this.form.get('expectedStartMonth').value) {
      this.searchCurrencyExchange(currency.transactionCurrencyId, moment(this.form.get('expectedStartMonth').value).format(API_DATE_FORMAT));
    }
  }

  ngOnInit() {
    // Watch account and set organization input state
    this.form.get('account').valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next && next !== prev) {
          this.syncOrganization(next.salesOrganizationId ?? next.salesOrganization.salesOrganizationId);

          this.opportunityProcess = this.getOpportunityProcess();

          const opportunityStatus = this.form.get('opportunityStatus');

          if (this.opportunityProceses.BASIC === this.opportunityProcess) {
            opportunityStatus.setValidators(Validators.required);
          } else {
            opportunityStatus.clearValidators();
            opportunityStatus.updateValueAndValidity();
          }

          this.store.select(getSalesOrganizationSettingValue(
            SalesOrganizationAdvancedSettingType.SENDING_MAIL_WITH_OPPORTUNITY, next.salesOrganizationId
          )).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(sendingMailWithOpportunity =>
            this.hasSendingMailWithOpportunity = sendingMailWithOpportunity
          );

          this.opportunityParameterService.search({ salesOrganizationId: next.salesOrganizationId }).pipe(
            map((response) => response.data)
          ).subscribe((data) => (this.opportunityParameters = data));

        }

        const organization = this.form.get('organization');
        const opportunityType = this.form.get('opportunityType');

        if (!this.opportunity || (this.opportunity && this.opportunity.opportunityId === Guid.EMPTY)) {
          organization.enable();
          opportunityType.enable({ onlySelf: true, emitEvent: false });
          organization.patchValue(null, { emitEvent: false });
          opportunityType.patchValue(null, { emitEvent: false });
        } else {
          organization.disable();
          opportunityType.disable({ onlySelf: true, emitEvent: false });
        }
      });

    // If account given with input, disable account changes.
    if (this.account) {
      this.form.get('account').setValue(this.account);

      if (!this.isAccountEnabled) {
        this.form.get('account').disable();
      }

    }

    if (this.contact) {
      this.setParticipants([this.contact]);
    }
  }

  ngAfterViewInit(): void {
    if (!this.isInitialized) {
      this.initialFormValue = this.form.getRawValue();
      this.isInitialized = true;
      this.formChangeDetection();
    }
  }

  onSelectionChange() {
    this.setParticipants([]);
  }

  setParticipants(contacts: Contact[]) {

    const control = this.form.get('participants');

    const participants: Participants = {
      contacts,
      systemUsers: control.value?.systemUsers ?? []
    };

    control.patchValue(participants);

  }

  searchCurrencyExchange(transactionCurrencyId: string, startDate: string) {
    this.currencyExchangeService.search({
      orderBy: 'startDate',
      orderType: 'dESC',
      page: 1,
      pageSize: 1,
      filter: {
        transactionCurrencyId,
        startDate
      }
    }).subscribe((currencyExchangeResponse) => this.opportunityCurrencyExchange = currencyExchangeResponse.data.results[0]);
  }

  getSalesOrganizationSetting() {
    this.store.select(getSalesOrganizationSettingValue(
      SalesOrganizationAdvancedSettingType.OPPORTUNITY_OFFER, this.account?.salesOrganizationId
    )).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(booleanValue => {
        this.hasOfferPdf = booleanValue;
      });
  }

  onSubmit(type?: string) {
    // If form is valid, emit save event
    if (this.form.valid) {
      const opportunityStatus = this.form.get('opportunityStatus');
      const opportunityType = this.form.get('opportunityType');

      if (
        (this.opportunityProcess === OpportunityProcessTypeEnum.BASIC &&
          opportunityStatus.value === OpportunityStatusType.LOST) ||
        (this.opportunityProcess === OpportunityProcessTypeEnum.DETAILED &&
          opportunityStatus.value === OpportunityStatusType.LOST &&
          opportunityType.value === OpportunityTypeOption.VOLUME_DEVELOPMENT)
      ) {
        this.oppService.getLostReasonType().subscribe((response) => {
          if (response.success) {
            this.lostInformationForm.reset();
            this.lostReasonTypes = response.data;
            this.dialogRef = this.dialog.open(this.lostInformationDialog, {
              autoFocus: false,
              width: '45em'
            });
          }
        });
      } else {
        if (!this.form.get('currency')?.value || this.defaultCurrency.transactionCurrencyId === this.form.get('currency')?.value?.transactionCurrencyId) {
          this.emitOpportunity(type);
          setTimeout(() => {
            this.resetFormChanges();
          }, 1500);
        } else {
          this.currencyExchangeService.search({
            orderBy: 'startDate',
            orderType: 'dESC',
            page: 1,
            pageSize: 1,
            filter: {
              transactionCurrencyId: this.form.get('currency').value.transactionCurrencyId,
              startDate: moment(this.form.get('expectedStartMonth').value).format(API_DATE_FORMAT)
            }
          }).subscribe((currencyExchangeResponse) => {
            if (currencyExchangeResponse.data.results.length > 0) {
              this.emitOpportunity(type);
              setTimeout(() => {
                this.resetFormChanges();
              }, 1500);
            } else {
              this.showInvalidCurrencyDateError();
            }
          });
        }
      }
    } else {
      this.controlFormValidity();
    }
  }

  async delete() {
    const result = await this.showDeleteDialog();

    if (result) {
      this.emitDelete.emit(this.opportunity?.opportunityId);
    }
  }

  private async showDeleteDialog(): Promise<boolean> {
    const result = await Swal.fire({
      html: this.translate.instant('OPPORTUNITY.OPPORTUNITY_WILL_BE_DELETED', { opportunityname: this.opportunity?.name }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F44336',
      confirmButtonText: this.translate.instant('GENERAL.YES'),
      cancelButtonText: this.translate.instant('GENERAL.NO')
    });

    return (result.value as boolean) || false;
  }

  showInvalidCurrencyDateError() {
    Swal.fire({
      html: this.translate.instant('OPPORTUNITY.NO_EXCHANGE_RATE_FOUND_FOR_THE_EXPECTED_START_DATE_PLEASE_UPDATE_THE_EXCHANGE_RATE'),
      icon: 'warning',
      confirmButtonText: this.translate.instant('GENERAL.OK'),
    }).then();
  }

  // Calculate total expected revenue from service revenues
  calculateExpectedRevenue(services: OpportunityService[]) {
    let totalRevenue = 0.0;
    let monthlyRevenue = 0.0;
    let forOnceRevenue = 0.0;
    if (services) {
      services.forEach(service => {
        totalRevenue += Number(service.revenue);
        if (service?.serviceId) {
          this.selectedServiceIds.push(service.serviceId);
          this.cdr.detectChanges();
        }
        if (service.revenueTypeId === RevenueTypeEnum.MONTHLY) {
          monthlyRevenue += Number(service.revenue);
        }
        if (service.revenueTypeId === RevenueTypeEnum.FORONCE) {
          forOnceRevenue += Number(service.revenue);
        }
      });
      this.form.get('expectedRevenue').setValue(Math.round((totalRevenue + Number.EPSILON) * 100) / 100);
      this.form.get('totalMonthlyRevenue').setValue(Math.round((monthlyRevenue + Number.EPSILON) * 100) / 100);
      this.form.get('totalForOnceRevenue').setValue(Math.round((forOnceRevenue + Number.EPSILON) * 100) / 100);
    }
  }

  syncOrganization(salesOrganizationId: string, accountId?: string) {
    const filters: OrganizationMainServiceServiceRequest = {
      salesOrganizationId,
      organizationService: true
    };
    if (!this.hasContractAdmin) {
      filters.systemUserId = this.activeUser.userId;
    }

    if (this.form.get('opportunityType').value === OpportunityTypeOption.VOLUME_DEVELOPMENT) {
      filters.accountId = accountId;
    }

    this.organizationMainServiceServiceService.search(filters).subscribe(response => {
      if (response.data?.length > 0) {
        const tempOrganizations = response.data?.map(item => item.organizations).flat();
        this.organizations = [...new Set(tempOrganizations.map(({ organizationId }) => organizationId))]
          .map(item => tempOrganizations.find(({ organizationId }) => organizationId === item));

        if (1 === this.organizations?.length) {
          this.form.get('organization').patchValue(this.organizations[0].organizationId);
        }
      }
    });
  }

  getOpportunityProcess(): OpportunityProcessTypeEnum {
    if (!!this.opportunity?.opportunityProcessTypeId) {
      return this.opportunity.opportunityProcessTypeId;
    } else {
      return this.account ? this.account.opportunityProcessType?.opportunityProcessTypeId :
        this.form.get('account').value?.opportunityProcessType.opportunityProcessTypeId;
    }
  }

  syncOrganizationMainServiceService() {
    const filters: OrganizationMainServiceServiceRequest = {
      accountId: this.account ? this.account.accountId : this.form.get('account').value.accountId,
      ...this.form.get('organization').value ? { organizationId: this.form.get('organization').value } : {},
      ...this.form.get('opportunityType').value ? { opportunityTypeId: this.form.get('opportunityType').value } : {},
      organizationService: true
    };

    if (!this.hasContractAdmin) {
      filters.systemUserId = this.activeUser.userId;
    }

    this.organizationMainServiceServiceService.search(filters).subscribe(response => {
      if (response.data?.length > 0) {
        const selectedServices = this.form.get('services').value?.map(service => service.serviceId);
        const filteredOMSS = response.data.filter(omss => {
          omss.organizations = omss.organizations?.filter(organization => {
            organization.mainServices = organization.mainServices?.filter(mainService => {
              mainService.services = mainService.services?.filter(service => service.enabled || selectedServices?.includes(service.serviceId));
              return mainService.services?.length > 0;
            });
            return organization.mainServices?.length > 0;
          });
          return omss.organizations?.length > 0;
        });
        this.organizationMainServiceService = filteredOMSS;

        this.cdr.detectChanges();
      }
    });
  }

  backToDraft() {
    Swal.fire({
      title: this.translate.instant('GENERAL.WARNING'),
      html: this.translate.instant('OPPORTUNITY.PRICE_LIST_AND_CONTRACT_APPROVALS_DOCUMENTS_OF_THE_OPPORTUNITY_WILL_BE_DELETED_DO_YOU_WANT_TO_CONTINUE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (result.value) {
        this.emitOpportunity('draft');
        this.dialogRef.close();
      }
    });
  }

  openTerminationDialog() {
    this.terminationForm.reset();
    this.reasonFormFields = false;
    this.isOpportunityStatusLost = false;

    // Open dialog by selected status
    this.dialogRef = this.dialog.open(this.terminationDialog, { autoFocus: false });
  }

  openExpectedDateDialog() {
    this.actionLoading = true;

    this.opportunityExpectedDateHistoryService.search({ opportunityIds: [this.opportunity.opportunityId] }).subscribe(response => {
      if (response.success) {
        this.opportunityExpectedDateHistory = response.data
          .map((history, index) => ({
            ...history,
            newDate: 0 === index ? this.opportunity.expectedStartMonth : response.data[index - 1].expectedStartMonth
          }));

        this.expectedDateForm.reset();
        this.expectedDateForm.patchValue({ expectedDate: this.opportunity.expectedStartMonth }, { emitEvent: false });
        this.dialogRef = this.dialog.open(this.expectedDateDialog, { autoFocus: false });
      }
    }).add(() => this.actionLoading = false);
  }

  checkExpectedDateRules(): boolean {
    const activeStatus = [
      OpportunityStatusType.OFFER,
      OpportunityStatusType.BUSINESS_DEVELOPMENT,
      OpportunityStatusType.APPROVAL_PROCESS,
      OpportunityStatusType.APPROVED,
      OpportunityStatusType.CONTRACT as string
    ].includes(this.opportunity?.opportunityStatus?.opportunityStatusId);

    return this.opportunity && activeStatus && this.opportunityProceses.DETAILED === this.opportunityProcess;
  }

  onSubmitExpectedDate() {
    // Touch all form fields and trigger validate
    this.expectedDateForm.markAllAsTouched();

    if (this.expectedDateForm.valid) {
      this.emitOpportunity('expectedDate');
      this.dialog.closeAll();
    }
  }

  onSubmitTerminationReason() {
    this.offerPdfAction = 'save';
    switch (this.terminationForm.get('reason').value) {
      case 1:
        this.openExpectedDateDialog();
        break;

      case 2:
        this.backToDraft();
        break;

      case 3:
        // Touch all form fields and trigger validate
        this.terminationForm.markAllAsTouched();

        if (this.terminationForm.valid) {
          Swal.fire({
            title: this.translate.instant('GENERAL.WARNING'),
            html: this.translate.instant('OPPORTUNITY.ALL_DATA_OF_THE_OPPORTUNITY_WILL_BE_SAVED_AS_READONLY_THIS_ACTION_CANNOT_BE_UNDONE_DO_YOU_WANT_TO_CONTINUE'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4caf50',
            confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
            cancelButtonText: this.translate.instant('GENERAL.CANCEL')
          }).then((result) => {
            if (result.value) {
              this.emitOpportunity('failure');
              this.dialogRef.close();
            }
          });
        }
        break;
    }
  }

  onSubmitLostInformation() {
    this.lostInformationForm.markAllAsTouched();

    if (this.lostInformationForm.valid) {
      this.emitOpportunity('lostInformation');
      this.dialogRef.close();
    }
  }

  onChangeStatus(event: MatSelectChange) {
    const lostReasonType = this.terminationForm.get('lostReasonType');
    const failureDescription = this.terminationForm.get('failureDescription');

    if (event.value === this.opportunityStatus.LOST) {
      this.isOpportunityStatusLost = true;

      lostReasonType.setValidators([Validators.required]);
      failureDescription.clearValidators();

      this.oppService.getLostReasonType().subscribe(response => {
        if (response.success) {
          const data = response.data;
          this.lostReasonTypes = data.sort((a, b) => a.orderBy - b.orderBy);
        }
      });
    } else {
      this.isOpportunityStatusLost = false;

      lostReasonType.clearValidators();
      failureDescription.setValidators([Validators.required]);
    }

    lostReasonType.updateValueAndValidity();
    failureDescription.updateValueAndValidity();
  }

  onChangeTemplate(event: MatSelectChange) {
    const generalConditionsTemplateForm = this.form.get('generalConditionsTemplate');
    const cachedTemplateFound = this.templateStorage.find(item => item.templateId === event.value);

    if (cachedTemplateFound) {
      generalConditionsTemplateForm.setValue(cachedTemplateFound.template);
    } else {
      const chosenTemplate = this.opportunityParameters.find(item => item.opportunityParameterId === event.value);
      generalConditionsTemplateForm.setValue(chosenTemplate.generalConditions);

      this.templateStorage.push({
        templateId: event.value,
        template: chosenTemplate.generalConditions
      });
    }
  }

  openFinancialInquiryDialog() {
    this.financialInquiryLoading = true;
    this.financialInquiryService.search({
      filter: {
        customerId: this.account.customerId,
        opportunityId: this.opportunity.opportunityId,
        activeFlag: true
      }
    })
      .subscribe(response => {
        if (response.success) {
          let tempFinancialInquiry: FinancialInquiry;

          if (response.data.results.length > 0) {
            tempFinancialInquiry = response.data.results.find(item => item.activeFlag === true);
          }

          const data: FinancialInquiryFormDialog = {
            customer: this.account.customer,
            financialInquiry: tempFinancialInquiry ? tempFinancialInquiry : null,
          };

          this.dialogRef = this.dialog.open(FinancialInquiryFormDialogComponent, {
            autoFocus: false,
            data
          }).addPanelClass('cdk-full-overlay');

          this.dialogRef
            .afterClosed()
            .subscribe((confirm: boolean) => {
              if (confirm) {
                this.emitFinancialInquiry.emit(tempFinancialInquiry ? true : false);
              }
            });
        }
      }).add(() => this.financialInquiryLoading = false);
  }

  getFxFlex(val1: any, val2: any) {
    if (val1 > 0 && val2 > 0) {
      return 80;
    }
    return 90;
  }

  unformat(value: any): number {
    if (value && typeof value !== 'number') {
      value = value
        .replace(/\./g, '')
        .replace(/,/g, '.')
        .replace(/[^0-9.]+/, '');
    }

    return coerceNumberProperty(parseFloat(value));
  }

  private isEmpty(item: OpportunityService) {
    return !item.serviceId && !item.unit && !item.metric && !item.revenue && !item.organizationMainServiceServiceId;
  }

  private emitOpportunity(type?: string) {
    const transactionCurrencyId = this.form.get('currency')?.value?.transactionCurrencyId;

    const opportunityInfos: OpportunityInfo[] = this.form.get('services').value
      .filter(service => false === this.isEmpty(service)).map((service: OpportunityService) => {
        const info: OpportunityInfo = {
          opportunityInfoId: service.opportunityInfoId,
          serviceId: service.serviceId,
          serviceUnitId: service.unit.serviceUnitId,
          ...transactionCurrencyId ? { transactionCurrencyId } : {},
          metric: this.castingMetric(service.metric),
          revenueTypeId: service.revenueTypeId,
          revenue: service.revenue,
          organizationMainServiceServiceId: service.organizationMainServiceServiceId,
          ...service.detail ? { detail: service.detail } : {},
          ...service.unitPrice ? { unitPrice: service.unitPrice } : {}
        };

        if (this.opportunity) {
          info.opportunityId = this.opportunity.opportunityId;
        }

        return info;
      });

    const opportunityName = this.form.get('name').value;
    const request: OpportunityRequest = {
      ...opportunityName ? { name: opportunityName } : {},
      accountId: this.form.get('account').value.accountId,
      organizationId: this.form.get('organization').value,
      ...transactionCurrencyId ? { transactionCurrencyId } : {},
      opportunityInfos,
      opportunityStatusId: this.opportunity ? this.opportunity.opportunityStatusId : OpportunityStatusType.CREATED,
      opportunityTypeId: this.form.get('opportunityType').value,
      probabilityRate: this.form.get('probabilityRate').value,
      expectedStartMonth: this.form.get('expectedStartMonth').value,
      expectedRevenue: this.form.get('expectedRevenue').value,
      totalForOnceRevenue: this.form.get('totalForOnceRevenue').value,
      totalMonthlyRevenue: this.form.get('totalMonthlyRevenue').value,
      description: this.form.get('description').value,
      localTotalForOnceRevenue: this.opportunity?.localTotalForOnceRevenue,
      localTotalMonthlyRevenue: this.opportunity?.localTotalMonthlyRevenue,
      exchangeRate: this.opportunity?.exchangeRate,
      defaultTransactionCurrencyId: this.defaultCurrency.transactionCurrencyId,
      dynamicFieldValues: processDynamicFieldValues(this.form.get('dynamicFieldValues')?.value),
      offerPdfAction: this.offerPdfAction,
      opportunityParameterId: this.opportunityParameterId,
      opportunityLostReasonTypeId: null,
      failureDescription: null,
      customConditions: !this.form.get('useGeneralConditions').value
        ? `<span id='opportunityParameterId'>${this.form.get('template').value}</span>${this.form.get('generalConditionsTemplate').value}`
        : null
    };

    const participants: Participants = this.form.get('participants').value;
    if (participants && participants?.contacts?.length > 0) {
      request.opportunityContacts = participants.contacts;
    }

    if (participants && participants.systemUsers.length > 0) {
      request.opportunitySystemUsers = participants.systemUsers;
    } else {
      request.opportunitySystemUsers = [SystemUserAdapter.fromUser(this.activeUser)];
    }

    if (
      this.form.get('opportunityType').value !== this.opportunityTypeOption.VOLUME_DEVELOPMENT &&
      this.opportunityProceses.DETAILED === this.opportunityProcess
    ) {
      if (type === 'offer') {
        request.opportunityStatusId = this.opportunityStatus.OFFER;
      }

      if (type === 'draft') {
        request.action = 'draft';
      }

      if (type === 'failure') {
        request.opportunityStatusId = this.terminationForm.get('status').value;
        request.opportunityLostReasonTypeId = this.terminationForm.get('lostReasonType').value;
        request.failureDescription = this.terminationForm.get('failureDescription').value;
      }

      if (type === 'expectedDate') {
        request.expectedStartMonth = this.expectedDateForm.get('expectedDate').value;
      }
    } else {
      request.opportunityStatusId = this.form.get('opportunityStatus').value;

      if (type === 'lostInformation') {
        request.opportunityLostReasonTypeId = this.lostInformationForm.get('lostReasonType').value;
        request.failureDescription = this.lostInformationForm.get('failureDescription').value;
      }
    }

    request.expectedStartMonth = moment(request.expectedStartMonth).format(API_DATE_FORMAT);

    // Emit save event with opportunity request object
    this.save.emit(request);
  }

  private formChangeDetection() {
    this.form.valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      const currentValue = this.form.getRawValue();
      const initialValue = cloneDeep(this.initialFormValue);

      const cloneDynamicFieldValues = cloneDeep(currentValue.dynamicFieldValues);
      const cloneInitDynamicFieldValues = cloneDeep(initialValue.dynamicFieldValues);
      const cloneServices = cloneDeep(currentValue.services);
      const cloneInitServices = cloneDeep(initialValue.services);

      currentValue.dynamicFieldValues = null;
      initialValue.dynamicFieldValues = null;
      currentValue.services = null;
      initialValue.services = null;

      const isFormChangeIsEqual = isEqual(initialValue, currentValue);
      const isFormChangeDynamicFieldValues = this.compareDynamicFieldFormValues(cloneDynamicFieldValues, cloneInitDynamicFieldValues);
      const isFormChangeServices = this.compareServices(cloneServices, cloneInitServices);

      const hasChanges = !(isFormChangeIsEqual && isFormChangeServices && isFormChangeDynamicFieldValues);

      if (this.formChanged !== hasChanges) {
        this.formChanged = hasChanges;
        this.isFormChange.emit(hasChanges);
      }
    });
  }

  private compareServices(prevFormValues: OpportunityService[], nextFormValues: OpportunityService[]) {
    if (prevFormValues?.length !== nextFormValues?.length) {
      return false;
    }

    for (let i = 0; i < prevFormValues.length; i++) {
      const field1 = prevFormValues[i];
      const field2 = nextFormValues[i];

      if (!field2) {
        return false;
      }

      if (field1.metric && field1.metric !== field2.metric) {
        return false;
      }
      if (field1.revenueTypeId && field1.revenueTypeId !== field2.revenueTypeId) {
        return false;
      }
      if (field1.serviceId && field1.serviceId !== field2.serviceId) {
        return false;
      }

      if (field1.unit.serviceUnitId && field1.unit.serviceUnitId !== field2.unit.serviceUnitId) {
        return false;
      }

      if (field1.revenue && field1.revenue !== field2.revenue) {
        return false;
      }
    }

    return true;

  }

  private compareDynamicFieldFormValues(prevFormValues: DynamicFieldValue[], nextFormValues: DynamicFieldValue[]) {
    if (prevFormValues?.length !== nextFormValues?.length) {
      return false;
    }

    for (const field1 of prevFormValues) {
      const field2 = nextFormValues.find(field => field.dynamicFieldId === field1.dynamicFieldId);

      if (!field2) {
        return false;
      }

      if (field1.textValue && field1.textValue !== field2.textValue) {
        return false;
      }
      if (field1.nameValue && field1.nameValue?.value !== field2.nameValue?.value) {
        return false;
      }

      if (field1.dateValue) {
        const a = moment(field1.dateValue);
        const b = moment(field2.dateValue);

        if (!a.isSame(b)) {
          return false;
        }
      }
      if (field1.nameValues) {
        if (!this.compareArrays(field1.nameValues, field2.nameValues)) {
          return false;
        }
      }
      if (field1.numberValue && field1.numberValue !== field2.numberValue) {
        return false;
      }
    }

    return true;
  }

  private compareArrays(prev, next) {
    if (prev.length !== next.length) {
      return false;
    }

    const prevValues = prev.map(item => item.value);
    const nextValues = next.map(item => item.value);

    return prevValues.every(value => nextValues.includes(value));
  }

  resetFormChanges() {
    this.initialFormValue = this.form.getRawValue();
    this.formChanged = false;
    this.isFormChange.emit(false);
  }

  private expectedDateWarning() {
    return Swal.fire({
      title: this.translate.instant('GENERAL.WARNING'),
      html: this.translate.instant('OPPORTUNITY.OPPORTUNITY_WILL_HAVE_AN_EXPECTED_START_DATE_OF_MORE_THAN_1_YEARS_DO_YOU_WANT_TO_CONTINUE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.YES'),
      cancelButtonText: this.translate.instant('GENERAL.NO')
    });
  }

  offerPdf(offerPdfAction, opportunityParameterId) {
    this.offerPdfAction = offerPdfAction;
    this.opportunityParameterId = opportunityParameterId;
    if (!this.form.valid) {
      this.emitOpportunity();
    }
    this.onSubmit();
  }

  copyOpportunityConfirm() {
    Swal.fire({
      html: this.translate.instant('OPPORTUNITY.COPY_MESSAGE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#7a7a7a',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (result.value) {

        const copiedOpportunity = {
          opportunityId: Guid.EMPTY,
          createdOn: moment(),
          expectedStartMonth: moment(),
          name: this.opportunity.name,
          probabilityRate: this.opportunity.probabilityRate,
          accountId: this.opportunity.accountId,
          account: this.opportunity.account,
          opportunityContacts: this.opportunity.opportunityContacts,
          organizationId: this.opportunity.organizationId,
          organization: this.opportunity.organization,
          transactionCurrencyId: this.opportunity.transactionCurrencyId,
          transactionCurrency: this.opportunity.transactionCurrency,
          dynamicFieldValues: this.opportunity.dynamicFieldValues,
          opportunityInfos: this.opportunity.opportunityInfos.map(service => {
            delete service.opportunityInfoId;
            delete service.opportunityId;

            return service;
          }),
          description: this.opportunity.description,
          expectedRevenue: this.opportunity.expectedRevenue,
          totalMonthlyRevenue: this.opportunity.totalMonthlyRevenue,
          totalForOnceRevenue: this.opportunity.totalForOnceRevenue,
          opportunitySystemUsers: this.opportunity.opportunitySystemUsers,
          opportunityStatusId: OpportunityStatusType.CREATED,
          opportunityStatus: {
            opportunityStatusId: 'c8eb8829-fcbc-4a39-9377-36d49fc797ec',
            name: 'Created',
            orderBy: 1,
            hierarchy: 1,
            positiveFlag: true,
            colorScheme: {
              colorSchemeId: '524041bc-c210-4f22-8365-9c6847e80446',
              foregroundColor: '#FFFFFF',
              backgroundColor: '#3a5193',
              graphicColor: '#3a5193',
              createdOn: null,
              readOnlyFlag: false
            },
            createdOn: null,
            readOnlyFlag: true,
            code: '1'
          } as OpportunityStatus,
          opportunityTypeId: this.opportunityProcess === OpportunityProcessTypeEnum.BASIC ? this.opportunity.opportunityTypeId : null
        } as Opportunity;

        localStorage.setItem('copiedOpportunity', JSON.stringify(copiedOpportunity));

        this.router.navigate(['/leads', this.opportunity.accountId, 'opportunities', 'new']);
      }
    });
  }

  castingMetric(metric: any) {
    const isTr = this.authService.numberLanguage === 'tr';
    const [thousandsSeparatorSymbol, decimalSymbol] = [(isTr ? '.' : ','), (isTr ? ',' : '.')];
    const metricNumber = Number(metric.toString().replaceAll(thousandsSeparatorSymbol, ''));
    return metricNumber;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  private getStaticFields(): Promise<void> {
    const request: StaticFieldFilterRequest = {
      filter: {
        entityName: 'Opportunity'
      }
    };

    return new Promise<void>((resolve) => {
      this.staticFieldService.search(request).pipe(
        map(response => response.data.results),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(fields => {
        this.staticFields = fields;
        resolve();
      });
    });
  }

  getStaticField(staticFieldId: string) {
    return this.staticFields.find(f => f.entityFieldId === staticFieldId);
  }

  getStaticFieldRequiredFlag(staticFieldId: string): boolean {
    return this.staticFields.find(f => f.entityFieldId === staticFieldId)?.requiredFlag ?? false;
  }

  getStaticFieldActiveFlag(staticFieldId: string): boolean {
    return this.staticFields.find(f => f.entityFieldId === staticFieldId)?.activeFlag ?? false;
  }

  addParticipantsRequired() {
    if (!this.getStaticFieldRequiredFlag(StaticFieldEnum.CONTACTS)) {
      return false;
    }

    this.form.get('participants').addValidators(ValidateParticipant.contactRequired);
    this.form.get('participants').updateValueAndValidity();
    return true;
  }

  getSelectedServiceId() {
    this.form.get('services').valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((services: OpportunityService[]) => {
      this.selectedServiceIds = services.filter(service => service?.serviceId).map(service => service.serviceId);
      this.cdr.detectChanges();
    });
  }

  statusChanges(status: OpportunityStatus) {
    if (this.opportunity && (this.opportunity?.account?.opportunityProcessType?.opportunityProcessTypeId === OpportunityProcessTypeEnum.BASIC
      || this.opportunity.opportunityTypeId === OpportunityTypeOption.VOLUME_DEVELOPMENT) && (!status.positiveFlag)) {
      this.clearExpectedStartMonthValidation = true;
    }
    else {
      this.clearExpectedStartMonthValidation = false;
    }
  }

  getMin() {
    return (this.hasContractAdmin || this.clearExpectedStartMonthValidation) ? null : this.today;
  }

  copySummary() {
    this.opportunityCopySummaryService.list(this.opportunity.account.salesOrganizationId).subscribe(response => {

      const tableRow = opportunityCopySummary(
        response.data,
        this.translations,
        this.opportunity,
        this.translate,
        this.authService,
        this.ngxMaskPipe,
        this.dynamicFields
      );

      const template = `<figure class="table">
                          <table style="background-color: #fff;">
                            <tbody>
                              ${tableRow}
                            </tbody>
                          </table>
                        </figure>`;

      const type = 'text/html';
      const blob = new Blob([template], { type });
      const writeData = [new ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(writeData);

      this.toastService.success(this.translations?.COPY_SUCCESSFUL);
    });
  }

  onDynamicFieldSearch(event: DynamicField[]) {
    this.dynamicFields = event;
  }

  controlFormValidity() {
    this.openSeparator = true;
    this.cdr.detectChanges();
    this.form.markAllAsTouched();
    scrollToError();
    this.openSeparator = false;
  }

  sendMailWithOpportunity() {
    this.controlFormValidity();

    if (this.form.valid) {
      const data = {
        account: this.form.get('account').value,
        contacts: this.form.get('participants')?.value,
        opportunity: this.opportunity,
        opportunityParameters: this.opportunityParameters
      };

      this.dialogRef = this.dialog.open(OpportunitySendingMailDialogComponent,
        {
          autoFocus: false,
          data
        });

      this.dialogRef
        .addPanelClass('confirm-dialog')
        .afterClosed()
        .subscribe((request: OpportunityMailRequest) => {
          if (request) {
            this.emitMailRequest.emit(request);

            this.offerPdfAction = 'save';
            this.onSubmit();
          }
        });
    }
  }

  private extractOpportunityParameterId(html: string, type: string) {
    if (html) {
      const htmlTemplate = new DOMParser().parseFromString(html, 'text/html');
      const opportunityParameterId = htmlTemplate.getElementById('opportunityParameterId');

      if (type === 'getValue') {
        return opportunityParameterId?.textContent;
      } else if (type === 'removeValue') {
        opportunityParameterId?.remove();
        return htmlTemplate.documentElement.outerHTML;
      }
    }

    return null;
  }

  private getRevenueTypes() {
    this.revenueTypeService.list({
      orderBy: 'orderBy',
      orderType: 'asc'
    }).pipe(
      map(response => response.data.results),
      map(revenueTypes => revenueTypes.sort((a, b) => a.orderBy - b.orderBy))
    ).subscribe((revenueTypes) => this.revenueTypes = revenueTypes);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {
  }
}
