<mat-select [placeholder]="placeholder" [tabIndex]="tabIndex" [compareWith]="compareFn" [formControl]="selectCtrl"
    (selectionChange)="onSelectionChange()" [matTooltip]="tooltip" (openedChange)="openedChange($event)"
    panelClass="custom-panel" #permissionSelect disableOptionCentering multiple>
    <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length > 2">
        {{'GENERAL.VALUE_PERMISSION_SELECTED' | translate: { value: selectCtrl.value.length } }}
    </mat-select-trigger>

    <input (keydown)="$event.stopPropagation()" (ngModelChange)="onKey($event)" [(ngModel)]="searchText"
        [ngClass]="isOpened ? 'visible-searchbox': 'invisible-searchbox'" [ngModelOptions]="{standalone: true}"
        class="border-0 outline-none bg-card block w-full my-2 ml-4"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">
    <div class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">{{'GENERAL.SELECT_ALL' | translate}}</button>
        <button mat-raised-button (click)="deselectAll()">{{'GENERAL.REMOVE_ALL' | translate}}</button>
    </div>
    <mat-option [matTooltip]="permission.description" matTooltipShowDelay=500
        *ngFor="let permission of systemPermissions" [disabled]="disabled" [value]="permission">
        {{ permission.description }}
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>