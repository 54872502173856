import { CXChannelType } from '@core/api';
import { IconSVG } from '@shared/enums';

export function getChannelIcon(cxChannelTypeId: string): string | null {
    switch (cxChannelTypeId) {
        case CXChannelType.WHATSAPP:
            return IconSVG.WHATSAPP;
        case CXChannelType.EMAIL:
            return IconSVG.EMAIL;
        case CXChannelType.FACEBOOK:
            return IconSVG.FACEBOOK;
        case CXChannelType.INSTAGRAM:
            return IconSVG.INSTAGRAM;
    }
}
