import {
    DynamicField, DynamicFieldEntityAsString, DynamicFieldValueTypeAsString, Opportunity, OpportunityCopySummary,
    OpportunityCopySummaryNameValue, OpportunityCopySummarySettings
} from '@core/api';
import { getDynamicFieldValueForCopySummary } from './dynamic-entity-fields';
import moment from 'moment';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/auth/auth.service';
import { NgxMaskPipe } from 'ngx-mask';

export function opportunityCopySummary(
    copySummaryInfos: OpportunityCopySummary[],
    translations: any,
    opportunity: Opportunity,
    translate: TranslateService,
    authService: AuthService,
    ngxMaskPipe: NgxMaskPipe,
    dynamicFields: DynamicField[],
): string {

    let tableRow = '';

    copySummaryInfos.forEach(info => {
        let serviceColumn = '';
        let isServiceElement = false;
        let isDynamicFieldRow = false;
        let dfSearchFound: DynamicField;

        const valueObj: OpportunityCopySummaryNameValue = {
            name: null,
            value: null
        };
        const valueList: OpportunityCopySummaryNameValue[] = [];

        switch (info.copySummarySettingId) {
            case OpportunityCopySummarySettings.CUSTOMER:
                valueObj.name = translations?.CUSTOMER;
                valueObj.value = opportunity.account.name;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.OPPORTUNITY_NAME:
                valueObj.name = translations?.OPPORTUNITY_NAME;
                valueObj.value = opportunity.name;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.CONTACTS:
                valueObj.name = translations?.CONTACTS;

                const mappedContacts = opportunity.opportunityContacts.map(contact => `${contact.contact.firstName} ${contact.contact.lastName}`);
                const mappedSystemUsers = opportunity.opportunitySystemUsers.map(systemUser => `${systemUser.systemUser.firstName} ${systemUser.systemUser.lastName}`);
                const combinedNames = mappedContacts.concat(mappedSystemUsers);

                valueObj.value = combinedNames.join('; ');
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.ORGANIZATION:
                valueObj.name = translations?.ORGANIZATION;
                valueObj.value = opportunity.organization.name;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.OPPORTUNITY_TYPE:
                valueObj.name = translations?.OPPORTUNITY_TYPE;
                valueObj.value = translations[opportunity.opportunityType.name];
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.CURRENCY:
                valueObj.name = translations?.CURRENCY;
                valueObj.value = `${translations[opportunity.transactionCurrency.currencyName]
                    } - ${opportunity.transactionCurrency.isoCurrencyCode}`;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.PROBABILITY:
                valueObj.name = translations?.PROBABILITY_OF_REALIZATION;
                valueObj.value = `${opportunity.probabilityRate}%`;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.CREATION_DATE:
                valueObj.name = translate.instant('OPPORTUNITY.CREATION_DATE');
                valueObj.value = moment(opportunity.createdOn).format('DD MMMM YYYY');
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.CLOSE_DATE:
                valueObj.name = translations?.ESTIMATED_REALIZATION_DATE;
                valueObj.value = opportunity.expectedStartMonth;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.DESCRIPTION:
                valueObj.name = translations?.DESCRIPTION;
                valueObj.value = opportunity.description;
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_SERVICE_NAME:
                isServiceElement = true;

                valueObj.name = translations?.SERVICE;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">${_info.service.name ?? ''}</td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_DETAIL:
                isServiceElement = true;

                valueObj.name = translations?.DETAIL;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">${_info.detail ?? ''}</td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_REVENUE_TYPE:
                isServiceElement = true;

                valueObj.name = translations?.REVENUE_TYPE;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">
                  ${translations[_info.revenueType.name] ?? ''}
                </td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_UNIT:
                isServiceElement = true;

                valueObj.name = translations?.UNIT;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">${_info.serviceUnit.name ?? ''}</td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_UNIT_PRICE:
                isServiceElement = true;

                valueObj.name = translations?.UNIT_PRICE;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">
                  ${ngxMaskPipe.transform(_info.unitPrice, 'separator.2', {
                        thousandSeparator: authService.thousandSeparator,
                        decimalMarker: authService.decimalMarker,
                        leadZero: true
                    }) ?? ''
                        } ${getCurrencySymbol(opportunity.transactionCurrency.isoCurrencyCode, 'narrow')}
                </td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_AMOUNT:
                isServiceElement = true;

                valueObj.name = translations?.AMOUNT;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">
                  ${ngxMaskPipe.transform(_info.metric, 'separator.0', {
                        thousandSeparator: authService.thousandSeparator,
                        decimalMarker: authService.decimalMarker
                    }) ?? ''
                        }
                </td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_REVENUE:
                isServiceElement = true;

                valueObj.name = translations?.REVENUE;
                opportunity.opportunityInfos.forEach(_info => {
                    serviceColumn += `
                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">
                  ${ngxMaskPipe.transform(_info.revenue, 'separator.2', {
                        thousandSeparator: authService.thousandSeparator,
                        decimalMarker: authService.decimalMarker,
                        leadZero: true
                    }) ?? ''
                        } ${getCurrencySymbol(opportunity.transactionCurrency.isoCurrencyCode, 'narrow')}
                </td>
              `;
                });
                valueList.push(valueObj);
                break;

            case OpportunityCopySummarySettings.SERVICE_TOTAL:
                const hasDiff = !opportunity.opportunityInfos.every((opportunityInfo, i, array) =>
                    opportunityInfo.revenueTypeId === array[0].revenueTypeId
                );

                if (opportunity.opportunityInfos.length > 1 && hasDiff) {
                    const revenues: OpportunityCopySummaryNameValue[] = [
                        {
                            name: translations?.Recurring,
                            value: `${ngxMaskPipe.transform(opportunity.totalMonthlyRevenue, 'separator.2', {
                                thousandSeparator: authService.thousandSeparator,
                                decimalMarker: authService.decimalMarker
                            }) ?? ''} ${getCurrencySymbol(opportunity.transactionCurrency.isoCurrencyCode, 'narrow')}`
                        },
                        {
                            name: translations['Non-Recurring'],
                            value: `${ngxMaskPipe.transform(opportunity.totalForOnceRevenue, 'separator.2', {
                                thousandSeparator: authService.thousandSeparator,
                                decimalMarker: authService.decimalMarker
                            }) ?? ''} ${getCurrencySymbol(opportunity.transactionCurrency.isoCurrencyCode, 'narrow')}`
                        }
                    ];

                    valueList.push(...revenues);
                } else {
                    valueList.push({
                        name: translations.TOTAL_PRICE,
                        value: `${ngxMaskPipe.transform(opportunity.expectedRevenue, 'separator.2', {
                            thousandSeparator: authService.thousandSeparator,
                            decimalMarker: authService.decimalMarker
                        }) ?? ''} ${getCurrencySymbol(opportunity.transactionCurrency.isoCurrencyCode, 'narrow')}`
                    });
                }
                break;

            default:
                isDynamicFieldRow = true;

                dfSearchFound = dynamicFields.find(element =>
                    element.dynamicFieldId === info.copySummarySetting?.dynamicFieldId
                );

                const valueFound = opportunity.dynamicFieldValues?.find(
                    dynamicField => dynamicField.dynamicFieldId === dfSearchFound?.dynamicFieldId
                );

                if (valueFound) {
                    if (valueFound.valueType === DynamicFieldValueTypeAsString.DECIMAL || valueFound.valueType === DynamicFieldValueTypeAsString.INTEGER) {
                        valueObj.value = `${ngxMaskPipe.transform(
                            getDynamicFieldValueForCopySummary(valueFound, info.copySummarySetting.dynamicField,
                                translate
                            ) as number, `separator.${dfSearchFound.precision ?? 0}`, {
                            thousandSeparator: authService.thousandSeparator,
                            decimalMarker: authService.decimalMarker
                        })
                            } ${dfSearchFound.unit ?? ''}`;
                    } else {
                        valueObj.value =
                            `${getDynamicFieldValueForCopySummary(valueFound, info.copySummarySetting.dynamicField, translate)} ${dfSearchFound.unit ?? ''}`;
                    }
                } else {
                    valueObj.value = null;
                }

                valueObj.name = translate.instant(`${DynamicFieldEntityAsString.OPPORTUNITY}DynamicField.${dfSearchFound.name}`);

                valueList.push(valueObj);
                break;
        }

        if (info.enabledFlag) {
            if (!isDynamicFieldRow || (isDynamicFieldRow && dfSearchFound?.conditionVisibility)) {
                if (valueList.length === 0) {
                    valueList.push(valueObj);
                }

                for (const element of valueList) {
                    if (element.value || isServiceElement) {
                        const otherColumn = `<td colspan="${opportunity.opportunityInfos.length}" style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">
                                        ${element.value}
                                      </td>`;

                        tableRow += `<tr>
                                <td style="border: 1px solid #000; border-collapse: collapse; padding:0px 3px;">
                                  <strong>${element.name ?? info.copySummarySetting.name}</strong>
                                </td>
                                ${isServiceElement ? serviceColumn : otherColumn}
                              </tr>`;
                    }
                }
            }
        }
    });

    return tableRow;

}
