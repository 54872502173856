<div class="content-container w-full h-full" fxLayout="column" fxLayoutAlign="center center">
  <main class="content">
    <router-outlet></router-outlet>
  </main>
  <video class="bg-video" poster="{{bgImage}}" muted autoplay loop>
    <source [src]="bgVideo" type="video/webm">
    <source [src]="bgVideo" type="video/mp4">
  </video>

  @if(!bgVideo) {
  <img class="bg-img" src="{{bgImage}}">
  }
</div>