import { MatSort, Sort } from '@angular/material/sort';
import { UserConfigService } from '@core/user-config/user-config.service';
import {
  UserConfigTableSortPrefix, UserConfigTableVisibilityPrefix, UserPageSizePrefix
} from '@core/user-config/user-config.service.enum';

import { TableColumn } from '../interfaces/table-column.interface';
import { TableFilterColumns } from '@core/api';

export function watchUserTableSort(name: string, matSort: MatSort, userConfig: UserConfigService, columns?: TableColumn<any>[], applySort = true) {
  const key = `${UserConfigTableSortPrefix}_${name}`;
  const userSort = userConfig.get(key);
  // If have user sort config, apply matSort
  if (userSort && applySort) {
    const id = Object.keys(userSort)[0];
    const start = userSort[id] === 1 ? 'desc' : 'asc';
    const isColumnContainFilter = columns?.filter((column) => column.property === id).length > 0;
    if (isColumnContainFilter) {
      matSort.sort({ id, start, disableClear: false });
    }
  }

  // Simply watch and sync table sort state
  matSort.sortChange.subscribe((sort: Sort) => {
    const config = {};
    const isColumnContainFilter = columns?.filter((column) => column.property === sort.active).length > 0;
    if (sort && sort.direction === '') {
      userConfig.delete(key);
      return;
    } else if (columns && !isColumnContainFilter) {
      userConfig.delete(key);
      return;
    }
    config[sort.active] = sort.direction === 'asc' ? 0 : 1;
    userConfig.set(key, config);
  });
}

export function updateUserColumnVisibility(name: string, columns: TableColumn<any>[], userConfig: UserConfigService) {
  const config = userConfig.get(`${UserConfigTableVisibilityPrefix}_${name}`);

  // If user have table config update columns status
  if (config) {
    for (const property in config) {
      if (config.hasOwnProperty(property)) {
        const column = columns.find(item => item.property === property);

        if (column) {
          column.visible = config[property] === 1;
        }
      }
    }
  }

  return columns;
}

export function saveUserPageSizeVisibility(name: string, userConfig: UserConfigService, pageSize: number) {
  userConfig.set(`${UserPageSizePrefix}_${name}`, pageSize);
}

export function updateUserPageSizeVisibility(name: string, userConfig: UserConfigService, pageSize: number) {
  const config = userConfig.get(`${UserPageSizePrefix}_${name}`);
  if (config) {
    return config;
  }
  else {
    return pageSize;
  }
}

export function saveUserColumnVisibility(name: string, columns: TableColumn<any>[], userConfig: UserConfigService) {
  const config = {};

  // Loop over columns and extract column visibility
  columns.forEach(column => config[column.property.toString()] = column.visible ? 1 : 0);
  // Save table config
  userConfig.set(`${UserConfigTableVisibilityPrefix}_${name}`, config);
}

export function saveColumnOrder(name: string, columns: TableColumn<any>[], userConfig: UserConfigService) {
  const config = {};

  // Loop over columns and extract column visibility
  columns.forEach(column => config[column.property] = column.order);
  // Save table config
  userConfig.set(`${UserConfigTableVisibilityPrefix}_${name}`, config);
}

export function updateColumnOrder(name: string, columns: TableColumn<any>[], userConfig: UserConfigService) {
  const config = userConfig.get(`${UserConfigTableVisibilityPrefix}_${name}`);
  // If user have table config update columns status
  if (config) {
    const maxOrderProperty = Object.keys(config).sort((a, b) => (config[a] < config[b]) ? 1 : -1)[0];
    let maxOrder = config[maxOrderProperty];
    columns.map(column => {
      if (config.hasOwnProperty(column.property)) {
        return column.order = config[column.property];
      }
      else {
        return column.order = ++maxOrder;
      }
    });
    columns.sort((a, b) => (a.order > b.order) ? 1 : -1);
  }

  return columns;
}

export function updateFilterSetColumnOrder(selectedFilterColumns: TableFilterColumns[], columns: TableColumn<any>[]) {
  columns.map((column) => {
    const selectedFilterColumn = selectedFilterColumns.find(_col => _col.property === column.property);
    if (selectedFilterColumn) {
      column.order = selectedFilterColumn.order;
      column.visible = selectedFilterColumn.visible;
    } else {
      delete column.order;
      column.visible = false;
    }
    return column;
  });
  return columns.sort((a, b) => (a.order > b.order) ? 1 : -1);
}
