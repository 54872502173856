import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Optional, Output, Self, OnInit
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  Opportunity, OpportunityProcessTypeEnum, OpportunityStatus,
  OpportunityStatusAccountStatusSearchRequest,
  OpportunityStatusAccountStatusService,
} from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-opportunity-status[salesOrganizationId]',
  templateUrl: './select-opportunity-status.component.html',
  styleUrls: ['./select-opportunity-status.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectOpportunityStatusComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectOpportunityStatusComponent implements OnDestroy, OnInit, DoCheck, ControlValueAccessor, MatFormFieldControl<string> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-opportunity-status';
  describedBy = '';
  stateChanges = new Subject<void>();

  defaultOpportunityStatuses: OpportunityStatus[] = [];
  opportunityStatuses: OpportunityStatus[] = [];
  selectCtrl = new UntypedFormControl();

  @Output() selectionChange: EventEmitter<OpportunityStatus> = new EventEmitter<OpportunityStatus>();

  @Input() tabIndex;

  @Input()
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  set salesOrganizationId(value: string) {
    this._salesOrganizationId = value;
    if (value) {
      this.initDefaultOptions();
    }
  }
  private _salesOrganizationId: string;

  @Input()
  get opportunity(): Opportunity { return this._opportunity; }
  set opportunity(opportunity: Opportunity) {
    this._opportunity = opportunity;
    if (this.defaultOpportunityStatuses) {
      this.opportunityStatuses = this.defaultOpportunityStatuses;
    }

    if (this.value) {
      this.hasValue();
    }
  }
  private _opportunity: Opportunity;

  @Input() opportunityProcess: OpportunityProcessTypeEnum;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string { return this.selectCtrl.value; }
  set value(value: string) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-opportunity-status-${SelectOpportunityStatusComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private translate: TranslateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private opportunityStatusAccountStatusService: OpportunityStatusAccountStatusService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  initDefaultOptions() {
    let request: OpportunityStatusAccountStatusSearchRequest;

    if (OpportunityProcessTypeEnum.DETAILED === this.opportunityProcess) {
      request = {
        filter: { opportunityProcessTypeId: OpportunityProcessTypeEnum.DETAILED }
      };
    } else {
      request = {
        filter: {
          salesOrganizationId: this.salesOrganizationId
        }
      };
    }
    this.opportunityStatusAccountStatusService.search(request).subscribe(response => {
      this.opportunityStatuses = response.data.results
        .sort((a, b) => a.orderBy - b.orderBy)
        .sort((a, b) => Number(b.positiveFlag) - Number(a.positiveFlag))
        .map(osas => {
          return {
            ...osas.opportunityStatus,
            name: this.translate.instant('OpportunityStatus.' + osas.opportunityStatus.name)
          };
        });

      this.defaultOpportunityStatuses = this.opportunityStatuses.map(status => status);

      if (this.value) {
        this.hasValue();
      }
    });
  }

  compareWith(one: string, two: string) {
    return one && two && one === two;
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.emitStatus();
    this.stateChanges.next();
  }

  ngOnInit() {
    this.emitStatus();
  }

  hasValue() {
    const hasValue = this.opportunityStatuses?.find(status => status.opportunityStatusId === this.value);

    if (!hasValue && this.opportunity) {
      this.opportunityStatuses.unshift(this.opportunity.opportunityStatus);
    }
  }

  emitStatus() {
    const opportunityStatus = this.opportunityStatuses?.find(status => status.opportunityStatusId === this.value);
    if (opportunityStatus) {
      this.selectionChange.emit(opportunityStatus);
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => {
    this.value = value;

    if (value) {
      this.hasValue();
    }
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
