import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { SeaLineService, SeaLine } from '@core/api';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-sea-line',
  templateUrl: './select-sea-line.component.html',
  styleUrls: ['./select-sea-line.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSeaLineComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectSeaLineComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SeaLine> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-sea-line';
  describedBy = '';
  stateChanges = new Subject<void>();

  seaLines: SeaLine[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SeaLine { return this.selectCtrl.value; }
  set value(value: SeaLine) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `select-sea-line-${SelectSeaLineComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    private seaLineService: SeaLineService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.listSeaLine();
  }

  listSeaLine() {
    this.loading = true;
    this.seaLineService.list().subscribe(response => {
      this.loading = false;
      this.seaLines = response.data;
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: SeaLine, two: SeaLine) => one && two && one.seaLineId === two.seaLineId;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SeaLine) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
