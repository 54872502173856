<mat-select #contactFilterSetSelect [placeholder]="placeholder" [formControl]="selectCtrl"
    (openedChange)="openedChange($event)" (closed)="search.value = ''; filteredContactList = contactList"
    (selectionChange)="onSelectionChange()" [compareWith]="compareWith">

    <input #search (keydown)="$event.stopPropagation()" (keyup)="onKey($event)"
        class="my-2 ml-4 border-0 outline-none w-full bg-card" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <mat-option *ngIf="!isOwnFilterSetSelected && oldFilterSet" [value]="oldFilterSet['contactFilterId']" disabled>
        {{oldFilterSet['contactFilterName']}}</mat-option>

    <mat-option *ngFor="let item of filteredContactList" [value]="item.id"
        (click)="selectedFilterSet.emit(item)">{{item.name}}</mat-option>

</mat-select>