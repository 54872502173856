import { EnvironmentName } from '@shared/enums';
import { environment } from '@env/environment';

export class CustomConsole {
    info(...data: any[]): void;
    info(message?: any, ...optionalParams: any[]): void;
    info(message?: unknown, optionalParams?: unknown[]): void {

        if (environment.name === EnvironmentName.PROD) {
            return;
        }

        console.info(message, optionalParams);
    }

    error(...data: any[]): void;
    error(message?: any, ...optionalParams: any[]): void;
    error(message?: unknown, optionalParams?: unknown[]): void {

        if (environment.name === EnvironmentName.PROD) {
            return;
        }

        console.error(message, optionalParams);
    }

    time(label?: string): void;
    time(label?: string): void;
    time(label?: string): void {

        if (environment.name === EnvironmentName.PROD) {
            return;
        }

        console.time(label);
    }

    timeEnd(label?: string): void;
    timeEnd(label?: string): void;
    timeEnd(label?: string): void {

        if (environment.name === EnvironmentName.PROD) {
            return;
        }

        console.timeEnd(label);
    }

    warn(...data: any[]): void;
    warn(message?: any, ...optionalParams: any[]): void;
    warn(message?: unknown, optionalParams?: unknown[]): void {

        if (environment.name === EnvironmentName.PROD) {
            return;
        }

        console.warn(message, optionalParams);
    }
}
