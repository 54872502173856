import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionDetectedEvent, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, first } from 'rxjs/operators';
import { concat, interval } from 'rxjs';
import { ToastService } from '../../shared/services/toast.service';
import { AuthService } from '@core/auth/auth.service';
import { AlertService } from '../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SwTypeEnum } from './sw-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SWService {

  constructor(
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    private toastService: ToastService,
    private alert: AlertService,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    if (this.swUpdate.isEnabled) {
      this.watchUpdates();
      this.listenUpdates();
    }
  }

  private watchUpdates() {
    const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
    concat(appIsStable$, interval(45 * 1000)).subscribe(() => this.swUpdate.checkForUpdate());
  }

  private listenUpdates() {
    // Open update snackbar on new version available
    this.swUpdate.versionUpdates.pipe(
      filter(response => response.type === SwTypeEnum.VERSION_DETECTED)
    ).subscribe((event: VersionDetectedEvent) => {
      this.snackBar.open(this.translate.instant('GENERAL.NEW_VERSION_IS_AVAIBLE', { value: event.version.appData['version'] }),
        this.translate.instant('GENERAL.UPDATE_STARTED'));

      // Show update alert
      this.alert.show(this.translate.instant('GENERAL.INSTALLING_UPDATES_PLEASE_WAIT'), true);

      // After activation, logout and hard reload
      this.swUpdate.activateUpdate().then(() => {
        this.auth.logout(false);
      });
    });

    // Show toast message when new version is installed
    this.swUpdate.versionUpdates.pipe(
      filter(response => response.type === SwTypeEnum.VERSION_READY)
    ).subscribe((event: VersionReadyEvent) => {
      this.toastService.info(this.translate.instant('GENERAL.NEW_VERSION_IS_INSTALLED', { value: event.currentVersion.appData['version'] }));
    });
  }
}
