import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  Board,
  Response,
  BoardRequest,
  PagedResponse,
  BoardSystemUser,
  BoardSearchRequest,
  SocketSearchRequest,
  BoardUpdateNameRequest,
  BoardSystemUserService,
  BoardSystemUserUpdateRequest,
} from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class BoardService {

  private readonly boardSystemUserService = inject(BoardSystemUserService)

  constructor(private http: HttpClient) { }

  insert(request: BoardRequest): Observable<Response<Board>> {
    return this.http.post<Response<Board>>(
      environment.apiUrl + '/api/Board/Insert',
      request
    );
  }

  update(request: BoardRequest): Observable<Response<Board>> {
    return this.http.post<Response<Board>>(
      environment.apiUrl + '/api/Board/Update',
      request
    );
  }

  updateUser(request: BoardSystemUserUpdateRequest): Observable<PagedResponse<BoardSystemUser>> {
    return this.http.post<Response<Board>>(environment.apiUrl + '/api/Board/UpdateUser', request).pipe(
      switchMap(res => this.boardSystemUserService.search({ filter: { boardId: request.boardId } }))
    );
  }

  updateName(request: BoardUpdateNameRequest): Observable<Response<Board>> {
    return this.http.post<Response<Board>>(environment.apiUrl + '/api/Board/UpdateName', request);
  }

  delete(request: BoardRequest): Observable<Response<Board>> {
    return this.http.post<Response<Board>>(
      environment.apiUrl + '/api/Board/Delete',
      request
    );
  }

  search(request: BoardSearchRequest): Observable<PagedResponse<Board>> {
    return this.http.post<PagedResponse<Board>>(
      environment.apiUrl + '/api/Board/Search',
      request
    );
  }

  get(uuid: string): Observable<Response<Board>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Board>>(
      environment.apiUrl + '/api/Board/Get',
      JSON.stringify(uuid),
      { headers }
    );
  }

  socketSearch(request: SocketSearchRequest): Observable<PagedResponse<Board>> {
    return this.http.post<PagedResponse<Board>>(
      environment.apiUrl + '/api/Board/SocketSearch',
      request
    );
  }

  exportExcel(request: SocketSearchRequest): Observable<PagedResponse<Board>> {
    return this.http.post<PagedResponse<Board>>(
      environment.apiUrl + '/api/Board/SocketDownload',
      request.filter
    );
  }
}
