import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DateDiffPipe } from '../pipes/date-diff.pipe';
import moment from 'moment';

export function minDateWithTimeByStartDate(dateDiff: DateDiffPipe): ValidatorFn {
    return (form: UntypedFormGroup) => {

        if (!(form.get('endDate').value || form.get('startDate').value)) {

            form.get('endDate').setErrors(form.get('endDate').errors);

            return null;

        }

        const startDate = moment(form.get('startDate').value)
            .hour(0)
            .minute(0)
            .second(0);

        const endDate = moment(form.get('endDate').value)
            .hour(0)
            .minute(0)
            .second(0);

        const dateDiffNumber = dateDiff.transform(startDate, endDate, 'day');

        if (dateDiffNumber < 0) {

            form.get('endDate').markAllAsTouched();

            form.get('endDate').setErrors({ incorrect: true });

            return { min: true };

        }

        if (!(form.get('endTime').value && form.get('startTime').value)) {

            form.get('endDate').setErrors(form.get('endDate').errors);

            return null;

        }

        if (dateDiffNumber === 0) {

            if (form.get('endTime').value.hour < form.get('startTime').value.hour) {

                form.get('endDate').markAllAsTouched();

                form.get('endDate').setErrors({ incorrect: true });

                return { min: true };

            }

            if (form.get('endTime')?.value?.hour === form.get('startTime')?.value?.hour
                && form.get('endTime')?.value?.minute < form.get('startTime')?.value?.minute) {

                form.get('endDate').markAllAsTouched();

                form.get('endDate').setErrors({ incorrect: true });

                return { min: true };

            }
        }

        form.get('endDate').setErrors(null);

        return null;

    };
}
