import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomerExperienceChart,
    CustomerExperienceChartSearchRequest,
    CustomerExperienceSearchRequest,
    GaugeChartData, PagedResponse,
    PieChartData,
    RadialBarChartData,
    ResponseTimeData,
    Response,
    SLAChartData
} from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceChartService {

    constructor(private http: HttpClient) { }

    get(uuid: string): Observable<Response<CustomerExperienceChart>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceChart>>(environment.apiUrl + '/api/CxChart/Get', JSON.stringify(uuid), { headers });
    }

    search(request: CustomerExperienceChartSearchRequest): Observable<PagedResponse<CustomerExperienceChart>> {
        return this.http.post<PagedResponse<CustomerExperienceChart>>(environment.apiUrl + '/api/CxChart/Search', request);
    }

    list(): Observable<Response<CustomerExperienceChart[]>> {
        return this.http.post<Response<CustomerExperienceChart[]>>(environment.apiUrl + '/api/CxChart/List', {});
    }

    setStatus(request: { cxChartId: string, activeFlag: boolean }): Observable<Response<CustomerExperienceChart>> {
        return this.http.post<Response<CustomerExperienceChart>>(environment.apiUrl + '/api/CxChart/SetStatus', request);
    }

    setDefault(request: { cxChartId: string, defaultFlag: boolean }): Observable<Response<CustomerExperienceChart>> {
        return this.http.post<Response<CustomerExperienceChart>>(environment.apiUrl + '/api/CxChart/SetDefault', request);
    }

    setOrderBy(request: { cxChartId: string, orderBy: number }): Observable<Response<CustomerExperienceChart>> {
        return this.http.post<Response<CustomerExperienceChart>>(environment.apiUrl + '/api/CxChart/SetOrderBy', request);
    }

    update(request: CustomerExperienceChart) {
        return this.http.post<Response<CustomerExperienceChart>>(environment.apiUrl + '/api/CxChart/Update', request);
    }

    firstResponseSLA(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<GaugeChartData>> {
        return this.http.post<Response<GaugeChartData>>(environment.apiUrl + '/api/CxChart/FirstResponseSLA', listviewFilter ?? {});
    }

    firstResponseSLAMe(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<RadialBarChartData>> {
        return this.http.post<Response<RadialBarChartData>>(environment.apiUrl + '/api/CxChart/FirstResponseSLAMe', listviewFilter ?? {});
    }

    resolutionSLA(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<GaugeChartData>> {
        return this.http.post<Response<GaugeChartData>>(environment.apiUrl + '/api/CxChart/ResolutionSLA', listviewFilter ?? {});
    }

    resolutionSLAMe(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<RadialBarChartData>> {
        return this.http.post<Response<RadialBarChartData>>(environment.apiUrl + '/api/CxChart/ResolutionSLAMe', listviewFilter ?? {});
    }

    ticketStatusDistribution(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<{ data: PieChartData[] }>> {
        return this.http.post<Response<{ data: PieChartData[] }>>(environment.apiUrl + '/api/CxChart/TicketStatusDistribution', listviewFilter ?? {});
    }

    categoryStatusDistribution(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<{ data: PieChartData[] }>> {
        return this.http.post<Response<{ data: PieChartData[] }>>(environment.apiUrl + '/api/CxChart/CategoryStatusDistribution', listviewFilter ?? {});
    }

    SLA(listviewFilter: CustomerExperienceSearchRequest): Observable<Response<SLAChartData>> {
        return this.http.post<Response<SLAChartData>>(environment.apiUrl + '/api/CxChart/SLA', listviewFilter ?? {});
    }

    messages(listviewFilter: any): Observable<Response<{ sentMessageCount: number, receivedMessageCount: number }>> {
        return this.http.post<Response<{ sentMessageCount: number, receivedMessageCount: number }>>(
            environment.apiUrl + '/api/CxChart/Messages',
            listviewFilter ?? {}
        );
    }

    unansweredMessages(listviewFilter: any): Observable<Response<{ count: number }>> {
        return this.http.post<Response<{ count: number }>>(environment.apiUrl + '/api/CxChart/UnansweredMessages', listviewFilter ?? {});
    }

    openTickets(listviewFilter: any): Observable<Response<{ count: number }>> {
        return this.http.post<Response<{ count: number }>>(environment.apiUrl + '/api/CxChart/OpenTickets', listviewFilter ?? {});
    }

    solvedTickets(listviewFilter: any): Observable<Response<{ count: number }>> {
        return this.http.post<Response<{ count: number }>>(environment.apiUrl + '/api/CxChart/SolvedTickets', listviewFilter ?? {});
    }

    totalTickets(listviewFilter: any): Observable<Response<{ count: number }>> {
        return this.http.post<Response<{ count: number }>>(environment.apiUrl + '/api/CxChart/TotalTickets', listviewFilter ?? {});
    }

    resolutionTime(listviewFilter: any): Observable<Response<{ resolutionTime: string }>> {
        return this.http.post<Response<{ resolutionTime: string }>>(environment.apiUrl + '/api/CxChart/ResolutionTime', listviewFilter ?? {});
    }

    responseTime(listviewFilter: any): Observable<Response<ResponseTimeData>> {
        return this.http.post<Response<ResponseTimeData>>(environment.apiUrl + '/api/CxChart/ResponseTime', listviewFilter ?? {});
    }

}
