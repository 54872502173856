import {
  CXChannelType,
  CustomerExperienceCategoriesService,
  CustomerExperienceCategoryBaseStatusEnum,
  CustomerExperienceChannel,
  CustomerExperienceChannelSettingsService,
  CustomerExperienceMainCategoriesService,
  CustomerExperienceSLAService,
  CustomerExperienceService,
  CustomerExperienceTicketSearchRequest,
  CustomerExperienceTicketService,
  SLADurationType,
  SystemUserService,
  User
} from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ApiDataSource } from '../api.data-source';

export class CustomerExperienceTicketDataSource extends ApiDataSource<any> {

  public isOpenedNewTab = false;

  public solvedSubStatuses = [];

  public $solvedSubStatuses = of([]);

  public $userList = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(users => users.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $channelTypes = this.channelService.getChannelTypes().pipe(
    map(response => response.data),
    map(chTypes => chTypes.map(channelType => {
      return {
        key: channelType.cxChannelTypeId,
        value: channelType.name
      };
    }))
  );

  public $channels = this.channelService.getUserAuthCxChannelList({
    orderBy: 'createdOn',
    orderType: 'ASC',
    page: 1,
    pageSize: 1000,
    filter: {}
  }).pipe(
    map(response => response.data),
    map(channels => channels.map(channel => {
      return {
        key: channel.cxChannelId,
        value: channel.name
      };
    }))
  );

  public $channelInfos = this.channelService.getUserAuthCxChannelList({
    orderBy: 'createdOn',
    orderType: 'ASC',
    page: 1,
    pageSize: 1000,
    filter: {
      activeFlag: true
    }
  }).pipe(
    map(response => response.data),
    map(channels => channels.map(channel => {
      return {
        key: this.getChannelInfoKey(channel),
        value: channel.cxChannelInfo
      };
    }))
  );

  public $mainCategories = this.mainCategoryService.search({
    orderBy: 'orderBy',
    orderType: 'ASC',
    page: 1,
    pageSize: 1000,
    filter: {}
  }).pipe(
    map(response => response.data.results),
    map(mainCategories => mainCategories.map(mainCategory => {
      return {
        key: mainCategory.cxMainCategoryId,
        value: mainCategory.name
      };
    }))
  );

  public $categories = this.categoryService.search({
    orderBy: 'orderBy',
    orderType: 'ASC',
    page: 1,
    pageSize: 1000,
    filter: {}
  }).pipe(
    map(response => response.data.results),
    map(categories => categories.map(category => {
      return {
        key: category.cxCategoryId,
        value: category.name
      };
    }))
  );

  public $slaList = this.slaService.search({
    orderBy: 'orderBy',
    orderType: 'ASC',
    page: 1,
    pageSize: 1000,
    filter: {}
  }).pipe(
    map(response => response.data.results),
    map(slaList => slaList.map(sla => {
      return {
        key: sla.cxSlaId,
        value: sla.name
      };
    }))
  );

  public $slaStatuses = of([
    { key: SLADurationType.ONGOING, value: this.translate.instant('CX.ONGOING') },
    { key: SLADurationType.MET, value: this.translate.instant('CX.MET') },
    { key: SLADurationType.BREACHED, value: this.translate.instant('CX.BREACHED') }
  ]);

  public $priorities = this.ticketService.listPriority().pipe(
    map(response => response.data),
    map(priorities => priorities.map(priority => {
      return {
        key: priority.ticketPriorityId,
        value: this.translate.instant('TicketPriority.' + priority.name)
      };
    }))
  );

  public $statuses = this.categoryService.categoryStatusFilterList().pipe(
    map(response => response.data),
    map(statuses => statuses.map(status => {
      return {
        key: status.cxCategoryStatusIds,
        value: this.translate.instant('CxStatus.' + status.name)
      };
    }))
  );

  public $subStatuses = this.categoryService.categoryStatusFilterList(true).pipe(
    map(response => response.data),
    map(filteredStatuses => filteredStatuses?.map(status => {
      return {
        key: status.cxCategoryStatusIds,
        value: this.translate.instant('CxStatus.' + status.name)
      };
    }) ?? [])
  );

  private filterReq: CustomerExperienceTicketSearchRequest;

  constructor(
    private ticketService: CustomerExperienceTicketService,
    private channelService: CustomerExperienceChannelSettingsService,
    private mainCategoryService: CustomerExperienceMainCategoriesService,
    private categoryService: CustomerExperienceCategoriesService,
    private slaService: CustomerExperienceSLAService,
    private systemUserService: SystemUserService,
    private user: User,
    private translate: TranslateService,
    private customerExperienceService: CustomerExperienceService,
    protected initialFilter?: any,
  ) {
    super(initialFilter);
  }

  private getChannelInfoKey(channel: CustomerExperienceChannel) {
    switch (channel.cxChannelType.cxChannelTypeId) {
      case CXChannelType.WHATSAPP:
        return channel.cxChannelWp?.cxChannelWpId;
      case CXChannelType.EMAIL:
        return channel.cxChannelEmail?.cxChannelEmailId;
      default:
        return null;
    }
  }

  getFilter() {
    return this.filterReq;
  }

  load(): void {
    if (!this.isOpenedNewTab) {
      // Init filter with data source's default filter
      const filterValue: any = { ...this.initialFilter, ...this.filter };

      if (filterValue.cxChannelIds?.length === 0) {
        this.dataSubject.next([]);
        this.dataCountSubject.next(0);
        this.rowCount$.next(0);
        this.empty = true;
        this.loadingSubject.next(false);
        return;
      }

      if (filterValue.cxCategoryStatusIds?.length > 0) {
        const cxCategoryStatusIds = [];
        filterValue.cxCategoryStatusIds.forEach(status => {
          if (Array.isArray(status)) {
            cxCategoryStatusIds.push(...status);
          } else {
            cxCategoryStatusIds.push(status);
          }
        });

        filterValue.cxCategoryStatusIds = cxCategoryStatusIds;
      }

      if (filterValue.cxCategorySubStatusIds?.length > 0) {
        const cxCategorySubStatusIds = [];
        filterValue.cxCategorySubStatusIds.forEach(status => {
          if (Array.isArray(status)) {
            cxCategorySubStatusIds.push(...status);
          } else {
            cxCategorySubStatusIds.push(status);
          }
        });

        filterValue.cxCategorySubStatusIds = cxCategorySubStatusIds;
      }

      // If filter keyword exists, filter data
      if (this.keyword) {
        filterValue.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request = this.getRequest();
      if (!request.orderType) {
        request.orderType = 'DESC';
        request.orderBy = 'lastMessageDate';
      }

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }

      // Add filters to request
      request.filter = filterValue;
      // if (this.filterReq.filter !== request.filter) {
      // }

      this.filterReq = { ...request };
      this.customerExperienceService.setListviewFilter(this.filterReq.filter);
      // Fetch data
      this.ticketService
        .search(request)
        .pipe(
          debounceTime(400),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
          // Update count and data subjects
          this.dataSubject.next(response.data.results);
          this.dataCountSubject.next(response.data.rowCount);
          this.rowCount$.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });
    }

    return this.loadingSubject.next(false);
  }
}
