import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from "@angular/material/autocomplete";
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MAT_BUTTON_TOGGLE_GROUP_DEFAULT_OPTIONS_FACTORY } from "@angular/material/button-toggle";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from "@angular/material/paginator";
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions } from "@angular/material/radio";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from "@angular/material/slide-toggle";
import { MatPaginatorIntlService } from "@shared/services";

export function provideMat() {
  return [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        hideSingleSelectionIndicator: true,
        panelWidth: 'auto',
      }
    }, {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        hideSingleSelectionIndicator: true,
        panelWidth: 'auto',
      }
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: "primary"
      } satisfies MatRadioDefaultOptions
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        hideIcon: true,
        color: 'primary'
      }
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        hideSingleSelectionIndicator: true,
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      } satisfies MatFormFieldDefaultOptions
    }, {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-Us'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill',
      }
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlService
    }
  ]
} 
