import { Subject, throwError } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import {
  Account,
  ActivityPurpose,
  ActivityPurposeService,
  ActivityPurposeType,
  Guid,
  Opportunity,
  OpportunityService,
  OpportunityStatusType
} from '@core/api';

import { OpportunityFormDialogComponent } from '../opportunity-form-dialog/opportunity-form-dialog.component';
import { SelectActivityPurpose } from './select-activity-purpose.component.model';
import { ActivityDialogType } from '../activity-form-dialog/activity-form-dialog.component.model';
import { ActivityDialogTypeEnum } from '../activity-form-dialog/activity-form-dialog.enum';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-activity-purpose[account]',
  templateUrl: './select-activity-purpose.component.html',
  styleUrls: ['./select-activity-purpose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectActivityPurposeComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectActivityPurposeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SelectActivityPurpose | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-activity-purpose';
  describedBy = '';
  stateChanges = new Subject<void>();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  hasActiveOpportunity = false;

  activityPurposes: ActivityPurpose[];
  activityPurposeType = ActivityPurposeType;
  activityDialogTypeEnum = ActivityDialogTypeEnum;

  opportunities: Opportunity[] = [];
  filteredOpportunities: Opportunity[] = [];

  purposeCtrl = new UntypedFormControl();
  opportunityCtrl = new UntypedFormControl();

  icAdd = Icon.IC_TWOTONE_ADD;
  icCancel = Icon.IC_TWOTONE_CANCEL;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() isDisabled = false;
  @HostBinding('id') id = `select-activity-purpose-${SelectActivityPurposeComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private opportunityService: OpportunityService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private activityPurposeService: ActivityPurposeService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.activityPurposeService.search({ isActive: true }).subscribe(response => {
      this.activityPurposes = response.data;

      if (this.value.activityPurpose) {
        const tempValue = this.activityPurposes.find(activityPurpose => activityPurpose.activityPurposeId === this.value.activityPurpose.activityPurposeId);
        if (!tempValue) {
          this.activityPurposes.push(this.value.activityPurpose);
        }
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  private _account: Account;

  @Input()
  get account() {
    return this._account;
  }

  set account(account: Account) {
    if (this.type !== this.activityDialogTypeEnum.LEAD_DRAFT) {
      this._account = account;
      this.disabled = !account;

      if (account) {
        this.onAccountChange();
      }
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.purposeCtrl.disable();
      this.opportunityCtrl.disable();
    } else {
      this.purposeCtrl.enable();
      this.opportunityCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  private _value: SelectActivityPurpose = {
    activityPurpose: null,
    opportunities: []
  };

  @Input()
  get value(): SelectActivityPurpose | null {
    return this._value;
  }

  set value(value: SelectActivityPurpose | null) {
    if (value.activityPurpose) {
      this.purposeCtrl.setValue(value.activityPurpose);
    }

    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
    this.changeDetectorRef.detectChanges();
  }

  private _type: ActivityDialogType = ActivityDialogTypeEnum.ACCOUNT;

  @Input()
  get type() {
    return this._type;
  }

  set type(value) {
    if (value) {
      this._type = value;
    }
  }

  get shouldLabelFloat() {
    return true;
  }

  get empty() {
    return !this.value.opportunities;
  }

  ngOnInit() {
    // Watch purpose control for fetch opportunities
    this.purposeCtrl.valueChanges.pipe(
      filter(value => !!value && !!this.account),
      tap(() => {
        this.loading = true;
        this.opportunities = [];
        this.value.opportunities = [];
      }),
      switchMap(value => {
        if (this.type !== this.activityDialogTypeEnum.LEAD_DRAFT) {
          return this.opportunityService.search({
            filter: {
              accountId: this.account.accountId,
              // activityPurposeId: value.activityPurposeId
            },
            pageSize: 100
          });
        }

        return throwError('The type is lead draft');
      })
    ).subscribe(response => {
      if (response.data.results.length > 0) {
        this.opportunities = response.data.results.filter(opportunity => opportunity.opportunityId !== Guid.EMPTY);
      } else {
        this.opportunities = [];
      }

      this.loading = false;
      this.opportunityCtrl.enable();
      this.opportunityCtrl.setValue(null);
      this.changeDetectorRef.detectChanges();
    }, err => this.loading = false);

    // Watch opportunity control for filter opportunities
    this.opportunityCtrl.valueChanges.pipe(
      startWith(''),
      filter(value => !this.loading),
      map(value => {
        if (value) {
          return this.opportunities.filter(opportunity => opportunity.name.toLocaleLowerCase().indexOf(value) > -1);
        }

        return this.opportunities.slice();
      }),
      map(opportunities => opportunities.filter(opportunity => {
        return !this.value.opportunities.some(selected => selected.opportunityId === opportunity.opportunityId);
      }))
    ).subscribe(opportunities => {
      this.filteredOpportunities = opportunities;
      this.changeDetectorRef.detectChanges();
    });

    // Detect changes after init
    this.changeDetectorRef.detectChanges();
  }

  reset() {
    this.input.nativeElement.value = '';
    this.opportunityCtrl.setValue(null);
  }

  add() {
    this.dialog.open(OpportunityFormDialogComponent, { autoFocus: false, data: { account: this.account } })
      .addPanelClass('cdk-overlay-full')
      .afterClosed()
      .subscribe((opportunity: Opportunity) => {
        if (opportunity) {
          this.opportunityService.get(opportunity.opportunityId).subscribe(response => {
            this.opportunities.push(response.data);
            this.value.opportunities.push(response.data);
            this.updateValue();
          });
        }
      });
  }

  onAddToken(event: MatChipInputEvent) {
    if (!this.matAutocomplete.isOpen) {
      event.input.value = '';
      this.opportunityCtrl.setValue(null);
    }
  }

  onSelectPurpose(event: MatSelectChange) {
    this.value.activityPurpose = event.value;
    this.updateValue();
  }

  onSelectOpportunity(event: MatAutocompleteSelectedEvent) {
    this.value.opportunities.push(event.option.value);
    this.reset();
    this.updateValue();
  }

  onRemoveOpportunity(opportunity: Opportunity) {
    const index = this.value.opportunities.indexOf(opportunity);

    if (index >= 0) {
      this.value.opportunities.splice(index, 1);
      this.updateValue();
    }
  }

  compareWith(one: ActivityPurpose, two: ActivityPurpose) {
    return one && two && one.activityPurposeId === two.activityPurposeId;
  }

  writeValue(value: SelectActivityPurpose) {
    if (value) {
      this.value = value;
    }
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  private updateValue() {
    this.onChange(this.value);
    this.stateChanges.next();
    this.changeDetectorRef.detectChanges();
  }

  private onAccountChange() {
    // Query there is active opportunity
    this.opportunityService.search({
      filter: {
        accountId: this.account.accountId
      },
      pageSize: 100
    }).subscribe(response => {
      this.hasActiveOpportunity = response.data.results.find(opportunity => opportunity.opportunityStatusId === OpportunityStatusType.ACTIVE) ? true : false;
      if (response.data.results.length > 0) {
        this.opportunities = response.data.results.filter(opportunity => opportunity.opportunityId !== Guid.EMPTY);
      } else {
        this.opportunities = [];
      }
      this.changeDetectorRef.detectChanges();
    });
  }

}
