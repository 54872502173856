import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MapGeocoder } from '@angular/google-maps';

declare var google: any;

@Injectable()
export class GeocodeService {
  private geocoder: any;

  // mapGeocoder = inject(MapGeocoder);

  constructor(private httpClient: HttpClient, private httpBackend: HttpBackend, private geocoderService: MapGeocoder) { }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }



  geocodeAddress(location: string): Observable<any> {
    return this.geocoderService.geocode({ address: location });
    // return new Observable(observer => {
    //   this.geocoderService.geocode({ address: location }).pipe(map(response => {
    //     if (response.status === google.maps.GeocoderStatus.OK) {
    //       observer.next({
    //         lat: response.results[0].geometry.location.lat(),
    //         lng: response.results[0].geometry.location.lng()
    //       });
    //     } else {
    //       observer.next({ lat: 0, lng: 0 });
    //     }
    //     observer.complete();
    //   }));
    // });
  }

  geocodeAddressWithPlaceId(placeId: string): Observable<any> {
    return new Observable(observer => {
      this.geocoderService.geocode({ placeId }).subscribe(response => {
        if (response.status === google.maps.GeocoderStatus.OK) {
          observer.next(
            response.results
          );
        } else {
          observer.next({ lat: 0, lng: 0 });
        }
        observer.complete();
      });
    });
  }

  search(term: string) {
    return new Observable<any[]>(observer => {

      const displaySuggestions = (predictions: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return;
        }

        observer.next(predictions);
        observer.complete();
      };
      if (term) {
        const mapp = new google.maps.Map(document.getElementById('map') as HTMLElement, {});
        const service = new google.maps.places.PlacesService(mapp);
        service.textSearch({ input: term }, displaySuggestions);
      }
    });
  }

}
