import { Injectable, inject } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { Permission } from '@core/auth';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  private readonly translate = inject(TranslateService);

  constructor() {
  }

  public loadNavigation(items: NavigationItem[]): void {
    this._items.next(items);
  }



}
