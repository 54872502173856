<div class="grow px-5 py-5 flex flex-col">
    <div *ngFor="let message of conversation; let index = index;">
        <net-chat-message 
            [cxChannelId]="cxChannelId"
            [message]="message" 
            [self]="isSelf(message)" 
            [isLog]="isLog(message)" 
            [isPrivateNote]="isPrivateNote(message)" 
            [followUp]="false"
            [permissions]="permissions"
            [headerInfo]="headerInfo"
            [isPreview]="isPreview">
        </net-chat-message>
    </div>
    <div *ngIf="unreadMessagesCount > 0" class="flex items-center justify-center sticky bottom-2 unread-message">
        <button [disabled]="true" class="bg-card" 
            mat-icon-button color="primary" [matBadge]="unreadMessagesCount > 999 ? '+999' : unreadMessagesCount" matBadgePosition="before" matBadgeColor="primary">
                <net-iconify-icon [icon]="icChevronDown"></net-iconify-icon>
        </button>
    </div>
</div>