<mat-chip-grid #chipList #origin="matAutocompleteOrigin" matAutocompleteOrigin [disabled]="disabled">
    <mat-chip-row *ngFor="let contact of value" [removable]="true" (removed)="remove(contact)">
        {{ contact?.firstName + ' ' + contact?.lastName}}
        <net-iconify-icon [icon]="icCancel" matChipRemove></net-iconify-icon>
    </mat-chip-row>
    <input #input="matAutocompleteTrigger" matInput [tabIndex]="tabIndex" [formControl]="inputCtrl"
        [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate" [matAutocomplete]="auto" (blur)="onBlur()"
        [netAutocompletePosition]="input" [matAutocompleteConnectedTo]="origin" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false">
</mat-chip-grid>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" [autoActiveFirstOption]="true"
    [panelWidth]="'auto'" [panelWidth]="panelWidth" (optionSelected)="onSelect($event)" (closed)="onClosed()">
    <ng-container *ngIf="this.contacts">
        <mat-option class="autocomplete-option" *ngFor="let contact of contacts" [value]="contact" multiple>
            {{ contact.firstName + ' ' + contact.lastName}}
            <div class="account">
                <span class="text-hint">{{ contact.customer.name }}</span>
            </div>
        </mat-option>
    </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>