import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PagedResponse, Translation, TranslationFilter, TranslationRequest, Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) { }

  search(request: TranslationFilter): Observable<PagedResponse<Translation>> {
    return this.http.post<PagedResponse<Translation>>(environment.apiUrl + '/api/Translation/Search', request);
  }

  update(request: TranslationRequest): Observable<Response<Translation>> {
    return this.http.post<Response<Translation>>(environment.apiUrl + '/api/Translation/Update', request);
  }
}
