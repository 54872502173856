import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime, delay, finalize, map } from 'rxjs/operators';

import { ApiDataSource } from '@core/api/api.data-source';
import {
  Notification,
  NotificationDataSourceFilter,
  NotificationIcon,
  NotificationService,
  NotificationSourceType,
  NotificationTemplateService,
  SalesOrganizationService,
  SystemUserService,
  User
} from '@core/api';
import { Icon } from '@shared/enums';

export class NotificationDataSource extends ApiDataSource<Notification> {
  orderBy: 'createdOn';
  orderType: 'DESC';

  public $createdBys = this.notificationService
    .listCreatedUsers().pipe(
      map(response => response.data),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $notificationsType = this.notificationTemplateService.list({ page: 1, pageSize: 100 }).pipe(
    map(response => response.data),
    map(notificationTypes => notificationTypes?.map(type => {
      return {
        key: type.notificationIconId,
        value: this.translate.instant(`NOTIFICATION.${type.notificationType.toUpperCase()}`)
      };
    }))
  );

  public $salesOrganization = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(statuses => statuses.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  public $isRead = of([
    { key: true, value: this.translate.instant('NOTIFICATION.READ') },
    { key: false, value: this.translate.instant('NOTIFICATION.UNREAD') },
  ]).pipe(delay(100));

  public $sources = of([
    { key: NotificationSourceType.Account, value: this.translate.instant('NOTIFICATION.ACCOUNT') },
    { key: NotificationSourceType.LeadDraft, value: this.translate.instant('NOTIFICATION.LEAD_DRAFT') },
    { key: NotificationSourceType.Activity, value: this.translate.instant('NOTIFICATION.ACTIVITY') },
    { key: NotificationSourceType.Opportunity, value: this.translate.instant('NOTIFICATION.OPPORTUNITY') },
    { key: NotificationSourceType.Assignment, value: this.translate.instant('NOTIFICATION.ASSIGNMENT') },
    { key: NotificationSourceType.Board, value: this.translate.instant('NOTIFICATION.BOARD') },
    { key: NotificationSourceType.Contact, value: this.translate.instant('NOTIFICATION.CONTACT') },
    { key: NotificationSourceType.Report, value: this.translate.instant('NOTIFICATION.REPORT') }
  ].sort((a, b) => (
    a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase() ? -1 : 1
  )));

  constructor(
    private notificationService: NotificationService,
    private notificationTemplateService: NotificationTemplateService,
    private salesOrganizationService: SalesOrganizationService,
    private translate: TranslateService,
    private systemUserService: SystemUserService,
    private user: User,
    protected initialFilter?: NotificationDataSourceFilter
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.notificationService
      .search(request, true)
      .pipe(
        debounceTime(400),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
