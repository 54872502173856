import moment from 'moment';
import { filter, map } from 'rxjs/operators';

import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Account,
  AccountTypeString,
  API_DATE_FORMAT,
  Contact,
  ContactHashtag,
  ContactRequest,
  ContactSpecialDayRequest,
  Customer,
  CustomerEmailType,
  CustomerPhoneType,
  DynamicFieldEntityAsString,
  DynamicFieldEntityId,
  Hashtag,
  processDynamicFieldValues,
  SpecialDayTypeEnum,
  User
} from '@core/api';
import { getUser } from '@core/store';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { MultiAccountDialogComponent } from '../multi-account-dialog/multi-account-dialog.component';
import { ContactDialogData } from '../contact-form-dialog/contact-form-dialog.component.model';
import { StaticField, StaticFieldFilterRequest } from '@core/api/static-field/static-field.model';
import { StaticFieldEnum } from '@core/api/static-field/static-field.enum';
import { StaticFieldService } from '@core/api/static-field/static-field.service';
import { InputCustomerEmailsAndPhonesValidators } from '../input-customer-emails/input-customer-emails-and-phones.validators';
import { Icon } from '@shared/enums';
import { InputHashtagType } from '../input-hashtag/input-hashtag.component.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpecialDay } from '../input-special-day/input-special-day.component.model';
import { scrollToError } from '@shared/utils';
import { SharedModule } from '@shared/modules';
import { InputCustomerEmailsComponent } from '../input-customer-emails/input-customer-emails.component';
import { InputCustomerAddressComponent } from '../input-customer-address/input-customer-address.component';
import { InputCustomerPhonesComponent } from '../input-customer-phones/input-customer-phones.component';
import { InputHashtagComponent } from '../input-hashtag/input-hashtag.component';
import { SelectAccountComponent } from '../select-account/select-account.component';
import { SelectClearanceComponent } from '../select-clearance/select-clearance.component';
import { SelectContactRoleGroupComponent } from '../select-contact-role-group/select-contact-role-group.component';
import { InputSpecialDayComponent } from '../input-special-day/input-special-day.component';
import { InputDynamicFieldValuesComponent } from '../input-dynamic-field-values/input-dynamic-field-values.component';
import { AppValidators } from 'src/app/app.validators';
import { ToastService } from '@shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    InputCustomerEmailsComponent,
    InputCustomerAddressComponent,
    InputCustomerPhonesComponent,
    InputHashtagComponent,
    SelectAccountComponent,
    SelectClearanceComponent,
    SelectContactRoleGroupComponent,
    InputSpecialDayComponent,
    InputDynamicFieldValuesComponent
  ]
})
export class ContactFormComponent implements OnInit, OnDestroy {

  @Input() set account(account: Account) {
    if (account) {
      this.customer = account.customer;
      this.customer.accountId = account.accountId;
      this.setCustomerForm();
    }
  }
  @Input() loading = false;
  @Input() isAccountEnabled: boolean;
  @Input() permissionControl = true;
  @Input() customer: Customer | Account = null;
  @Input() prefill: ContactDialogData['prefill'] | null;
  @Output() save = new EventEmitter<ContactRequest>();
  user: User;
  form: UntypedFormGroup;
  isPage = false;
  isDialog = false;
  hasClearance = true;
  openSeparator = false;
  inputHashtagType = InputHashtagType;
  dynamicFieldEntityIds = DynamicFieldEntityId;
  DynamicFieldEntityAsString = DynamicFieldEntityAsString;

  icSave = Icon.IC_SAVE;
  icClose = Icon.IC_TWOTONE_CLOSE;
  icWarning = Icon.IC_TWOTONE_WARNING;
  staticFields: StaticField[] = [];
  staticFieldEnum = StaticFieldEnum;

  @ViewChildren(InputCustomerPhonesComponent) phoneInput: QueryList<InputCustomerPhonesComponent>;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private staticFieldService: StaticFieldService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastService,
    private translate: TranslateService
  ) {
    // Create contact form
    this.form = this.formBuilder.group({
      // salutation: [null, Validators.required],
      firstName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      customer: [this.customer, Validators.required],
      account: [null, Validators.required],
      clearanceLevel: [null],
      contactRoleGroup: [null],
      address: [null],
      jobTitle: [null, Validators.maxLength(255)],
      emails: [null, { validators: [InputCustomerEmailsAndPhonesValidators.validateEmails(true)] }],
      phones: [null, AppValidators.phones],
      specialDays: [null],
      hashtags: [null],
      description: [null],
      dynamicFieldValues: [null],
    });

    // Get static fields
    this.getStaticFields();

    // Sync account changes with customer
    this.form.get('account')
      .valueChanges
      .pipe(filter(value => !!value))
      .subscribe((account: any) => {
        if (account.accountType === AccountTypeString.CONSUMER) {
          this.toastrService.warn(this.translate.instant('ResultMessage.Contact cannot be added to Consumers.'));
          this.form.get('customer').setValue(null);
          this.form.get('account').setValue(null);
          this.account = null;
          this.customer = null;
        } else {
          this.customer = account.customer ?? account;
          this.form.get('customer').setValue(this.customer);
        }
      });

    // Fetch current user
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);
  }

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  @Input()
  set newContact(contact: Contact) {
    if (contact) {
      this.form.patchValue({
        // salutation: contact.salutation,
        firstName: contact.firstName,
        lastName: contact.lastName,
      });

      if (contact.customer) {
        this.customer = contact.customer;
        this.setCustomerForm();
        // this.form.get('customer').setValue(contact.customer);
        // this.form.get('customer').disable();
        // this.form.get('account').disable();
      }

      this.form.patchValue({
        clearanceLevel: '1',
        emails: [{ mailName: contact?.emails?.length > 0 ? contact?.emails[0]?.mailName : '', emailTypeId: CustomerEmailType.CORPORATE }],
        phones: [{ phoneTypeId: CustomerPhoneType.OFFICE, phoneNumber: contact?.phones?.length > 0 ? contact?.phones[0]?.phoneNumber : '' }]
      });
    }
  }

  private _contact: Contact = undefined;

  get contact(): Contact {
    return this._contact;
  }

  @Input()
  set contact(contact: Contact) {
    this._contact = contact;

    if (contact) {
      this.form.patchValue({
        // salutation: contact.salutation,
        firstName: contact.firstName,
        lastName: contact.lastName,
        clearanceLevel: contact.clearenceLevel.toString(),
        contactRoleGroup: contact.contactRoleGroup,
        address: contact.address,
        jobTitle: contact.jobTitle,
        emails: contact.emails,
        phones: contact.phones,
        hashtags: contact.contactHashtags?.map(item => item.hashtag),
        specialDays: contact.contactSpecialDays?.map(item => ({
          contactSpecialDayId: item.contactSpecialDayId,
          date: item.specialDay,
          type: item.specialDayType,
          description: item.description,
        } as SpecialDay)),
        description: contact.description,
        dynamicFieldValues: contact.dynamicFieldValues || [],
      });

      if (contact.customer) {
        this.customer = contact.customer;
        this.setCustomerForm();
        // this.form.get('customer').setValue(contact.customer);
        // this.form.get('customer').disable();
        // this.form.get('account').disable();
      }

      // Disable form when user clearance level is lower than contact
      if (this.user.clearenceLevel < contact.clearenceLevel) {
        this.hasClearance = false;
        this.form.disable();
      }

      // pdplFlag is true when contact is Consumer Contact
      if (this.contact.pdplFlag) {
        this.form.disable();
      }

      return;
    }

    this.form.patchValue({
      clearanceLevel: '1',
      emails: [{ mailName: '', emailTypeId: CustomerEmailType.CORPORATE }],
      phones: [{ phoneTypeId: CustomerPhoneType.OFFICE }]
    });
  }

  updateSpecialDaysValidator(required: boolean) {

    if (required) {

      this.form.get('specialDays').setValidators(Validators.required);
      this.form.get('specialDays').markAsTouched();
    } else {
      this.form.get('specialDays').setValidators([]);
    }
    this.form.get('specialDays').updateValueAndValidity({
      emitEvent: false
    });

  }

  updateRequiredValidators() {
    const emails = this.form.get('emails');

    const isEmailRequired = this.getStaticFieldRequiredFlag(this.staticFieldEnum.EMAIL_CONTACT);

    emails.setValidators(InputCustomerEmailsAndPhonesValidators.validateEmails(isEmailRequired));

    emails.updateValueAndValidity();
  }

  getPhones() {
    return this.form.get('phones').value.filter(phone => phone.phoneNumber && phone.phoneNumber !== `+${phone.country.code}` && phone.phoneTypeId);
  }

  ngOnInit() {

    // If customer given with input, disable customer changes.
    if (this.customer) {
      this.setCustomerForm();
    }

    // When prefilled data provided apply to the form
    if (this.prefill) {
      Object.keys(this.prefill).forEach(key => {
        // Extract value from prefill
        const value = this.prefill[key];

        // Handle customer value set
        if ('customer' === key) {
          this.form.get('account').setValue(value);
          this.form.get('customer').setValue(value);
          return;
        }

        const control = this.form.get(key);
        if (!control) {
          console.warn(`Contact form does not have ${key} control, prefill failed. {key: ${key}, value: ${value}}}`);
          return;
        }

        control.setValue(value);
      });
    }
  }

  goTo() {
    if (this.contact?.customer?.accounts?.length > 1) {
      this.dialog
        .open(MultiAccountDialogComponent, {
          autoFocus: false,
          data: { accounts: this.contact },
        })
        .addPanelClass('cdk-full-overlay');
    } else if (this.contact?.customer?.accounts?.length === 1) {
      this.router.navigate(['/leads', this.contact?.customer?.accounts[0]?.accountId]);
    } else {
      this.router.navigate(['/leads-drafts', this.contact?.customer?.leadDraft?.leadDraftId]);
    }
  }

  setCustomerForm() {
    this.form.get('account').patchValue(this.customer, { emitEvent: false });
    this.form.get('customer').setValue(this.customer);

    if (!this.isAccountEnabled) {
      this.form.get('customer').disable();
      this.form.get('account').disable();
    }
  }

  onSubmit() {
    this.form.markAllAsTouched();

    const isSpeacialDaysValid = !this.form.get('specialDays').hasValidator(Validators.required);
    this.updateRequiredValidators();

    if (this.getStaticFieldRequiredFlag(this.staticFieldEnum.EMAIL_CONTACT)) {
      const emails = this.form.get('emails')?.value;
      if (emails.length === 0) {
        return;
      }
      for (const email of emails) {
        if (!(email.mailName && email.emailTypeId)) {
          return;
        }
      }
    }

    if (this.getStaticFieldRequiredFlag(this.staticFieldEnum.PHONE_CONTACT)) {
      const phones = this.form.get('phones')?.value;
      if (phones.length === 0) {
        return;
      }
      for (const phone of phones) {
        if (!(phone.phoneNumber && phone.phoneTypeId && phone.country)) {
          return;
        }
      }
    }

    let isPhoneValid = true;
    for (const phoneForm of this.phoneInput) {
      if (!phoneForm.isFormValid()) {
        isPhoneValid = false;
        break;
      }
    }

    if (this.form.valid && this.hasClearance && isPhoneValid) {
      if (isSpeacialDaysValid) {
        this.emitContact();
      } else {
        this.form.get('specialDays').markAsTouched();
        this.openSeparator = true;
        this.cdr.detectChanges();
        scrollToError();
      }

    } else {
      this.form.markAllAsTouched();
      this.openSeparator = true;
      this.cdr.detectChanges();
      scrollToError();
      this.openSeparator = false;
    }
  }

  private getStaticFields() {
    const request: StaticFieldFilterRequest = {
      filter: {
        entityName: 'Contact'
      }
    };

    this.staticFieldService.search(request).pipe(
      map(response => response.data.results),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((staticFields) => {
      this.staticFields = staticFields;
      this.updateRequiredValidators();
    });

  }

  getStaticFieldRequiredFlag(staticFieldId: string): boolean {
    return this.staticFields.find(f => f.entityFieldId === staticFieldId)?.requiredFlag ?? false;
  }

  getStaticFieldActiveFlag(staticFieldId: string): boolean {
    return this.staticFields.find(f => f.entityFieldId === staticFieldId)?.activeFlag ?? false;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  private emitContact() {
    const hashtags: Hashtag[] = this.form.get('hashtags').value || [];
    const specialDays: SpecialDay[] = this.form.get('specialDays').value || [];
    let contactSpecialDays = [];

    if (specialDays) {
      contactSpecialDays = specialDays.map(day => {
        const specialDay: ContactSpecialDayRequest = {
          contactSpecialDayId: day.contactSpecialDayId,
          specialDay: moment(day.date).format(API_DATE_FORMAT),
          description: day.description,
          specialDayTypeId: SpecialDayTypeEnum.OTHER
        };

        if (this.contact) {
          specialDay.contactId = this.contact.contactId;
        }

        return specialDay;
      });
    }

    const enabled = this.contact ? this.contact.enabled : true;

    // Emit save event with contact request object
    this.save.emit({
      // salutationId: this.form.get('salutation').value.salutationId,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      customerId: this.form.get('customer').value.customerId,
      jobTitle: this.form.get('jobTitle')?.value,
      clearenceLevel: Number(this.form.get('clearanceLevel').value),
      contactRoleGroupId: this.form.get('contactRoleGroup')?.value ? this.form.get('contactRoleGroup').value.contactRoleGroupId : null,
      addressId: this.form.get('address')?.value ? this.form.get('address').value.addressId : null,
      emails: this.form.get('emails').value ? this.form.get('emails').value.filter(email => email.mailName) : null,
      phones: this.form.get('phones').value ? this.getPhones() : null,
      enabled,
      contactSpecialDays,
      accountIds: [this.form.get('account').value.accountId],
      contactHashtags: hashtags.map(hashtag => {
        const contactHashtag: ContactHashtag = {};

        if (this.contact) {
          contactHashtag.contactId = this.contact.contactId;
        }

        if (hashtag.hashtagId) {
          contactHashtag.hashtagId = hashtag.hashtagId;
        } else {
          contactHashtag.hashtag = hashtag;
        }

        return contactHashtag;
      }),
      description: this.form.get('description').value,
      dynamicFieldValues: processDynamicFieldValues(this.form.get('dynamicFieldValues')?.value),
    });
  }
}
