import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  FilterRequest, Response, PagedResponse, SystemUserStructure,
  SystemUserStructureUserInfo, SystemUserStructureDownload, SystemUserStructureUserCopy
} from '@core/api';

@Injectable({
  providedIn: 'root'
})

export class SystemUserStructureService {
  constructor(private http: HttpClient) { }

  search(request: FilterRequest): Observable<PagedResponse<SystemUserStructure>> {
    return this.http.post<PagedResponse<SystemUserStructure>>(environment.apiUrl + '/api/SystemUserStructure/Search', request);
  }

  get(systemUserStructureId: string): Observable<Response<SystemUserStructure>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserStructure/Get', JSON.stringify(systemUserStructureId), { headers });
  }

  userCopySearch(systemUserStructureId: string[]): Observable<Response<SystemUserStructure[]>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SystemUserStructure[]>>(environment.apiUrl +
      '/api/SystemUserStructure/UserCopySearch', JSON.stringify(systemUserStructureId), { headers });
  }

  userCopy(request: SystemUserStructureUserCopy): Observable<Response<SystemUserStructure>> {
    return this.http.post<Response<SystemUserStructure>>(environment.apiUrl + '/api/SystemUserStructure/UserCopy', request);
  }

  insert(request: any) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserStructure/Insert', request);
  }

  update(request: any) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserStructure/Update', request);
  }

  delete(request: any) {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserStructure/Delete', request);
  }

  userInfo() {
    return this.http.post<Response<SystemUserStructureUserInfo>>(environment.apiUrl + '/api/SystemUserStructure/UserInfo', {});
  }

  download(request: { salesOrganizationId: string }): Observable<Response<SystemUserStructureDownload>> {
    return this.http.post<Response<SystemUserStructureDownload>>(environment.apiUrl + '/api/SystemUserStructure/Download', request);
  }
}
