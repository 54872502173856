<input #input (blur)="onBlur()" [formControl]="inputCtrl" [matAutocomplete]="auto"
  [matTooltipDisabled]="!!this.customer" [matTooltip]="'GENERAL.PLEASE_SELECT_A_CUSTOMER' | translate"
  [readonly]="!this.customer || isDisabled" [tabIndex]="tabIndex" class="autocomplete-input" matInput>
<mat-autocomplete #auto="matAutocomplete" (closed)="onClosed()" (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true" [displayWith]="displayName">
  @if (addresses) {
  @if (addresses.length === 0 && !loading) {
  <mat-option [disabled]="isDisabled" disabled>{{"GENERAL.ADDRESS_NOT_FOUND" | translate}}</mat-option>
  }

  @for (address of addresses; track $index) {
  <mat-option [disabled]="isDisabled" [disabled]="isDisabled" [value]="address">
    <span>{{ address.name }}</span>
    <span class="address-detail" fxHide.lt-md> - {{ address.addressLine }}
      @if (address.county?.name && address.city?.name) {
      <ng-container>
        {{ address.county.name}}/{{ address.city.name }}
      </ng-container>
      }

    </span>
  </mat-option>
  }
  }

</mat-autocomplete>

@if (!disabled && editable && value) {
<button (click)="add(value)" [disabled]="isDisabled" [hidden]="this.loading" class="edit-address" mat-icon-button
  type="button">
  <net-iconify-icon [icon]="icEdit" size="16"></net-iconify-icon>
</button>
}



@if (!disabled) {
<button (click)="add()" [disabled]="isDisabled" [hidden]="this.loading" class="add-address" mat-icon-button
  type="button">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>
}


@if (loading === true) {
<mat-progress-spinner *ngIf="" diameter="16" mode="indeterminate"></mat-progress-spinner>
}