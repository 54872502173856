import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@core/api';
import { environment } from '@env/environment';

import { ContractCurrency, ContractCurrencyRequest } from './contract-currency.model';

@Injectable({
  providedIn: 'root'
})
export class ContractCurrencyService {
  constructor(private http: HttpClient) { }

  get(uuid: string): Observable<Response<ContractCurrency>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<ContractCurrency>>(environment.apiUrl + '/api/ContractCurrency/Get', JSON.stringify(uuid), { headers });
  }

  insert(request: ContractCurrencyRequest): Observable<Response<ContractCurrency>> {
    return this.http.post<Response<ContractCurrency>>(environment.apiUrl + '/api/ContractCurrency/Insert', request);
  }

  update(request: ContractCurrencyRequest): Observable<Response<ContractCurrency>> {
    return this.http.post<Response<ContractCurrency>>(environment.apiUrl + '/api/ContractCurrency/Update', request);
  }
}
