import { NgModule } from '@angular/core';
import { CommonModule, NgIf, UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomNumberPipe, DynamicTranslatePipe, ImagePipe, IncludesPipe, MomentFormatPipe, NormalizeDecimalSeparatorPipe, OrderByPipe, SortByPipe } from '@shared/pipes';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableCustomizedFiltersComponent } from '@shared/components/mat-table-customized-filters/mat-table-customized-filters.component';
import { MatTableColumnFilterComponent } from '@shared/components/mat-table-column-filter/mat-table-column-filter.component';
import { MatHeaderFilterComponent } from '@shared/components/mat-header-filter/mat-header-filter.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProfilePhotoComponent } from '@shared/components/profile-photo/profile-photo.component';
import { ProgressButtonComponent } from '@shared/components/progress-button/progress-button.component';
import { MatTablePaginatorComponent } from '@shared/components/mat-table-paginator/mat-table-paginator.component';
import { ScrollDirective, UpperCaseDirective } from '@shared/directives';
import { IconifyIconComponent } from '@shared/components/iconify-icon/iconify-icon.component';
import { FullSpinnerComponent } from 'src/app/layouts/components/full-spinner/full-spinner.component';
import { InputDatetimeComponent } from '@shared/components/input-datetime/input-datetime.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InputDynamicFieldPicklistComponent } from '@shared/components/input-dynamic-field-picklist/input-dynamic-field-picklist.component';
import { InputTimeComponent } from '@shared/components/input-time/input-time.component';
import { ReportCustomizedFiltersComponent } from '@shared/components/report-customized-filters/report-customized-filters.component';
import { InputNumberComponent } from '@shared/components/input-number/input-number.component';
import { NgxMaskDirective } from 'ngx-mask';
import { SelectServiceUnitPipe } from '@shared/components/select-service-unit/select-service-unit.pipe';
import { SelectServicePipe } from '@shared/components/select-service/select-service.pipe';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatSpinnerButtonComponent } from '@shared/components/spinner-button/spinner-button.component';
import { SelectMeetingStatusComponent } from '@shared/components/select-meeting-status/select-meeting-status.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InputEditorCkComponent } from '@shared/components/input-editor-ck/input-editor-ck.component';
import { SelectCarrierUnitComponent } from '@shared/components/select-carrier-unit/select-carrier-unit.component';
import { SelectNotificationTemplateComponent } from '@shared/components/select-notification-template/select-notification-template.component';
import { FocusOnShowDirective } from '@shared/directives/focus-on-show.directive';
import { NumbersOnlyDirective } from '@shared/directives/numbers-only.directive';


@NgModule({
  declarations: [
    MomentFormatPipe,
    NormalizeDecimalSeparatorPipe,
    DynamicTranslatePipe,
    OrderByPipe,
    IncludesPipe,
    SortByPipe,

    UpperCaseDirective,

    MatTableCustomizedFiltersComponent,
    MatTableColumnFilterComponent,
    MatHeaderFilterComponent,
    ReportCustomizedFiltersComponent,
    ProfilePhotoComponent,
    ProgressButtonComponent,
    MatSpinnerButtonComponent,
    MatTablePaginatorComponent,
    IconifyIconComponent,
    FullSpinnerComponent,
    InputDatetimeComponent,
    InputDynamicFieldPicklistComponent,
    InputTimeComponent,
    InputNumberComponent,
    SelectServiceUnitPipe,
    SelectServicePipe,
    SelectMeetingStatusComponent,
    InputEditorCkComponent,
    SelectCarrierUnitComponent,
    SelectNotificationTemplateComponent,
    FocusOnShowDirective,
    NumbersOnlyDirective
  ],
  imports: [
    NgxMaskDirective,
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    CKEditorModule,
    NgIf,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    OverlayModule,
    CustomNumberPipe,
    UpperCasePipe,
    InfiniteScrollDirective,
    ScrollDirective
  ],
  exports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    MaterialModule,
    OverlayModule,
    NgxSkeletonLoaderModule,
    CKEditorModule,

    MomentFormatPipe,
    NormalizeDecimalSeparatorPipe,
    DynamicTranslatePipe,
    CustomNumberPipe,
    OrderByPipe,
    UpperCasePipe,
    IncludesPipe,
    SortByPipe,

    UpperCaseDirective,

    NgIf,
    MatTableCustomizedFiltersComponent,
    MatTableColumnFilterComponent,
    MatHeaderFilterComponent,
    ReportCustomizedFiltersComponent,
    ProfilePhotoComponent,
    ProgressButtonComponent,
    MatTablePaginatorComponent,
    IconifyIconComponent,
    FullSpinnerComponent,
    InputDatetimeComponent,
    InputDynamicFieldPicklistComponent,
    InputTimeComponent,
    InputNumberComponent,
    SelectServiceUnitPipe,
    SelectServicePipe,
    InfiniteScrollDirective,
    ScrollDirective,
    SelectMeetingStatusComponent,
    InputEditorCkComponent,
    FocusOnShowDirective,
    NumbersOnlyDirective
  ], providers: [
    NormalizeDecimalSeparatorPipe,
    ImagePipe
  ]
})
export class SharedModule { }
