import { Component, OnInit, TemplateRef } from '@angular/core';
import { animate, group, style, transition, trigger } from '@angular/animations';

import { PopoverContent, PopoverRef } from './popover-ref';
import { NgComponentOutlet, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'net-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  standalone: true,
  imports: [
    NgSwitchCase,
    NgSwitch,
    NgTemplateOutlet, NgComponentOutlet
  ],
  animations: [
    trigger('transformPopover', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0.6)'
        }),
        group([
          animate('100ms linear', style({
            opacity: 1
          })),
          animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({
            transform: 'scale(1)',
          })),
        ])
      ]),
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate('100ms linear', style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class PopoverComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component';
  content: PopoverContent;
  context;

  constructor(private popoverRef: PopoverRef) {
  }

  ngOnInit() {
    this.content = this.popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef)
      };
    }

  }
}
