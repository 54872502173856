/**
 * Color generator with seed support
 *
 * Generates constant randomness with given seed value
 */
export class ColorGenerator {
  private seedValue = Math.random() * (1000000 - 1) + 1;
  private boardReportColor: string[] = ['#574c98', '#69b8c9', '#4474b4', '#335199', '#d8dbe2', '#a9bbcf', '#7785c2', '#9fcee2', '#b197c7', '#6a469d', '#57739b', '#9da5d4',
    '#14a556', '#aad7ac', '#21b5a7', '#028283', '#d17462', '#f5a08b', '#febd6b', '#e5ebdf', '#7e8598', '#455b82', '#a9bbcf', '#d7dae1',
    '#ee6a5b', '#f4f1bc', '#5ba4aa', '#9cc0bc', '#0a889f', '#55beed', '#9ad7d0', '#74c6a0', '#f8953a', '#4b8579', '#115878', '#6cb25c'];

  private calendarColors: string[] = [
    '#564c97', '#4574b4', '#335199', '#68b8c8',
    '#53518C', '#2F2E61', '#3F4081', '#6A67AB', '#7E7FCA', '#A09FDD', '#C0BFF5', '#CDD0ED',
    '#3475B9', '#005BA2', '#1675C5', '#3886D2', '#549BE6', '#6DABF2', '#82BAF9', '#9AC7F9',
    '#28519D', '#153776', '#24488A', '#365BA4', '#476DB7', '#507ACB', '#678FE2', '#87AFFF',
    '#44BAC9', '#008E9F', '#00A1B2', '#00B1C3', '#35C0CE', '#4DD1E0', '#72D9E7', '#A2E4EE'
  ];
  private availableColors: string[];
  private useOriginalColors = true;

  constructor(seed?: number | string) {
    if (seed) {
      this.seed(seed);
    }
    this.resetColors();
  }

  /**
   * Returns random value like Math.random with generator's seed value
   *
   * @private
   */
  private random(): number {
    const random = Math.sin(this.seedValue) * 10000;
    this.seedValue++;

    return (random - Math.floor(random));
  }

  /**
   * Updates and resets seed value of generator with given seed
   *
   * @param seed
   */
  seed(seed: number | string) {
    if (typeof seed === 'string') {
      // tslint:disable-next-line:no-bitwise
      this.seedValue = Array.from(seed).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
      return;
    }

    this.seedValue = seed;
  }

  /**
   * Returns random color intensity between 0 - 255
   */
  randomIntensity(): number {
    return Math.floor(this.random() * 255);
  }

  /**
   * Returns random rgba color in string with opacity support
   *
   * @param opacity
   */
  rgba(opacity?: number): string {
    return `rgba(${this.randomIntensity()}, ${this.randomIntensity()}, ${this.randomIntensity()}, ${opacity ?? 1})`;
  }

  darkRgba() {
    // validate hex string
    const lum = -0.30;
    let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    let rgb = '#';
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ('96' + c).substr(c.length);
    }
    return rgb;
  }

  rgb2hex(rgb): string {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? '#' +
      ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
      ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
      ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
  }

  getBordChartColor(index: number): string {
    return this.boardReportColor[index % 36];
  }

  getCalendarColor(): string {
    if (this.availableColors.length === 0) {
      this.resetColors();
    }
    const randomIndex = Math.floor(Math.random() * this.availableColors.length);
    const color = this.availableColors[randomIndex];
    this.availableColors.splice(randomIndex, 1);
    return color;
  }

  private resetColors(): void {
    if (this.useOriginalColors) {
      this.availableColors = [...this.calendarColors];
    } else {
      this.availableColors = this.calendarColors.map(color => this.lightenColor(color, 5));
    }
    this.shuffleArray(this.availableColors);
    this.useOriginalColors = !this.useOriginalColors;
  }

  private shuffleArray(array: string[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  private lightenColor(color: string, percent: number): string {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    const amt = Math.round(2.55 * percent);

    const newR = Math.min(255, Math.max(0, r + amt));
    const newG = Math.min(255, Math.max(0, g + amt));
    const newB = Math.min(255, Math.max(0, b + amt));

    return `#${this.padZero(newR.toString(16))}${this.padZero(newG.toString(16))}${this.padZero(newB.toString(16))}`;
  }

  private padZero(str: string): string {
    return str.padStart(2, '0');
  }
}
