import { of } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';

import {
    BoardCardUserData, BoardInfoUserReportSearchDataRequest, BoardReportFilter, BoardReportService
} from '@core/api';

import { ApiDataSource } from '../api.data-source';

export class BoardReportCreaterUserTableDataSource extends ApiDataSource<BoardCardUserData> {

  public $users = of([]).pipe(delay(10));

  constructor(
    private boardReportService: BoardReportService,
    public initialFilter?: BoardInfoUserReportSearchDataRequest,
    public tableFilter?: BoardReportFilter
  ) {
    super(initialFilter);
    this.$users = of(this.tableFilter.systemUsers.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1).map(item => {
      return {
        key: item.systemUserId,
        value: item.fullName
      };
    }));
  }

  load(): void {
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    if (request.orderBy === undefined) {
      request.orderType = 'DESC';
    }
    else {
      if (request.orderBy !== 'totalCardCount' &&
        request.orderBy !== 'assignedSystemUserIds' &&
        request.orderBy !== 'fullName') {
        filter.boardInfoId = request.orderBy;
        request.orderBy = 'boardInfoId';
      }
    }

    request.filter = filter;

    // Fetch data
    this.boardReportService
      .cardCreateUserReportData(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe((response) => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
