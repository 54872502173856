export enum NotificationSourceType {
    Account = '0',
    LeadDraft = '1',
    Activity = '2',
    Opportunity = '3',
    Assignment = '4',
    Board = '5',
    Card = '6',
    CustomerNote = '7',
    Contact = '8',
    Report = '9'
}

export enum NotificationIcon {
    CHECK = 'f8877b9e-88dc-4a59-9c1b-fd61e2d79be9',
    INFO_OUTLINE = '1b390cba-dbc1-4486-b13e-98c32fb30ec0',
    ALARM_ADD = '5b869f98-f161-4e73-abf7-bd62b39de7f7',
    CANCEL = 'd270e5d3-3042-4095-836b-b8200e07cc14'
}
