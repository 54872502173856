import { finalize } from 'rxjs/operators';
import { ApiDataSource } from '../api.data-source';
import { AccountWarningService } from './account-warning.service';
import { AccountWarning } from './account-warning.model';

export class AccountWarningDataSource extends ApiDataSource<AccountWarning> {

  constructor(
    private accountWarningService: AccountWarningService,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load() {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    this.accountWarningService.search(request).pipe(
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(response => {
      // Update count and data subjects
      this.dataSubject.next(response.data.results);
      this.dataCountSubject.next(response.data.rowCount);
      this.rowCount$.next(response.data.rowCount);
    });
  }
}
