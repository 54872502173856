import { Pipe, PipeTransform } from '@angular/core';

type unit = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

/**
 * Convert bytes into largest possible unit.
 * Takes an precision argument that can be a number or a map for each unit.
 *
 * Usage:
 *   bytes | fileSize:precision
 * @example
 * // returns 1 KB
 * {{ 1500 | fileSize }}
 * @example
 * // returns 2.1 GB
 * {{ 2100000000 | fileSize }}
 * @example
 * // returns 1.46 KB
 * {{ 1500 | fileSize:2 }}
 */
@Pipe({
  name: 'formatFileSize',
  standalone: true
})
export class FormatFileSizePipe implements PipeTransform {
  private readonly units: unit[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  private defaultPrecisionMap: { [u in unit]: number; } = {
    B: 0,
    KB: 0,
    MB: 1,
    GB: 1,
    TB: 2,
    PB: 2
  };

  transform(bytes: number = 0, precision: number | unitPrecisionMap = this.defaultPrecisionMap): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '?';
    }

    let unitIndex = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unitValue = this.units[unitIndex];

    if (typeof precision === 'number') {
      return `${bytes.toFixed(+precision)} ${unitValue}`;
    }

    return `${bytes.toFixed(precision[unitValue])} ${unitValue}`;
  }
}
