<form [formGroup]="form" class="assignment-detail" [class.page]="isPage" [class.dialog]="isDialog">
  <ng-template #content>
    <div class="form-elements" style="max-width: 100% !important;" fxLayout="column">
      <ng-container *ngIf="!loading">
        <div class="adjustment" style="width: 40rem !important;"></div>
        <mat-form-field class="full-column" fxFlex="100">
          <mat-label>{{ 'GENERAL.TASK' | translate}}</mat-label>
          <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>

        <div [fxLayout]="isDialog ? 'column' : ''" [fxLayout.gt-xs]="isDialog ? 'column' : 'row'"
          [fxLayoutGap.gt-xs]="isDialog ? '' : '24px'">
          <mat-form-field fxFlex="100">
            <mat-label>{{ 'GENERAL.SALES_ORGANIZATION' | translate}}</mat-label>
            <input matInput *ngIf="assignment; else salesOrganizationList" type="text"
              [value]="assignment.salesOrganization.name" [disabled]="true">

            <ng-template #salesOrganizationList>
              <net-select-sales-organization [systemUserId]="user?.userId" formControlName="salesOrganization">
              </net-select-sales-organization>
            </ng-template>
          </mat-form-field>

          <mat-form-field fxFlex="100" *ngIf="accountSearchPermission && form.get('salesOrganization').value">
            <mat-label>{{ 'GENERAL.CUSTOMER' | translate}}</mat-label>
            <net-select-account [click]="!!form.get('account').value"
              [salesOrganizationId]="form.get('salesOrganization').value.salesOrganizationId" formControlName="account"
              [ngClass]="{'customer-name-zone': form.get('account').value}" [dialogClose]="dialogClose"
              [isClickable]="!!assignment?.assignmentId">
            </net-select-account>

            <input matInput *ngIf="!form.get('account').value && assignment?.activityAssignments?.length > 0 "
              type="text"
              [value]="assignment?.activityAssignments[0]?.activity?.leadDraftInfoActivity?.leadDraftInfo?.leadDraft?.customer.customerShortName"
              [disabled]=" true" class="mt-1">
          </mat-form-field>

        </div>

        <div [fxLayout]="isDialog ? 'column' : ''" [fxLayout.gt-xs]="isDialog ? 'column' : 'row'"
          [fxLayoutGap.gt-xs]="isDialog ? '' : '24px'">
          <mat-form-field fxFlex="100" *ngIf="form.get('salesOrganization').value">
            <mat-label>{{ 'GENERAL.ASSIGNEE' | translate}}</mat-label>
            <input matInput *ngIf="assignment; else userList" type="text" [value]="assignment.assignedUser.displayName"
              [disabled]="true">

            <ng-template #userList>
              <net-multi-select-user [salesOrganizationIds]="[form.get('salesOrganization').value?.salesOrganizationId]"
                [matTooltip]="'GENERAL.SELECT_SALES_ORGANIZATION' | translate"
                [matTooltipDisabled]="form.get('salesOrganization').value" [showOnlyActiveUsers]="true"
                formControlName="assignedUser"></net-multi-select-user>
            </ng-template>
          </mat-form-field>

          <div class="flex items-center" fxFlex="100">
            <ng-container *ngIf="(!form.get('isRecurring').value || !isAddForm()); else repeats">
              <mat-form-field [fxFlex]="isDialog ? 70 : 50">
                <mat-label>{{'GENERAL.DUE_DATE' | translate}}</mat-label>
                <input matInput formControlName="dueDate" readonly [placeholder]="'GENERAL.DUE_DATE' | translate"
                  [matDatepicker]="datePicker" (click)="datePicker.open()">
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              </mat-form-field>
            </ng-container>
            <ng-template #repeats>
              <mat-form-field [fxFlex]="isDialog ? 70 : 50">
                <mat-label>{{ 'TASK.REPEATS' | translate}}</mat-label>
                <mat-select formControlName="repeatOption">
                  <mat-option *ngFor="let item of repeatValues" [value]="item.key">{{item.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
            <div class="flex justify-center pb-2" [fxFlex]="isDialog ? 30 : 50">
              <mat-slide-toggle formControlName="isRecurring" [matTooltip]="getTooltip()"
                matTooltipClass="recurring-tooltip" color="primary">
                {{ 'TASK.RECURRING_TASK' | translate}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <ng-container *ngIf="form.get('isRecurring').value && isAddForm()">
          <ng-container *ngIf="form.get('repeatOption').value === EnumRepeatCycle.Weekly">
            <mat-form-field fxFlex="100">
              <mat-label>{{ 'TASK.DEADLINE_DAYS' | translate}}</mat-label>
              <mat-select formControlName="deadlineDays" multiple>
                <mat-option *ngFor="let item of deadlineDays" [value]="item.key">{{item.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="form.get('repeatOption').value === EnumRepeatCycle.Monthly">
            <div class="full-column mb-4" fxFlex="100">
              <ng-container *ngIf="user.culture === 'en'; then enVersion1 else trVersion1"></ng-container>
              <ng-template #enVersion1>
                <div class="h-9">Repeat every
                  <mat-form-field class="mx-2 w-12">
                    <input formControlName="repeatEvery" matInput type="number">
                  </mat-form-field> month
                </div>
              </ng-template>
              <ng-template #trVersion1>
                <div class="h-9">Her
                  <mat-form-field class="mx-2 w-12">
                    <input formControlName="repeatEvery" matInput type="number">
                  </mat-form-field> ayda bir tekrarla
                </div>
              </ng-template>
              <div>
                <mat-radio-group formControlName="selectedMonthlyOption" color="primary"
                  class="flex flex-col my-3 items-start">
                  <div class="flex items-center h-9">
                    <mat-radio-button value='1'>
                    </mat-radio-button>
                    <div class="flex items-center gap-4">
                      <span>{{'TASK.ON_DAY' | translate:{ day: '' } }}</span>
                      <mat-form-field class="w-12">
                        <input formControlName="onDay" matInput type="number">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="flex items-center h-9">
                    <mat-radio-button value='2'>
                    </mat-radio-button>
                    <div class="flex items-center gap-4">
                      <span>{{'TASK.ON_THE_WEEKDAYS' | translate:{ firstLastOption: '', weekdays: '' } }}</span>
                      <mat-form-field>
                        <mat-select formControlName="monthlyOption2_1">
                          <mat-option *ngFor="let item of options" [value]="item.key">{{item.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-select formControlName="monthlyOption2_2">
                          <mat-option *ngFor="let item of deadlineDays" [value]="item.key">{{item.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="flex items-center h-9">
                    <mat-radio-button value='3'>
                    </mat-radio-button>
                    <div class="flex items-center gap-4">
                      <span>{{'TASK.ON_THE_DAYOPTION' | translate:{ firstLastOption: '', dayOption: '' } }}</span>
                      <mat-form-field>
                        <mat-select formControlName="monthlyOption3_1">
                          <mat-option *ngFor="let item of options" [value]="item.key">{{item.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-select formControlName="monthlyOption3_2">
                          <mat-option *ngFor="let item of dayOptions" [value]="item.key">{{item.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>
          </ng-container>

          <div fxFlex="100" class="relative">
            <mat-form-field fxFlex="100">
              <mat-label class="inline-flex gap-2">{{ 'TASK.ASSIGNMENT_DATE' | translate}}
                <net-iconify-icon [matTooltip]="'TASK.ASSIGNMENT_DATE_INFO' | translate"
                  class="fs-base text-primary-500 pointer-events-auto" [icon]="icInfo" size="16">
                </net-iconify-icon>
              </mat-label>
              <mat-select formControlName="assignmentDate">
                <mat-option *ngFor="let item of assignmentDates" [value]="item.assignmentDatePropertyId">
                  {{("AssignmentDateProperty." + item.name) | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex-col full-column" fxFlex="100">
            <div class="recurring-date-container justify-between" fxFlex="100">
              <mat-form-field fxFlex="49">
                <mat-label>{{'GENERAL.START_DATE' | translate}}</mat-label>
                <input formControlName="recurringStartDate" matInput readonly
                  [placeholder]="'GENERAL.START_DATE' | translate" [matDatepicker]="datePicker1" [min]="today"
                  (click)="datePicker1.open()">
                <mat-datepicker #datePicker1></mat-datepicker>
                <mat-datepicker-toggle [for]="datePicker1" matSuffix></mat-datepicker-toggle>
              </mat-form-field>

              <mat-form-field fxFlex="49">
                <input formControlName="recurringEndDate" matInput readonly
                  [placeholder]="'GENERAL.END_DATE' | translate" [matDatepicker]="datePicker2"
                  [min]="form.get('recurringStartDate').value" (click)="datePicker2.open()">
                <mat-datepicker #datePicker2></mat-datepicker>
                <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
                <mat-error *ngIf="form.hasError('minDateError')">
                  {{ 'TASK.END_DATE_CANNOT_BE_LESS_THAN_THE_START_DATE' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-error class="-mt-4 mb-2" *ngIf="form.hasError('dateRangeError')">
              {{ 'TASK.DATE_INTERVAL_CAN_BE_MAX_2_YEARS' | translate }}
            </mat-error>
          </div>

        </ng-container>

        <mat-form-field [class.full-column]="isAddForm() && form.get('repeatOption').value !== EnumRepeatCycle.Monthly">
          <mat-label>{{ 'TASK.PRIORITY' | translate}}</mat-label>
          <net-select-assignment-priority formControlName="assignmentPriorityId">
          </net-select-assignment-priority>
        </mat-form-field>

        <net-input-dynamic-field-values [columns]="isDialog ? 1 : 2" [entityId]="DynamicFieldEntityId.TASK"
          [entityName]="'Task'" formControlName="dynamicFieldValues" [isDisabled]="form.disabled"
          [mode]="!assignment ? 'add' : 'edit'" [openSeparator]="openSeparator" [uploadType]="'Assignment'"
          class="full-column"></net-input-dynamic-field-values>

        <mat-form-field fxFlex="100" [hidden]="!assignment">
          <mat-label>{{ 'GENERAL.STATUS' | translate}}</mat-label>
          <mat-select [placeholder]="'GENERAL.STATUS' | translate" formControlName="assignmentStatusId">
            <ng-container *ngIf="!assignment">
              <mat-option [value]="assignmentStatus.ASSIGNED">{{'AssignmentStatus.Assigned' | translate}}</mat-option>
            </ng-container>
            <ng-container *ngIf="assignment">
              <ng-container>
                <ng-container *ngFor="let status of assignmentStatuses">
                  <mat-option *ngIf="checkStatusVisibility(status)" [value]="status.key">
                    {{ status.value }}
                  </mat-option>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="form.get('assignmentStatusId').getError('disableItemSelected')" style="font-size: 10px;">
            {{ 'TASK.PASSIVE_STATUS_CANNOT_BE_USED' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-column" fxFlex="100"
          *ngIf="form.value.assignmentStatusId !== assignmentStatus.ASSIGNED">
          <mat-label>{{'GENERAL.DETAIL' | translate}}</mat-label>
          <textarea matInput formControlName="explanation" cdkTextareaAutosize cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>

        <net-assignment-document *ngIf="hasDocumentViewPermission && assignment" [assignment]="assignment"
          class="full-column">
        </net-assignment-document>

        <net-mail-link-list class="mt-4" [type]="mailLinkTypes.ASSIGNMENT" [objectId]="assignment.assignmentId" [accountId]="form.get('account').value?.accountId"
          [collapsible]="true" [showIcon]="true" *ngIf="hasMailLinkSearchPermission && assignment"></net-mail-link-list>

        <net-ticket-link-list class="mt-4" [type]="ticketLinkTypes.ASSIGNMENT" [objectId]="assignment.assignmentId"
          [collapsible]="true" [showIcon]="true" *ngIf="hasSearchCXLinkPermitted && assignment"></net-ticket-link-list>

        <!-- <net-document-upload *ngIf="hasDocumentViewPermission && !assignment" [type]="uploadType.ASSIGNMENT"
          [options]="uploaderOptions" [systemUserParameter]="systemUserParameter" [submit]="submit">
        </net-document-upload> -->

      </ng-container>
      <ng-container *ngIf="loading">
        <div style="width: 40rem"></div>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex class="textarea" appearance="line"></ngx-skeleton-loader>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #action>
    <net-progress-button class="ml-auto mt-3" [icon]="icSave" [active]="loading" [disabled]="assignment && isReadOnly()"
      *ngIf="(form.enabled && assignment && !isReadOnly() && isUserIncludesAssignment()) || (form.enabled && !assignment) || assignmentUpdateAllUserPermission"
      (btnClick)="onSubmit()" text="{{'GENERAL.SAVE' | translate}}"></net-progress-button>
  </ng-template>

  <ng-template [ngIf]="isDialog">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0" fxFlex="auto">
        <ngx-skeleton-loader *ngIf="loading" fxFlex appearance="line"></ngx-skeleton-loader>
        <span *ngIf="assignment && !loading">{{ 'TASK.TASK_DETAIL' | translate}}</span>
        <span *ngIf="!assignment && !loading">{{ 'TASK.NEW_TASK' | translate}}</span>
      </h2>

      <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </mat-dialog-actions>
  </ng-template>

  <ng-template [ngIf]="isPage">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <div fxLayout="row" fxLayoutAlign="start center" class="w-full">
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </div>
  </ng-template>

</form>