import { isMoment } from 'moment';

export type MatCustomDataAccessor = (item: any, property: string) => string | number | null;

export function matFilterDataAccessor(item, property, customAccessor?: MatCustomDataAccessor) {
  const value = item[property];

  if (isMoment(value)) {
    return value.format('DD MMMM YYYY');
  }

  if (customAccessor) {
    return customAccessor.call(null, item, property);
  }

  return value;
}

export function matSortingDataAccessor(item, property, customAccessor?: MatCustomDataAccessor) {
  let value = item[property];

  if (customAccessor) {
    const customValue = customAccessor.call(null, item, property);

    if (!!customValue) {
      value = customValue;
    }
  }

  if (isMoment(value)) {
    return value.format('YYYYMMDDHHmmss');
  }

  return value;
}
