import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Response } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class SystemUserPasswordCodeInfoService {
  constructor(private http: HttpClient) { }

  insert(request: any): Promise<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserPasswordCodeInfo/Insert', request).toPromise();
  }

}
