import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Account, Document, DocumentDetail, DocumentService, DocumentTypeEnum, User } from '@core/api';
import { SystemUserParameter } from '@core/api/system-user-parameter/system-user-parameter.model';
import { Permission } from '@core/auth/auth.enum';
import { AuthService } from '@core/auth/auth.service';
import { getUser, hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import { FormatFileSizePipe } from '@shared/pipes';
import { NgxUploaderModule, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import Swal from 'sweetalert2';
import { ToastService } from '../../services/toast.service';
import { SelectSalesOrganizationDialogComponent } from '../select-sales-organization-dialog/select-sales-organization-dialog.component';
import { DocumentUploadType } from './document-upload.component.enum';
import { DocumentUploadOptions } from './document-upload.component.model';


@Component({
  selector: 'net-document-upload[type][systemUserParameter]',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  providers: [FormatFileSizePipe],
  standalone: true,
  imports: [
    SharedModule,
    NgxUploaderModule
  ]
})
export class DocumentUploadComponent implements OnInit, OnDestroy {
  static nextId = 0;

  readonly defaultOptions: UploaderOptions = {
    concurrency: 2,
  };

  dragOver: boolean;
  formData: FormData;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

  loading = true;
  singleLoading = false;
  hasUploadPermission = false;
  hasDeletePermission = false;
  hasDownloadPermission = false;
  hasOpportunityCommercialDocumentDeletePermission = false;

  icFile = Icon.FA_SOLID_FILE_UPLOAD;
  icUpload = Icon.FA_SOLID_UPLOAD;
  icRemove = Icon.SOLID_TRASH_ALT;
  icDownload = Icon.FA_SOLID_DOWNLOAD;

  translations: any;
  documentUploadTypes = DocumentUploadType;
  user: User;

  @Input() type: DocumentUploadType;
  @Input() options: DocumentUploadOptions = {};
  @Input() multiple = true;
  @Input() document: DocumentDetail;
  @Input() uploadData: { [key: string]: string | Blob; };
  @Input() description: string;
  @Input() systemUserParameter: SystemUserParameter;
  @Input() icon: any;
  @Input() url: string;
  @Input() hasOpportunityCommercialDocumentDeletePermissionControl = false;
  @Input() account: Account;
  @Output() done: EventEmitter<null> = new EventEmitter<null>();
  @Output() upload: EventEmitter<Document[]> = new EventEmitter<Document[]>();
  @Output() delete: EventEmitter<DocumentDetail> = new EventEmitter<DocumentDetail>();
  @Output() download: EventEmitter<Document> = new EventEmitter<Document>();
  @Output() uploadFiles: EventEmitter<UploadFile[]> = new EventEmitter<UploadFile[]>();
  @HostBinding('id') id = `net-document-upload-${DocumentUploadComponent.nextId++}`;
  @ViewChild('documentInput') documentInput: ElementRef<any>;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private store: Store,
    private toastService: ToastService,
    private documentService: DocumentService,
    private formatFileSizePipe: FormatFileSizePipe,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    translate.get(['DOCUMENT', 'GENERAL']).subscribe(translations => {
      this.translations = translations;
    });

    // Fetch user permissions
    this.store.select(hasUserPermission(Permission.DOCUMENT_UPLOAD))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.hasUploadPermission = response);
    this.store.select(hasUserPermission(Permission.DOCUMENT_DOWNLOAD))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.hasDownloadPermission = response);
    this.store.select(hasUserPermission(Permission.DOCUMENT_DELETE))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.hasDeletePermission = response);
    this.store.select(hasUserPermission(Permission.OPPORTUNITY_COMMERCIAL_DOCUMENT_DELETE))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.hasOpportunityCommercialDocumentDeletePermission = response);
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);
  }

  private _submit: string;

  @Input()
  get submit(): string {
    return this._submit;
  }

  set submit(value: string) {
    if (value !== '') {
      if (DocumentUploadType.ASSIGNMENT === this.type) {
        this.options.queryString = value;
      }
      this.startUpload();
    }
  }

  ngOnInit(): void {
    // Extend custom options over default options
    if (this.options) {
      this.options = { ...this.defaultOptions, ...this.options };
    }

    if (!this.options.allowedExtensions) {
      this.getEtensionType();
    } else {
      this.loading = false;
    }
  }

  getEtensionType() {
    // Fetch document extension type
    this.documentService.extensionType().subscribe(response => {
      if (response.success) {
        const allowedExtensions = response.data.map(item => {
          return item.name.toLocaleLowerCase();
        });

        this.options.allowedExtensions = allowedExtensions;
      }
    }).add(() => this.loading = false);
  }

  async onUploadOutput(output: UploadOutput): Promise<void> {
    switch (output.type) {
      case 'allAddedToQueue':
        let allowUpload = true;
        if (this.options.salesOrganizationSelection) {
          const dialogRef = this.dialog.open(SelectSalesOrganizationDialogComponent, {
            data: { userId: this.user.userId, salesOrganizations: this.options.salesOrganizationIds },
            width: '550px',
          });

          const result = await dialogRef.afterClosed().toPromise();
          if (result) {
            this.options.queryString = this.updateSalesOrganizationId(this.options.queryString, result.salesOrganizationId);
          } else {
            allowUpload = false;
          }
        }

        if (!allowUpload) {
          this.clearUploadInput();
          this.files = [];
          break;
        }

        if (allowUpload && this.options.autoUpload) {
          const event: UploadInput = {
            type: 'uploadAll',
            url: (this.url ?? this.documentService.uploadUrl) + (this.options.queryString ?? ''),
            method: 'POST',
            fieldName: 'uploadFiles',
            headers: {
              authorization: `Bearer ${AuthService.getToken()}`
            },
            includeWebKitFormBoundary: true,
          };

          if (this.uploadData) {
            event.data = this.uploadData;
          }

          this.uploadInput.emit(event);
        }
        break;

      case 'addedToQueue':
        if (!output.file) {
          return;
        }

        this.files.push(output.file);
        this.uploadFiles.emit(this.files);

        setTimeout(() => {
          this.validate();
        });
        break;

      case 'start':
        if (!output.file) {
          return;
        }

        this.singleLoading = true;
        break;

      case 'uploading':
        if (!output.file) {
          return;
        }

        const index = this.files.findIndex(file => file.id === output.file.id);
        this.files[index] = output.file;

        this.uploadFiles.emit(this.files);
        break;

      case 'done':
        if (!output.file) {
          return;
        }
        // Fire document upload event
        if (output.file.response.success) {
          this.upload.next(output.file.response);
        }

        // Fire upload done event when selected file stack is completed
        if (this.files.length === 1) {
          setTimeout(() => this.done.next(null));
        }
        this.clearUploadInput();
        // Remove uploaded file from list after a while
        setTimeout(() => this.removeUpload(output.file.id), 1);
        break;

      case 'rejected':
        if (!output.file) {
          return;
        }

        if (this.files.length >= this.options.maxUploads) {
          this.toastService.info(this.translate.instant('DOCUMENT.YOU_CAN_UPLOAD_MAXIMUM_FILES', { value: this.options.maxUploads }));
          return;
        }

        if (output.file.size > this.options.maxFileSize) {
          this.toastService.info(this.translate.instant('DOCUMENT.DOCUMENT_EXCEEDS_THE_MAXIMUM_FILE_SIZE_UPLOAD_FAILED',
            { value1: output.file.name, value2: this.formatFileSizePipe.transform(this.options.maxFileSize, 1) }));
        }

        break;

      case 'removed':
      case 'cancelled':
        if (!output.file) {
          return;
        }

        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        this.uploadFiles.emit(this.files);
        this.singleLoading = false;
        break;

      case 'removedAll':
        if (!output.file) {
          return;
        }

        this.files = [];
        this.uploadFiles.emit(this.files);
        break;

      case 'dragOver':
        this.dragOver = true;
        break;

      case 'drop':
      case 'dragOut':
        this.dragOver = false;
        break;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: (this.url ?? this.documentService.uploadUrl) + this.options.queryString,
      method: 'POST',
      fieldName: 'uploadFiles',
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`
      },
      includeWebKitFormBoundary: true,
    };

    if (this.uploadData) {
      event.data = this.uploadData;
    }

    this.uploadInput.emit(event);
  }

  removeUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id });
    this.uploadInput.emit({ type: 'remove', id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  validate(): void {
    if (this.files.length > 0) {
      let currentUploadSize = this.systemUserParameter?.systemUserCurrentUploadSize;

      this.files.forEach(file => {
        currentUploadSize += file.size;

        // Make sure file is allowed
        if (this.options.allowedExtensions && false === this.options.allowedExtensions.includes(file.name.toLocaleLowerCase().match(/(\.([^.]+))$/i)[0])) {
          this.toastService.info(this.translate.instant('DOCUMENT.FILE_WAS_NOT_ACCEPTED_BECAUSE_IT_IS_NOT_AMONG_THE_ALLOWED_FILE_TYPES', { value: file.name }));
          this.removeUpload(file.id);
        }

        // Make sure system user upload size is not exceeded after file
        if (currentUploadSize > this.systemUserParameter?.systemUserUploadSize) {
          this.toastService.info(this.translate.instant('DOCUMENT.EXCEEDS_THE_MAXIMUM_FILE_SIZE_OF_RESERVED_FOR_YOU_UPLOADING_IS_NOT_POSSIBLE',
            { value: this.formatFileSizePipe.transform(this.systemUserParameter?.systemUserUploadSize, 1) }));
          this.removeAllFiles();
          return;
        }

        // Make sure content upload size is not exceeded after file
        if (currentUploadSize > this.systemUserParameter?.systemUserUploadSize) {
          this.toastService.info(this.translate.instant('DOCUMENT.EXCEEDS_THE_MAXIMUM_FILE_SIZE_ALLOWED_FOR_THE_VALUE_UPLOADING_IS_NOT_POSSIBLE',
            {
              value1: this.getTypeName(),
              value2: this.formatFileSizePipe.transform(this.systemUserParameter.contentUploadSize, 1)
            }));
          this.removeAllFiles();
          return;
        }
      });
    }
  }

  clickDelete() {
    if ((!this.multiple && !this.document) || !this.hasDeletePermission) {
      return;
    }

    Swal.fire({
      icon: 'warning',
      html: `
        <strong>${this.document.name}</strong> ${this.translations?.DOCUMENT.DOCUMENT_WILL_BE_DELETED}
        <br><br>
        ${this.translations?.GENERAL.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE} `,
      focusCancel: true,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#7a7a7a',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL'),
    }).then((result) => {
      if (result.value) {
        this.documentService
          .delete(this.document.documentId)
          .subscribe(response => {
            if (response.success) {
              this.delete.emit(this.document);
              this.clearUploadInput();
              // this.toastService.info(`${this.document.name} ${this.translations?.DOCUMENT.DOCUMENT_WAS_DELETED}`);
              this.document = null;
            } else {
              this.toastService.error(`${this.document.name} ${this.translations?.DOCUMENT.DOCUMENT_COULD_NOT_BE_DELETED_PLEASE_TRY_AGAIN}`);
            }
          });
      }
    });
  }

  clickDownload(preview = false) {
    if (!this.document || !this.hasDownloadPermission) {
      return;
    }

    this.download.emit(this.document);

    const documentName = this.document.name + this.document.documentExtensionType.name;

    this.documentService.download(this.document.documentId, documentName, preview);
  }

  getOpportunityCommercialDocumentDeletePermissionControl() {
    const isPriceListOrContract = [
      DocumentTypeEnum.SIGNED_PRICE_LIST,
      DocumentTypeEnum.SIGNED_CONTRACT,
    ].includes(this.document.documentTypeId as DocumentTypeEnum);

    const isCusOppDoc = [
      DocumentTypeEnum.OPP_DOC_ONE,
      DocumentTypeEnum.OPP_DOC_TWO,
      DocumentTypeEnum.OPP_DOC_THREE,
      DocumentTypeEnum.OPP_DOC_FOUR
    ].includes(this.document.documentTypeId as DocumentTypeEnum);

    if (isCusOppDoc) {
      if (!this.hasOpportunityCommercialDocumentDeletePermissionControl && this.hasDeletePermission) {
        return true;
      }
    }

    if (isPriceListOrContract) {
      if ((!this.hasOpportunityCommercialDocumentDeletePermissionControl && this.hasDeletePermission) ||
        (this.hasOpportunityCommercialDocumentDeletePermissionControl && this.hasDeletePermission && this.hasOpportunityCommercialDocumentDeletePermission)
      ) {
        return true;
      }
    } else {
      if ((!this.account.customer.hasActivePassiveOpportunities && this.hasDeletePermission) ||
        (this.account.customer.hasActivePassiveOpportunities && this.hasDeletePermission && this.hasOpportunityCommercialDocumentDeletePermission)
      ) {
        return true;
      }
    }

    return false;
  }

  clearUploadInput() {
    if (this.documentInput) {
      this.documentInput.nativeElement.value = '';
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  private getTypeName(): string {
    switch (this.type) {
      case DocumentUploadType.ACCOUNT:
        return this.translations?.GENERAL.CUSTOMER;
      case DocumentUploadType.ACTIVITY:
        return this.translations?.GENERAL.ACTIVITY;
      case DocumentUploadType.OPPORTUNITY:
        return this.translations?.GENERAL.OPPORTUNITY;
      default:
        throw Error('Unknown page name for page type.');
    }
  }

  private updateSalesOrganizationId(url: string, newSalesOrganizationId: string) {
    return url.replace(/(salesOrganizationId=)[^&]+/, `$1${newSalesOrganizationId}`);
  }
}
