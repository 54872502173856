import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy,
  OnInit, Optional, Output, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import {
  MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Country } from '@core/api';
import { GetCountries, getCountries, getCountriesIsLoading } from '@core/store/country';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/modules';
import { Icon } from '@shared/enums';

@Component({
  selector: 'net-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCountryComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectCountryComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Country | null>, OnInit {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-country';
  describedBy = '';
  stateChanges = new Subject<void>();

  countries: Country[] = [];
  filteredCountries: Country[] = [];
  inputCtrl = new UntypedFormControl();

  icArrowDropDown = Icon.IC_TWOTONE_ARROW_DROP_DOWN;

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  @Input() tabIndex: number;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Country | null { return this._value; }
  set value(value: Country | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Country;

  @Input() panelWidth: string | number;

  @Input() tooltip: string;

  @Output() optionSelected = new EventEmitter<Country>();

  @HostBinding('id') id = `select-country-${SelectCountryComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private translate: TranslateService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.store.dispatch(new GetCountries());
    this.store.select(getCountries()).subscribe((countries) => {
      this.countries = countries;
      this.filteredCountries = countries;
    });

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.filteredCountries = [];
        this.value = null;
      })
    ).subscribe((response) => {
      const filterValue = response.toLowerCase();
      this.filteredCountries = this.countries.filter(country => this.translate.instant(`Country.${country.name}`)?.toLowerCase().includes(filterValue));
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.store.select(getCountriesIsLoading).subscribe(isLoading => {
      this.loading = isLoading;
      this.cdr.detectChanges();
    });
  }

  displayName = (country: Country) => country ? this.translate.instant(`Country.${country.name}`) : '';

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.filteredCountries = this.countries;
  }

  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplete.openPanel();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();

    this.optionSelected.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value && !this.loading && this.filteredCountries.length !== 0) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Country) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
