import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BaseResponse,
  CustomListTable,
  CustomListTableRequest,
  CustomListUpdateUsersRequest,
  CustomListValue,
  DynamicField,
  DynamicFieldDefaultValue,
  DynamicFieldEntity,
  DynamicFieldFilterRequest,
  DynamicFieldPicklist,
  DynamicFieldPicklistFilter,
  DynamicFieldPicklistRequest,
  DynamicFieldRequest,
  DynamicFieldType,
  DynamicFieldUpdateOrderItem,
  FilterRequest,
  PagedResponse,
  PagedResponseData,
  Response,
  SystemUser,
} from '@core/api';
import { environment } from '@env/environment';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicFieldService {

  customListRefreshRequired$ = new ReplaySubject<boolean>();

  customList = {
    search: (request: FilterRequest) => {
      return this.http.post<PagedResponse<CustomListTable>>(environment.apiUrl + '/api/CustomList/Search', request);
    },
    list: () => {
      return this.http.post<Response<any[]>>(environment.apiUrl + '/api/CustomList/List', {});
    },
    insert: (request: CustomListTableRequest) => {
      return this.http.post<Response<CustomListTable>>(environment.apiUrl + '/api/CustomList/Insert', request);
    },
    update: (request: CustomListTableRequest) => {
      return this.http.post<Response<CustomListTable>>(environment.apiUrl + '/api/CustomList/Update', request);
    },
    updateOrder: (request: any) => {
      return this.http.post<Response<CustomListTable>>(environment.apiUrl + '/api/CustomList/UpdateOrder', request);
    },
    updateUsers: (request: CustomListUpdateUsersRequest) => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/CustomList/UpdateUsers', request);
    },
    delete: (uuid: string) => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<BaseResponse>(environment.apiUrl + '/api/CustomList/Delete', JSON.stringify(uuid), { headers });
    },
    get: (uuid: string) => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<CustomListTable>>(environment.apiUrl + '/api/CustomList/Get', JSON.stringify(uuid), { headers });
    },
    users: (request: { customListId: string }) => {
      return this.http.post<Response<SystemUser[]>>(environment.apiUrl + '/api/CustomList/Users', request);
    }
  };

  customListDynamicField = {
    insert: (customListId: string, request: DynamicFieldRequest) => {
      return this.http.post<BaseResponse>(environment.apiUrl + `/api/CustomListDynamicField/Insert?customListId=${customListId}`, request);
    }
  };

  customListValue = {
    search: (request: FilterRequest) => {
      return this.http.post<PagedResponse<CustomListValue>>(environment.apiUrl + '/api/CustomListValue/Search', request);
    },
    update: (request: CustomListValue) => {
      return this.http.post<Response<CustomListValue>>(environment.apiUrl + '/api/CustomListValue/Update', request);
    },
    insert: (request: CustomListValue) => {
      return this.http.post<Response<CustomListValue>>(environment.apiUrl + '/api/CustomListValue/Insert', request);
    },
    delete: (uuid: string) => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<BaseResponse>(environment.apiUrl + '/api/CustomListValue/Delete', JSON.stringify(uuid), { headers });
    }
  };

  constructor(private http: HttpClient) { }

  get(dynamicFieldId) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<DynamicField>>(environment.apiUrl + '/api/DynamicField/Get', JSON.stringify(dynamicFieldId), { headers });
  }

  search(request?: DynamicFieldFilterRequest) {
    return this.http.post<Response<PagedResponseData<DynamicField>>>(environment.apiUrl + '/api/DynamicField/Search', request);
  }

  delete(dynamicFieldId: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicField/Delete', JSON.stringify(dynamicFieldId), { headers });
  }

  insert(request: DynamicFieldRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicField/Insert', request);
  }

  insertBoard(boardId: string, request: DynamicFieldRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + `/api/BoardDynamicField/Insert/${boardId}?reportFlag=${request.reportFlag ?? false}`, request);
  }

  updateBoard(boardId: string, request: DynamicFieldRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + `/api/BoardDynamicField/Update/${boardId}?reportFlag=${request.reportFlag ?? false}`, request);
  }

  update(request: DynamicFieldRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicField/Update', request);
  }

  updateOrder(request: DynamicFieldUpdateOrderItem) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicField/UpdateOrder', request);
  }

  toggleStatus(dynamicFieldId: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicField/UpdateStatus', JSON.stringify(dynamicFieldId), { headers });
  }

  types() {
    return this.http.post<Response<DynamicFieldType[]>>(environment.apiUrl + '/api/DynamicFieldType/List', {});
  }

  entities() {
    return this.http.post<Response<DynamicFieldEntity[]>>(environment.apiUrl + '/api/DynamicFieldEntity/Search', {});
  }

  defaultValues(dynamicFieldTypeId: string) {
    return this.http.post<Response<DynamicFieldDefaultValue[]>>(environment.apiUrl + '/api/DynamicFieldDefaultValue/List', { dynamicFieldTypeId });
  }

  picklistInsert(request: DynamicFieldPicklistRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicFieldPickList/Insert', request);
  }

  picklistUpdate(request: DynamicFieldPicklistRequest) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicFieldPickList/Update', request);
  }

  picklistUpdateOrder(dynamicFieldPickListId: string, orderBy: number) {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicFieldPickList/UpdateOrder', { dynamicFieldPickListId, orderBy });
  }

  picklistUpdateStatus(dynamicFieldPickListId: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicFieldPickList/UpdateStatus', JSON.stringify(dynamicFieldPickListId), { headers });
  }

  picklistSearch(request) {
    return this.http.post<Response<DynamicFieldPicklist[]>>(environment.apiUrl + '/api/DynamicFieldPickList/Search', request);
  }

  picklistPaginationSearch(request: DynamicFieldPicklistFilter) {
    return this.http.post<Response<PagedResponseData<DynamicFieldPicklist>>>(environment.apiUrl + '/api/DynamicFieldPickList/PaginationSearch', request);
  }

  picklistDelete(dynamicFieldPickListId: string) {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/DynamicFieldPickList/Delete', JSON.stringify(dynamicFieldPickListId), { headers });
  }

  existingPicklists(request = {}) {
    return this.http.post<Response<DynamicFieldPicklist[]>>(environment.apiUrl + '/api/DynamicFieldPickList/ExistedPicklists', request);
  }

  setCapacity(request) {
    return this.http.post<Response<DynamicField>>(environment.apiUrl + '/api/DynamicFieldEntity/SetCapacity', request);
  }

  listBoards() {
    return this.http.post<Response<any[]>>(environment.apiUrl + '/api/DynamicField/ListBoards', {});
  }
}
