import { debounceTime, finalize, map, tap } from 'rxjs/operators';

import {
  AccountSourceService,
  AccountTypeNumber,
  ActivityFollowupStatusService,
  ApiService,
  CustomerTypeService,
  LeadDraftInfo,
  LeadDraftInfoStatusService,
  LocationService,
  SalesOrganizationService,
  SalesRouteDefinitionService,
  SectorMainService,
  SystemUserService,
  User
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';

export class LeadDraftDataSource extends ApiDataSource<LeadDraftInfo> {


  public isOpenedNewTab = false;
  public $leadDraftInfoStatus = this.leadDraftInfoStatusService.list({}).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.leadDraftInfoStatusId,
        value: this.translate.instant('LeadDraftInfoStatus.' + item.name)
      };
    }))
  );

  public $salesOrganizations = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(salesOrganizations => salesOrganizations.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  public $customerTypes = this.customerTypeService.list({}).pipe(
    map(response => response.data.results.sort((a, b) => b.orderBy - a.orderBy)),
    map(customerTypes => customerTypes.filter((customerType) => customerType.isUsed || customerType.enableFlag)),
    map(customerTypes => customerTypes.map(item => {
      return {
        key: item.customerTypeId,
        value: item.name
      };
    }))
  );

  public $enableCustomerTypes = this.customerTypeService.list({}).pipe(
    map(response => response.data.results.sort((a, b) => b.orderBy - a.orderBy)),
    map(customerTypes => customerTypes.find((customerType) => customerType.enableFlag === true) ? true : false)
  );

  public $countries = this.locationService.countryList({}).pipe(
    map(response => response.data.results),
    map(countries => countries.map(item => {
      return {
        key: item.countryId,
        value: this.translate.instant('Country.' + item.name)
      };
    }))
  );

  public $owners = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(owners => owners.map(item => {
        return {
          key: item.systemUserId,
          value: item.displayName,
        };
      }))
    );

  public $sectorMain = this.sectorMainService.list().pipe(
    map(response => response.data.sort((a, b) =>
      this.translate.instant('SectorMain.' + a.name).localeCompare(this.translate.instant('SectorMain.' + b.name))
    )),
    map(sectors => sectors.map(item => {
      return {
        key: item.sectorMainId,
        value: this.translate.instant('SectorMain.' + item.name)
      };
    }))
  );

  public $sources = this.accountService.list().pipe(
    map(response => response.data.sort((a, b) =>
      this.translate.instant('AccountSource.' + a.name).localeCompare(this.translate.instant('AccountSource.' + b.name))
    )),
    map(sources => sources.map(item => {
      return {
        key: item.accountSourceId,
        value: this.translate.instant('AccountSource.' + item.name)
      };
    }))
  );

  public $activityFollowupStatuses = this.activityFollowupStatusService.list().pipe(
    map(response => response.data),
    map(statuses => statuses.sort((a, b) => a.orderBy - b.orderBy)),
    map(followupStatuses => followupStatuses.map(item => {
      return {
        key: item.activityFollowupStatusId,
        value: this.translate.instant('LEAD_QUALIFICATION.' + item.name)
      };
    }))
  );

  public $salesRouteCities = this.salesRouteDefinitionService.salesRouteCityListForLeadDraft({}).pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(cities => cities.map(item => {
      return {
        key: item.cityId,
        value: this.translate.instant('City.' + item.name)
      };
    }))
  );

  public $accountTypes = of([
    { key: AccountTypeNumber.BUSINESS.toString(), value: this.translate.instant('GENERAL.BUSINESS') },
    { key: AccountTypeNumber.CONSUMER.toString(), value: this.translate.instant('GENERAL.CONSUMER') }
  ]);

  public request$ = new BehaviorSubject<{}>(null);

  constructor(
    private accountService: AccountSourceService,
    private activityFollowupStatusService: ActivityFollowupStatusService,
    private api: ApiService,
    private customerTypeService: CustomerTypeService,
    private leadDraftInfoStatusService: LeadDraftInfoStatusService,
    private locationService: LocationService,
    private salesOrganizationService: SalesOrganizationService,
    private sectorMainService: SectorMainService,
    private systemUserService: SystemUserService,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    private translate: TranslateService,
    private user: User,
    public initialFilter?: any,
  ) {
    super(initialFilter);
  }

  load(): void {
    if (!this.isOpenedNewTab) {

      // Init filter with data source's default filter
      const filter: any = { ...this.initialFilter, ...this.filter };

      // If filter keyword exists, filter data
      if (this.keyword) {
        filter.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request = this.getRequest();

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }
      // debugger;
      // Add filters to request
      request.filter = filter;

      // Fetch data
      this.api.leadDraft.info
        .search(request)
        .pipe(
          tap(() => this.request$.next(request)),
          debounceTime(400),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
          // Update count and data subjects
          this.dataSubject.next(response.data.results);
          this.dataCountSubject.next(response.data.rowCount);
          this.rowCount$.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });
    }
    return this.loadingSubject.next(false);
  }
}
