import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { MatProgressBarIcon } from './progress-button.component.model';
import { MatProgressButtonOptions } from '../spinner-button/mat-progress-buttons.interface';

@Component({
  selector: 'net-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent implements OnInit {
  @Input() isDisabled = false;
  options: MatProgressButtonOptions = {
    text: null,
    active: false,
    buttonIcon: { tailwindIcon: this.icon },
  };
  @Input() block = false;
  @Input() raised = true;
  @Input() stroked = false;
  @Input() fab = false;
  @Input() flat = false;
  @Input() type = 'submit';
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() color: ThemePalette = 'primary';
  @Input() spinner = 18;
  @Output() btnClick = new EventEmitter<MouseEvent>();
  @ViewChild('contentRef', { static: true }) contentRef: ElementRef<HTMLDivElement>;

  constructor(private domSanitizer: DomSanitizer, private iconRegistry: MatIconRegistry) {
  }

  private _customClass;

  @Input()
  get customClass(): string {
    return this._customClass;
  }

  set customClass(value: string) {
    this._customClass = value;
    if (value) {
      this.options = { ...this.options, customClass: value };
    }
  }

  private _text;

  @Input()
  get text(): any {
    return this._text;
  }

  set text(value: any) {
    this._text = value;
    if (value) {
      this.options.text = value;
    }
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;

    this.options = { ...this.options, disabled: value };
  }

  get active() {
    return this.options.active;
  }

  @Input()
  set active(value: boolean) {
    this.options = { ...this.options, active: value };
  }

  private _icon: string;

  get icon() {
    return this._icon;
  }

  @Input()
  set icon(value: string) {

    this._icon = value;
  }

  ngOnInit() {
    this.options = {
      mode: this.mode,
      text: this.text,
      type: this.type,
      active: this.active,
      raised: this.raised,
      fab: this.fab,
      flat: this.flat,
      disabled: this.disabled,
      fullWidth: this.block,
      buttonColor: this.color,
      spinnerSize: this.spinner,
      buttonIcon: { tailwindIcon: this.icon },
      stroked: this.stroked,
      customClass: this.customClass
    };

    if (this.contentRef.nativeElement.innerText) {
      this.options.text = this.contentRef.nativeElement.innerText;
    }
  }

  onBtnClick(event) {
    this.btnClick.emit(event);
  }

}
