<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center">
  <h2 fxFlex="auto">{{'GENERAL.KEYBOARD_SHORTCUTS' | translate}}</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <net-iconify-icon [icon]="icClose"></net-iconify-icon>
  </button>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-content>
  <p class="mt-4 mb-4">{{'GENERAL.YOU_CAN_OPERATE_FASTER_BY_USING_THE_SHORTCUT_TABLE_BELOW' | translate}}</p>
  <table>
    <tr>
      <th>{{'GENERAL.SHORTCUT' | translate}}</th>
      <th>{{'GENERAL.TASK' | translate}}</th>
    </tr>
    <tr *ngFor="let item of hotkeys">
      <td><kbd>{{ item.keys }}</kbd></td>
      <td>{{ item.description }}</td>
    </tr>
  </table>
</mat-dialog-content>