import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding, Input,
  OnDestroy, OnInit, Optional, Self, ViewChild
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Customer, CustomerEmailType, Email } from '@core/api';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-input-customer-emails[customer]',
  templateUrl: './input-customer-emails.component.html',
  styleUrls: ['./input-customer-emails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputCustomerEmailsComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class InputCustomerEmailsComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Email[] | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-customer-emails';
  describedBy = '';
  stateChanges = new Subject<void>();
  emailTypes = CustomerEmailType;

  emails: Email[] = [];
  form = new UntypedFormGroup({
    emails: new UntypedFormArray([this.createEmailForm()])
  });

  icAdd = Icon.IC_TWOTONE_ADD;
  icMinus = Icon.IC_TWOTONE_MINUS;

  @Input()
  get customer() { return this._customer; }
  set customer(customer: Customer) {
    this._customer = customer;

    if (customer) {
      this.form.enable();
    }
  }
  private _customer: Customer;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Email[] | null { return this._value; }
  set value(emails: Email[] | null) {
    this._value = emails;
    this.onChange(emails);
    this.stateChanges.next();
    this.changeDetectorRef.detectChanges();
  }
  private _value: Email[] = null;

  @HostBinding('id') id = `input-customer-emails-${InputCustomerEmailsComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @ViewChild('select', { static: true }) select: MatSelect;

  get shouldLabelFloat() { return true; }

  get empty() { return !this.value || this.value.length === 0; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const emails = this.getEmailsForm().value;
    if (emails[0]?.type) {
      this.updateEmails();
    }

    this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.updateEmails();
    });

    if (!this.customer) {
      this.form.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  updateEmails() {
    const emails: Email[] = [];

    this.getEmailsForm().controls.forEach((control, index) => {
      if (!this.customer) {
        return;
      }

      if (this.getEmailValue(index, 'emailId')) {
        emails.push({
          mailName: control.get('email').value,
          emailTypeId: control.get('type').value,
          customerId: this.customer.customerId,
          emailId: this.getEmailValue(index, 'emailId'),
          contactId: this.getEmailValue(index, 'contactId'),
          createdOn: this.getEmailValue(index, 'createdOn'),
          code: this.getEmailValue(index, 'code')
        });
      } else {
        emails.push({
          mailName: control.get('email').value,
          emailTypeId: control.get('type').value,
          customerId: this.customer.customerId
        });
      }
    });

    this.value = emails;
  }

  getEmailsForm(): UntypedFormArray {
    return this.form.get('emails') as UntypedFormArray;
  }

  getEmailValue(index: number, key: keyof Email): any {
    if (!this.emails || !this.emails[index]) {
      return null;
    }

    return this.emails[index][key];
  }

  createEmailForm(email?: Email): UntypedFormGroup {
    return this.formBuilder.group({
      type: [email ? email.emailTypeId : null, this.required ? Validators.required : null],
      email: [email ? email.mailName : null, this.required ? [Validators.required, Validators.email] : null]
    });
  }

  add(email?: Email) {
    this.getEmailsForm().push(this.createEmailForm(email));
    this.changeDetectorRef.detectChanges();
  }

  remove(index: number) {
    const form = this.getEmailsForm();

    if (form.length > 0) {
      form.removeAt(index);
      this.stateChanges.next();
      this.changeDetectorRef.detectChanges();
    }
  }

  writeValue(emails: Email[]) {
    if (emails?.length > 0) {
      this.getEmailsForm().clear();
      emails.forEach(email => this.add(email));

      this.emails = emails;
      this.value = emails;
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.touched && (this.ngControl.invalid || this.form.invalid);

      this.stateChanges.next();
    }
  }
}
