import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import './app/core/api/extensions';
import { environment } from '@env/environment';
import { enableProdMode } from '@angular/core';
import { Initial } from '@core/api';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MaintenanceModule } from './maintenance/maintenance.module';

if (environment.production) {
  enableProdMode();
}

(async () => {

  fetch(environment.apiUrl + '/api/Home/Initial', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(
    async (response) => {

      if (response.status === 200) {
        const result: Initial = await response.json();
        localStorage.setItem('system_setting', JSON.stringify(result ?? []));

        const setting = result?.googleAnalytics;
        localStorage.setItem('googleTagManagerId', setting ?? '@TODO');

        bootstrapApplication(AppComponent, appConfig).then().catch((err) =>
          console.error(err)
        );
      }

    }, async (error) => {
      document.getElementById('net-splash-screen').style.display = 'none';

      platformBrowserDynamic()
        .bootstrapModule(MaintenanceModule)
        .catch((err) => console.error(err));
    });

})();


