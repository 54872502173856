import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { SocketSearchRequest } from './board/board.model';
import * as signalR from '@microsoft/signalr';
import { debounceTime, filter, take, takeUntil, tap } from 'rxjs/operators';
import { BoardGroupType } from './board-detail-signal-r-type/board-group-type.enum';
import { CustomConsole } from '@shared/utils';
import { BoardDetailFilterService, BoardInfoSignalRService, BoardHubRequest, CardHubRequest } from '@core/api';

export abstract class BoardDetailSignalR {

    console = new CustomConsole();

    protected groupName: string;
    protected viewName: string;
    protected hubConnection: signalR.HubConnection;
    public filter: SocketSearchRequest;

    private filterSubject = new BehaviorSubject<SocketSearchRequest>(null);
    filter$ = this.filterSubject.asObservable();

    public loadingSubject = new ReplaySubject<boolean>();
    public loading$ = this.loadingSubject.asObservable();

    public panelLoadingSubject = new ReplaySubject<boolean>();
    public panelLoading$ = this.panelLoadingSubject.asObservable();

    public openedCardIdsSubject = new BehaviorSubject<string[]>(null);

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        protected boardInfoSignalRService: BoardInfoSignalRService,
        protected boardDetailFilterService?: BoardDetailFilterService
    ) {
        this.hubConnection = this.boardInfoSignalRService.getHubConnection();
    }

    getBoardInfosListener() {
        this.boardInfoSignalRService.addBoardInfoDataListener(this.groupName);
        this.boardInfoSignalRService.addCards();
    }

    removeFromGroup() {

        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            this.hubConnection.invoke(BoardGroupType.RemoveFromGroup, this.groupName, null);
            this.removeJoinedGroupList();
        }
    }

    clearBoardInfoData() {
        this.boardInfoSignalRService.clearBoardInfoData();
    }

    listenHeaderFilter(request: SocketSearchRequest) {
        // this.getBoardInfosListener();
        this.subscribeBoardDetailFilterSubject();
        this.subscribeFilterSubject();
        this.boardDetailFilterService.updateFilter(request);
    }

    subscribeBoardDetailFilterSubject() {

        this.boardDetailFilterService.filter$.pipe(
            filter(request => !!request),
            debounceTime(400),
            takeUntil(this.unsubscribe$)
        ).subscribe(response => {
            this.filterSubject.next({ ...this.filterSubject.value, ...response });
        });

    }

    subscribeFilterSubject() {

        this.filter$.pipe(
            filter(request => !!request),
            tap((request) => {
                this.loadingSubject.next(true);
                this.console.info('request', request);
            }),
            debounceTime(400),
            takeUntil(this.unsubscribe$)
        ).subscribe((response) => {
            this.boardInfoSignalRService.connection$.pipe(
                filter(() => this.hubConnection.state === signalR.HubConnectionState.Connected),
                take(1)
            ).subscribe(connected => {
                if (connected && this.hubConnection.state === signalR.HubConnectionState.Connected) {
                    response.filter = {
                        ...response.filter,
                        ...this.openedCardIdsSubject.value ? { openedSubcardsParentIds: this.openedCardIdsSubject.value } : {}
                    };
                    response = {
                        ...response,
                        orderBy: 'OrderBy',
                        orderType: 'ASC'
                    };
                    this.filter = response;

                    this.boardInfoSignalRService.addBoardInfoDataListener(this.groupName);
                    this.boardInfoSignalRService.invokeBoardInfos(this.viewName, response);
                }
            });
        });
    }

    removeJoinedGroupList() {
        this.boardInfoSignalRService.joinedGroupList = [];
    }

    unsubscribeFilter() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    abstract boardDataInvoke(methodName: string, request: CardHubRequest | BoardHubRequest);
}
