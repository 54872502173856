
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { Response, PagedResponse, Organization, OrganizationFilterRequest } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient) { }

  search(request: OrganizationFilterRequest): Observable<PagedResponse<Organization>> {
    return this.http.post<PagedResponse<Organization>>(environment.apiUrl + '/api/Organization/Search', request);
  }

  list(): Observable<PagedResponse<Organization>> {
    return this.http.post<PagedResponse<Organization>>(environment.apiUrl + '/api/Organization/List', {});
  }

  get(organizationId: string): Observable<Response<Organization>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Organization>>(environment.apiUrl + '/api/Organization/Get', JSON.stringify(organizationId), { headers });
  }

  update(request: any): Observable<Response<Organization>> {
    return this.http.post<Response<Organization>>(environment.apiUrl + '/api/Organization/Update', request);
  }

  insert(request: any): Observable<Response<Organization>> {
    return this.http.post<Response<Organization>>(environment.apiUrl + '/api/Organization/Insert', request);
  }

  delete(request: any): Observable<Response<Organization>> {
    return this.http.post<Response<Organization>>(environment.apiUrl + '/api/Organization/Delete', request);
  }
}
