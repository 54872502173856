<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <net-profile-photo class="mr-3" [height]="48" [width]="48" [fontSize]="24"
        [systemUser]="user"></net-profile-photo>
      <div class="dropdown-heading" *ngIf="user">{{ user.firstName }} {{ user.lastName }}</div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu"
            mat-icon-button
            matTooltip="Settings"
            matTooltipPosition="before"
            type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button> -->
  </div>


  <div class="dropdown-content">
    <a (click)="close()" *ngFor="let item of items; trackBy: trackById.bind(this, 'id')" [routerLink]="item.route"
      class="notification" fxLayout="row" fxLayoutAlign="start center" matRipple>
      <net-iconify-icon [icon]="item.icon" [ngClass]="item.colorClass" class="notification-icon" size="24"
        fxFlex="none"></net-iconify-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{item.label}}</div>
        <!-- <div class="notification-description">{{ item.description }}</div> -->
      </div>
      <net-iconify-icon [icon]="icChevronRight" class="notification-chevron" fxFlex="none"></net-iconify-icon>
    </a>
  </div>


  <div *ngIf="user?.userName?.indexOf('@') !== -1" class="dropdown-footer toolbarFooterText">
    <!-- <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">
      <ng-container *ngFor="let status of statuses; trackBy: trackById">
        <span *ngIf="status === activeStatus">
          <mat-icon [icIcon]="status.icon" [ngClass]="status.colorClass"></mat-icon>
          <span>{{ status.label }}</span>
          <mat-icon [icIcon]="icArrowDropDown" class="dropdown-footer-select-caret"></mat-icon>
        </span>
      </ng-container>
    </button> -->

    <a class="dropdown-footer-link" (click)="openUpdatePasswordDialog()" color="primary" mat-button>
      {{'TOOLBAR.PASSWORD_UPDATE' | translate}}
    </a>
  </div>
  <div class="dropdown-footer toolbarFooterText" fxLayout="row" fxLayoutAlign="space-between center">
    <a class="dropdown-footer-link" (click)="logout()" color="primary" mat-button>
      {{'TOOLBAR.SAFE_LOGOUT' | translate}}
    </a>
  </div>

</div>

<!--<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)" *ngFor="let status of statuses; trackBy: trackById" mat-menu-item>
    <ic-icon [icon]="status.icon" [ngClass]="status.colorClass" inline="true" size="24px"></ic-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>


<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icBusiness"></mat-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icVerifiedUser"></mat-icon>
    <span>Change Username</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icLock"></mat-icon>
    <span>Change Password</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>-->