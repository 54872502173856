import { BehaviorSubject, of } from 'rxjs';
import { delay, finalize, map, tap } from 'rxjs/operators';
import { SectorMainService, SurveyService } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { SurveyReportService } from './survey-report.service';
import { SurveyReport } from './survey-report.model';
import { SurveyMailTypeService } from '../survey-mail-type/survey-mail-type.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getNetPromoterScoreReportState } from '@core/store';
import { NpsAccountStatusBySalesOrganizationPipe } from 'src/app/nps-report/nps-account-status-by-sales-organization.pipe';

export class SurveyReportDataSource extends ApiDataSource<SurveyReport> {
  public skipParams = false;

  public $surveyMailTypes = this.surveyMailTypeService.list({ orderBy: 'name' }).pipe(
    map(response => response.data),
    map(types => {
      return types.map(type => {
        return {
          key: type.surveyMailListTypeId,
          value: this.translate.instant(`MAIL_LIST.${type.name}`)
        };
      });
    })
  );

  public $sectorMains = this.sectorMainService?.list().pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.sectorMainId,
        value: this.translate.instant('SectorMain.' + item.name)
      };
    }))
  );

  public $surveys = this.surveyService.search({ pageSize: 1000, filter: {} }).pipe(
    map(response => response.data.results),
    map(surveys => surveys.sort((a, b) => a.name.localeCompare(b.name)).map(survey => {
      return {
        key: survey.surveyId,
        value: survey.name
      };
    }))
  );

  public $accountStatuses = this.store.select(getNetPromoterScoreReportState)
    .pipe(
      map(response => new NpsAccountStatusBySalesOrganizationPipe().transform(response.accountStatuses, response.salesOrganizationId)),
      map(accountStatuses => {
        return accountStatuses.sort((a, b) => a.orderBy - b.orderBy).map(accountStatus => {
          return {
            key: accountStatus.accountStatusId,
            value: this.translate.instant('AccountStatus.' + accountStatus.name)
          };
        });
      }));

  public $salesRouteDefinitions = this.store.select(getNetPromoterScoreReportState)
    .pipe(
      map(response => response.salesRouteDefinitions),
      map(salesRouteDefinitions => {
        return salesRouteDefinitions.map(salesRouteDefinition => {
          return {
            key: salesRouteDefinition.salesRouteDefinitionId,
            value: salesRouteDefinition.name
          };
        });
      }));

  public $industries = this.store.select(getNetPromoterScoreReportState)
    .pipe(
      map(response => response.industries),
      map(industries => {
        return industries.map(industry => {
          return {
            key: industry.sectorMainId,
            value: this.translate.instant('SectorMain.' + industry.name)
          };
        });
      }));

  public $mailList = this.store.select(getNetPromoterScoreReportState)
    .pipe(map(response => response.mailList), map(mailList => {
      return mailList.sort((a, b) => a.name.localeCompare(b.name)).map(mail => {
        return {
          key: mail.surveyMailListId,
          value: mail.name
        };
      });
    }));

  public $recurrence = of([
    { key: '0', value: this.translate.instant('MAIL_LIST.RECURRING') },
    { key: '1', value: this.translate.instant('MAIL_LIST.ONLY_ONCE') },
    { key: '2', value: this.translate.instant('MAIL_LIST.WHEN_ACTIVITY') }
  ]).pipe(delay(100));

  public request$ = new BehaviorSubject<{}>(null);

  constructor(
    private surveyService: SurveyService,
    private surveyReportService: SurveyReportService,
    private surveyMailTypeService: SurveyMailTypeService,
    private translate: TranslateService,
    private store: Store,
    public initialFilter: any,
    private sectorMainService?: SectorMainService,
    private skip?: boolean
  ) {
    super(initialFilter);
    this.skipParams = this.skip ?? false;
  }

  load(): void {

    if (this.skipParams) {
      this.dataCountSubject.next(0);
      this.rowCount$.next(0);
      this.loadingSubject.next(false);
      return;
    }
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
      filter.contactName = this.keyword;
      // filter.searchText = this.keyword; // @TODO: add feedback field
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.surveyReportService
      .search(request)
      .pipe(
        tap(() => this.request$.next(request)),
        finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);
        this.rowCount$.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });

  }

}
