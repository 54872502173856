import { Component, Inject } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Icon } from '@shared/enums';
import { Hotkey } from '@core/api';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-hotkey-dialog',
  templateUrl: './hotkey-dialog.component.html',
  styleUrls: ['./hotkey-dialog.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    TitleCasePipe
  ],
  providers: [TitleCasePipe]
})
export class HotkeyDialogComponent {

  icClose = Icon.IC_TWOTONE_CLOSE;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Hotkey[],
    private titleCase: TitleCasePipe
  ) { }

  get hotkeys(): Hotkey[] {
    return this.data.map(item => ({
      keys: this.keyView(item.keys),
      description: item.description
    }));
  }

  keyView(keys: string): string {
    return keys
      .split('.')
      .map(key => this.titleCase.transform(key))
      .join(' + ');
  }

}
