<div class="history-container border-t-1 mt-2 flex flex-row w-full h-full justify-start items-center gap-4">

  @defer (when loading() === false) {

  @if (entries.length > 0) {

  <ul class="overflow-auto w-full">

    @for (item of entries; track $index) {

    <li class="border-t pt-3 pb-3 min-sm:small-text">

      <div class="flex flex-row min-sm:flex-col justify-between items-center text-secondary text-xs gap-2.5">

        <span class="flex-auto">{{ item.transactionBy }} -
          {{ getTranslate(item) }} -
          {{ item.documentName }} -
          {{ getHistoryType(item) }}
        </span>

        @if(item.createdOn) {

        <span class="created-on pr-3 justify-end items-start">
          {{item.createdOn | momentFormat:'DD MMMM YYYY HH:mm' }}
        </span>

        }

      </div>

    </li>

    }
  </ul>

  }

  @if (entries.length === 0) {

  <p class="w-full text-center">{{'GENERAL.NO_RESULT_FOUND' | translate}}</p>

  }

  } @placeholder(minimum 500ms) {

  <ngx-skeleton-loader class="flex" animation="pulse" count="3" appearance="line"></ngx-skeleton-loader>

  }

</div>