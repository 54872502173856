<mat-chip-grid #origin [disabled]="isDisabled || disabled" matAutocompleteOrigin>
  <mat-chip-row (removed)="onRemove({type: type.CONTACT, contact: contact})" *ngFor="let contact of value.contacts"
    [disabled]="isDisabled" [matTooltip]="contact.jobTitle" [removable]="true" class="chip-contact" fxFlex="nogrow">
    {{ contact.firstName }} {{ contact.lastName }}
    <net-iconify-icon [icon]="icCancel" inline="true" matChipRemove></net-iconify-icon>
  </mat-chip-row>
  <mat-chip-row (removed)="onRemove({type: type.SYSTEM_USER, systemUser: user})" *ngFor="let user of value.systemUsers"
    [disabled]="isDisabled" [matTooltip]="user.jobTitle"
    [removable]="!removeSelf && user.systemUserId !== activeUser?.userId" class="chip-user" fxFlex="nogrow">
    {{ user.firstName }} {{ user.lastName }}
    <net-iconify-icon *ngIf="!removeSelf && user.systemUserId !== activeUser?.userId" [icon]="icCancel" inline="true"
      matChipRemove></net-iconify-icon>
  </mat-chip-row>
  <input #input matInput (focus)="reset()" [disabled]="isDisabled && inputCtrl.enabled" [formControl]="inputCtrl"
    [matChipInputFor]="origin" [matAutocomplete]="auto" [placeholder]="placeholder" [tabIndex]="tabIndex" fxFlex
    type="text">
</mat-chip-grid>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" [autoActiveFirstOption]="false">
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="isMode(modes.CONTACT)">
      <mat-optgroup [disabled]="isDisabled"
        [label]="(filteredContacts.length ? 'GENERAL.CONTACTS_COUNT': 'GENERAL.CONTACTS') | translate:{contactsCount:filteredContacts.length}">
        <mat-option *ngIf="filteredContacts.length === 0" [disabled]="isDisabled" disabled>{{'GENERAL.NOT_FOUND' |
          translate}}</mat-option>
        <mat-option *ngFor="let contact of filteredContacts" [disabled]="isDisabled"
          [value]="{ type: 'contact', contact: contact }">
          {{ contact.firstName }} {{ contact.lastName }} <span class="job-title">{{ contact.jobTitle }}</span>
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <ng-container *ngIf="inputCtrl.value && isMode(modes.SYSTEM_USER)">
      <mat-optgroup [disabled]="isDisabled"
        [label]="(filteredSystemUsers.length ? 'GENERAL.EMPLOYEES': 'GENERAL.EMPLOYEES_COUNT') | translate:{employeesCount:filteredSystemUsers.length}">
        <mat-option *ngIf="filteredSystemUsers.length === 0" [disabled]="isDisabled" disabled>{{'GENERAL.NOT_FOUND' |
          translate}}</mat-option>
        <mat-option *ngFor="let user of filteredSystemUsers" [disabled]="isDisabled"
          [value]="{ type: 'systemUser', systemUser: user }">
          {{ user.firstName }} {{ user.lastName }} <span class="job-title">{{ user.jobTitle }}</span>
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </ng-container>
</mat-autocomplete>

<button (click)="add()" *ngIf="!disabled && allowInsertContact" [disabled]="isDisabled" [hidden]="this.loading" class="add-contact"
  mat-icon-button type="button">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>