import { Component, DestroyRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { NavigationItem } from '../../../core/navigation/navigation-item.interface';
import { Observable, of } from 'rxjs';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { environment } from '@env/environment';
import { SetStyleComponent } from '../set-style/set-style.component';
import { Store } from '@ngrx/store';
import { getSystemSettingValue } from '@core/store';
import { SystemSettingType } from '@core/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import { trackByRoute } from '@shared/utils';
import { UserConfigService } from '@core/user-config/user-config.service';
import { LayoutService } from '@shared/services';
import { ScrollbarDirective } from '@shared/directives';
import { Error403Module } from "../../../errors/error-403/error-403.module";
import { SharedModule } from "../../../shared/modules/shared.module";

@Component({
  selector: 'net-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    NgFor,
    SidenavItemComponent,
    AsyncPipe,
    SetStyleComponent,
    ScrollbarDirective,
    Error403Module,
    SharedModule
  ]
})
export class SidenavComponent implements OnDestroy {
  @ViewChild('scrollBar') scrollBarDirective: ScrollbarDirective;

  @Input() collapsed: boolean;
  collapsedOpen$: Observable<boolean>;

  trackByRoute = trackByRoute;
  icRadioButtonChecked = Icon.IC_RADIO_BUTTON_CHECKED;
  icRadioButtonUnchecked = Icon.IC_RADIO_BUTTON_UNCHECKED;

  appInfo: string;
  version = environment.version;
  logoPath = { light: '', dark: '' };

  isDropdown = this.navigationService.isDropdown;

  items$: Observable<NavigationItem[]> = this.navigationService.items$;

  private readonly destroyRef = inject(DestroyRef);


  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private userConfig: UserConfigService,
    private store: Store,
  ) {

    this.appInfo = 'v' + this.version + ' - ' + environment.name;

    this.setSystemSettings();

    this.collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  onClickedSidenavItem(item: NavigationItem) {
    if (this.isDropdown(item)) {
      setTimeout(() => {
        const scrollElem = this.scrollBarDirective?.scrollbarRef.getScrollElement();
        scrollElem?.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
      }, 225);
    }
  }

  toggleCollapse() {
    if (this.collapsed) {
      this.layoutService.expandSidenav();
      this.onMouseEnter();
      this.userConfig.set(UserConfigType.SIDENAV, UserConfigValue.SIDENAV_EXPANDED);
    } else {
      this.layoutService.collapseSidenav();
      this.onMouseLeave();
      this.userConfig.set(UserConfigType.SIDENAV, UserConfigValue.SIDENAV_COLLAPSED);
    }
  }

  setSystemSettings() {
    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_LIGHT)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(result => {
      this.logoPath.light = result as string;
    });

    this.store.select(getSystemSettingValue(SystemSettingType.LOGO_DARK)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(result => {
      this.logoPath.dark = result as string;
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }
}
