import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AccountDocumentService, DocumentDetail } from '@core/api';
import { Subject } from 'rxjs';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';
import { MatSelect } from '@angular/material/select';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-multi-select-document[activityId]',
  templateUrl: './multi-select-document.component.html',
  styleUrls: ['./multi-select-document.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectDocumentComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class MultiSelectDocumentComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<DocumentDetail[] | null> {
  static nextId = 0;

  loading = true;
  focused = false;
  errorState = false;
  controlType = 'multi-select-document';
  describedBy = '';
  stateChanges = new Subject<void>();

  documentList: DocumentDetail[];
  selectCtrl = new UntypedFormControl();

  searchText = '';
  filteredDocuments: any[];
  activityDocuments: any[];
  filteredActivityDocuments: any[];
  isOpened = false;

  @Input() tabIndex;

  @Input()
  get activityId(): string { return this._activityId; }
  set activityId(value: string) {
    this._activityId = value;

    if (value) {
      this.loading = true;
      this.documentList = [];
      this.selectCtrl.disable();

      this.initDefaultOptions().finally(() => {
        this.onSelectionChange();

        if (!this.disabled) {
          this.selectCtrl.enable();
        }

        this.loading = false;
      });
    }
    this.stateChanges.next();
  }
  private _activityId: string;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): any[] { return this.selectCtrl.value; }
  set value(value: any[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @HostBinding('id') id = `multi-select-document-${MultiSelectDocumentComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  @ViewChild('docSelect') docSelect: MatSelect;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private accountDocumentService: AccountDocumentService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void { }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.accountDocumentService.additionalDocumentsList({ filter: { activityId: this._activityId } })
        .toPromise()
        .then((response) => {
          this.activityDocuments = response.data.results;
          this.documentList = this.activityDocuments;
          this.filteredActivityDocuments = this.documentList;
        })
        .finally(() => resolve());
    });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.docSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
    this.isOpened = isOpened;
    const value = '';
    this.onKey(value);
  }

  onKey(value) {
    this.searchText = value;
    this.filteredDocuments = this.search(value);
    this.activityDocuments = this.filteredDocuments;
    this.filteredActivityDocuments = this.activityDocuments;
  }

  search(value: string) {
    const filter = replaceTurkishCharacters(value).toLocaleUpperCase();
    if (!filter) {
      return this.documentList;
    }
    if (this.activityDocuments.length < 1) {
      this.activityDocuments = this.documentList;
    }
    const docs = this.documentList.filter((option) => option?.name &&
      replaceTurkishCharacters(option?.name?.toUpperCase()).includes(filter)
    );

    if (docs) {
      const selectedDocs = (this.value || []).map(item => {
        const isHave = docs.find(key => key.documentId === item?.documentId);
        if (!isHave) {
          return item;
        }

      });
      this.activityDocuments = [...new Set([...docs, ...selectedDocs.filter(e => !!e)])];
    }
    return this.activityDocuments;
  }

  selectAll() {
    this.selectCtrl.patchValue(this.activityDocuments);
    this.onSelectionChange();
  }

  deselectAll() {
    this.selectCtrl.patchValue(null);
    this.onSelectionChange();
  }

  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: DocumentDetail[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
