<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0" fxFlex="auto">
        {{'MAIL.Which sales organization do you want to add email to?' | translate}}
    </h2>

    <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
</div>

<mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
<mat-dialog-content>
    <div [formGroup]="form" fxLayout="column" class="mt-4">
        <mat-form-field fxFlex="100">
            <mat-label>{{ 'GENERAL.SALES_ORGANIZATION' | translate }}</mat-label>
            <net-select-sales-organization
                formControlName="salesOrganization"
                [systemUserId]="userId"
                [externalSalesOrganizations]="salesOrganizations">
            </net-select-sales-organization>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="ml-auto mt-2" fxLayout="column">
        <net-progress-button [disabled]="!form.valid" [icon]="icSave" (btnClick)="onSubmit()"
            class="ml-auto" [text]="'GENERAL.SAVE' | translate"></net-progress-button>
    </div>
</mat-dialog-actions>