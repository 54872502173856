import { MatSort } from '@angular/material/sort';
import { KeyValue } from '@angular/common';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';

import { debounceTime, filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import {
  DynamicField, OrderType,
  TableFilter, MatHeaderFilterDateRange
} from '@core/api';
import {
  getDynamicFieldIdFromFilterKey,
  getValueTypeFromDynamicFieldTypeId
} from '@shared/utils';

export abstract class ApiDataSource<T> implements DataSource<T> {
  protected loadingSubject = new ReplaySubject<boolean>();
  protected filterSubject = new ReplaySubject<{}>();

  protected dataSubject = new BehaviorSubject<T[]>([]);
  protected dataCountSubject = new ReplaySubject<number>();

  protected sortSubscribe: Subscription;
  protected pageSubscribe: Subscription;
  protected loadingSubscribe: Subscription;
  protected rowCountSubscribe: Subscription;
  protected keywordSubscribe: Subscription;
  protected filterSubscribe: Subscription;

  protected page = 1;
  public filter: any = {};
  public dateFilterRange: MatHeaderFilterDateRange[] = [];
  protected pageSize = 10;
  orderBy: string;
  orderType: OrderType;
  dynamicFields: DynamicField[];
  private firstLoad = false;

  /**
   * Data source empty state true when there is no data
   */
  public empty: boolean;

  /**
   * Data source loading state, true when data source is performing fetch
   */
  public loading = false;

  /**
   * Data source active filter keyword
   */
  public keyword: string;

  /**
   * Data source rowCount
   */
  public rowCount: number;

  /**
   * Data source rowCount for update filter
   */
  public rowCount$ = new BehaviorSubject<number>(null);

  /**
   * Data source keyword subject for update filter
   */
  public keyword$ = new BehaviorSubject<string>(null);

  /**
   * Data source loading observable for watch loading states
   */
  public loading$ = this.loadingSubject.asObservable();

  public filter$ = new BehaviorSubject(null);
  public filterReset$ = new Subject();

  private _data: T[];
  public get data(): T[] {
    return this._data;
  }
  public set data(data: T[]) {
    // Update data reference
    this._data = data;

    // Update count and data subjects
    this.dataSubject.next(data);
  }


  /**
   * Instance of the MatSort directive used by the table to control its sorting.
   * Sort changes emitted by the MatSort will trigger an update to the table's rendered data.
   */
  private _sort: MatSort | null;
  public get sort() { return this._sort; }
  public set sort(value) {
    this._sort = value;

    if (value) {
      this.sortSubscribe = this.sort?.sortChange
        .pipe(filter(sort => this.orderBy !== sort?.active || this.orderType !== this.sort?.direction.toUpperCase()))
        .subscribe(() => {
          // Reset pagination
          this.page = 1;
          this.paginator ? this.paginator.pageIndex = 0 : this.paginator = null;

          // Update data order track values
          if (this.sort.direction) {
            this.updateSourceOrder();
          } else {
            this.orderBy = null;
            this.orderType = null;
          }

          // Trigger data source load
          if (this.firstLoad) {
            this.load();
          } else {
            this.listenKeyword();
          }

        });

      if (this.sort.direction) {
        this.updateSourceOrder();
      }
    }
  }

  /**
   * Instance of the MatPaginator component used by the table to control what page of the data is
   * displayed. Page changes emitted by the MatPaginator will trigger an update to the
   * table's rendered data.
   *
   * Note that the data source uses the paginator's properties to calculate which page of data
   * should be displayed. If the paginator receives its properties as template inputs,
   * e.g. `[pageLength]=100` or `[pageIndex]=1`, then be sure that the paginator's view has been
   * initialized before assigning it to this data source.
   */
  private _paginator: MatPaginator | null;
  public get paginator() { return this._paginator; }
  public set paginator(value) {
    this._paginator = value;

    if (value) {
      this.pageSubscribe = this.paginator.page.subscribe(() => {
        // Update page track values
        this.page = this.paginator.pageIndex + 1;
        this.pageSize = this.paginator.pageSize;

        // Trigger data source load
        this.load();
      });
    }
  }

  protected constructor(protected initialFilter?: any) {
    // Initiate base filter with construct filters
    if (this.initialFilter) {
      this.filter = this.initialFilter;
    }

    if (!this.sort?.active) {
      this.listenKeyword();
    }

    // Subscribe data changes and update local data reference
    this.dataSubject.subscribe(data => {
      // Update data reference
      this._data = data;

      // Update data source's empty based row count
      this.empty = data.length === 0;
    });

    // Subscribe data count change and update paginator length
    this.dataCountSubject.subscribe(count => {
      // Update paginator length
      if (this.paginator) {
        this.paginator.length = count;
      }

      // Update empty reference by count
      this.empty = count === 0;
    });

    // Subscribe filter changes
    this.filterSubscribe = this.filter$
      .pipe(filter(newFilter => newFilter !== this.filter))
      .subscribe(value => this.filter = value ?? {});

    // Subscribe loading state for public access
    this.loadingSubscribe = this.loading$.subscribe(value => this.loading = value);
    this.rowCountSubscribe = this.rowCount$.subscribe(value => this.rowCount = value);
  }

  private listenKeyword() {

    // Subscribe keyword changes and trigger data load for keyword filter
    this.keywordSubscribe = this.keyword$
      .pipe(
        debounceTime(300),
        map(value => value && value.trim().length > 0 ? value : null),
        filter(keyword => keyword !== this.keyword && this.filter?.searchText !== keyword)
      ).subscribe(value => {
        this.firstLoad = true;
        this.keyword = value;

        this.refresh();
      });

  }

  private updateSourceOrder() {
    const isDynamicField = this.sort.active.startsWith('dynamicField-');

    // Update order type
    this.orderType = this.sort.direction.toUpperCase() as OrderType;

    // Update order by dynamic or default
    if (isDynamicField) {
      const dynamicFieldId = getDynamicFieldIdFromFilterKey(this.sort.active);
      const dynamicField = this.dynamicFields.find(f => f.dynamicFieldId === dynamicFieldId);
      this.orderBy = 'dynamicFieldValue';
      this.filter.dynamicFieldOrderId = dynamicFieldId;
      this.filter.dynamicFieldOrderValueType = getValueTypeFromDynamicFieldTypeId(dynamicField.dynamicFieldTypeId);
      return;
    }

    this.orderBy = this.sort.active;
  }

  refresh() {
    this.resetPagination();
    this.load();
  }

  resetPaginationForCustomizedFilter() {
    this.resetPagination();
  }

  protected resetPagination() {
    this.page = 1;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  protected getRequest() {
    const request: any = { page: this.page, pageSize: this.pageSize };

    if (this.orderBy) {
      request.orderBy = this.orderBy;
      request.orderType = this.orderType;
    }

    return request;
  }

  public setDataSubject(data: any[]) {
    this.dataSubject.next(data);
  }

  public setRowCount(rowCount: number) {
    this.dataCountSubject.next(rowCount);
    this.rowCount$.next(rowCount);
  }

  public setLoadingSubject(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  /**
   * Applies data source filter value for given key and load the source
   *
   * @param key filter key
   * @param value filter value
   */
  applyFilter(key: string, value: any): void {
    if (value) {
      this.filter[key] = value;
    } else {
      delete this.filter[key];
    }

    // Reset order and pagination
    this.resetPagination();

    this.load();
  }

  /**
   * Applies data source filter value for given key value pairs and load the source
   *
   * @param filters filter key value pairs
   */
  applyFilters(filters: KeyValue<string, string>[], reset: boolean): void {
    // Apply plain filter values
    filters
      .forEach((item) => {
        if (![null, undefined, ''].includes(item.value)) {
          this.filter[item.key] = item.value;
        } else {
          delete this.filter[item.key];
        }
      });

    this.filter$.next(this.filter);
    this.filterReset$.next(true);

    if (reset) {
      this.refresh();
    }
  }

  loadFilters(customFilter: TableFilter, reset: boolean): void {
    if (!customFilter || Object.keys(customFilter).length < 1) {
      this.keyword$.next('');
      this.filter$.next({});

      if (this.sort) {
        this.loadSortFilter();
      }
    } else {
      if (customFilter.filter) {
        this.keyword$.next(customFilter.filter.searchText);
        this.filter$.next(customFilter.filter ?? {});
      }

      if (customFilter?.orderBy && this.sort) {
        this.loadSortFilter(customFilter);
      }
    }

    if (reset) {
      this.load();
    }
  }

  loadSortFilter(customFilter?: TableFilter) {
    this.sort.active = customFilter?.orderBy ?? '';
    this.sort.direction = customFilter?.orderType?.toLowerCase() as any ?? 'asc';
    this.orderBy = customFilter?.orderBy;
    this.orderType = customFilter?.orderType;
    this.sort._stateChanges.next();

    if (customFilter && Object.keys(customFilter).length > 0) {
      const activeSortHeader = this.sort.sortables.get(customFilter.orderBy);
      if (activeSortHeader) {
        const key = '_setAnimationTransitionState';
        activeSortHeader[key]({
          fromState: customFilter?.orderType.toLowerCase() || 'asc',
          toState: 'active'
        });
      }
    }
  }

  /**
   * Triggers data load to data source.
   * Implement this method for load data to data source subject like after HTTP API service call etc.
   */
  abstract load(): void;

  /**
   * @inheritDoc
   */
  connect(): Observable<T[] | ReadonlyArray<T>> {
    return this.dataSubject.asObservable();
  }

  /**
   * @inheritDoc
   */
  disconnect(): void {
    // Complete created subjects
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.dataCountSubject.complete();

    // Unsubscribe from subscribed observables
    if (this.sortSubscribe) { this.sortSubscribe.unsubscribe(); }
    if (this.pageSubscribe) { this.pageSubscribe.unsubscribe(); }
    this.loadingSubscribe.unsubscribe();
    this.rowCountSubscribe.unsubscribe();
    this.keywordSubscribe.unsubscribe();
    this.filterSubscribe.unsubscribe();
  }
}
