<mat-select [placeholder]="placeholder" [tabIndex]="tabIndex" [formControl]="selectCtrl"
    (selectionChange)="onSelectionChange()" [multiple]="multiple" [compareWith]="compareFn" name="selectCtrl"
    panelClass="custom-panel" disableOptionCentering>
    <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length>1">
        {{selectCtrl.value.length}} Seviye Seçili
    </mat-select-trigger>
    <div *ngIf="multiple" class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate}}
        </button>
        <button mat-raised-button (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate}}
        </button>
    </div>
    <mat-option *ngFor="let organizationalStructure of organizationalStructures" [disabled]="disabled"
        [value]="organizationalStructure">{{ organizationalStructure.name }}
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>