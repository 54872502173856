@defer(when loading === false) {
@if(userCopySearch) {

<mat-select [tabIndex]="tabIndex" (selectionChange)="onSelectionChange()" [formControl]="inputCtrl"
  [displayWith]="displayName">
  <mat-option *ngFor="let user of userCopyUsers" [value]="user">
    {{user.systemUser.firstName}} {{user.systemUser.lastName}}
  </mat-option>
</mat-select>

} @else {

<input #input (blur)="onBlur()" [formControl]="inputCtrl" [matAutocomplete]="auto" [readonly]="isDisabled"
  [tabIndex]="tabIndex" matInput [placeholder]="placeholder">
<mat-autocomplete #auto="matAutocomplete" (closed)="onClosed()" (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true" [displayWith]="displayName">
  <ng-container *ngIf="this.users">
    <mat-option *ngFor="let user of users" [disabled]="isDisabled" [value]="user">{{ user.firstName }} {{ user.lastName
      }}</mat-option>
  </ng-container>
</mat-autocomplete>

}
} @placeholder {
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>
}