import { ActivatedRouteSnapshot } from '@angular/router';
import { NetRouteData } from '@shared/interfaces';


export function checkRouterChildsData(
  route: ActivatedRouteSnapshot & { data?: NetRouteData },
  compareWith: (data: NetRouteData) => boolean
) {
  if (compareWith(route.data)) {
    return true;
  }

  if (!route.firstChild) {
    return false;
  }

  return checkRouterChildsData(route.firstChild, compareWith);
}

export function getAllParams(route: ActivatedRouteSnapshot & { data?: NetRouteData }, result = new Map<string, string>()): Map<string, string> {
  if (route.params) {
    for (const key of Object.keys(route.params)) {
      result.set(key, route.params[key]);
    }
  }

  if (!route.firstChild) {
    return result;
  }

  return getAllParams(route.firstChild, result);
}

