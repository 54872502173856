import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Activity, ActivityRequest, ActivityService, ActivityStatusType, Language } from '@core/api';
import { ActivityStaticRecipientService } from '@core/api/activity-static-recipient/activity-static-recipient.service';
import { createFileWindow } from 'src/app/shared/utils/open-file-url';
import { FileLoadingTranslationService } from '@shared/services';
import { Icon } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/modules';
import { MultiSelectDocumentComponent } from '@shared/components/multi-select-document/multi-select-document.component';
import { MultiSelectSystemUserComponent } from '@shared/components/multi-select-system-user/multi-select-system-user.component';
import { SelectActivityPdfTemplateComponent } from '@shared/components/select-activity-pdf-template/select-activity-pdf-template.component';
import { SelectLanguageComponent } from '@shared/components/select-language/select-language.component';

@Component({
  selector: 'net-alert-activity-dialog',
  templateUrl: './alert-activity-dialog.component.html',
  styleUrls: ['./alert-activity-dialog.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    MultiSelectDocumentComponent,
    MultiSelectSystemUserComponent,
    SelectActivityPdfTemplateComponent,
    SelectLanguageComponent
  ]
})
export class AlertActivityDialogComponent implements OnInit {

  icSave = Icon.IC_SAVE;
  icPdf = Icon.IC_TWOTONE_PICTURE_AS_PDF;
  icDownload = Icon.MDI_DOWNLOAD;
  icPdfOutLine = Icon.MDI_FILE_PDF_OUTLINE;

  language: Language;
  hasEmail: boolean;
  request: ActivityRequest;
  activity: Activity;
  activityStatus = ActivityStatusType;


  form = this.formBuilder.group({
    sendUsers: this.formBuilder.array([]),
    ccUsers: [null],
    activityPdfTemplate: null,
    assigmentFlag: [false],
    documentFlag: [false],
    documentList: [null],
    language: [null],
  });

  defaultSelectedUserIds: string[];

  salesOrganizationId: string;

  isLoading = true;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertActivityDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private fileLoadingTranslationService: FileLoadingTranslationService,
    private activityService: ActivityService,
    private activityStaticRecipientService: ActivityStaticRecipientService
  ) {

    this.request = data.request;
    this.activity = data.activity;

    this.findActivityUsers(this.request);
    this.getActivityStaticRecipientService();
  }

  ngOnInit(): void {

    this.form.valueChanges.subscribe(pdf => {
      if (pdf) {
        this.request.activityPdfTemplateId = pdf?.activityPdfTemplate?.activityPdfTemplateId;
      }
    });

    this.form.get('sendUsers').valueChanges.subscribe(value => {
      const language = this.form.get('language');
      if (value.filter(user => user.checked)?.length) {
        language.setValidators([Validators.required]);
        this.hasEmail = true;
      } else {
        language.clearValidators();
        this.hasEmail = false;
      }

      language.updateValueAndValidity();
    });

    this.form.get('documentFlag').valueChanges.subscribe(value => {
      const documentList = this.form.get('documentList');
      if (value) {
        documentList.setValidators([Validators.required]);
      } else {
        documentList.clearValidators();
        documentList.patchValue(null);
      }

      documentList.updateValueAndValidity();
    });

  }

  findActivityUsers(request: ActivityRequest) {
    this.getSendUserForm().clear();

    if (request.activityContacts.length > 0) {
      request.activityContacts.forEach(contact => {
        this.getSendUserForm().push(this.createSendUserForm({
          contactId: contact.contactId,
          name: `${contact.firstName} ${contact.lastName}`,
          checked: true
        }));
      });
    }

    if (request.activitySystemUsers.length > 0) {
      request.activitySystemUsers.forEach(systemUser => {
        this.getSendUserForm().push(this.createSendUserForm({
          systemUserId: systemUser.systemUserId,
          name: `${systemUser.firstName} ${systemUser.lastName}`,
          checked: true
        }));
      });
    }
  }

  getActivityStaticRecipientService() {
    this.activityStaticRecipientService.getSalesOrganizationMailSystemUsers({ salesOrganizationId: this.request.account.salesOrganizationId })
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
        this.defaultSelectedUserIds = response.data.map(user => user.systemUserId);
        this.isLoading = false;
      });
  }

  getSendUserForm(): UntypedFormArray {
    return this.form.get('sendUsers') as UntypedFormArray;
  }

  createSendUserForm(item: { contactId?: string; name: string; checked: boolean; systemUserId?: string; }): UntypedFormGroup {
    return this.formBuilder.group({
      contactId: [item ? item.contactId : null],
      systemUserId: [item ? item.systemUserId : null],
      name: [item ? item.name : null],
      checked: [item ? item.checked : null],
    });
  }

  closeAlertDialog(submit = false) {
    this.form.markAllAsTouched();

    if (this.form.valid || !submit) {

      const users = this.getSendUserForm().value?.filter(user => user.checked);
      const ccUsers = this.form.get('ccUsers').value;
      const assignmentFlag = this.form.get('assigmentFlag').value;
      const languageId = this.form.get('language').value.languageId;
      const documentIds = this.getDocumentIds();
      const activityPdfTemplateId = this.form.get('activityPdfTemplate').value.activityPdfTemplateId;

      const dialogCloseResponse = {
        confirm: submit,
        users,
        ccUsers,
        assignmentFlag,
        languageId,
        documentIds,
        activityPdfTemplateId
      };

      this.dialogRef.close(dialogCloseResponse);
    }
  }

  private getDocumentIds() {
    return this.form.get('documentList').value ? this.form.get('documentList').value.map(doc => doc.documentId) : [];
  }

  onSave(request: ActivityRequest) {
    const assignmentFlag = this.form.get('assigmentFlag').value;
    const languageId = this.form.get('language').value.languageId;

    switch (request.activityAction) {
      case 'download':
        this.download(request, languageId, assignmentFlag);
        break;
      case 'preview':
        this.download(request, languageId, assignmentFlag);
        break;
    }

  }

  download(request: ActivityRequest, languageId: string, assigmentFlag) {

    this.fileLoadingTranslationService.setTranslations();
    // Create new file window
    const fileWindow = createFileWindow();
    // Helper for start activity download
    const download = () => {
      this.activity.activityPdfTemplateId = request.activityPdfTemplateId;
      this.activityService
        .download(this.activity, request.activityAction, languageId, fileWindow, assigmentFlag)
        .catch(() => fileWindow.close())
        .finally(() => {

          this.fileLoadingTranslationService.removeTranslations();
        });
    };

    if (this.activity) {
      download();
      return;
    }
  }

}
