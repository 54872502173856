<net-activity-form mode="dialog" [loading]="loading" [activity]="activity" [account]="account"
  [leadDraftInfo]="leadDraftInfo" [type]="data?.type" (save)="onSave($event)"></net-activity-form>

<ng-template #alertActivity>
  <form [formGroup]="form">
    <h2 mat-dialog-title>{{'ACTIVITY.MAIL_SENDING' | translate}}</h2>
    <mat-dialog-content class="pb-4" fxLayout="column">
      <span>{{'ACTIVITY.MAIL_SENDING_DESCRIPTION' | translate}}</span>
      <h4 class="font-medium mt-4"> {{'ACTIVITY.PARTICIPANTS' | translate}} </h4>

      <div fxLayout="column" formArrayName="sendUsers">
        <ng-container *ngFor="let control of getSendUserForm().controls; let index = index" [formGroupName]="index">
          <mat-checkbox formControlName="checked" color="primary">
            {{control.get('name').value}}
          </mat-checkbox>
        </ng-container>
      </div>

      <h4 class="font-medium mt-4">{{'ACTIVITY.CC' | translate}}</h4>
      <mat-form-field>
        <mat-label>{{'ACTIVITY.USER_CHOICE' | translate}}</mat-label>
        <net-multi-select-system-user formControlName="ccUsers"
          [salesOrganizationId]="request.account?.salesOrganizationId">
        </net-multi-select-system-user>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"ACTIVITY.PDF_THEME" | translate}}</mat-label>
        <net-select-activity-pdf-template [salesOrganizationId]="request?.account?.salesOrganizationId"
          formControlName="activityPdfTemplate"></net-select-activity-pdf-template>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'PROFILE.SELECTED_LANGUAGE' | translate}}</mat-label>
        <net-select-language formControlName="language" [withCustomCode]="true" [isSpecialLanguage]="true"
          [isActive]="true" [placeholder]="'LANGUAGE.SELECT_LANGUAGE' | translate"></net-select-language>
      </mat-form-field>
      <mat-checkbox formControlName="assigmentFlag" color="primary">
        {{'ACTIVITY.Include Activity Tasks in Pdf' | translate}}
      </mat-checkbox>
    </mat-dialog-content>

    <mat-dialog-actions class="items-start">

      <button class="ml-auto mr-2" color="secondary" mat-button (click)="closeAlertDialog()">{{'GENERAL.CANCEL' |
        translate}}</button>
      <button (click)="$event.stopPropagation()"
        *ngIf="activity && activity.activityStatusId === activityStatus.COMPLETED" [matMenuTriggerFor]="menu"
        class="mr-2" color="primary" fxFlex.gt-xs="125px" fxFlex.xs="100%" mat-raised-button type="button">
        <net-iconify-icon [icon]="icPdf" class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2" inline="true"
          size="18"></net-iconify-icon>
        <span fxHide.lt-sm>{{"ACTIVITY.CREATE_PDF" | translate}}</span>
      </button>
      <net-progress-button [icon]="icSave" (btnClick)="closeAlertDialog(true)"
        [text]="(hasEmail ? 'GENERAL.SAVE_SEND' : 'GENERAL.SAVE') | translate">
      </net-progress-button>
    </mat-dialog-actions>
  </form>
</ng-template>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent>
    <button (click)="request.activityAction = 'download'; onSave(request)" color="primary" mat-menu-item>
      <net-iconify-icon [icon]="icDownload"></net-iconify-icon>
      <span>{{"GENERAL.DOWNLOAD" | translate}}</span>
    </button>

    <button (click)="request.activityAction = 'preview'; onSave(request)" color="primary" mat-menu-item>
      <net-iconify-icon [icon]="icPdfOutLine"></net-iconify-icon>
      <span>{{"GENERAL.PREVIEW" | translate}}</span>
    </button>
  </ng-template>
</mat-menu>