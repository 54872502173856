import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

import { MatFormFieldControl } from '@angular/material/form-field';

import { Subject } from 'rxjs';

import { OfferServiceType } from '@core/api';
import { Icon } from '@shared/enums';

@Component({
  selector: 'net-input-offer-service',
  templateUrl: './input-offer-service.component.html',
  styleUrls: ['./input-offer-service.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputOfferServiceComponent
    }
  ]
})
export class InputOfferServiceComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<OfferServiceType> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-offer-service';
  describedBy = '';
  stateChanges = new Subject<void>();

  icLocalAirport = Icon.IC_TWOTONE_LOCAL_AIRPORT;
  icLocalShipping = Icon.IC_TWOTONE_LOCAL_SHIPPING;
  icDirectionsBoat = Icon.IC_TWOTONE_DIRECTIONS_BOAT;

  service = OfferServiceType;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): OfferServiceType { return this._value; }
  set value(value: OfferServiceType) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: OfferServiceType;

  @HostBinding('id') id = `input-offer-service-${InputOfferServiceComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return true; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  isDisabled(value: OfferServiceType): boolean {
    return value !== OfferServiceType.LAND;
  }

  onClick(value: OfferServiceType) {
    if (this.disabled || this.isDisabled(value)) {
      return;
    }

    this.value = value;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
