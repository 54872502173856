import { Component, DestroyRef, inject, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Account, DocumentTypeEnum, SystemUserParameter, SystemUserParameterService, User } from '@core/api';
import { DocumentUploadOptions } from '../../../../shared/components/document-upload/document-upload.component.model';
import { DocumentUploadType } from '../../../../shared/components/document-upload/document-upload.component.enum';
import { ActivatedRoute } from '@angular/router';
import { getUser } from '@core/store';
import { Store } from '@ngrx/store';
import { DocumentUploadComponent } from '../../../../shared/components/document-upload/document-upload.component';
import { Icon } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'net-lead-detail-document',
  templateUrl: './lead-detail-document-dialog.component.html',
})
export class LeadDetailDocumentDialogComponent {
  icSave = Icon.IC_SAVE;
  icClose = Icon.IC_TWOTONE_CLOSE;
  icWarning = Icon.IC_TWOTONE_WARNING;
  systemUserParameter: SystemUserParameter;
  uploaderOptions: DocumentUploadOptions = { maxFileSize: 26214400 };
  uploadType = DocumentUploadType;
  uploadDocuments: any[] = [];
  loading = false;
  account: Account;
  user: User;

  @ViewChild(DocumentUploadComponent) documentUploadComponent: DocumentUploadComponent;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LeadDetailDocumentDialogComponent>,
    private systemUserParameterService: SystemUserParameterService,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    if (data) {
      this.account = data;
      const param = `accountId=${this.account.accountId}`;
      this.uploaderOptions.queryString = `?documentTypeId=${DocumentTypeEnum.Other}&${param}`;
    }
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      if (user) {
        this.user = user;

        this.systemUserParameterService.search({
          systemUserId: this.user.userId,
          accountId: this.account.accountId
        }).subscribe(response => {
          this.systemUserParameter = response.data;
        });
      }
    });

  }

  uploadResponse(data: any) {
    this.uploadDocuments.push(data.data);
  }

  async onUploadDone($event) {
    if (this.uploadDocuments.length > 0) {
      this.loading = false;
      this.dialogRef.close(true);
    }
  }

  jsonToQueryString(json): string {
    return '?' +
      Object.keys(json).map((key) => encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key])).join('&');
  }

  async onSubmit() {
    if (this.documentUploadComponent.files && this.documentUploadComponent.files.length > 0) {
      this.loading = true;
    }
  }

  closeDialog() {
    this.dialogRef.componentInstance.loading = false;
    this.dialogRef.close(true);
  }

}
