import { Action } from '@ngrx/store';
import { Response, RecentPage } from '@core/api';

export enum RecentPageActionTypes {
    GET_RECENT_PAGE = '[Recent Page] GET_RECENT_PAGE',
    GET_RECENT_PAGE_SUCCESS = '[Recent Page] GET_RECENT_PAGE_SUCCESS',
    GET_RECENT_PAGE_ERROR = '[Recent Page] GET_RECENT_PAGE_ERROR',
}

export class GetRecentPage implements Action {
    readonly type = RecentPageActionTypes.GET_RECENT_PAGE;
}

export class GetRecentPageSuccess implements Action {
    readonly type = RecentPageActionTypes.GET_RECENT_PAGE_SUCCESS;
    constructor(public payload: RecentPage[]) { }
}

export class GetRecentPageError implements Action {
    readonly type = RecentPageActionTypes.GET_RECENT_PAGE_ERROR;
    constructor(public payload: Response<null>) { }
}

export type RecentPageActions = GetRecentPage | GetRecentPageSuccess | GetRecentPageError;
