<div>
  <div class="dropdown">
    <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <div class="dropdown-heading">{{'GENERAL.NOTIFICATIONS' | translate}}</div>
      </div>
    </div>
    <div @stagger class="dropdown-content" *ngIf="!loading && notifications?.length>0">
      <a *ngFor="let notification of notifications; trackBy: trackById.bind(this, 'notificationId')"
        [ngClass]="{'read': notification.isReaded, 'unread': !notification.isReaded }"
        (click)="makeRead(notification); goToLink(notification.url, notification)" class="notification" fxLayout="row"
        fxLayoutAlign="start center" matRipple>
        <div fxFex="32px" class="read-icon mr-4" [style.background-color]="'#fff3cd'">
          <mat-icon class="read-icon" fontSet="material-icons" size="24">
            {{ notification.notificationIcon.icon }}
          </mat-icon>
        </div>

        <div class="w-full mr-4 notification-message-container">
          <div class="notification-label customer-name">{{ notification.referenceName }}</div>
          <div class="notification-label" style="font-size: 12px;"><span
              [innerHTML]="notification.construct | dynamicTranslate: 'NotificationConstruct': notification.notificationText"></span>
          </div>
          <div class="notification-description" style="font-size: 10px;">{{ notification.createdBefore }}</div>
        </div>

        <div fxLayout fxFex="18px" fxFlexAlign="end center">
          <net-iconify-icon [icon]="icChevronRight" class="notification-chevron"></net-iconify-icon>
        </div>
      </a>
    </div>
    <div @stagger class="dropdown-content" *ngIf="!loading && notifications?.length === 0">
      <a class="notification" fxLayout="row" fxLayoutAlign="start center" matRipple>

        <div fxFex="32px" class="read-icon mr-4">
          <net-iconify-icon class="read-icon" [backgroundColor]="'#fff3cd'" [icon]="icInfo" size="24">
          </net-iconify-icon>
        </div>

        <div fxFlex="auto">
          <div class="notification-label" style="font-size: 12px;">{{'NOTIFICATION.THERE_ARE_NO_NEW_NOTIFICATIONS' |
            translate}}</div>
        </div>
      </a>
    </div>
    <div>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="42" class="mx-auto my-6">
      </mat-progress-spinner>
    </div>
    <div *ngIf="!loading" class="dropdown-footer" fxLayout="column" fxLayoutAlign="center center">
      <button color="primary" class="mr-0" mat-raised-button type="button" (click)="navigateToNotifications()">
        <span style="font-size: small;">&nbsp;&nbsp; {{'NOTIFICATION.VIEW_ALL' | translate}} </span>
        <net-iconify-icon [icon]="icArrows" class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2" inline="true" [size]="'20'">
        </net-iconify-icon>
      </button>
    </div>
  </div>
</div>