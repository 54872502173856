import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { GetStaticField, GetStaticFieldError, GetStaticFieldSuccess, StaticFieldActionTypes } from './static-field.actions';
import { StaticFieldService } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class StaticFieldEffects {

    constructor(
        private staticFieldService: StaticFieldService,
        private actions$: Actions
    ) { }

    getSettings$ = createEffect(() => this.actions$.pipe(
        ofType(StaticFieldActionTypes.GET_STATIC_FIELD),
        mergeMap((request: GetStaticField) => this.staticFieldService.search(request.payload)),
        switchMap(response => of(new GetStaticFieldSuccess(response.data.results))),
        catchError((error) => of(new GetStaticFieldError(error)))
    ));

}
