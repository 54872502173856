// import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  CustomerExperienceTicketSignalRService, BoardInfoSignalRService,
  MailService, MailSignalRService,
  MeetingParticipantStatus,
  MeetingService,
  NotificationSignalRService,
  SalesOrganizationSettingInfoService,
  SystemSettingType,
  User,
  UserPermission
} from '@core/api';
import {
  CreatePasswordRequest, ForgotPasswordRequest, LoginRequest, LoginResponse, RefreshTokenRequest,
  UpdatePasswordRequest
} from '@core/auth';
import {
  GetSalesOrganizationSettingClear, GetStaticFieldClear, getSystemSettingValue, getUser, Logout, MeSuccess,
  SessionUpdate
} from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { IS_REQUEST_BEFORE_LOGOUT } from '@core/auth/auth.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static AUTH_REGEX = new RegExp('^' + environment.apiUrl + '/(Login|RefreshToken)');
  thousandSeparator: string;
  decimalMarker: '.' | ',' | ['.', ','];
  numberLanguage = 'tr';
  authUser: User;

  static getToken(): string {
    return localStorage.getItem('api_token');
  }

  static getRefreshToken(): string {
    return localStorage.getItem('api_refresh_token');
  }

  static getUser(): string {
    return localStorage.getItem('api_user');
  }

  private static clearToken() {
    localStorage.removeItem('api_user');
    localStorage.removeItem('api_token');
    localStorage.removeItem('api_refresh_token');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    // private gtmService: GoogleTagManagerService,
    private meetingService: MeetingService,
    private mailIntegration: MailService,
    private injector: Injector,
    private store: Store,
    private notificationSignalRService: NotificationSignalRService
  ) {
    this.store.select(getSystemSettingValue(SystemSettingType.DECIMAL_USAGE)).subscribe(result => {
      this.numberLanguage = !result ? 'tr' : 'en';

      // Define separators
      this.defineSeparators();
    });

    this.store.select(getUser).subscribe(user => {
      this.authUser = user;
    });

    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        if (!this.isAuthenticated() && '/auth/login' !== location.pathname) {
          this.logout();
        }

        const localUser = localStorage.getItem('api_user');
        if (localUser && this.isAuthenticated() && JSON.stringify(this.authUser) !== localUser) {

          store.dispatch(new SessionUpdate({
            user: JSON.parse(localUser),
            accessToken: localStorage.getItem('api_token'),
            refreshToken: localStorage.getItem('api_refresh_token'),
          }));
        }
      }

    }, false);
  }

  private defineSeparators() {
    const isTr = this.numberLanguage === 'tr';
    const thousandSeparator = isTr ? '.' : ',';
    const decimalMarker = isTr ? ',' : '.';

    this.thousandSeparator = thousandSeparator;
    this.decimalMarker = decimalMarker;
  }

  persistToken(response: LoginResponse) {
    if (response.success) {
      const user: User = response.data.user;
      user.permissions = response.data.permissions ?? [];

      localStorage.setItem('api_user', JSON.stringify(user));
      localStorage.setItem('api_token', response.data.accessToken);
      localStorage.setItem('api_refresh_token', response.data.refreshToken);
    } else {
      localStorage.removeItem('api_user');
      localStorage.removeItem('api_token');
      localStorage.removeItem('api_user');
    }
  }

  async persistUser(user: User, permissions: UserPermission[], isFromLogin = false) {
    // Apply permission's to users
    user.permissions = permissions ?? [];

    // Persist user
    localStorage.setItem('api_user', JSON.stringify(user));

    // this.gtmService.pushTag({
    //   userId: user.userId + '-' + user.userName
    // });

    if (user.culture) {
      if (!isFromLogin && (this.translate.currentLang !== user.culture)) {
        location.reload();
      }
      await this.translate.use(user.culture).toPromise();
    }
  }

  isAuthenticated(): boolean {
    return !!AuthService.getToken() && !!AuthService.getRefreshToken();
  }

  refreshToken(): Observable<LoginResponse> {
    const body: RefreshTokenRequest = {
      accessToken: AuthService.getToken(),
      refreshToken: AuthService.getRefreshToken()
    };

    return this.http.post<LoginResponse>(
      environment.apiUrl + '/RefreshToken?device=web ',
      body,
    ).pipe(tap((response) => {
      this.persistToken(response);
      this.storeSessionService(response);
    }));
  }

  private storeSessionService(response: LoginResponse): void {
    if (response.success) {
      const user: User = response.data.user;
      user.permissions = response.data.permissions ?? [];

      const store = this.injector.get(Store);
      store.dispatch(new MeSuccess(response));
    }
  }

  login(request: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(environment.apiUrl + '/Login?device=web', request).pipe(tap((response) => {
      if (response.success) {
        this.persistUser(response.data.user, response.data.permissions, true);
      }
    }));
  }

  me(): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(environment.apiUrl + '/Me?device=web', {}).pipe(
      tap(response => {
        if (response.success) {
          this.persistUser(response.data.user, response.data.permissions);
        }
      })
    );
  }

  logout(navigate = true, isReturn = false) {
    const beforeLogout = [];
    const meetingDetail = localStorage.getItem('meeting_detail');

    // Add mail logout to before operations
    if (MailSignalRService.tokenConnectionId) {
      beforeLogout.push(
        new Promise<void>((resolve, reject) => {
          this.mailIntegration
            .logout(new HttpContext().set(IS_REQUEST_BEFORE_LOGOUT, true))
            .pipe(catchError((error) => throwError(error)))
            .subscribe(
              () => {
                console.info('[logout] Mail logout completed.');
                resolve();
              },
              (err) => {
                console.error('[logout] Mail logout failed.', err);
                reject(err);
              }
            );
        })
      );
    }

    // Make active meeting participant status as offline
    if (meetingDetail) {
      const data = JSON.parse(meetingDetail);
      beforeLogout.push(
        new Promise<void>((resolve, reject) => {
          this.meetingService
            .updateParticipantStatus(
              {
                meetingParticipantId: data.meetingParticipantId,
                meetingParticipantStatusId: MeetingParticipantStatus.OFFLINE
              },
              new HttpContext().set(IS_REQUEST_BEFORE_LOGOUT, true)
            )
            .pipe(catchError((error) => throwError(error)))
            .subscribe(
              () => {
                localStorage.removeItem('meeting_detail');
                console.info('[logout] Meeting participant offline completed.');
                resolve();
              },
              (err) => {
                console.error('[logout] Meeting participant offline failed.', err);
                reject(err);
              }
            );
        })
      );
    }

    // Complete all actions before logout and start logout
    Promise.allSettled(beforeLogout).then(() => {
      AuthService.clearToken();
      SalesOrganizationSettingInfoService.clearToken();

      if (isReturn) {
        localStorage.setItem('returnUrl', window.location.pathname + window.location.search);
      }

      this.router.navigate(['/auth/login']).then(() => {
        const store = this.injector.get(Store);
        store.dispatch(new Logout());
        store.dispatch(new GetStaticFieldClear());
        store.dispatch(new GetSalesOrganizationSettingClear());
        this.notificationSignalRService.disconnectNotificationListener();

        const boardInfoSignalRService = this.injector.get(BoardInfoSignalRService);
        boardInfoSignalRService.disconnectBoardDataListener();

        const customerExperienceTicketSignalRService = this.injector.get(CustomerExperienceTicketSignalRService);
        customerExperienceTicketSignalRService.disconnectTicketListener();
      });

      if (!navigate) {
        location.reload();
      }
    });
  }

  forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<ForgotPasswordRequest> {
    return this.http.post<ForgotPasswordRequest>(
      environment.apiUrl + '/ForgotPassword',
      forgotPasswordRequest,
    );
  }

  updatePassword(updatePasswordRequest: UpdatePasswordRequest): Observable<UpdatePasswordRequest> {
    return this.http.post<UpdatePasswordRequest>(
      environment.apiUrl + '/UpdatePassword',
      updatePasswordRequest,
    );
  }

  createPassword(createPassword: CreatePasswordRequest): Observable<CreatePasswordRequest> {
    return this.http.post<CreatePasswordRequest>(
      environment.apiUrl + '/CreatePassword',
      createPassword,
    );
  }
}
