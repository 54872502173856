import { StaticField } from '@core/api/static-field/static-field.model';
import { StaticFieldActionTypes, StaticFieldActions } from './static-field.actions';

export interface StaticFieldState {
    fields: StaticField[];
    isLoading: boolean;
    errorMessage: string;
    errorCode: string;
}

export const staticFieldState: StaticFieldState = {
    fields: null,
    isLoading: false,
    errorMessage: null,
    errorCode: null,
};

export function staticFieldReducer(state = staticFieldState, action: StaticFieldActions): StaticFieldState {
    switch (action.type) {
        case StaticFieldActionTypes.GET_STATIC_FIELD:
            return {
                ...state,
                errorMessage: null,
                isLoading: true
            };
        case StaticFieldActionTypes.GET_STATIC_FIELD_SUCCESS:
            return {
                ...state,
                fields: action.payload,
                errorMessage: null,
                errorCode: null,
                isLoading: false,
            };
        case StaticFieldActionTypes.GET_STATIC_FIELD_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.message
            };
        case StaticFieldActionTypes.GET_STATIC_FIELD_CLEAR:
            return {
                fields: null,
                errorMessage: null,
                errorCode: null,
                isLoading: null,
            };
        default:
            return state;
    }
}
