import { iif, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { LocationService } from '@core/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
    CountryActionTypes, GetCountriesError, GetCountriesSuccess, LoadCountries
} from './country.actions';
import { countries, CountryState } from './country.reducer';

@Injectable()
export class CountryEffects {

    private readonly locationService = inject(LocationService);

    constructor(
        private store: Store,
        private actions$: Actions
    ) { }

    getCountries$ = createEffect(() => this.actions$.pipe(
        ofType(CountryActionTypes.GET_COUNTRIES),
        withLatestFrom(this.store.select((state: any) => state.country)),
        switchMap(([_, country]: [never, CountryState]) => {
            const useStore = country?.countries.length > 0;
            return iif(
                () => useStore,
                of(new LoadCountries()),
                this.locationService.countries({ filter: {}, pageSize: 500 }).pipe(
                    map(response => new GetCountriesSuccess(response.data.results)),
                    catchError((error) => of(new GetCountriesError(error)))
                )
            );
        })
    ));
}
