import { finalize, map } from 'rxjs/operators';

import { CardObject, CardObjectService, CardObjectTypeService, CardStatusService, SystemUserService, User } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';

export class CardObjectDataSource extends ApiDataSource<CardObject> {

  public $cardUsers = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $cardObjectTypes = this.cardObjectTypesService
    .list().pipe(
      map(response => response.data),
      map(statuses => statuses.map(item => {
        return {
          key: item.cardObjectTypeId,
          value: this.translate.instant('CardObjectType.' + item.name)
        };
      }))
    );

  public $cardStatuses = this.cardStatusService
    .list().pipe(
      map(response => response.data),
      map(statuses => statuses.map(item => {
        return {
          key: item.cardStatusId,
          value: this.translate.instant('CardStatus.' + item.name)
        };
      }))
    );

  constructor(
    private cardObjectService: CardObjectService,
    private systemUserService: SystemUserService,
    private cardObjectTypesService: CardObjectTypeService,
    private cardStatusService: CardStatusService,
    private user: User,
    private translate: TranslateService,


    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.cardObjectService
      .search(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        const results = response.data.results;
        // Update count and data subjects
        this.dataSubject.next(results);
        this.dataCountSubject.next(results.length);

        // Update data source's empty based row count
        this.empty = results.length === 0;
      });
  }
}
