import { Component, HostBinding, Input } from '@angular/core';
import { Guid, SystemUser, User } from '@core/api';
import { environment } from '@env/environment';

@Component({
  selector: 'net-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss']
})
export class ProfilePhotoComponent {
  static nextId = 0;

  @Input() height: number;
  @Input() width: number;
  @Input() fontSize: number;
  @Input() systemUser: SystemUser | User;
  @Input() externalClass: string;
  @Input() initials: string;
  @Input() externalBgColor: string;

  guid = Guid;

  @HostBinding('id') id = `profile-photo-${ProfilePhotoComponent.nextId++}`;

  constructor() { }

  getImageUrl() {
    return `${environment.apiUrl}/api/SystemUserPicture/GetPicture/${this.systemUser.systemUserPicture.pictureId}`;
  }
}
