import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { ChannelType, CustomerExperienceChannelSettingsService } from '@core/api';
import { replaceTurkishCharacters } from '@shared/utils/replace-turkish-characters';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'net-select-customer-experience-channel-type',
  templateUrl: './select-customer-experience-channel-type.component.html',
  styleUrls: ['./select-customer-experience-channel-type.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCustomerExperienceChannelTypeComponent,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatButtonModule
  ]
})
export class SelectCustomerExperienceChannelTypeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | string[] | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-channel-type';
  describedBy = '';
  stateChanges = new Subject<void>();

  channelTypes: ChannelType[] = [];
  filteredChannelTypes: ChannelType[] = [];
  selectCtrl = new UntypedFormControl();
  searchText = '';

  @Input() multiple: boolean;
  @Input() mdoe: 'add' | 'edit';
  @Input() tabIndex;
  @Input() defaultSelected = true;
  @Input() firstTypeSelected = false;
  @Input() showOnlyActiveChannels = false;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | string[] | null { return this.selectCtrl.value as string | string[]; }
  set value(value: string | string[] | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: string | string[];

  @Output() dropdownValues: EventEmitter<ChannelType[]> = new EventEmitter<ChannelType[]>();

  @ViewChild('channelTypeSelect') channelTypeSelect: MatSelect;

  @HostBinding('id') id = `select-channel-type-${SelectCustomerExperienceChannelTypeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.channelService.getChannelTypes()
        .toPromise()
        .then((response) => {
          this.channelTypes = this.showOnlyActiveChannels ? response.data.filter(ch => ch.isActive) : response.data;
          this.filteredChannelTypes = [...this.channelTypes];

          if (this.defaultSelected && this.filteredChannelTypes.length === 1) {

            this.value = this.filteredChannelTypes.map(v => v.cxChannelTypeId);

          }

          if (this.firstTypeSelected) {
            this.value = this.filteredChannelTypes[0].cxChannelTypeId;
          }

          this.dropdownValues.emit(this.channelTypes);

        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private channelService: CustomerExperienceChannelSettingsService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initDefaultOptions();
  }

  selectAll() {

    if (!this.multiple) {
      return;
    }

    this.selectCtrl.patchValue(this.filteredChannelTypes);
    this.value = this.filteredChannelTypes.map(v => v.cxChannelTypeId);

  }

  deselectAll() {

    if (!this.multiple) {
      return;
    }

    this.selectCtrl.patchValue(null);
    this.value = [];
  }

  onKey(event) {
    if (!this.multiple) {
      return;
    }
    const selectedWarning = Array.isArray(this.value) ? this.channelTypes.filter(channelType =>
      (this.value as string[]).map(type => type).includes(channelType.cxChannelTypeId)) : [];
    this.filteredChannelTypes = [...new Set([...this.search(this.channelTypes, 'name', event.target.value), ...selectedWarning])];

  }

  search(searchList: any[], searchKey: string, searchText: string) {
    const filter = replaceTurkishCharacters(searchText).toUpperCase();
    return searchList?.filter(option => option[searchKey].toUpperCase().includes(filter));
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.channelTypeSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: string, two: string) => one && two && one === two;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: string | string[]) {
    this.value = value;
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
