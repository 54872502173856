@if(buttons.length > 0 && isActive) {

<div class="fab-container">
  <button type="button" color="primary" class="toggler" mat-fab (click)="toggle()">
    <span [class]="'iconify ' + icAdd + ' icon flex items-center justify-center'"
      [@toggler]="{value: display ? 'active' : 'inactive'}"></span>
  </button>
  <div class="button-container" [@stager]="display">
    @for (button of activeButtons; track $index) {
    <div class="item" (click)="onClick(button)">
      <span class="label">{{ button.label }}</span>
      <button type="button" class="fab-secondary quick-item" color="secondary" mat-mini-fab>
        @if(button.icon) {
        <net-iconify-icon [icon]="button.icon" size="24" color="#000"></net-iconify-icon>
        }
      </button>
    </div>
    }

  </div>
</div>

}