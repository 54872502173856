<button #trigger="cdkOverlayOrigin" cdkOverlayOrigin mat-icon-button fxFlex="none" fxLayoutAlign="center center"
  [ngClass]="{'has-filter': column.isFilterActive, 'is-open': isOpen}" class="mat-header-filter" (click)="toggle()">
  <net-iconify-icon [icon]="icFilter"></net-iconify-icon>
</button>

<ng-template cdkConnectedOverlay cdkConnectedOverlayBackdropClass="filter-card-backdrop"
  [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="toggle()">
  <mat-card appearance="outlined" class="filter-card mat-elevation-z22">
    <mat-card-header>
      <mat-card-title>{{'GENERAL.FILTER' | translate}}</mat-card-title>
      <mat-card-actions fxLayout>
        <button mat-button color="warn" (click)="clean()"
          [disabled]="!column.isFilterActive && isEmpty">{{'GENERAL.CLEAR' |
          translate}}</button>
        <button class="progress-button" mat-raised-button color="primary" (click)="apply()">{{'GENERAL.APPLY' |
          translate}}</button>
      </mat-card-actions>
    </mat-card-header>
    <mat-divider></mat-divider>

    <mat-card-content>
      <ng-container *ngTemplateOutlet="activeTemplate"></ng-container>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #searchFilter>
  <mat-form-field class="w-full filter-search" floatLabel="always">
    <mat-label>{{'GENERAL.SEARCH' | translate}}</mat-label>
    <input type="text" matInput [formControl]="searchCtrl">
    <mat-hint class="text-10.5">{{'GENERAL.TYPE_KEYWORD_FOR_FILTERING_AND_APPLY_THE_FILTER' |
      translate}}</mat-hint>
  </mat-form-field>
</ng-template>

<ng-template #minMaxFilter>
  <div class="filter-minmax" [formGroup]="minMaxGroup">
    <mat-form-field class="w-1/2 pr-2" floatLabel="always">
      <mat-label>{{'GENERAL.MINIMUM' | translate}}</mat-label>
      <net-input-number formControlName="min"></net-input-number>
    </mat-form-field>
    <mat-form-field class="w-1/2 pl-2" floatLabel="always">
      <mat-label>{{'GENERAL.MAXIMUM' | translate}}</mat-label>
      <net-input-number formControlName="max"></net-input-number>
    </mat-form-field>
    <mat-hint class="text-10.5">{{'GENERAL.TYPE_THE_MINIMUM_OR_MAXIMUM_VALUE_FOR_FILTERING_AND_APPLY_THE_FILTER' |
      translate}}</mat-hint>
  </div>
</ng-template>

<ng-template #dateFilter>
  <div fxLayout="column" fxFlex>
    <mat-button-toggle-group *ngIf="dateRangeTypes.prev === dateRangeType">
      <mat-button-toggle (click)="applyDateRange('prev30day')">{{'GENERAL.LAST_THIRTY_DAYS' | translate}}
      </mat-button-toggle>
      <mat-button-toggle (click)="applyDateRange('prev3month')">{{'GENERAL.LAST_THREE_MONTHS' | translate}}
      </mat-button-toggle>
      <mat-button-toggle (click)="applyDateRange('prev1year')">{{'GENERAL.LAST_ONE_YEAR' | translate}}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group *ngIf="dateRangeTypes.next === dateRangeType">
      <mat-button-toggle (click)="applyDateRange('month')">{{'GENERAL.THIS_MONTH' | translate}}</mat-button-toggle>
      <mat-button-toggle (click)="applyDateRange('next3month')">{{'GENERAL.NEXT_THREE_MONTHS' | translate}}
      </mat-button-toggle>
      <mat-button-toggle (click)="applyDateRange('year')">{{'GENERAL.THIS_YEAR' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="w-full filter-date mt-2" floatLabel="always">
      <mat-label>{{'GENERAL.DATE' | translate}}</mat-label>
      <mat-date-range-input [formGroup]="dateCtrl" [rangePicker]="datePicker" (click)="datePicker.open()">
        <input type="text" matStartDate readonly formControlName="min" [placeholder]="'GENERAL.START' | translate">
        <input type="text" matEndDate readonly formControlName="max" [placeholder]="'GENERAL.END' | translate">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #datePicker></mat-date-range-picker>
      <mat-hint class="text-10.5">{{'GENERAL.SELECT_THE_DATE_FOR_FILTERING_AND_APPLY_THE_FILTER' |
        translate}}</mat-hint>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #selectFilter>
  <mat-form-field class="w-full filter-select bg-card" floatLabel="always">
    <mat-label>{{'GENERAL.OPTIONS' | translate}}</mat-label>
    <mat-select [formControl]="selectCtrl" multiple [disabled]="!options" (openedChange)="openedChange()"
      [placeholder]="selectCtrl.value?.length > 0 ? getSelectedValuesString() : ''" panelClass="custom-panel">
      <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length>0">
        {{getSelectedValuesString()}}
      </mat-select-trigger>

      <input class="mt-2 ml-4 pb-2 border-0 outline-none w-full bg-card"
        [placeholder]="'GENERAL.SEARCH_ELLIPSIS' | translate" type="text" [(ngModel)]="searchText"
        (ngModelChange)="onKey($event)" (keydown)="checkKey($event)">

      <ng-template [ngIf]="options">
        <div *ngIf="isSelectAllEnabled" class="mat-option-button">
          <button mat-raised-button (click)="selectAll()"> {{'GENERAL.SELECT_ALL' | translate}} </button>
          <button mat-raised-button (click)="deselectAll()"> {{'GENERAL.REMOVE_ALL' | translate}} </button>
        </div>
        <mat-option *ngFor="let option of filteredOptions" [value]="option.key" (click)="changeActiveItem(option.key)">
          {{ option.value }}</mat-option>
      </ng-template>
    </mat-select>
    <mat-spinner matSuffix *ngIf="!options" [diameter]="18"></mat-spinner>
    <mat-hint class="text-10.5">{{'GENERAL.SELECT_OPTIONS_FOR_FILTERING_AND_APPLY_THE_FILTER' |
      translate}}</mat-hint>
  </mat-form-field>
</ng-template>

<ng-template #oneSelectFilter>
  <mat-form-field class="w-full filter-select" floatLabel="always">
    <mat-label>{{'GENERAL.OPTIONS' | translate}}</mat-label>
    <input type="text" [placeholder]="'GENERAL.SEARCH' | translate" matInput [formControl]="autoCtrl"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="onSelectOption($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-autocomplete>
    <mat-spinner matSuffix *ngIf="!options" [diameter]="18"></mat-spinner>
    <mat-hint class="text-10.5">{{'GENERAL.SELECT_OPTIONS_FOR_FILTERING_AND_APPLY_THE_FILTER' |
      translate}}</mat-hint>
  </mat-form-field>
</ng-template>

<ng-template #paginationSelect>
  <net-input-dynamic-field-picklist [entityName]="entityName" [fieldId]="dynamicFieldId"
    (selectOn)="picklistItemsSelected($event)" [picklistItems]="picklistItems"></net-input-dynamic-field-picklist>
</ng-template>

<ng-template #extendedDateFilter>
  <div fxLayout="column" fxFlex>
    <ng-container *ngIf="dateRangeTypes.prev === dateRangeType">

      <mat-button-toggle-group>
        <mat-button-toggle (click)="applyDateRange('thisWeek')">{{'GENERAL.THIS_WEEK' | translate}}
        </mat-button-toggle>
        <mat-button-toggle (click)="applyDateRange('nextWeek')">{{'GENERAL.NEXT_WEEK' | translate}}
        </mat-button-toggle>
        <mat-button-toggle (click)="applyDateRange('thisMonth')">{{'GENERAL.THIS_MONTH' | translate}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>

    <mat-form-field class="w-full filter-date mt-2" floatLabel="always">
      <mat-label>{{'GENERAL.DATE' | translate}}</mat-label>
      <mat-date-range-input [formGroup]="dateCtrl" [rangePicker]="datePicker" (click)="datePicker.open()">
        <input type="text" matStartDate readonly formControlName="min" [placeholder]="'GENERAL.START' | translate">
        <input type="text" matEndDate readonly formControlName="max" [placeholder]="'GENERAL.END' | translate">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #datePicker></mat-date-range-picker>
      <mat-hint class="text-10.5">{{'GENERAL.SELECT_THE_DATE_FOR_FILTERING_AND_APPLY_THE_FILTER' |
        translate}}</mat-hint>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #oneSelectWithoutAutoComplete>
  <mat-form-field class="w-full filter-select" floatLabel="always">
    <mat-label>{{'GENERAL.OPTIONS' | translate}}</mat-label>

    <mat-select [formControl]="oneselectWithoutAutoCompleteCtrl" [disabled]="!options">

      <input class="mt-2 ml-4 pb-2 border-0 outline-none w-full bg-card" autocomplete="select-filter"
        [placeholder]="'GENERAL.SEARCH' | translate" [(ngModel)]="searchText" (ngModelChange)="onKey($event)"
        (keydown)="checkKey($event)">

      <mat-option *ngFor="let option of filteredOptions" [value]="option.key" (click)="changeActiveItem(option.key)">
        {{ option.value }}</mat-option>

    </mat-select>
    <mat-spinner matSuffix *ngIf="!options" [diameter]="18"></mat-spinner>
    <mat-hint class="text-10.5">{{'GENERAL.SELECT_OPTIONS_FOR_FILTERING_AND_APPLY_THE_FILTER' |
      translate}}</mat-hint>
  </mat-form-field>
</ng-template>