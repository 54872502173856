import { finalize } from 'rxjs/operators';

import { ApiDataSource } from '@core/api/api.data-source';

import { NoteLogs } from './note-logs.model';
import { NoteLogsService } from './note-logs.service';

export class NoteLogsDataSource extends ApiDataSource<NoteLogs> {
  customerNoteId: string;

  constructor(private noteLogsService: NoteLogsService, public initialFilter: any) {
    super(initialFilter);
  }

  getData() {
    return this.dataSubject;
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);
    this.orderBy = 'createdOn';
    this.orderType = 'DESC';
    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.noteLogsService
      .search(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
