import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import { StyleService, } from '@shared/services';
import { Icon, Style } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { VexConfigService } from '@vex/config/vex-config.service';
import { VexColorScheme, VexConfigName } from '@vex/config/vex-config.interface';
import { UserConfigService } from '@core/user-config/user-config.service';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-set-style',
  templateUrl: './set-style.component.html',
  styleUrls: ['./set-style.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    SharedModule
  ]
})
export class SetStyleComponent implements OnInit, OnDestroy {

  icons = Icon;

  Style = Style;
  active: Style;

  private readonly destroyRef = inject(DestroyRef);

  constructor(private styleService: StyleService,
    private readonly configService: VexConfigService,
    private userConfig: UserConfigService
  ) { }

  ngOnInit() {
    this.styleService.style$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(style => this.active = style);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }

  setStyle(style: Style) {
    let userConfigStyle: UserConfigValue;

    switch (style) {
      case Style.default:
        userConfigStyle = UserConfigValue.STYLE_DEFAULT;
        this.disableDefaultMode();
        break;

      case Style.light:
        userConfigStyle = UserConfigValue.STYLE_LIGHT;
        this.enableLightMode();
        break;

      case Style.dark:
        userConfigStyle = UserConfigValue.STYLE_DARK;
        this.enableDarkMode();
        break;
    }

    this.userConfig.set(UserConfigType.STYLE, userConfigStyle);

    this.styleService.setStyle(style);
  }

  enableLightMode(): void {
    this.configService.setConfig(VexConfigName.ares);
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.DARK
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  disableDefaultMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

}
