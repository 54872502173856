import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUser } from '@core/store';
import { SystemUserService, User } from '@core/api';
import { ReportFilters, ReportCustomFilter } from './report-customized-filter.model';

import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services';


@Injectable({
    providedIn: 'root',
})
export class ReportCustomFilterService implements OnDestroy {
    private user: User;
    userReportFilters: ReportFilters[] = [];
    userReportFilter$ = new BehaviorSubject(null);
    private reportFiltersSubscription: Subscription;
    constructor(
        private store: Store,
        private systemUserService: SystemUserService,
        private toastService: ToastService,
        private translate: TranslateService
    ) {
        this.store.select(getUser).subscribe(user => {
            this.user = user;

            if (user && user.reportFilter) {
                const reportFilters = JSON.parse(user.reportFilter);

                if (!reportFilters || !Array.isArray(reportFilters)) {
                    return;
                }

                this.userReportFilter$.next(reportFilters);
            } else {
                this.userReportFilter$.next([]);
            }
        });

        this.reportFiltersSubscription = this.userReportFilter$.subscribe(reportFilters => this.userReportFilters = reportFilters ?? []);
    }

    private setReportFilters(reportFilters: ReportFilters[], isToastrActive = false): void {
        this.systemUserService.updateReportFilterConfigs(this.user?.userId, JSON.stringify(reportFilters)).pipe(take(1)).subscribe((response: any) => {
            this.userReportFilter$.next(JSON.parse(response?.data?.reportFilter));
            if (isToastrActive) {
                this.toastService.success(response?.message);
            }
        }, () => {
            this.toastService.error(
                this.translate.instant('GENERAL.AN_ERROR_OCCURRED_WHILE_SAVING_USER_SETTINGS')
            );
        });
    }

    getReportFilters(reportId: string): Observable<ReportCustomFilter[]> {
        return this.userReportFilter$.pipe(
            map((reportArray) => {
                return reportArray?.find((report: ReportFilters) => report.reportId === reportId)?.customFilters || [];
            })
        );
    }

    getReportActiveFilter(reportId: string): Observable<ReportCustomFilter> {
        return this.userReportFilter$.pipe(
            map((reportArray: ReportFilters[]) => {
                const filters = reportArray?.find(report => report.reportId === reportId).customFilters;
                return filters?.find(customFilter => customFilter.selected);
            })
        );
    }

    deleteReportFilter(reportId: string, filterId: string) {
        const report = this.userReportFilters?.find(userReport => userReport.reportId === reportId);
        report.customFilters = report.customFilters?.filter(customFilter => customFilter.id !== filterId);
        this.setReportFilters(this.userReportFilters, true);
    }

    addReportFilter(reportId: string, customFilter: ReportCustomFilter, resetActiveFilter = false) {
        const report = this.userReportFilters?.find(userReport => userReport.reportId === reportId);
        if (report) {
            const index = report.customFilters?.findIndex(filter => filter.id === customFilter.id);
            if (index !== -1) {
                report.customFilters[index] = {
                    ...customFilter,
                    selected: report.customFilters[index].selected
                };
                return;
            }
            report.customFilters?.push(customFilter);

            if (resetActiveFilter) {
                report.customFilters?.forEach(tableFilter => tableFilter.selected = false);
            }
        } else {
            const newReport = {
                reportId,
                customFilters: [customFilter]
            } as ReportFilters;
            this.userReportFilters.push(newReport);
        }
        this.setReportFilters(this.userReportFilters, true);
    }

    updateReportFilter(reportId: string, customFilter: ReportCustomFilter) {
        const report = this.userReportFilters?.find(filter => filter.reportId === reportId);
        const index = report.customFilters?.findIndex(filter => filter.id === customFilter.id);
        report.customFilters[index] = customFilter;

        this.setReportFilters(this.userReportFilters);
    }

    setReportActiveFilter(reportId: string, filterId: string) {
        const selectedReport = this.userReportFilters?.find(report => report.reportId === reportId);
        selectedReport?.customFilters?.map(filter => {
            filter.selected = filter.id === filterId;
        });

        this.setReportFilters(this.userReportFilters);
    }

    ngOnDestroy(): void {
        this.reportFiltersSubscription.unsubscribe();
    }
}
