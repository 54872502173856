import { of } from 'rxjs';
import { delay, finalize, map } from 'rxjs/operators';

import { ApiService, Note, SalesOrganizationService, User } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';

import { NoteType } from '../api.enum';
import { SystemUserService } from '../system-user/system-user.service';

export class NoteDataSource extends ApiDataSource<Note> {
  initNoteType: NoteType;

  public $salesOrganizations = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(salesOrganizations => salesOrganizations.map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  public $createdBys = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $isRead = of([
    { key: false, value: 'Var' },
    { key: true, value: 'Yok' },
  ]).pipe(delay(1000));

  constructor(
    private apiService: ApiService,
    private salesOrganizationService: SalesOrganizationService,
    private systemUserService: SystemUserService,
    private user: User,
    noteType: NoteType,
    public initialFilter: any
  ) {
    super(initialFilter);
    this.initNoteType = noteType;
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.apiService.note<any>(this.initNoteType)
      .search(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
