import { of } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';

import {
  Activity,
  ActivityPurposeService,
  ActivityService,
  ActivityStatusService,
  ActivityTypeService,
  SalesOrganizationService,
  SystemUserService,
  User
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';

export class LeadDraftInfoActivityDataSource extends ApiDataSource<Activity> {
  public isOpenedNewTab = false;

  public $activityStatuses = this.activityStatusService.list({ filter: { isContract: false } }).pipe(
    map(response => response.data.results),
    map(statuses => statuses.map(item => {
      return {
        key: item.activityStatusId,
        value: this.translate.instant('ActivityStatus.' + item.name)
      };
    }))
  );

  public $activityTypes = this.activityTypeService.list({}).pipe(
    map(response => response.data.results),
    map(statuses => statuses.map(item => {
      return {
        key: item.activityTypeId,
        value: this.translate.instant('ActivityType.' + item.name)
      };
    }))
  );

  public $activityPurposes = this.activityPurposeService.search({ isActive: true, isUsed: true }).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.activityPurposeId,
        value: this.translate.instant('ActivityPurpose.' + item.name)
      };
    }))
  );

  public $createdBys = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $activitySources = of([
    { key: true, value: this.translate.instant('GENERAL.LEAD') },
    { key: false, value: this.translate.instant('GENERAL.CUSTOMER') }
  ]);

  public $salesOrganizations = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(salesOrganizations => salesOrganizations.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  constructor(
    private activityService: ActivityService,
    private activityPurposeService: ActivityPurposeService,
    private activityStatusService: ActivityStatusService,
    private activityTypeService: ActivityTypeService,
    private leadDraftId: string,
    private systemUserService: SystemUserService,
    private translate: TranslateService,
    private user: User,
    private salesOrganizationService: SalesOrganizationService,
    public initialFilter?
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter = {
      ...this.initialFilter,
      ...this.filter,
      leadSourceFlag: true,
      leadDraftId: this.leadDraftId
    };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.activityService
      .search(request)
      .pipe(
        debounceTime(400),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });

    return this.loadingSubject.next(false);
  }
}
