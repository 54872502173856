export enum CustomerExperienceTicketEnum {
    CUSTOMER_EXPERIENCE_LISTVIEW = 'TicketListview',
    JOIN_GROUP = 'JoinGroup',
    REMOVE_FROM_GROUP = 'RemoveFromGroup',
    JOIN_GROUP_FOR_RECONNECT = 'JoinGroupForReconnect',
}

export enum CustomerExperienceTicketGroupEnum {
    TICKET_LISTVIEW = 'ticketlistview',
    TICKET = 'ticket'
}

export enum CustomerExperienceTicketDetailActionTypeEnum {
    COMPLETE = 'Complete',
    REOPEN = 'Reopen',
    UPDATE_ASSIGNEE = 'UpdateAssignee',
    UPDATE_CATEGORY = 'UpdateCategory',
    UPDATE_PRIORITY = 'UpdatePriority',
    UPDATE_STATUS = 'UpdateStatus',
    UPDATE_SUBJECT = 'UpdateSubject',
    GET_TICKET_MESSAGES = 'GetTicketMessages',
    REPLY_TICKET_MESSAGE = 'ReplyTicketMessage',
    TICKET_MESSAGE_RESULT = 'TicketMessageResult',
    TICKET_LOG = 'TicketLog',
    TICKET_PRIVATE_NOTE = 'TicketPrivateNote',
    TICKET_MERGE = 'TicketMerge',
    TICKET_SPLIT = 'TicketSplit',
    TICKET_GENERATE_REPLY = 'TicketGenerateReply',
    GENERATED_ANSWER = 'GeneratedAnswer',
    TICKET_DYNAMIC_FIELD_UPDATE = 'TicketDynamicFieldUpdate',
    UPDATE_DYNAMIC_FIELD = 'UpdateDynamicField',
    TICKET_MESSAGE_REMOVED = 'TicketMessageRemoved'
}

export enum CustomerExperienceMessageRequestType {
    Message = 0,
    PrivateNote = 1
}

export enum GenerateReplyTypeEnum {
    Default = 0,
    Longer = 1,
    Shorter = 2
}
