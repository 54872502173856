<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="vex-sidenav flex flex-col">
  <div class="vex-sidenav-toolbar sidenav-toolbar flex-none flex items-center">
    <div class="netlog-logo">
      <img [src]="logoPath?.light" class="light" alt="XOOI">
      <img [src]="logoPath?.dark" class="dark" alt="XOOI">
    </div>

    <button (click)="toggleCollapse()" class="w-8 h-8 -mr-2 leading-none flex-none" mat-icon-button type="button" style="align-items: center;
    display: flex;">
      <net-iconify-icon *ngIf="!collapsed" [icon]="icRadioButtonChecked" size="14"
        color="var(--vex-sidenav-item-color-active)"></net-iconify-icon>
      <net-iconify-icon *ngIf="collapsed" [icon]="icRadioButtonUnchecked" size="14"
        color="var(--vex-sidenav-item-color-active)"></net-iconify-icon>
    </button>
  </div>

  <div class="sidenav-items flex-auto mb-10" #scrollBar=netScrollbarDirective netScrollbar>
    <net-sidenav-item *ngFor="let item of (items$ | async); trackBy: trackByRoute" [item]="item" [level]="0"
      [collapsed]="collapsed && [false, undefined, null].includes(collapsedOpen$ | async)"
      (click)="onClickedSidenavItem(item)"></net-sidenav-item>
  </div>
  <net-set-style></net-set-style>
  <div class="text-center opacity-50 cursor-default" style="color: var(--vex-sidenav-item-color-active);">{{ appInfo }}
  </div>
</div>