import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Component, DestroyRef, DoCheck, ElementRef, HostBinding, inject, Input, OnDestroy, Optional, Self } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { Permission } from '@core/auth/auth.enum';
import { hasUserPermission } from '@core/store';
import { Board, BoardService } from '@core/api';
import { SharedModule } from '@shared/modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'net-select-board',
  templateUrl: './select-board.component.html',
  styleUrls: ['./select-board.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectBoardComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectBoardComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Board> {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-board';
  describedBy = '';
  stateChanges = new Subject<void>();

  boards: Board[] = [];
  selectCtrl = new FormControl();

  @Input() tabIndex;

  @HostBinding('id') id = `select-board-${SelectBoardComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Board { return this.selectCtrl.value; }
  set value(value: Board) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get empty() { return !this.value; }

  get showNotFound(): boolean {
    return !this.loading && this.boards.length === 0;
  }

  get shouldLabelFloat() {
    return this.focused || this.showNotFound || !this.empty;
  }

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private store: Store,
    private boardService: BoardService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    this._focusMonitor.monitor(this._elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.store
      .select(hasUserPermission(Permission.BOARD_SEARCH))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(permission => {
        if (!permission) {
          return;
        }

        this.loading = true;
        this.boardService
          .search({ page: 1, pageSize: 500, filter: {} })
          .subscribe((response) => this.boards = response.success ? response.data.results : [])
          .add(() => this.loading = false);
      });
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  writeValue = (value: Board) => {
    this.value = value;

    if (value) {
      const hasValue = this.boards?.find(board => board.boardId === value.boardId) ?? false;

      if (!hasValue) {
        this.boards.push(value);
      }
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: Board, two: Board) => one && two && one.boardId === two.boardId;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();
}
