type Validator<T> = (value: T) => boolean;

export const isValidValue: Validator<any> = (value) => {
  if (value === null || value === undefined) { return false; }
  if (typeof value === 'boolean') { return value === true; }
  if (typeof value === 'string') { return value.trim().length > 0; }
  if (Array.isArray(value)) { return value.length > 0; }
  if (typeof value === 'number') { return true; } // Sayılar için ekstra kontrol eklenebilir
  if (value instanceof Date) { return !isNaN(value.getTime()); }
  if (typeof value === 'object') { return Object.keys(value).length > 0; } // Obje ise boş olup olmadığını kontrol et
  return false;
};

export function isAnyValueValid<T>(obj: Record<string, T>, validator: Validator<T>): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (validator(obj[key])) {
        return true;
      }
    }
  }

  return false;
}
