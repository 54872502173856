import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap, filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  Board, BoardInfo, BoardInfoAdditionalData, BoardService, Card, PagedResponseData, SocketSearchRequest,
  BoardResponse, setBoardInfoProperties, setBoardProperties, BoardDetailFilterService,
  setCardProperties
} from '@core/api';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import {
  SetBoardDetail, SetBoardDetailBoardInfo, SetBoardDetailHashtag, SetBoardDetailSystemUser, SetBoardReportBoard, GetBoardWithBoardInfos
} from '@core/store';

@Injectable({
  providedIn: 'root'
})
export class BoardResolverService {

  private board$: ReplaySubject<Board> = new ReplaySubject<Board>(1);
  updateCustomFields$: Subject<void> = new Subject();

  private board: Board;
  boardPageInfos: PagedResponseData<BoardInfo>;

  constructor(
    private boardService: BoardService,
    private store: Store,
    private boardDetailFilterService: BoardDetailFilterService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.get(route.params['boardId']);
  }

  getBoard(): Subject<Board> {
    return this.board$;
  }

  get(boardId: string) {
    const observable = this.boardService.get(boardId).pipe(
      filter(response => response.success),
      map(response => response?.data),
      tap(board => {
        this.board = board;
        this.store.dispatch(new SetBoardDetail(board));
        this.store.dispatch(new SetBoardReportBoard(board));
        this.store.dispatch(new GetBoardWithBoardInfos(board.boardId));
        this.store.dispatch(new SetBoardDetailSystemUser(board.boardSystemUsers));
        this.store.dispatch(new SetBoardDetailHashtag(board.boardHashtags));
        this.board$.next(board);
      })
    );

    return observable;
  }


  updateBoardInfoData(
    boardInfosResponses: BoardResponse<BoardInfo>[],
    hubConnection: signalR.HubConnection,
    groupName: string,
    request: SocketSearchRequest) {

    this.store.dispatch(new SetBoardDetailBoardInfo(setBoardInfoProperties(boardInfosResponses, this.board,
      hubConnection, groupName, request, this.board$).boardInfos));

    // this.store.dispatch(new BoardReportBoardInfosAction(this.board.boardId));
  }

  updateCardData(cardsResponses: BoardResponse<Card>[], request?: SocketSearchRequest, type?: string, hubConnection?: signalR.HubConnection) {
    let currentFilter: SocketSearchRequest = {};
    this.boardDetailFilterService.filter$.pipe(
      take(1)
    ).subscribe(_filter => currentFilter = _filter);

    this.store.dispatch(new SetBoardDetailBoardInfo(setCardProperties(cardsResponses, this.board, request, currentFilter, type, hubConnection).boardInfos));
    // this.updateBoard(setCardProperties(cardsResponses, this.board, request, currentFilter, type));
  }

  updateBoardData(boardsResponses: BoardResponse<Board>[]) {
    this.store.dispatch(new SetBoardDetail(setBoardProperties(boardsResponses, this.board, this.store)));

    // this.updateBoard(setBoardProperties(boardsResponses, this.board, this.store));
  }

  addSectionsToScrum(boardInfos: PagedResponseData<BoardInfo>, request: SocketSearchRequest) {
    this.boardPageInfos = boardInfos;

    boardInfos.results.map(boardInfo => boardInfo.page = 1);

    if (this.board.boardInfos && request.page > 1) {
      this.board.boardInfos = [...this.board.boardInfos, ...boardInfos.results];
    } else {
      this.board.boardInfos = boardInfos.results;
    }

    this.board.boardInfos = this.board.boardInfos?.map((boardInfo) => {
      if (!boardInfo.boardInfoUuid) {
        boardInfo.boardInfoUuid = uuidv4();
      }

      boardInfo.cards = boardInfo.cards.map(card => {
        if (!card.cardUuid) {
          card.cardUuid = uuidv4();
        }
        return card;
      });

      return boardInfo;
    });

    this.store.dispatch(new SetBoardDetailBoardInfo(this.board.boardInfos));

    this.updateBoard(this.board);
  }

  addCardsToScrumSections(cards: Card[]) {
    if (cards.length === 0) {
      return;
    }
    const section = this.board?.boardInfos?.find(sec => sec.boardInfoId === cards[0].boardInfoId);

    if (!section) {
      return;
    }
    cards.map(card => {

      section.cards.push(card);
    });

    section.cards.sort((a, b) => a.orderBy < b.orderBy ? -1 : 1);

    this.board.boardInfos = this.board.boardInfos?.map((boardInfo) => {
      boardInfo.cards = boardInfo.cards.map(card => {
        if (!card.cardUuid) {
          card.cardUuid = uuidv4();
        }
        return card;
      });

      return boardInfo;
    });

    this.store.dispatch(new SetBoardDetailBoardInfo(this.board.boardInfos));


    // this.updateBoard(this.board);
  }

  addBoardInfosToLists(boardInfos: BoardInfo[], request: SocketSearchRequest, additionalData?: BoardInfoAdditionalData) {
    if (request.page === 1) {
      this.board.boardInfos = boardInfos.map(boardInfo => {
        return {
          ...boardInfo,
          cardCount: boardInfo.cardCount,
          completedCardCount: boardInfo.completedCardCount,
          totalCardCount: boardInfo.totalCardCount
        };
      });

      this.store.dispatch(new SetBoardDetail({ ...this.board }));
    } else if (this.board.boardInfos && ((!request.filter.cardStatusId && !request.filter.hasDetailedFilter) || request.page > 1)) {
      if (additionalData?.paginationType && additionalData.paginationType === 'collapse') {
        this.setClickedBoardInfo(additionalData);
      }

      boardInfos.map(boardInfo => {
        const secIndex = this.board.boardInfos.findIndex(sec => sec.boardInfoId === boardInfo.boardInfoId);

        if (secIndex === -1) {
          this.board.boardInfos.push(boardInfo);
        } else {
          this.board.boardInfos[secIndex] = {
            ...this.board.boardInfos[secIndex],
            cardCount: boardInfo.cardCount,
            completedCardCount: boardInfo.completedCardCount,
            totalCardCount: boardInfo.totalCardCount
          };
          // Creating unique cards array for the chosen section
          const cardsForSection = [...this.board.boardInfos[secIndex].cards, ...boardInfo.cards].filter((item, index, thisArray) => {
            return index === thisArray.findIndex(obj => item.cardId === obj.cardId);
          });

          this.board.boardInfos[secIndex].cards = cardsForSection.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        }
      });
    } else {
      this.board.boardInfos = boardInfos;
    }

    this.board.boardInfos = this.board.boardInfos?.map((boardInfo) => {
      if (!boardInfo.boardInfoUuid) {
        boardInfo.boardInfoUuid = uuidv4();
      }

      boardInfo.cards = boardInfo.cards.map(card => {
        if (!card.cardUuid) {
          card.cardUuid = uuidv4();
        }
        return card;
      });

      return boardInfo;
    });

    this.store.dispatch(new SetBoardDetailBoardInfo(this.board.boardInfos));
    // this.updateBoard(this.board);
  }

  updateBoard(board: Board) {
    board = {
      ...board,
      boardInfos: this.board?.boardInfos?.filter(x => !x.deleteFlag) ?? []
    };
    this.board$.next(board);
  }

  clearBoardInfo() {
    delete this.board.boardInfos;
    this.store.dispatch(new SetBoardDetailBoardInfo([]));
    this.updateBoard(this.board);
  }

  private setClickedBoardInfo(additionalData: BoardInfoAdditionalData) {

    // If section is collapsed, remove cards of the section
    // If section is expanded, mark it as last section
    if (additionalData.clickedBoardInfo?.collapse) {
      const collapsedSection = this.board.boardInfos.find(section => section.boardInfoId === additionalData.clickedBoardInfo.boardInfoId);
      collapsedSection.cards = [];
    } else {
      const lastSection = this.board.boardInfos.find(section => section.boardInfoId === additionalData.clickedBoardInfo.boardInfoId);

      if (lastSection.cardCount > 50) {
        this.board.boardInfos = this.board.boardInfos.filter(section => section.orderBy <= lastSection.orderBy);
      }
    }
  }
}
