import { create } from 'canvas-confetti';

const thing = create(undefined, { useWorker: true, resize: true });

export function canvasConfetti() {
  const colors = ['#3178b9', '#ffffff', '#5552a3', '#2fbfcb'];
  let interval: NodeJS.Timeout;

  interval = setInterval(() => {
    thing({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors
    });
    thing({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors
    });
  }, 1);

  setTimeout(() => {
    clearInterval(interval);
  }, 1000);
}
