import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BoardDetailFormDialogActionTypes, GetBoardDetailFormDialogError, GetBoardDetailFormDialogSuccess, SetBoardDetailFormDialog } from '@core/store';
import { CardService } from '@core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
// TODO: Will be uncommented when the layout service is ready
// import { LayoutService } from 'src/app/layout/services/layout.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class BoardDetailFormDialogEffects {
    constructor(
        private cardService: CardService,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        //private layoutService: LayoutService,
        private actions$: Actions
    ) { }

    getReportBoard$ = createEffect(() => this.actions$.pipe(
        ofType(BoardDetailFormDialogActionTypes.GET_BOARD_DETAIL_FORM_DIALOG),
        mergeMap((action: SetBoardDetailFormDialog) => this.cardService.get(action.payload)),
        tap(response => {
            if (!response.success && Number(response.errorCode) === 449) {

                this.toastrService.info(this.translate.instant(`ResultMessage.${response[0].message}`));
                //this.layoutService.closeCardDetail();

                this.router.navigate([], {
                    relativeTo: this.activatedRoute
                });
                return;
            }
        }),
        switchMap(response => of(new GetBoardDetailFormDialogSuccess(response.data))),
        catchError((error) => of(new GetBoardDetailFormDialogError(error)))
    ));
}
