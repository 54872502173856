import { debounceTime, finalize, map } from 'rxjs/operators';
import { ApiDataSource } from '../api.data-source';
import { OrderType } from '../api.type';
import { StaticFieldService } from './static-field.service';
import { StaticFieldFilterRequest } from './static-field.model';
import { DynamicFieldService } from '../dynamic-field/dynamic-field.service';
import { of } from 'rxjs';

export class StaticFieldDataSource extends ApiDataSource<any> {

  public $status = of([
    { key: false, value: 'Passive' },
    { key: true, value: 'Active' }
  ]);

  public $dynamicFieldTypesEnums = of([
    { key: '0', value: 'Text' },
    { key: '1', value: 'Integer' },
    { key: '2', value: 'Decimal' },
    { key: '3', value: 'Date' },
    { key: '4', value: 'Datetime' },
    { key: '5', value: 'Picklist' },
    { key: '6', value: 'Separator' }
  ]);

  public $dynamicFieldTypes = this.dynamicField.types().pipe(map(response => response.data),
    map(types => {
      return types.map(type => {
        return {
          key: type.dynamicFieldTypeId,
          value: type.name
        };
      });
    }));

  orderBy = 'orderBy';
  orderType = 'ASC' as OrderType;

  constructor(
    public ready: boolean,
    private staticFieldService: StaticFieldService,
    private dynamicField: DynamicFieldService,
    public initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    // Load dynamic fields when ready
    if (!this.ready) {
      return;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request: StaticFieldFilterRequest = {
      filter: { ...this.initialFilter, ...this.filter },
      ...this.getRequest()
    };

    // If filter keyword exists, filter data
    if (this.keyword) {
      request.filter.searchText = this.keyword;
    }

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    this.staticFieldService.search(request).pipe(
      debounceTime(400),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe((response) => {
      this.dataSubject.next(response.data.results);
      this.dataCountSubject.next(response.data.rowCount);

      // Update data source's empty based row count
      this.empty = response.data.rowCount === 0;
    });
  }
}
