import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { CustomNumberPipe } from '../../pipes/custom-number.pipe';
import { provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'net-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    CustomNumberPipe,
    {
      provide: MatFormFieldControl,
      useExisting: InputNumberComponent
    },
    provideNgxMask()
  ]
})
export class InputNumberComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<number> {

  static nextId = 0;

  decimalSeparator = this.authService.numberLanguage === 'tr' ? ',' : '.';
  amount: UntypedFormControl = new UntypedFormControl(null);

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-number';
  describedBy = '';
  stateChanges = new Subject<void>();

  @Input() readonly = false;
  @Input() autocomplete: 'on' | 'off' = 'on';
  @Input() isTextInput = false;
  @Input() maxLength = 20;
  @Input() clearZero = false;

  @Input()
  get removeMask(): boolean { return this._removeMask; }
  set removeMask(value: boolean) {
    if (value) {
      this.mask = null;
    }

    this._removeMask = value;
    this.stateChanges.next();
  }
  private _removeMask = false;

  @Input()
  get allowNegative(): boolean { return this._allowNegative; }
  set allowNegative(value: boolean) {
    this._allowNegative = value;
    this.stateChanges.next();
  }
  private _allowNegative = false;

  @Input()
  get allowDecimal(): boolean { return this._allowDecimal; }
  set allowDecimal(value: boolean) {
    this._allowDecimal = value;
    this.stateChanges.next();
  }
  private _allowDecimal = true;

  @Input()
  get decimalDigitCount(): number { return this._decimalDigitCount; }
  set decimalDigitCount(value: number) {
    this._decimalDigitCount = value;
    this.mask = `separator.${this._decimalDigitCount}`;
    this.stateChanges.next();
  }
  private _decimalDigitCount = 0;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder = '';

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @HostBinding() id = `input-number-${InputNumberComponent.nextId++}`;
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() value: number;

  get empty() {
    return this.value === null || this.value === undefined;
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);

    if (this._disabled) {
      this.amount.disable();
    } else {
      this.amount.enable();
    }

    this.stateChanges.next();
  }
  private _disabled = false;

  mask = `separator.${this.decimalDigitCount}`;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private customNumber: CustomNumberPipe,
    public authService: AuthService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.amount.valueChanges
      .pipe(startWith(this.amount.value))
      .subscribe(value => {
        this.value = value;
        this.onChange(this.value);
      });
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  onFocus() {
    if (!this.clearZero) {
      return;
    }

    if (this.value === 0) {
      this.writeValue(null);
    }
  }

  onFocusout() {
    if (!this.clearZero) {
      return;
    }

    if (!this.value) {
      this.writeValue(0);
    }
  }

  writeValue(value: number) {
    this.value = value;
    this.amount.setValue(value);
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
