import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AssignmentPriority, AssignmentPriorityService } from '@core/api';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-assignment-priority',
  templateUrl: './select-assignment-priority.component.html',
  styleUrls: ['./select-assignment-priority.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectAssignmentPriorityComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectAssignmentPriorityComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | null> {

  static nextId = 0;

  loading = true;
  focused = false;
  errorState = false;
  controlType = 'select-assignment-priority';
  describedBy = '';
  stateChanges = new Subject<void>();

  assignmentPriorities: AssignmentPriority[] = [];
  mainAssignmentPriorities: AssignmentPriority[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() isDisabled = false;
  @Input() tabIndex;
  @HostBinding('id') id = `select-activity-status-${SelectAssignmentPriorityComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private assignmentPriorityService: AssignmentPriorityService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _isCreate = false;

  @Input()
  get isCreate(): boolean {
    return this._isCreate;
  }

  set isCreate(value: boolean) {
    this._isCreate = coerceBooleanProperty(value);
    if (this._isCreate) {
      this.mainAssignmentPriorities = this.assignmentPriorities;
    } else {
      this.assignmentPriorities = this.mainAssignmentPriorities;
    }
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this.selectCtrl.value;
  }

  set value(value: string | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  ngOnInit() {
    this.fetchList();
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  compareWith = (one: string, two: string) => one && two && one === two;

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue = (value: string) => this.value = value;

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  private fetchList() {
    this.assignmentPriorityService.list({}).subscribe(response => {
      if (response.success) {
        this.assignmentPriorities = response.data.sort((a, b) => (a.priority > b.priority) ? 1 : -1);
      }
    }).add(() => {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }
}
