import { ENVIRONMENT_INITIALIZER, ErrorHandler, inject } from "@angular/core";
import { SplashScreenService } from "../services/splash-screen.service";
import { LocaleProvider } from "../services/locale.provider";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "@core/auth";
import { ApiInterceptor, LanguageDataPassService, NotePassService } from "@core/api";
import { CustomErrorHandlerService, GeocodeService, GoogleTranslateService, LayoutService } from "@shared/services";

export function provideNet() {
  return [
    LocaleProvider,
    LayoutService,
    LanguageDataPassService,
    GeocodeService,
    GoogleTranslateService,
    NotePassService,
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(SplashScreenService),
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }, {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService
    }
  ]
}
