import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { ActivityVideoConferenceTool } from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-activity-video-conference-tool',
  templateUrl: './select-activity-video-conference-tool.component.html',
  styleUrls: ['./select-activity-video-conference-tool.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectActivityVideoConferenceToolComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectActivityVideoConferenceToolComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-activity-video-conference-tool';
  describedBy = '';
  stateChanges = new Subject<void>();

  @Input() isDisabled = false;
  @Input() activityVideoConferenceTool: ActivityVideoConferenceTool[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;
  @HostBinding('id') id = `select-activity-video-conference-tool-${SelectActivityVideoConferenceToolComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this.selectCtrl.value;
  }

  set value(value: string | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  getToolName(selectId: string) {
    const name = this.translate.instant(`MeetingType.${this.activityVideoConferenceTool.find((tool) => tool.meetingTypeId === selectId)?.name}`);
    return name ?? null;
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => {
  }
  onTouched = () => {
  }
  compareWith = (one: string, two: string) => one && two && one === two;
  onContainerClick = () => {
  }
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
