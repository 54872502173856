import { EnumRepeatCycle } from '@core/api/survey-mail/survey-mail.enum';
import CronTime from 'cron-time-generator';
import { DayOption, FirstLastOption } from 'src/app/assignment/assignment.models';

export function cronExpression(formValueRealtime: any) {
    let expression = null;
    const hourTime = formValueRealtime.recurrenceCronObject.repeatEveryAtTime;
    switch (formValueRealtime.recurrenceCronObject.repeatOption) {
        case EnumRepeatCycle.WEEKLY:
            if (hourTime) {
                expression = CronTime.onSpecificDaysAt(formValueRealtime.recurrenceCronObject.deadlineDays, hourTime.hour, hourTime.minute);
            }
            break;

        case EnumRepeatCycle.MONTHLY:
            if (hourTime) {
                const firstOrLast = formValueRealtime.recurrenceCronObject.firstLast;
                const repeatEvery = formValueRealtime.recurrenceCronObject.repeatEvery;
                switch (formValueRealtime.recurrenceCronObject.selectedRadioOption) {
                    case 1:
                        expression = `${hourTime.minute} ${hourTime.hour} ${formValueRealtime.recurrenceCronObject.onDay} */${repeatEvery} *`;
                        break;
                    case 2:
                        const monthlyOptionDayOption = formValueRealtime.recurrenceCronObject.monthlyOptionDayOption;
                        expression = getMonthlyCronDayOption(firstOrLast, repeatEvery, monthlyOptionDayOption, hourTime.hour, hourTime.minute);
                        break;
                    case 3:
                        const monthlyOptionWeekday = formValueRealtime.recurrenceCronObject.monthlyOptionWeekday;
                        expression = getMonthlyCronWeekdayOption(firstOrLast, repeatEvery, monthlyOptionWeekday, hourTime.hour, hourTime.minute);
                        break;
                    default:
                        break;
                }
            }
            break;

        case EnumRepeatCycle.YEARLY:
            if (hourTime) {
                switch (formValueRealtime.recurrenceCronObject.selectedRadioOption) {
                    case 1:

                        expression = CronTime.everyYearIn(
                            formValueRealtime.recurrenceCronObject.selectedMonthlyOption,
                            formValueRealtime.recurrenceCronObject.onDay,
                            hourTime.hour,
                            hourTime.minute
                        );
                        break;
                    case 2:
                        const dayOption = formValueRealtime.recurrenceCronObject.monthlyOptionDayOption;
                        const monthOption = formValueRealtime.recurrenceCronObject.selectedMonthlyOption;
                        expression = getDayCronYearly(formValueRealtime.recurrenceCronObject.firstLast, dayOption, monthOption, hourTime.hour, hourTime.minute);
                        break;

                    default:
                        break;
                }
            }
            break;

        default:
            break;
    }
    return expression;
}

export function getMonthlyCronDayOption(firstOrLast: FirstLastOption, repeatEvery: number, dayOption: number, hour?: number, minute?: number) {
    return `${minute ?? '*'} ${hour ?? '*'} * */${repeatEvery} ${getDayCronWithDayOption(firstOrLast, dayOption)}`; // Example: 0 0 * */3 1L || 0 0 * */3 3#1
}

export function getMonthlyCronWeekdayOption(firstOrLast: FirstLastOption, repeatEvery: number, weekdayOption: number, hour?: number, minute?: number) {
    return `${minute ?? '*'} ${hour ?? '*'} ${getDayCronWithWeekday(firstOrLast, weekdayOption)} */${repeatEvery} *`; // Example: 0 0 1W */3 * || 0 0 LW */3 *
}

export function getDayCronWithDayOption(firstOrLast: FirstLastOption, dayOption: number) {
    return firstOrLast === FirstLastOption.First ? `${dayOption}#1` : `${dayOption}L`;
}

export function getDayCronWithWeekday(firstOrLast: FirstLastOption, weekdayOption: number) {
    if (firstOrLast === FirstLastOption.First) {
        return weekdayOption === DayOption.Day ? '1' : '1W';
    }

    if (firstOrLast === FirstLastOption.Last) {
        return weekdayOption === DayOption.Day ? 'L' : 'LW';
    }
}

export function getDayCronYearly(firstOrLast: FirstLastOption, dayOption: number, monthOption: number, hour?: number, minute?: number) {
    return `${minute ?? '*'} ${hour ?? '*'} * ${monthOption} ${getDayCronWithDayOption(firstOrLast, dayOption)}`;
}
