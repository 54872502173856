import { DestroyRef, inject, Injectable } from '@angular/core';
import { SystemUserService, User } from '@core/api';
import { GetRecentPageSuccess, getAccount, getUser } from '@core/store';
import { Store } from '@ngrx/store';
import { RecentPage } from './recent-pages.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class RecentPageService {

  private recentPages: RecentPage[] = [];
  private user: User = null;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private systemUserService: SystemUserService,
    private store: Store,
  ) {
    this.getRecentPages().then();
  }

  async getRecentPages(): Promise<RecentPage[]> {
    return new Promise((resolve) => {
      this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
        this.user = user;
        if (user && user.recentPage) {
          this.recentPages = JSON.parse(user.recentPage);
        } else {
          this.recentPages = [];
        }
        this.store.dispatch(new GetRecentPageSuccess(this.recentPages));
        resolve(this.recentPages);
      });
    });
  }

  async setRecentPages(addedPage: RecentPage) {

    if (this.recentPages.length === 0) {
      this.recentPages = await this.getRecentPages();
    } else {
      await this.getRecentPages();
    }

    const index = this.recentPages.findIndex(recent => recent.url === addedPage.url);

    // recent pages kısmında card ismi yerine board ismi göründüğü için ikinci koşul eklendi
    if (index === 0 && !this.recentPages[index].url.includes('/board/')) {
      return;
    }

    if (index !== -1) {
      this.recentPages = this.recentPages.filter(recentPage => recentPage.url !== addedPage.url);
    }

    this.recentPages = [addedPage, ...this.recentPages];

    this.recentPages = this.recentPages.length > 10 ? this.recentPages.splice(0, 10) : this.recentPages;
    this.systemUserService.updateRecentPageConfigs(
      this.user?.userId,
      JSON.stringify(this.recentPages)
    ).subscribe(() => {
      this.store.dispatch(new GetRecentPageSuccess(this.recentPages));
    });
  }

  addItem(addedPage: RecentPage) {
    this.setRecentPages(addedPage);
  }

  addItemWithParentRoute(addedPage: RecentPage) {
    this.store.select(getAccount).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(account => {
      if (!account) {
        this.setRecentPages(addedPage);
      }
    });
  }
}
