<mat-select [placeholder]="placeholder" [tabIndex]="tabIndex" [compareWith]="compareUserFn" [formControl]="selectCtrl"
  (selectionChange)="onSelectionChange()" [matTooltip]="tooltip" (openedChange)="openedChange($event)"
  panelClass="custom-panel" #userSelect disableOptionCentering multiple>
  <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length > 2">
    {{'GENERAL.VALUE_USERS_SELECTED' | translate: { value: selectCtrl.value.length } }}
  </mat-select-trigger>

  <input (keydown)="$event.stopPropagation()" (ngModelChange)="onKey($event)" [(ngModel)]="searchText"
    [ngClass]="isOpened ? 'visible-searchbox': 'invisible-searchbox'" [ngModelOptions]="{standalone: true}"
    class="mt-4 ml-4  pb-2 border-0 outline-none w-full bg-card" placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">
  <div *ngIf="type !== 'opportunityInitials'" class="mat-option-button">

    <button mat-raised-button (click)="selectAll()">
      {{'GENERAL.SELECT_ALL' | translate}}
    </button>
    <button mat-raised-button (click)="deselectAll()">
      {{'GENERAL.REMOVE_ALL' | translate}}
    </button>
  </div>
  <mat-option *ngFor="let systemUser of systemUsers" [disabled]="disabled" [value]="systemUser">{{ systemUser.firstName
    }} {{ systemUser.lastName }}
  </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>