import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetRecentPageError, GetRecentPageSuccess, RecentPageActionTypes } from './recent-page.action';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Response, RecentPageService, RecentPage } from '@core/api';
@Injectable()
export class RecentPageEffects {

    private readonly recentPageService = inject(RecentPageService)

    constructor(
        private actions$: Actions
    ) { }

    recentPages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RecentPageActionTypes.GET_RECENT_PAGE),
            switchMap(() => this.recentPageService.getRecentPages()),
            map((pages: RecentPage[]) => new GetRecentPageSuccess(pages)),
            catchError((error: Response<null>) => of(new GetRecentPageError(error)))
        )
    );
}
