import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomConsole } from 'src/app/shared/utils/custom-console';
import {
    CustomerExperienceChatModel,
    CustomerExperienceService, CustomerExperienceTicket, CustomerExperienceTicketDetailActionTypeEnum,
    CustomerExperienceTicketEnum, CustomerExperienceTicketGroupEnum, CustomerExperienceTicketSignalRRequest
} from '@core/api';
import { setChat } from './helpers/customer-experience-chat.helper';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/shared/services/toast.service';

@Injectable({
    providedIn: 'root'
  })
  export class CustomerExperienceTicketSignalRService {
  
    console = new CustomConsole();
  
    private ticket: CustomerExperienceTicket;
    private ticket$: ReplaySubject<CustomerExperienceTicket> = new ReplaySubject<CustomerExperienceTicket>(1);
    private ticketForInteraction: ReplaySubject<CustomerExperienceTicket> = new ReplaySubject<CustomerExperienceTicket>(1);
  
    private conversation: CustomerExperienceChatModel[];
    private conversation$: ReplaySubject<CustomerExperienceChatModel[]> = new ReplaySubject<CustomerExperienceChatModel[]>(1);
  
    public loadingSubject = new ReplaySubject<boolean>();
    public loading$ = this.loadingSubject.asObservable();
  
    public connectionSubject = new ReplaySubject<boolean>();
    public connection$ = this.connectionSubject.asObservable();
  
    private generatedAnswerSubject = new ReplaySubject<string>(1);
    public generatedAnswer$ = this.generatedAnswerSubject.asObservable();
  
    private unreadMessagesCount = new BehaviorSubject<number>(null);
    private redirectTicketId = new BehaviorSubject<string>(null);
  
    private hubConnection: signalR.HubConnection;
    joinedGroupList: string[] = [];
  
    constructor(
      private auth: AuthService,
      private customerExperienceService: CustomerExperienceService,
      private translate: TranslateService,
      private toastService: ToastService
    ) { }
  
    public startConnection = () => {
  
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.socketUrl}/tickethub?x-client=${environment.socketTenant}`, {
          accessTokenFactory: async () => {
            await this.getToken(); return AuthService.getToken();
          }, withCredentials: true, transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
  
      this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;
      this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60;
  
      this.hubConnection.onclose((err) => {
        if (err) {
          if (err.stack.includes('WebSocket closed with status code: 1006 ()')) { setTimeout(() => { location.reload(); }, 1000); }
          setTimeout(() => {
            this.hubConnection.start()
              .then(() => this.console.info('Websocket Connection Established'))
              .catch(error => this.console.error('SignalR Connection Error: ', error));
          }, 500);
        }
      });
  
      this.hubConnection.onreconnected(() => {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
          this.console.info('Websocket Reconnected', this.joinedGroupList);
          this.joinGroupForReconnect();
        }
      });
  
      this.hubConnection
        .start()
        .then(() => {
          // Ticket değişikliklerini almak için gruba dahil olma
          if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
  
            this.listenJoinGroup();
  
  
          }
        })
        .catch(err => this.console.info('Error while starting connection: ' + err));
    }
  
    joinGroupForTicket = (ticketId) => {
      this.listenTicket();
  
      this.hubConnection.invoke(CustomerExperienceTicketEnum.JOIN_GROUP, CustomerExperienceTicketGroupEnum.TICKET, ticketId, []).finally(() => {
        this.connectionSubject.next(true);
        this.loadingSubject.next(false);
      });
    }
  
    closeTicketListener() {
      this.hubConnection.off(CustomerExperienceTicketGroupEnum.TICKET);
    }
  
    joinGroupForTicketListview = (groupName: string, channels?: string[]) => {
      this.listenListview();
      this.hubConnection.invoke(
        CustomerExperienceTicketEnum.JOIN_GROUP,
        groupName,
        null as string, channels
      ).finally(() => {
        this.console.info('Connection started');
        this.connectionSubject.next(true);
        this.loadingSubject.next(false);
      });
    }
  
    public invokeTicket = (target: CustomerExperienceTicketDetailActionTypeEnum, request: CustomerExperienceTicketSignalRRequest) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        this.console.info(target, request);
        this.hubConnection.invoke(target, request);
      }
    }
  
    private listenListview = () => {
      this.hubConnection.on(CustomerExperienceTicketGroupEnum.TICKET_LISTVIEW, (response: boolean) => {
        if (response) {
          this.customerExperienceService.triggerRefreshButton();
        } else {
          this.customerExperienceService.clearRefreshListviewButton();
        }
        this.loadingSubject.next(false);
      });
    }
  
    private listenTicket = () => {
      this.hubConnection.on(CustomerExperienceTicketGroupEnum.TICKET, (response: any) => {
        // console.log("TICKET ON!!!!!!!!", response?.data ? response.data.enumTicketActionType : response.enumTicketActionType)
        this.loadingSubject.next(false);
  
        if ('success' in response) {
          if (response.success) {
            const actionType = response.data.enumTicketActionType;
  
            if (actionType === CustomerExperienceTicketDetailActionTypeEnum.TICKET_MERGE) {
              const redirectTicketId = response.data?.data?.[0]?.mergedTicketId;
              this.redirectTicketId.next(redirectTicketId);
              return;
            }
  
            if (actionType === CustomerExperienceTicketDetailActionTypeEnum.TICKET_SPLIT) {
              const redirectTicketId = response.data?.data?.[0]?.sourceTicketId;
              this.redirectTicketId.next(redirectTicketId);
              return;
            }
  
            if (actionType === CustomerExperienceTicketDetailActionTypeEnum.GENERATED_ANSWER) {
              this.generatedAnswerSubject.next(response.data[0]);
              return;
            }
  
            // Return if the action is not relevant to the ChatAction
            if (!this.isRelevantTicketChatAction(actionType)) {
              // this.setTicket(setTicketProperties(response.data, this.ticket));
              return;
            }
  
            this.console.info('listenTicket', response.data);
            if (this.hasUnreadMessages()) {
              this.updateUnreadMessages(response.data.length);
            } else {
              this.setConversation(setChat(response.data, this.conversation));
            }
          } else {
            this.handleTicketError(response);
            return;
          }
        } else {
  
          if (response.enumTicketActionType === CustomerExperienceTicketDetailActionTypeEnum.GENERATED_ANSWER) {
            this.generatedAnswerSubject.next(response.data[0]);
            return;
          }
  
          this.console.info('listenTicket', response);
  
          if (response.enumTicketActionType === CustomerExperienceTicketDetailActionTypeEnum.TICKET_LOG) {
            if (this.hasUnreadMessages()) {
              this.updateUnreadMessages(1);
            } else {
              this.setConversation(setChat(response, this.conversation));
            }
          }
        }
      });
    }
  
    private handleTicketError(response: any): void {
      const actionType = response.data.enumTicketActionType;
      switch (actionType) {
        case CustomerExperienceTicketDetailActionTypeEnum.TICKET_SPLIT:
        case CustomerExperienceTicketDetailActionTypeEnum.REPLY_TICKET_MESSAGE:
          this.toastService.error(this.translate.instant(`ResultMessage.${response.message}`));
          break;
        default:
          this.console.warn(`Unhandled error for action type: ${actionType}`, response);
      }
    }
  
    private isRelevantTicketChatAction(actionType: CustomerExperienceTicketDetailActionTypeEnum): boolean {
      const relevantActionTypes = [
        CustomerExperienceTicketDetailActionTypeEnum.GET_TICKET_MESSAGES,
        CustomerExperienceTicketDetailActionTypeEnum.TICKET_MESSAGE_RESULT,
        CustomerExperienceTicketDetailActionTypeEnum.TICKET_PRIVATE_NOTE,
        CustomerExperienceTicketDetailActionTypeEnum.TICKET_MESSAGE_REMOVED
      ];
  
      return relevantActionTypes.includes(actionType);
    }
  
    private updateUnreadMessages(dataLength: number): void {
      if (dataLength > 0) {
        this.setUnreadMessagesCount(this.unreadMessagesCount.value + 1);
      }
    }
  
    removeFromGroupForTicket = () => {
      this.hubConnection.invoke(CustomerExperienceTicketEnum.REMOVE_FROM_GROUP, CustomerExperienceTicketGroupEnum.TICKET, null);
      this.joinedGroupList = this.joinedGroupList.filter(x => x.startsWith('ticket/'));
      this.conversation$.next([]);
    }
  
    removeFromGroupForTicketListview = () => {
      this.hubConnection.invoke(CustomerExperienceTicketEnum.REMOVE_FROM_GROUP, CustomerExperienceTicketGroupEnum.TICKET_LISTVIEW, null);
      this.joinedGroupList = this.joinedGroupList.filter(x => x.startsWith('ticketlistview/'));
    }
  
    private listenJoinGroup = () => {
      this.hubConnection.on(CustomerExperienceTicketEnum.JOIN_GROUP, (response: string | string[]) => {
        if (Array.isArray(response)) {
          this.joinedGroupList = response;
        } else {
          this.joinedGroupList = [response];
        }
        this.loadingSubject.next(false);
        this.console.info('Joined', this.joinedGroupList);
      });
    }
  
    private joinGroupForReconnect = () => {
      this.hubConnection.invoke(CustomerExperienceTicketEnum.JOIN_GROUP_FOR_RECONNECT, this.joinedGroupList);
    }
  
    private getToken(): Promise<void> {
      return new Promise((resolve) => {
        this.auth.refreshToken().pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse) {
              switch (error.status) {
                case 401:
                  this.auth.logout(true, true);
                  break;
              }
            }
            return throwError(error);
          })
        ).subscribe(() => resolve());
      });
    }
  
    public disconnectTicketListener = () => {
      if (!this.hubConnection) {
        return;
      }
  
      this.hubConnection.stop();
      this.loadingSubject.next(false);
      this.connectionSubject.next(false);
    }
  
    getTicket() {
      return this.ticket$;
    }
  
    setTicket(ticket) {
      this.ticket = ticket;
      this.ticket$.next(ticket);
      this.setTicketForInteraction(ticket);
    }
  
    getTicketForInteraction() {
      return this.ticketForInteraction.asObservable();
    }
  
    setTicketForInteraction(ticket) {
      this.ticketForInteraction.next(ticket);
    }
  
    getConversation() {
      return this.conversation$;
    }
  
    setConversation(conversation) {
      this.conversation = conversation;
      this.conversation$.next(conversation);
    }
  
    getUnreadMessagesCount() {
      return this.unreadMessagesCount.asObservable();
    }
  
    setUnreadMessagesCount(count: number) {
      this.unreadMessagesCount.next(count);
    }
  
    private hasUnreadMessages() {
      return this.unreadMessagesCount.getValue() > 0;
    }
  
    getRedirectTicketId() {
      return this.redirectTicketId.asObservable();
    }
  
    getHubConnection(): signalR.HubConnection {
      return this.hubConnection;
    }
  
    clearGeneratedAnswerSubject() {
      this.generatedAnswerSubject.next(null);
    }
  
  }
