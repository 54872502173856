import moment from 'moment';
import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DestroyRef, DoCheck, ElementRef, EventEmitter, HostBinding, inject, Input, OnDestroy, Optional, Output, Self
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  Account, Activity, ActivityFilterRequest, ActivityService, ActivityStatusType
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared/modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'net-select-activity',
  templateUrl: './select-activity.component.html',
  styleUrls: ['./select-activity.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectActivityComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectActivityComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Activity> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-activity';
  describedBy = '';
  stateChanges = new Subject<void>();

  activities: Activity[] = [];
  selectCtrl = new UntypedFormControl();
  status = ActivityStatusType;

  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get account() { return this._account; }
  set account(account: Account) {
    this._account = account;

    if (account) {
      this.store.select(hasUserPermission(Permission.ACTIVITY_SEARCH)).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(permission => {
          if (permission) {
            this.value = null;
            this.searchActivity();
          } else {
            this.activities = this.value ? [this.value] : [];
          }
        });
    }
  }
  private _account: Account;

  @Input()
  get value(): Activity { return this.selectCtrl.value; }
  set value(value: Activity) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @Output() selectionChange: EventEmitter<Activity> = new EventEmitter<Activity>();

  @HostBinding('id') id = `select-activity-${SelectActivityComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private store: Store,
    private activityService: ActivityService,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  searchActivity() {
    this.loading = true;
    const request: ActivityFilterRequest = {
      filter: {
        accountId: this.account.accountId
      }
    };

    this.activityService.search(request).subscribe(response => {
      if (response.success) {
        this.activities = response.data.results;

        if (this.value) {
          const hasValue = this.activities?.find(activity => activity.activityId === this.value.activityId);
          if (!hasValue) {
            this.activities.push(this.value);
          }
        }

        this.activities = this.activities.sort((a, b) => this.getActivityDate(a)?.isAfter(this.getActivityDate(b)) ? -1 : 1);
        this.cdr.detectChanges();
      }
    }).add(() => this.loading = false);
  }

  getActivityDate(activity: Activity): moment.Moment {
    return this.status.PLANNED === activity.activityStatusId ? activity.planningStartDate : activity.actualStartDate;
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();

    this.selectionChange.emit(this.value);
  }

  writeValue = (value: Activity) => {
    this.value = value;

    if (value) {
      const hasValue = this.activities?.find(activity => activity.activityId === value.activityId);
      if (!hasValue) {
        this.activities.push(value);
      }
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: Activity, two: Activity) => one && two && one.activityId === two.activityId;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
