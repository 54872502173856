import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  Self,
  Input,
  DoCheck,
  Optional,
  Component,
  ViewChild,
  OnDestroy,
  ElementRef,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';


import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { Board, Card, CardService } from '@core/api';
import { Contact } from '@core/api';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-board-card[board]',
  templateUrl: './select-board-card.component.html',
  styleUrls: ['./select-board-card.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectBoardCardComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectBoardCardComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Card | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-board-card';
  describedBy = '';
  stateChanges = new Subject<void>();

  cards: Card[] = [];
  contacts: Contact[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @HostBinding('id') id = `select-board-card-${SelectBoardCardComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input() tabIndex;
  @Input() panelWidth: string | number = 'auto';

  @Input() set board(board: Board) {
    this._board = null;
    this.reset();

    if (board) {
      this._board = board;
      this.loading = true;
      this.inputCtrl.disable();

      // Init with default list
      this.initDefaultOptions().finally(() => {
        this.loading = false;
        if (!this.disabled) {
          this.inputCtrl.enable();
        }

        this.cdr.detectChanges();

      });
    }
  }
  get board(): Board {
    return this._board;
  }
  private _board: Board;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Card | null { return this._value; }
  set value(value: Card | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Card;

  get shouldLabelFloat() { return !!this.board && this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.cardService.search({
        filter: { boardId: this.board.boardId },
        pageSize: 100,
        orderBy: 'createdOn',
        orderType: 'DESC',
      }).toPromise()
        .then((response) => this.cards = response.data.results)
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private cardService: CardService,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => this.board && typeof value === 'string'),
      filter(value => value.length > 2 || value.length === 0),
      tap(() => {
        this.loading = true;
        this.cards = [];
        // this.value = null;
      }),
      switchMap(value => this.cardService.search({
        filter: {
          boardId: this.board.boardId,
          searchText: value,
        },
        pageSize: 100,
        orderBy: 'createdOn',
        orderType: 'DESC',
      })),
      tap(() => this.loading = false)
    ).subscribe((response) => {
      this.cards = response.data.results;
      this.cdr.detectChanges();
    });
  }

  displayName = (item: Card) => item ? `${item.cardCode} - ${item.name}` : '';

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.cards = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();

    if (this.board) {
      this.initDefaultOptions();
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  writeValue(value: Card) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();
}
