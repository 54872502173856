export enum CXChannelType {
  WHATSAPP = 'c42d8abc-6891-48ef-9b1a-672cac1811e3',
  EMAIL = 'f3fefbc0-1c14-4c54-a576-720d35fb1972',
  FACEBOOK = '7aef8b0d-eaef-42d3-9d9e-376911db35fe',
  INSTAGRAM = 'a9921cb8-1e81-4ac2-b06c-493b037e04ca'
}

export enum SmtpAuthType {
  EMAIL_ADDRESS = 0,
  USERNAME = 1
}

export enum ImapAuthType {
  EMAIL_ADDRESS = 0,
  USERNAME = 1
}