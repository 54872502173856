import { inject, Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';

import { environment } from '@env/environment';
import { AuthService } from '@core/auth/auth.service';
import { MailService, MailStateEvent } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class MailSignalRService {
  public static readonly tokenConnectionStorageKey = 'mail_token_connection_id';

  public static activeState: MailStateEvent;
  public static connectionId: string;
  public static get tokenConnectionId(): string | null {
    if (MailSignalRService.tokenConnectionIdReference) {
      return this.tokenConnectionIdReference;
    }

    return localStorage.getItem(MailSignalRService.tokenConnectionStorageKey);
  }
  private static tokenConnectionIdReference: string;

  private hubConnection: signalR.HubConnection;
  public stateSubject: Subject<MailStateEvent> = new Subject<MailStateEvent>();

  private readonly mailService = inject(MailService);

  constructor() {
    // Listen state changes and update references then persist
    this.stateSubject.subscribe((state) => {
      // Update active references
      MailSignalRService.activeState = state;
      MailSignalRService.connectionId = state.connectionId;
      if (state?.tokenConnectionId) {

        MailSignalRService.tokenConnectionIdReference = state.tokenConnectionId;

        // Persist active references
        localStorage.setItem(MailSignalRService.tokenConnectionStorageKey, state.tokenConnectionId);
      }

    });
  }

  public static onLogout() {
    // Update active references
    MailSignalRService.activeState = null;
    MailSignalRService.connectionId = null;
    MailSignalRService.tokenConnectionIdReference = null;

    // Remove persisted reference
    localStorage.removeItem(MailSignalRService.tokenConnectionStorageKey);
  }

  public connect(isListenOutlook: boolean = false) {
    const onConnection = () => {
      console.info('[MailSignalR] connection established.');

      // Listen mail events
      this.hubConnection.on('mail', (state: MailStateEvent) => {
        console.info('[MailSignalR] received mail.', { state });
        this.stateSubject.next(state);
      });

      // Listen get connection id events
      this.hubConnection.on('GetConnectionId', (connectionId) => {
        console.info('[MailSignalR] received GetConnectionId.', { connectionId });
        MailSignalRService.connectionId = connectionId;

        if (isListenOutlook) {
          this.mailService.listenOutlookInbox().subscribe((response) => { });
        }

      });
    };

    // Initiate mail hub connection
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.socketUrl}/mailintegrationhub?x-client=${environment.socketTenant}`, {
        transport: signalR.HttpTransportType.WebSockets,
        withCredentials: true,
        skipNegotiation: true,
        accessTokenFactory: () => AuthService.getToken(),
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    // Update mail hub timeout and keep alive interval
    this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60;
    this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;

    // Handle mail hub close and fire retry
    this.hubConnection.onclose((_error) => {
      if (_error) {
        console.error('[MailSignalR] connection failed, retrying to connect.', _error);
        setTimeout(
          () => {
            this.hubConnection
              .start()
              .then(onConnection)
              .catch(retryError => console.error('[MailSignalR] retry connection failed. ', retryError));
          },
          1000
        );
        return;
      }

      console.info('[MailSignalR] connection closed.');
    });

    // Start mail hub connection
    this.hubConnection
      .start()
      .then(onConnection)
      .catch(_error => console.error('[MailSignalR] connection failed. ', _error));
  }

  public getHubConnection(): signalR.HubConnection {
    return this.hubConnection;
  }

  public disconnect() {
    this.hubConnection.stop();
  }
}
