<div style="width: 100%">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0" fxFlex="auto" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{'GENERAL.ADD_FILE' | translate}}</span>
    </h2>

    <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
      <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
  </div>
  <mat-divider class="mx-6 mb-4 text-border"></mat-divider>

  <mat-dialog-content style="width: 40rem" class="mx-4 my-5">
    <net-document-upload [type]="uploadType.ACCOUNT" [options]="uploaderOptions"
      [systemUserParameter]="systemUserParameter" [submit]="loading" (upload)="uploadResponse($event)"
      (done)="onUploadDone($event)"></net-document-upload>
  </mat-dialog-content>

  <mat-divider class="-mx-6 mt-4 mb-4 text-border"></mat-divider>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <p class="alert alert-icon alert-warning">
      <net-iconify-icon [icon]="icWarning"></net-iconify-icon>
      {{'GENERAL.MAXIMUM_FILE_SIZE_CAN_BE_MB' | translate: {value: 25} }}
    </p>

    <net-progress-button class="ml-auto" [icon]="icSave" [active]="loading" (btnClick)="onSubmit()"
      [text]="'GENERAL.SAVE' | translate"></net-progress-button>
  </mat-dialog-actions>
</div>