

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { inject, Injectable, OnDestroy } from '@angular/core';
import { SystemUserService, User } from '@core/api';
import { getUser } from '@core/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TableCustomFilter, TableFilter, TableFilters } from './table-custom-filter.model';
import { ToastService } from '@shared/services';

@Injectable({
    providedIn: 'root'
})
export class TableCustomFilterService implements OnDestroy {
    private user: User;
    private userTableFilters: TableFilters[] = [];
    private tableFiltersSubscription: Subscription;

    userTableFilters$ = new BehaviorSubject(null);

    private readonly toastService = inject(ToastService);

    constructor(
        private systemUserService: SystemUserService,
        private store: Store,
        private translate: TranslateService
    ) {
        // Fetch user and load user config
        this.store.select(getUser).subscribe(user => {
            this.user = user;

            if (user && user.tableFilter) {
                const tablefilters = JSON.parse(user.tableFilter);
                if (!tablefilters || !Array.isArray(tablefilters)) {
                    return;
                }

                this.userTableFilters$.next(tablefilters);
            }
        });

        this.tableFiltersSubscription = this.userTableFilters$.subscribe((tablefilters) => this.userTableFilters = tablefilters ?? []);
    }

    private setTableFilters(tableFilters: TableFilters[], isToastrActive = false) {
        this.systemUserService.updateTableFilterConfigs(this.user.userId, JSON.stringify(tableFilters)).subscribe((data: any) => {
            this.userTableFilters$.next(JSON.parse(data.data.tableFilter));
            if (isToastrActive) {
                this.toastService.success(data.message);
            }
        }, () => {
            if (isToastrActive) {
                this.toastService.error(this.translate.instant('GENERAL.AN_ERROR_OCCURRED_WHILE_SAVING_USER_SETTINGS'));
            }
        });
    }

    getFilters(tableId: string): Observable<TableCustomFilter<TableFilter>[]> {
        return this.userTableFilters$.pipe(map((tableArray) => tableArray?.find((table) => table.tableId === tableId)?.customFilters || []));
    }

    getTableActiveFilter(tableId: string): Observable<TableCustomFilter<TableFilter>> {
        return this.userTableFilters$.pipe(
            map((tableArray: TableFilters[]) => {
                const filters = tableArray?.find((table) => table.tableId === tableId)?.customFilters;
                return filters?.find(customFilter => customFilter.selected);
            })
        );
    }

    deleteFilter(tableId: string, filterId: string) {
        const table = this.userTableFilters.find(userTable => userTable.tableId === tableId);
        table.customFilters = table.customFilters.filter(customFilter => customFilter.id !== filterId);
        this.setTableFilters(this.userTableFilters, true);
    }

    addFilter(tableId: string, customFilter: TableCustomFilter<TableFilter>, resetActiveFilter = false, isToastrActive = true) {
        const table = this.userTableFilters.find(userTable => userTable.tableId === tableId);
        if (table) {
            if (resetActiveFilter) {
                table.customFilters.forEach(tableFilter => tableFilter.selected = false);
            }

            const filterIndex = table.customFilters.findIndex(_filter => _filter.id === customFilter.id);
            if (filterIndex !== -1) {
                table.customFilters[filterIndex] = {
                    ...customFilter,
                    selected: table.customFilters[filterIndex].selected
                };
                this.userTableFilters$.next(this.userTableFilters);
                return;
            }
            table.customFilters.push(customFilter);
        } else {
            const newTable = {
                tableId,
                customFilters: [customFilter]
            } as TableFilters;
            this.userTableFilters.push(newTable);
        }

        this.setTableFilters(this.userTableFilters, isToastrActive);
    }

    updateFilter(tableId: string, newFilter: TableCustomFilter<TableFilter>) {
        const table = this.userTableFilters.find(userTable => userTable.tableId === tableId);
        const filterIndex = table.customFilters.findIndex(customFilter => customFilter.id === newFilter.id);
        table.customFilters[filterIndex] = newFilter;

        this.setTableFilters(this.userTableFilters, true);
    }

    setActiveFilter(tableId: string, filterId: string) {
        const table = this.userTableFilters.find(userTable => userTable.tableId === tableId);
        table?.customFilters?.forEach(customFilter => customFilter.selected = false);

        const selectedFilter = table.customFilters.find(customFilter => customFilter.id === filterId);
        if (selectedFilter) {
            selectedFilter.selected = true;
        }

        this.setTableFilters(this.userTableFilters);
    }

    ngOnDestroy(): void {
        this.tableFiltersSubscription.unsubscribe();
    }
}
