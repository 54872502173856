import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomerType } from '@core/api';


export class SelectCustomerTypeValidator {
    static validator(control: AbstractControl): ValidationErrors | null {
        const value: CustomerType = control.value;

        if (!value) {
            return null;
        }

        if (!value.customerTypeId) {
            return { required: true };
        }

        if (!value.enableFlag) {
            return { invalidCustomerType: true };
        }

        return null;
    }
}
