<ng-container *ngIf="mode === 'chatView'; else mailView">
    <div class="chat-view">
        <div
            class="whitespace-nowrap rounded-lg border-2 border-primary-400 flex items-center hover:bg-hover relative">
            <p class="ml-2 text-xs">{{ fileName }}</p>
            <p class="text-xs text-secondary ml-2">{{ fileSize }}</p>
            <button (click)="download()" class="w-8 h-8 leading-none cursor-pointer flex items-center justify-center p-0" mat-icon-button type="button">
                <net-iconify-icon *ngIf="!loading" class="text-primary-500" [icon]="icDownloadFill" size="18"></net-iconify-icon>
                <mat-spinner *ngIf="loading" class="ml-2" color="primary" diameter="16"></mat-spinner>
            </button>
        </div>
    </div>
</ng-container>

<ng-template #mailView>
    <div class="mail-view" (click)="download()">
        <div class="h-full w-full flex justify-center items-center p-4">
            <div class="p-4 shadow bg-card rounded-full select-none">
                <span *ngIf="!loading && !icon" style="font-size: 20px">{{ extension }}</span>
                <net-iconify-icon *ngIf="!loading && !!icon" [icon]="icon" class="flex-none text-primary" size="30"></net-iconify-icon>
                <mat-progress-spinner *ngIf="loading === true" diameter="30"
                    mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>

        <div class="vex-mail-attachment-overlay" [matTooltipDisabled]="!(scanFlag && loading === true)"
            [matTooltip]="'MAIL.SCANNING' |translate">
            <p class="truncate">{{ fileName }}</p>
        </div>
    </div>
</ng-template>