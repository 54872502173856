<a *ngIf="isLink(item) && !isFunction(item.route)" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--vex-sidenav-item-ripple-color)"
  [routerLinkActive]="'vex-sidenav-item--active'">
  <span *ngIf="level === 0 && item.icon" class="vex-sidenav-item__icon iconify {{item.icon}}"></span>

  <span *ngIf="level === 0 && collapsed && item.badge && badgeValue()" [style.background]="item.badge.background"
    [style.color]="item.badge.color" fxFlex="none" class="item-badge item-icon-badge">{{ badgeValue() }}</span>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>


  <span *ngIf="item.badge && badgeValue()" [style.background]="item.badge.background" [style.color]="item.badge.color"
    class="item-badge " fxFlex="none">{{ badgeValue() }}</span>

</a>

<div (click)="item.route()" *ngIf="isLink(item) && isFunction(item.route)" class="vex-sidenav-item" matRipple
  matRippleColor="var(--vex-sidenav-item-ripple-color)" routerLinkActive="vex-sidenav-item--active">
  <span *ngIf="level === 0 && item.icon" class="vex-sidenav-item__icon iconify {{item.icon}}"></span>
  <span class="vex-sidenav-item__label">{{ item.label }}</span>
  <span *ngIf="item.badge && badgeValue()" [style.background]="item.badge.background" [style.color]="item.badge.color"
    class="item-badge " fxFlex="none">{{ badgeValue() }}</span>
  >
</div>

<ng-container *ngIf="isDropdown(item)">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--vex-sidenav-item-ripple-color)">
    <span *ngIf="level === 0 && item.icon" class="vex-sidenav-item__icon iconify {{item.icon}}"></span>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge && badgeValue()" [style.background]="item.badge.background" [style.color]="item.badge.color"
      class="item-badge " fxFlex="none">{{ badgeValue() }}</span>
    <span class="vex-sidenav-item__dropdown-icon iconify {{icKeyboardArrowRight}}"></span>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <net-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"
      [collapsed]="collapsed"></net-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isSubheading(item)">
  <div class="vex-sidenav-subheading">{{ item.label }}</div>
  <net-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"
    [collapsed]="collapsed"></net-sidenav-item>
</ng-container>