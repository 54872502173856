import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { tap, } from 'rxjs/operators';
import { Permission } from '../auth.enum';

export const userPermissionGuard = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);

  let hasPermission: boolean;

  // If role not defined for route, skip verification
  if (!route.data['permission']) {
    return true;
  }

  store.select(hasUserPermission(route.data['permission'])).pipe(
    tap(permission => {
      hasPermission = permission;
      const extras = router.getCurrentNavigation()?.extras;
      if (!permission) {
        // if the navigation was made from the login page, redirects to the '/notification' page
        if (extras?.state?.['fromLogin'] || (extras?.replaceUrl && route.data['permission'] === Permission.HOME_DASHBOARD)) {
          router.navigate(['/notification']);
        } else {
          router.navigate(['error-403']);
        }
      }
    })
  ).subscribe();

  return hasPermission;
}
