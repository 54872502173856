import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  DoCheck,
  ElementRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';

import moment from 'moment';
import { Subject } from 'rxjs';
import { API_DATETIME_FORMAT } from '@core/api';
import { Icon } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type DateTimeValue = moment.Moment;

@Component({
  selector: 'net-input-datetime',
  templateUrl: './input-datetime.component.html',
  styleUrls: ['./input-datetime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => InputDatetimeComponent),
    }
  ]
})
export class InputDatetimeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<DateTimeValue | null> {
  static nextId = 0;

  focused = false;
  errorState = false;
  controlType = 'input-datetime';
  describedBy = '';

  form: UntypedFormGroup;
  stateChanges = new Subject<void>();

  icClear = Icon.IC_TWOTONE_CLEAR;
  icToday = Icon.IC_TWOTONE_TODAY;

  @Input() isDisabled = false;
  @HostBinding('id') id = `input-datetime-${InputDatetimeComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Create form
    this.form = this.formBuilder.group({
      date: [null],
      time: [null],
    });

    // Subscribe form value changes to update date object
    this.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(values => {

      // Generate date object when we have date and time
      if (!values.date || !values.time) {
        if (null !== this.value) {
          this.value = null;
        }
        return;
      }

      const value = moment(values.date)
        .hour(values.time.hour)
        .minute(values.time.minute);

      if (value?.format(API_DATETIME_FORMAT) === this.value?.format(API_DATETIME_FORMAT)) {
        return;
      }

      // Update value object with date and time
      this.value = value;
    });

    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    // Set ngControl value accessor
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _value: DateTimeValue | null = null;

  @Input()
  get value(): DateTimeValue | null {
    return this._value;
  }

  set value(value: DateTimeValue | null) {

    if (value?.format(API_DATETIME_FORMAT) === this.value?.format(API_DATETIME_FORMAT)) {
      return;
    }

    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return false;
  }

  set required(value: boolean) {
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  writeValue(value: DateTimeValue | null) {
    if (value?.format(API_DATETIME_FORMAT) === this.value?.format(API_DATETIME_FORMAT)) {
      return;
    }

    const datetime = moment(value);
    let date = null;
    let time = null;

    // Update default values when value have information
    if (null !== value) {
      date = datetime;
      time = {
        hour: datetime.hour(),
        minute: datetime.minute()
      };
    }

    // Update form and value reference
    this.value = value;
    this.form.setValue({ date, time });

    this.changeDetectorRef.detectChanges();
  }

  onClearValue() {
    if (!this.form.value.date && !this.form.value.time) {
      return;
    }

    this.form.setValue({ date: null, time: null });
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    // Trigger initial change detection
    this.changeDetectorRef.detectChanges();
  }

  ngDoCheck(): void {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  onChange = (_: any) => {
  }
  onTouched = () => this.form.markAsTouched();
  onContainerClick = () => {
  }
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
}
