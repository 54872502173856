import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SalesOrganizationSettingState } from '@core/store';
import { AccountType, SalesOrganizationActivitySettingType, SalesOrganizationAdvancedSettingType } from '@core/api';

export const selectSettingsState = createFeatureSelector('salesOrganizationSetting');

export const getSalesOrganizationSettings = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => state.salesOrganizationSettings
);

export const getSalesOrganizationSettingError = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => ({ errorCode: state.errorCode, errorMessage: state.errorMessage })
);

export const getSalesOrganizationSettingIsLoading = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => state.isLoading
);

export const getSalesOrganizationSettingValue = (key: SalesOrganizationAdvancedSettingType | SalesOrganizationActivitySettingType, salesOrganizationId: string) => createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => {

    const salesOrganizationSetting = state.salesOrganizationSettings?.find(x => x.salesOrganizationId === salesOrganizationId);

    if (!salesOrganizationSetting) {
      return false;
    }

    return salesOrganizationSetting[key] ?? false;
  },
);

export const isOpenSwitchSalesOrganizations = (key: string) => createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => {

    if (!state.salesOrganizationSettings || state.salesOrganizationSettings.length === 0) {
      return false;
    }

    const keyValues = state.salesOrganizationSettings?.map(x => {
      if (x[key]) {
        return x[key];
      }
    });

    return keyValues?.find(x => x) ?? false;
  });

export const getSalesOrganizationSettingAccountType = createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => {
    const business = state.salesOrganizationSettings?.some(setting =>
      setting?.consumerAccountTypeIds.includes(AccountType.BUSINESS) &&
      !setting?.opportunityAdvanced
    );
    const consumer = state.salesOrganizationSettings?.some(setting =>
      setting?.consumerAccountTypeIds.includes(AccountType.CONSUMER) &&
      !setting?.opportunityAdvanced
    );
    return { business, consumer, settings: state.salesOrganizationSettings };
  }
);

export const getAccountTypeBySalesOrganization = (salesOrganizationId: string) => createSelector(
  selectSettingsState,
  (state: SalesOrganizationSettingState) => {
    const salesOrganizationSetting = state.salesOrganizationSettings?.find(x => x.salesOrganizationId === salesOrganizationId);
    if (!salesOrganizationSetting) {
      return { business: false, consumer: false };
    }
    const business = salesOrganizationSetting.consumerAccountTypeIds.includes(AccountType.BUSINESS);
    const consumer = salesOrganizationSetting.consumerAccountTypeIds.includes(AccountType.CONSUMER);
    return { business, consumer };
  }
);
