import { inject } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivityComponentCanDeactivate } from './activity.model';
import { Store } from '@ngrx/store';
import { SetUnsavedChanges } from '@core/store/unsaved-changes/unsaved-changes.actions';

export const activityCanDeactivateGuard = (
  component: ActivityComponentCanDeactivate,
  nextState: RouterStateSnapshot
) => {

  if (!component.hasUnsavedChanges() || nextState.url.includes('auth/login')) {
    return true;
  }

  const translate: TranslateService = inject(TranslateService);
  const store: Store = inject(Store);

  return new Observable<boolean>(observer => {
    Swal.fire({
      text: translate.instant('GENERAL.PAGE_LEAVE_WITHOUT_SAVING'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#7a7a7a',
      confirmButtonText: translate.instant('GENERAL.YES'),
      cancelButtonText: translate.instant('GENERAL.NO')
    }).then((result) => {
      observer.next(result.value ? true : false);
      observer.complete();
      if (!result.value) {
        removeFocusFromTab();
      } else {
        store.dispatch(new SetUnsavedChanges(false));
      }
    });
  });

};

const removeFocusFromTab = (): void => {
  setTimeout(() => {
    const focusedTab = document.querySelector('.cdk-focused');
    (focusedTab as HTMLElement)?.blur();
  }, 300);
}
