import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyType } from '@core/api';

@Pipe({
  name: 'currencyTypeCode',
  standalone: true
})
export class CurrencyTypeCodePipe implements PipeTransform {

  transform(type: CurrencyType | string): string {
    switch (type) {
      case CurrencyType.EUR: return 'EUR';
      case CurrencyType.GBP: return 'GBP';
      case CurrencyType.TRY: return 'TRY';
      case CurrencyType.USD: return 'USD';
    }

    return null;
  }

}
