import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    OpportunityApprovalRequest,
    OpportunityApprovalRequestApprove,
    OpportunityApprovalRequestInsert,
    OpportunityApprovalRequestReject,
    OpportunityApprovalRequestSearch,
    OpportunityApprovalType,
    OpportunityCommunication,
    PagedResponse, Response
} from '@core/api';
import { environment } from '@env/environment';
@Injectable({
    providedIn: 'root'
})
export class OpportunityApprovalsService {

    constructor(private http: HttpClient) { }

    updateAll(request: OpportunityApprovalType[]) {
        return this.http.post<Response<OpportunityApprovalType[]>>(environment.apiUrl + '/api/OpportunityApprovalType/UpdateAll', request);
    }

    search(request: {
        filter: {
            salesOrganizationId?: string,
            salesOrganizationIds?: string[]
        }
    }) {
        return this.http.post<PagedResponse<OpportunityApprovalType>>(environment.apiUrl + '/api/OpportunityApprovalType/Search', request);
    }

    insertApprovalRequest(request: OpportunityApprovalRequestInsert) {
        return this.http.post<Response<OpportunityApprovalRequest>>(environment.apiUrl + '/api/OpportunityApprovalRequest/Insert', request);
    }

    searchApprovalRequest(request: OpportunityApprovalRequestSearch) {
        return this.http.post<PagedResponse<OpportunityApprovalRequest>>(environment.apiUrl + '/api/OpportunityApprovalRequest/Search', request);
    }

    rejectApprovalRequest(request: OpportunityApprovalRequestReject) {
        return this.http.post<Response<OpportunityApprovalRequest>>(environment.apiUrl + '/api/OpportunityApprovalRequest/RejectApprovalRequest', request);
    }

    cancelApprovalRequest(opportunityApprovalRequestId: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<OpportunityApprovalRequest>>(environment.apiUrl + '/api/OpportunityApprovalRequest/CancelApprovalRequest',
            JSON.stringify(opportunityApprovalRequestId), { headers });
    }

    approveApprovalRequest(request: OpportunityApprovalRequestApprove) {
        return this.http.post<Response<OpportunityApprovalRequest>>(environment.apiUrl + '/api/OpportunityApprovalRequest/ApproveApprovalRequest', request);
    }

    history(request: OpportunityApprovalRequestSearch) {
        return this.http.post<PagedResponse<OpportunityCommunication>>(environment.apiUrl + '/api/OpportunityApprovalRequestHistory/Search', request);
    }
}
