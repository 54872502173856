import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedResponse, Response } from '../api.model';
import { Warning, WarningSearchRequest } from './warning.model';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class WarningService {
    constructor(
        private http: HttpClient
    ) { }

    search(request: WarningSearchRequest): Observable<PagedResponse<Warning>> {
        return this.http.post<PagedResponse<Warning>>(environment.apiUrl + '/api/Warning/Search', request);
    }

    update(request: any): Promise<Response<Warning>> {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/Warning/Update', request).toPromise();
    }

    insert(request: any): Promise<Response<any>> {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/Warning/Insert', request).toPromise();
    }

    delete(request: any): Observable<Response<any>> {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/Warning/Delete', request);
    }
}
