<input #input matInput class="autocomplete-input" [matTooltip]="'GENERAL.PLEASE_SELECT_A_CUSTOMER' | translate"
  [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto" [matTooltipDisabled]="!!this.customer"
  [readonly]="!this.customer" (blur)="onBlur()">
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" [autoActiveFirstOption]="true"
  (optionSelected)="onSelect($event)" (closed)="onClosed()">

  @if (contacts) {
  @if (contacts.length === 0 && !loading) {
  <mat-option disabled>{{"CONTACT.CONTACT_NOT_FOUND" |
    translate}}</mat-option>
  }

  @for (contact of contacts; track $index) {
  <mat-option [value]="contact">
    <span>{{ contact.firstName }} {{ contact.lastName }}</span>
    @if (contact.jobTitle) {
    <span fxHide.lt-md class="contact-detail"> - {{ contact.jobTitle }}</span>
    }
  </mat-option>
  }

  }

</mat-autocomplete>

@if (!disabled && value) {
<button type="button" class="edit-contact" mat-icon-button [hidden]="this.loading" (click)="add(value)">
  <net-iconify-icon [icon]="icEdit" size="16"></net-iconify-icon>
</button>
}

@if (!disabled) {
<button type="button" class="add-contact" mat-icon-button [hidden]="this.loading" (click)="add()">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>
}

@if (loading === true) {
<mat-progress-spinner diameter="16" mode="indeterminate"></mat-progress-spinner>
}