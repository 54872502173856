<net-base-layout *ngIf="config$ | async as config">
  <net-progress-bar></net-progress-bar>

  <net-search></net-search>

  <net-quick-action></net-quick-action>

  <mat-sidenav-container class="vex-layout-sidenav-container">
    <mat-sidenav #sidenav (closedStart)="onSidenavClosed()" [disableClose]="!!(sidenavDisableClose$ | async)"
      [fixedInViewport]="!!(sidenavFixedInViewport$ | async)"
      [mode]="(isDesktop$ | async) === false || (isLayoutVertical$ | async) === true ? 'over' : 'side'"
      [opened]="!!(sidenavOpen$ | async)">
      <net-sidenav [collapsed]="!!(sidenavCollapsed$ | async)"></net-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="vex-layout-sidenav-content">
      <net-toolbar [hasShadow]="(toolbarShadowEnabled$ | async)=== true" [mobileQuery]="(isDesktop$ | async) === false"
        [class.dark]="config.layout === 'vertical'" class="vex-toolbar"></net-toolbar>
      <main class="vex-layout-content">
        <router-outlet></router-outlet>
      </main>

      @for (preview of mailPreviews; track preview.id; let index = $index) {
      <net-mail-preview [index]="index" [preview]="preview"></net-mail-preview>
      }

      @for (preview of ticketPreviews; track preview.ticketId; let index = $index) {
      <net-customer-experience-ticket-preview [index]="index" [preview]="preview"
        [conversation]="preview['conversation']"></net-customer-experience-ticket-preview>
      }

    </mat-sidenav-content>
  </mat-sidenav-container>

</net-base-layout>