import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  PagedResponse, Response, Account, AccountNameModel, AccountFilterRequest,
  AccountStatusUpdateRequest, AccountUpdateRequest, AccountDeleteRequest, BaseResponse,
  AccountDownload, AccountInsertRequest, AccountInsertResponse,
  AccountUpdateReminderDateRequest, AccountUpdateReminderDateResponse, AccountUpdateSalesRouteRequest
} from '@core/api';
import { tap } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private refreshAccountSubject = new BehaviorSubject<boolean>(false);
  refreshAccount$ = this.refreshAccountSubject.asObservable();

  constructor(private http: HttpClient) { }

  setRefreshAccount(value: boolean): void {
    this.refreshAccountSubject.next(value);
  }

  insert(request: AccountInsertRequest): Observable<Response<AccountInsertResponse>> {
    return this.http.post<Response<AccountInsertResponse>>(environment.apiUrl + '/api/Account/Insert', request);
  }

  search(request: any): Observable<PagedResponse<Account>> {
    return this.http.post<PagedResponse<Account>>(environment.apiUrl + '/api/Account/Search', request);
  }

  exportExcel(request: any): Observable<Response<AccountDownload[]>> {
    return this.http.post<Response<AccountDownload[]>>(environment.apiUrl + '/api/Account/Download', request.filter);
  }

  updateName(request: { accountId: string, name: string }): Observable<Response<Account>> {
    return this.http.post<Response<Account>>(environment.apiUrl + '/api/Account/UpdateName', request);
  }

  getName(uuid: string): Observable<Response<AccountNameModel>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<AccountNameModel>>(environment.apiUrl + '/api/Account/GetName', JSON.stringify(uuid), { headers });
  }

  get(uuid: string): Observable<Response<Account>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Account>>(environment.apiUrl + '/api/Account/Get', JSON.stringify(uuid), { headers }).pipe(
      tap((response) => {
        response.data = this.accountTransformer(response.data);

        return response;
      })
    );
  }

  update(request: AccountUpdateRequest): Observable<Response<Account>> {
    return this.http.post<Response<Account>>(environment.apiUrl + '/api/Account/Update', request);
  }

  delete(request: AccountDeleteRequest): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(environment.apiUrl + '/api/Account/Delete', request);
  }

  updateStatus(request: AccountStatusUpdateRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(environment.apiUrl + '/api/Account/UpdateStatus', request);
  }

  updateSalesRoute(request: AccountUpdateSalesRouteRequest): Observable<Response<Account>> {
    return this.http.post<Response<Account>>(environment.apiUrl + '/api/Account/UpdateSalesRoute', request);
  }

  elasticSearch(request: AccountFilterRequest) {
    return this.http.post<PagedResponse<Account>>(environment.apiUrl + '/api/Account/ElasticSearch', request).pipe(
      tap(response => response.data.results.forEach(account => this.accountTransformer(account)))
    );
  }

  updateProductionAccountCode(request: { accountId: string, opportunityId?: string, productionAccountCode: string }): Observable<Response<Account>> {
    return this.http.post<Response<Account>>(environment.apiUrl + '/api/Account/UpdateProductionAccountCode', request);
  }

  rejectProductionAccountCode(request: { accountId: string, opportunityId: string, description: string }): Observable<Response<Account>> {
    return this.http.post<Response<Account>>(environment.apiUrl + '/api/Account/RejectProductionAccountCode', request);
  }

  updateReminderDate(request: AccountUpdateReminderDateRequest): Observable<Response<AccountUpdateReminderDateResponse>> {
    return this.http.post<Response<AccountUpdateReminderDateResponse>>(environment.apiUrl + '/api/Account/UpdateReminderDate', request);
  }

  getWarningCount(ignoreLoader?: boolean): Observable<Response<any>> {
    let headers;
    if (ignoreLoader) {
      headers = { ignoreLoadingBar: '' };
    }
    return this.http.post<Response<number>>(environment.apiUrl + '/api/Account/GetUserAccountWarningCount', {}, { headers });
  }

  private accountTransformer(account: Account): Account {
    ['createdOn', 'lastDoneVisitDate', 'nextPlannedVisitDate'].forEach((key) => {
      const date = account && account[key] ? moment(account[key]) : moment('');
      account[key] = date?.isValid() ? date : null;
    });

    return account;
  }
}
