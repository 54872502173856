import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'net-breadcrumb',
  template: '<ng-content></ng-content>',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true
})
export class BreadcrumbComponent {

  @HostBinding('class') class = 'vex-breadcrumb body-2 text-hint leading-none hover:text-primary-500 no-underline trans-ease-out ltr:mr-2 rtl:ml-2';

  constructor() { }

}
