<button type="button" class="add-email" mat-icon-button (click)="add()" *ngIf="!disabled">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>
<ng-container [formGroup]="form">
  <div fxLayout="column" formArrayName="emails" matTooltip="{{'GENERAL.PLEASE_SELECT_CUSTOMER_FIRST' | translate}}"
    [matTooltipDisabled]="!!customer">
    <div class="input-email" fxLayout="row" [formGroupName]="index"
      *ngFor="let control of getEmailsForm().controls; let index = index">

      <mat-form-field class="grouped-zone">
        <mat-select formControlName="type" placeholder="{{'GENERAL.TYPE' | translate}}">
          <mat-option [value]="emailTypes.CORPORATE">{{ ('EmailTypeId.' + emailTypes.CORPORATE) | translate }}
          </mat-option>
          <mat-option [value]="emailTypes.OTHER">{{ ('EmailTypeId.' + emailTypes.OTHER) | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <input type="email" fxFlex matInput formControlName="email" [placeholder]="'GENERAL.EMAIL_ADDRESS' | translate">

      <button type="button" class="remove-email" mat-icon-button (click)="remove(index)">
        <net-iconify-icon [icon]="icMinus" size="16"></net-iconify-icon>
      </button>
    </div>
  </div>
</ng-container>