<mat-select [placeholder]="placeholder" [tabIndex]="tabIndex" [formControl]="selectCtrl"
    (selectionChange)="onSelectionChange()" panelClass="custom-panel" disableOptionCentering multiple>
    <mat-select-trigger *ngIf="selectCtrl.value && selectCtrl.value.length > 2">
        {{'GENERAL.VALUE_DOCUMENTS_SELECTED' | translate: {value: selectCtrl.value.length} }}
    </mat-select-trigger>
    <input (keydown)="$event.stopPropagation()" (ngModelChange)="onKey($event)" [(ngModel)]="searchText"
        [ngClass]="isOpened ? 'visible-searchbox': 'invisible-searchbox'" [ngModelOptions]="{standalone: true}"
        class="pt-4 pb-2 pl-4 first-line:border-0 outline-none w-full bg-card" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">
    <div class="mat-option-button">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate}}
        </button>
        <button mat-raised-button (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate}}
        </button>
    </div>
    <mat-option *ngFor="let document of activityDocuments" [disabled]="disabled" [value]="document">
        {{ document.name }}
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>