import {
    BoardInfoSignalRService, BoardUpdateNameRequest, BoardHashtagRequest,
    BoardSystemUserUpdateRequest, CardRangeRequest, BoardSystemUserRequest, BoardActionType
} from '@core/api';
import * as signalR from '@microsoft/signalr';

export class BoardSignalR {

    private hubConnection: signalR.HubConnection;

    constructor(
        private boardInfoSignalRService: BoardInfoSignalRService
    ) {
        this.hubConnection = this.boardInfoSignalRService.getHubConnection();
    }

    boardNameUpdate(methodName: string, request: BoardUpdateNameRequest) {
        return this.hubConnection.invoke(methodName, request);
    }

    boardHashtagOperation(methodName: string, request: BoardHashtagRequest) {
        this.hubConnection.invoke(methodName, request);
    }

    boardUserOperation(methodName: string, request: BoardSystemUserUpdateRequest) {
        return this.hubConnection.invoke(methodName, request);
    }

    boardCustomFieldOperation(methodName: string, request) {
        this.hubConnection.invoke(methodName, request);
    }

    boardCardAddRange(request: CardRangeRequest) {
        this.hubConnection.invoke(BoardActionType.BoardCardAddRange, request);
    }

    boardSetAdmin(request: BoardSystemUserRequest) {
        this.hubConnection.invoke(BoardActionType.BoardSetAdmin, request);
    }
}
