import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CardRequest } from '@core/api';
import { SharedModule } from '@shared/modules';
import { Icon } from '@shared/enums';
import { SelectBoardComponent } from '../select-board/select-board.component';
import { SelectBoardSectionComponent } from '../select-board-section/select-board-section.component';

@Component({
  selector: 'net-board-card-form',
  templateUrl: './board-card-form.component.html',
  styleUrls: ['./board-card-form.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    SelectBoardComponent,
    SelectBoardSectionComponent
  ]
})
export class BoardCardFormComponent {
  icSave = Icon.IC_SAVE;
  icClose = Icon.IC_TWOTONE_CLOSE;
  icWarning = Icon.IC_TWOTONE_WARNING;

  form = this.formBuilder.group({
    board: [null, Validators.required],
    section: [null, Validators.required],
    name: [null, Validators.required],
  });

  isPage = false;
  isDialog = false;

  @Input() loading = false;
  @Output() save = new EventEmitter<CardRequest>();

  @Input()
  set mode(value: 'page' | 'dialog') {
    if (value === 'page') {
      this.isPage = true;
    }

    if (value === 'dialog') {
      this.isDialog = true;
    }
  }

  constructor(private formBuilder: UntypedFormBuilder) { }

  onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.save.emit({
      boardId: this.form.get('board').value?.boardId,
      boardInfoId: this.form.get('section').value?.boardInfoId,
      name: this.form.get('name').value,
    });
  }
}
