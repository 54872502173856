import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Response, ContactSpecialDay, ContactSpecialDayRequest } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class ContactSpecialDayService {
    constructor(private http: HttpClient) { }

    search(request: { customerId: string }): Observable<Response<ContactSpecialDay[]>> {
        return this.http.post<Response<ContactSpecialDay[]>>(environment.apiUrl + '/api/ContactSpecialDay/Search', request);
    }

    bulkUpdate(request: ContactSpecialDayRequest[]): Observable<Response<ContactSpecialDay[]>> {
        return this.http.post<Response<ContactSpecialDay[]>>(environment.apiUrl + '/api/ContactSpecialDay/BulkUpdate', request);
    }
}
