import { CountryCode } from 'libphonenumber-js';
import * as moment from 'moment';

import {
  Account, AccountSource, Address, Competitor, ContactSpecialDay,
  ContactSpecialDayRequest, Contract, ContractType, Customer, CustomerType,
  DynamicFieldValue, Hashtag, LeadDraftInfoStatus, LeadDraftInfoStatusType, OfferStatus,
  OfferType, OperationType, OpportunityContract, OpportunityLostReasonType, OpportunityMailRequest,
  OpportunityPriceList, OpportunityProcessType, OpportunityProcessTypeEnum, OpportunityStatus, OpportunityStatusType,
  OpportunityType, Organization, Phone, SalesOrganization, SalesRouteDefinition,
  SectorMain, Segment, ServiceMain, ServiceUnit, SystemUser,
  SystemUserPicture, SystemUserStructure, TransactionCurrency, AccountWarning,
  CurrencyType, NoteAccessType, ShipmentIntervalType,
  OrganizationMainServiceServiceModel
} from '@core/api';
import { OrderType } from '@core/api';
import { Permission } from '@core/auth';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export interface BaseResponse {
  success: boolean;
  errorCode: string;
  message: string;
  url: string;
  resultMessage: ResultMessage;
}

export interface Response<T> extends BaseResponse {
  data: T;
}

export interface PagedResponseData<T> {
  results: T[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  readOnly: boolean;
  lastRowOnPage: number;
}

export interface PagedResponse<T> extends Response<any> {
  data: PagedResponseData<T>;
}

export interface SearchRequest {
  text: string;
  page?: number;
  pageSize?: number;
}

export interface FilterRequest {
  filter?: any;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  orderType?: OrderType;
}

export interface CountryFilterRequest extends FilterRequest {
  filter?: {
    countryName?: string;
  };
}

export interface CityFilterRequest extends FilterRequest {
  filter?: {
    cityName?: string;
    countryId?: string;
    ownerId?: string;
    stateCode?: number;
    statusCode?: number;
  };
}

export interface CountyFilterRequest extends FilterRequest {
  filter?: {
    countyName?: string;
    cityId?: string;
    ownerId?: string;
    stateCode?: number;
    statusCode?: number;
  };
}

export interface NeighborhoodFilterRequest extends FilterRequest {
  filter?: {
    neighborhoodName?: string;
    countyId?: string;
    ownerId?: string;
    stateCode?: number;
    statusCode?: number;
  };
}

export interface CustomFilterRequest extends FilterRequest {
  filter?: {
    countryId?: string;
    name?: string;
  };
}

export interface MainServiceFilterRequest extends FilterRequest {
  filter?: {
    id?: string;
    organizationId?: string;
    mainServiceId?: string;
    serviceId?: string;
    ownerId?: string;
    stateCode?: number;
    statusCode?: number;
  };
}

export interface LeadFilterRequest extends FilterRequest {
  filter?: {
    contactName?: string;
    companyName?: string;
    cityId?: string;
    countyId?: string;
    neighborhoodId?: string;
    sectorMainId?: string;
    revenueRangeId?: string;
    accountSourceId?: string;
    dueDateStart?: string;
    dueDateEnd?: string;
    ownerId?: string;
    stateCode?: number;
    statusCode?: number;
    leadDraftStatusId?: LeadDraftInfoStatus[];
    salesOrganizationId?: string;
    salesOrganizationIds?: string[];
    ownerIds?: string[]
  };
}

export interface LeadDeleteRequest {
  leadDraftId?: string;
}

export interface LeadDraftInfoDeleteRequest {
  leadDraftInfoId?: string;
}

export interface LeadDraftInfoFilterRequest {
  leadDraftId?: string;
  organizationMainServiceServiceId?: string;
  salesOrganizationId?: string[];
}

export interface ContactFilterRequest extends FilterRequest {
  filter?: {
    contactRoleGroupId?: string;
    contactFunctionId?: string;
    contactLevelId?: string;
    customerId?: string;
    contactFullName?: string;
    jobTitle?: string;
    contactPhoneNumber?: string;
    enabled?: boolean;
    permissionControl?: boolean;
    leadContact?: boolean;
    salesOrganizationIds?: string[],
    phone?: string;
  };
}

export interface OpportunityFilterRequest extends FilterRequest {
  filter?: {
    opportunityName?: string;
    accountId?: string;
    activityPurposeId?: string;
    organizationId?: string;
    opportunityStatusId?: string;
    opportunityStatusIds?: string[];
    transactionCurrencyId?: string;
    paymentMethodId?: string;
    addressId?: string;
    contactId?: string;
    activityId?: string;
    isContract?: boolean;
    contractTypeIds?: string[];
    noticePeriodDays?: number;
  };
}

export interface OfferFilterRequest extends FilterRequest {
  filter?: {
    offerName?: string;
    offerId?: string;
    accountId?: string;
    offerStatusId?: string;
    offerStatusIds?: string[];
    offerRejectionReasonId?: string;
    incotermId?: string;
    acceptedPrice?: number;
    rejectedPrice?: number;
    mainOfferId?: string;
    sourceOfferId?: string;
  };
}

export interface RevenueRangeFilterRequest extends FilterRequest {
  revenueRangeName?: string;
  transactionCurrencyId?: string;
  salesOrganizationId?: string;
  countyId?: string;
}

export interface ContactNoteFilterRequest extends FilterRequest {
  filter?: {
    opportunityNoteId?: string;
    opportunityId?: string;
    createdBy?: string;
    noteType?: NoteAccessType;
  };
}

export interface CustomerNoteFilterRequest extends FilterRequest {
  filter?: {
    customerNoteId?: string;
    accountId?: string;
    leadDraftId?: string;
    customerId?: string;
    createdBy?: string;
    noteType?: NoteAccessType;
  };
}

export interface OpportunityNoteFilterRequest extends FilterRequest {
  filter?: {
    contactNoteId?: string;
    contactId?: string;
    createdBy?: string;
    noteType?: NoteAccessType;
  };
}

export interface SalesRouteFilterRequest {
  salesOfficeId?: string;
  salesOrganizationId?: string[];
  revenueRangeId?: string;
  countyId?: string;
}

export interface ShipmentProfileFilterRequest {
  accountId?: string;
  fromCountryId?: string;
  fromCityId?: string;
  toCountryId?: string;
  toCityId?: string;
}

export interface LeadDraftRequest {
  leadDraftId?: string;
  customerId?: string;
  companyName: string;
  sectorMainId: string;
  phones: Phone[];
  emails: Email[];
  url?: string;
  customerShortName?: string;
  customerTypeId?: string;
  dynamicFieldValues: DynamicFieldValue[];
  salesOrganizationsIds?: SalesOrganization[];
  pdplFlag?: boolean;
  accountType?: string;
}

export interface Country {
  countryId: string;
  name: string;
  phoneCode?: string;
  sortName?: CountryCode;
  countryLocationLevel?: {
    cityLocationLevel?: {
      name: string;
    },
    countyLocationLevel?: {
      name: string;
    }
  };
}

export interface City {
  cityId: string;
  cityCode: string;
  name: string;
  countryId: string;
}

export interface County {
  countyId: string;
  name: string;
  cityId: string;
  createdOn: string;
}

export interface Neighborhood {
  neighborhoodId: string;
  neighborhoodCode: number;
  name: string;
}

export interface Custom {
  customsId: string;
  name: string;
}

export interface Email {
  emailId?: string;
  mailName: string;
  emailTypeId?: number;
  customerId?: string;
  contactId?: string;
  customer?: Customer;
  createdOn?: string;
  code?: string;
}

export interface OrganizationServiceModel {
  id: string;
  serviceId: string;
  serviceMainId: string;
  organizationId: string;
  service: {
    id: string;
    serviceId: string;
    name: string;
    orderBy: number;
  };
  serviceMain: ServiceMain;
  organization: Organization;
}

export interface ServiceWithUnit {
  serviceId?: string;
  name: string;
  units: ServiceUnit[];
}

export interface RevenueRange {
  revenueRangeId?: string;
  salesOrganizationId?: string;
  salesRouteDefinitionId?: string;
  revenueRangeCode?: number;
  revenueRangeGroupId?: string;
  minimumRevenue?: number;
  maximumRevenue?: number;
  name?: string;
  isActive?: boolean;
  default?: boolean;
  orderBy?: number;
}

export interface User {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  clearenceLevel: number;
  culture: string;
  email: string;
  image: string;
  roles: any;
  property: string;
  permissions: UserPermission[];
  organizations: string[];
  salesOrganizations: string[];
  notificationCount: {
    offerDraft: number;
  };
  isPasswordExpired: boolean;
  systemUserPicture?: SystemUserPicture;
  systemUserId: string;
  tableFilter?: string;
  reportFilter?: string;
  recentPage?: string;
  customDashboardId: string;
  userStructures?: SystemUserStructure[];
  displayName?: string;
}

export interface UserPermission {
  permissionCode: Permission;
  permissionName: string;
}

export interface Contact {
  contactId: string;
  customerId: string;
  customer: Customer;
  contactRoleGroupId: string;
  contactRoleGroup: ContactRoleGroup;
  contactFunctionId: string;
  contactLevelId: string;
  addressId: string;
  clearenceLevel: number;
  address: Address;
  firstName: string;
  middleName: string;
  lastName: string;
  jobTitle: string;
  salutation: Salutation;
  description: string;
  emails: Email[];
  enabled?: boolean;
  phones: Phone[];
  contactSpecialDays: ContactSpecialDay[];
  contactHashtags: ContactHashtag[];
  createdOn: moment.Moment;
  code: string;
  dynamicFieldValues: DynamicFieldValue[];
  createdUser?: SystemUser;
  displayName?: string;
  pdplFlag?: boolean;
}

export interface ContactDownload {
  code: string;
  salesOrganizationName?: string;
  customerShortName?: string;
  phone: string;
  accountStatus?: string;
  leadStatus?: string;
  fullName?: string;
  title: string;
  status?: string;
  address: Address;
  email: Email[];
  specialDayDate?: moment.Moment;
  specialDayNote?: string;
  routeOwner?: string;
  salesRoute?: string;
  createdBy?: SystemUser;
  createdOn: moment.Moment;
  dynamicFieldValues: DynamicFieldValue[];
  contactType?: string;
  organizationMainServiceService?: OrganizationMainServiceServiceModel;
}

export interface ContactRequest {
  contactId?: string;
  customerId: string;
  clearenceLevel: number;
  contactRoleGroupId: string;
  addressId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  jobTitle: string;
  salutationId?: string;
  description: string;
  emails: Email[];
  phones: Phone[];
  enabled: boolean;
  contactSpecialDays: ContactSpecialDayRequest[];
  contactHashtags: ContactHashtag[];
  dynamicFieldValues: DynamicFieldValue[];
  accountIds?: string[];
}

export interface ContactHashtag {
  hashtagId?: string;
  contactId?: string;
  hashtag?: Hashtag;
}

export interface Opportunity {
  opportunityId: string;
  name: string;
  accountId: string;
  organizationId: string;
  transactionCurrencyId: string;
  paymentMethodId: string;
  opportunityStatusId: OpportunityStatusType;
  ownerId: string;
  probabilityRate: number;
  expectedStartMonth: moment.Moment;
  expectedRevenue: number;
  exchangeRate: number;
  description: string;
  opportunityStatus: OpportunityStatus;
  opportunityStatuses: OpportunityStatus[];
  transactionCurrency: TransactionCurrency;
  paymentMethod: any;
  account: Account;
  organization: Organization;
  owner: SystemUser;
  opportunityInfos: OpportunityInfo[];
  opportunityContacts: {
    id: string;
    contact: Contact;
  }[];
  createdOn: moment.Moment;
  code: string;
  contractId?: string;
  contract: Contract;
  opportunitySystemUsers: OpportunitySystemUsers[];
  failureDescription: string;
  opportunityContracts: OpportunityContract[];
  opportunityPriceLists: OpportunityPriceList[];
  opportunityTypeId: string;
  opportunityType: OpportunityType;
  activeBusinessDevelopmentId: string;
  activeBusinessDevelopmentUser: string;
  activeBusinessDevelopmentUserId: string;
  isCancelable: boolean;
  contractDefinitionRequestUserId: string;
  financialInquiryStatusName: string;
  productionAccountCodeFlag: boolean;
  totalForOnceRevenue: number;
  totalMonthlyRevenue: number;
  localTotalForOnceRevenue?: number;
  localTotalMonthlyRevenue?: number;
  opportunityProcessTypeId: OpportunityProcessTypeEnum;
  opportunityProcessType: OpportunityProcessType;
  dynamicFieldValues: DynamicFieldValue[];
  sapOrganizationCode?: string;
  creditLimit?: number;
  createdUser?: {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  approvalUsers?: ApprovalUsers;
  statusLastUpdate: moment.Moment;
  opportunityLostReasonType: OpportunityLostReasonType;
  customConditions?: string;
  mailRequest?: OpportunityMailRequest;
  approvalTypes?: string[];
}

export interface OpportunityDownload {
  code: string;
  customerName: string;
  name?: string;
  organizationName: string;
  salesOrganizationName: string;
  serviceUnitId: string;
  financialInquiryStatusName?: string;
  services: string;
  salesRouteDefinitionName: string;
  opportunityStatus: string;
  opportunityType?: string;
  service?: any;
  totalMonthlyRevenue: number;
  localTotalMonthlyRevenue: number;
  totalForOnceRevenue: number;
  localTotalForOnceRevenue: number;
  exchangeRate?: number;
  transactionCurrency?: string;
  opportunity: any;
  probabilityRate: number;
  createdOn: moment.Moment;
  statusLastUpdate: moment.Moment;
  expectedStartMonth: moment.Moment;
  deadlineDate: moment.Moment;
  creditLimit?: string;
  createdUser?: string;
  salesRouteDefinitonAssignedUser?: string;
  productionAccountCode?: string;
  activeBusinessDevelopmentUser?: string;
  approvalUsers?: string;
  elapsedTime?: moment.Moment;
  dynamicFieldValues: DynamicFieldValue[];
  approvalType?: string;
}

export interface ClosedOpportunityDownload {
  code: string;
  contractCode: string;
  customerName: string;
  salesOrganizationName: string;
  name?: string;
  operationStartDate?: moment.Moment;
  renewal?: string;
  creditLimit?: string;
  createdUser?: string;
  opportunityType?: string;
  gainDate?: moment.Moment;
  salesRouteDefinitionName: string;
  salesRouteDefinitonAssignedUser?: string;
  organizationName: string;
  contractStartDate?: moment.Moment;
  contractEndDate?: moment.Moment;
  opportunityLostReasonType: string;
  opportunityInfos?: OpportunityInfo[];
  opportunityStatus: string;
  totalMonthlyRevenue: number;
  localTotalMonthlyRevenue: number;
  totalForOnceRevenue: number;
  localTotalForOnceRevenue: number;
  transactionCurrency?: string;
  productionAccountCode?: string;
  dynamicFieldValues: DynamicFieldValue[];
  approvalType?: string;
  approvalUsers?: string;
  contractTypeName?: ContractType;
  noticePeriodDays?: number;
  contractPeriodYears?: number;
  reminderDate?: moment.Moment;
  services?: string;
}

export interface OpportunitySystemUsers {
  id: string;
  opportunityId: string;
  systemUserId: string;
  systemUser: SystemUser;
  createdBy: string;
  createdOn: string;
  code: string;
}

export interface OpportunityInfo {
  opportunityId?: string;
  serviceId: string;
  serviceUnitId: string;
  transactionCurrencyId?: string;
  revenueTypeId: string;
  metric: any;
  revenue: number;
  revenueType?: OpportunityInfoRevenueType;
  service?: any;
  serviceUnit?: any;
  organizationMainServiceServiceId: string;
  organizationMainServiceService?: OrganizationMainServiceServiceModel;
  detail?: string;
  unitPrice?: number;
  opportunityInfoId?: string;
  code?: string;
}
export interface OpportunityRequest {
  opportunityId?: string;
  name?: string;
  accountId: string;
  organizationId: string;
  transactionCurrencyId?: CurrencyType;
  opportunityInfos: OpportunityInfo[];
  opportunityContacts?: Contact[];
  opportunityStatusId: string;
  opportunityTypeId: string;
  probabilityRate: number;
  expectedStartMonth: string;
  expectedRevenue: number;
  totalMonthlyRevenue: number;
  totalForOnceRevenue: number;
  description: string;
  exchangeRate?: number;
  opportunitySystemUsers?: SystemUser[];
  failureDescription?: string;
  action?: string;
  localTotalForOnceRevenue?: number;
  localTotalMonthlyRevenue?: number;
  defaultTransactionCurrencyId?: string;
  dynamicFieldValues: DynamicFieldValue[];
  sapOrganizationCode?: string;
  offerPdfAction?: string;
  opportunityParameterId?: string;
  opportunityLostReasonTypeId?: string;
  opportunityInfoIds?: string[];
  customConditions?: string;
}

export interface OpportunityInfoRevenueType {
  createdOn: string;
  name: string;
  readOnlyFlag: boolean;
  revenueTypeId: string;
}

export interface ApprovalUsers {
  systemUserId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  clearenceLevel: number;
  phone: string;
  isMailActive: boolean;
  isNotificationActive: boolean;
  createdOn: string;
  readOnlyFlag: boolean;
}

export interface LeadDraft {
  leadDraftId: string;
  companyName: string;
  customerId: string;
  customer: Customer;
  sectorMainId: string;
  phoneId: string;
  faxId: string;
  emailId: string;
  sectorMain: SectorMain;
  email: Email;
  phones: Phone[];
  url: string;
  createdOn: moment.Moment;
  createdBy: string;
  code: string;
  leadDraftInfos: LeadDraftInfo[];
  customerTypeId?: string;
  customerType: CustomerType;
  dynamicFieldValues: DynamicFieldValue[];
  accountType: string;
  pdplFlag: boolean;
}

export interface LeadDraftInfo {
  code: string;
  leadDraftInfoId: string;
  leadDraftId: string;
  leadDraft: LeadDraft;
  salesOrganizationId: string;
  salesRouteDefinitionId: string;
  accountSourceId: string;
  ownerId: string;
  leadDraftInfoStatusId: string;
  contactId: string;
  addressId: string;
  accountId: string;
  dueDate: moment.Moment;
  reminderDate: moment.Moment;
  reason: string;
  note: string;
  salesOrganization: SalesOrganization;
  revenue: number;
  salesRouteDefinition: any;
  accountSource: AccountSource;
  owner: SystemUser;
  contact: Contact;
  address: Address;
  account: Account;
  leadDraftInfoStatus: {
    leadDraftInfoStatusId: LeadDraftInfoStatusType;
    name: string;
    orderBy: number;
  };
  leadDraftInfoDetails: LeadDraftInfoDetail[];
  leadDraftInfoCompetitors: LeadDraftInfoCompetitor[];
  autoGenerated: boolean;
}

export interface LeadDraftInfoRequest {
  leadDraftInfoId?: string;
  leadDraftId: string;
  salesOrganizationId: string;
  revenue: number;
  salesRouteDefinitionId: string;
  accountSourceId: string;
  contactId: string;
  addressId: string;
  dueDate?: string;
  reminderDate: moment.Moment;
  reason: string;
  note: string;
  leadDraftInfoStatusId: string;
  leadDraftInfoDetails: LeadDraftInfoDetail[];
  leadDraftInfoCompetitors: LeadDraftInfoCompetitor[];
}

export interface LeadDraftInfoDownload {
  code?: string;
  salesOrganizationName: string;
  customerShortName: string;
  leadDraftInfoStatus: string;
  source: string;
  owner: string;
  createdBy: string;
  addressId: string;
  dueDate?: string;
  createdOn: moment.Moment;
  sectorMain: string;
  country: string;
  city: string;
  modifiedOn: moment.Moment;
  dynamicFieldValues: DynamicFieldValue[];
  lastActivityDate?: string;
  followupStatus?: string;
  reminderDate?: string;
  accountType?: string;
}

export interface LeadDraftInfoDetail {
  leadDraftInfoId?: string;
  organizationMainServiceServiceId: string;
  organizationMainServiceService?: OrganizationServiceModel;
}

export interface LeadDraftInfoCompetitor {
  leadDraftInfoId?: string;
  competitorId: string;
  competitor?: Competitor;
}

export interface AccountHashtag {
  hashtagId?: string;
  accountId: string;
  hashtag?: Hashtag;
  account?: Account;
  id?: string;
}

export interface Salutation {
  salutationId: string;
  name: string;
}

export interface SearchResult {
  accounts: Account[];
  addresses: Address[];
  contacts: Contact[];
  opportunities: Opportunity[];
}

export interface Note {
  note: string;
  noteType: NoteAccessType;
  subject: string;
  createUser: {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  modifyUser: {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  modifiedOn: moment.Moment;
  createdOn: moment.Moment;
  salesCallReminder: boolean;
  isReaded: boolean;
  account: Account;
  customerNoteId: string;
}

export interface AccountNote extends Note {
  customerNoteId: string;
  customerId: string;
  accountId: string;
  isReaded: boolean;
  salesCallReminder: boolean;
}

export interface ContactNote extends Note {
  contactNoteId: string;
  contactId: string;
}

export interface LeadDraftNote extends Note {
  customerNoteId: string;
  customerId: string;
  leadDraftId: string;
}

export interface OpportunityNote extends Note {
  opportunityNoteId: string;
  opportunityId: string;
}

export interface SalesRoute {
  salesRouteId: string;
  salesOfficeId: string;
  salesOffice: {
    name: string;
    salesSegmentation: {
      salesOrganizationId: string;
      salesSegmentationId: string;
      name: string;
    }
  };
  name: string;
  description: string;
  code: string;
  systemUserSalesRoutes: {
    id: string;
    systemUserId: string;
    systemUser: SystemUser
  }[];
}

export interface Clearance {
  clearanceLevelId: number;
  name: string;
}

export interface ContactRoleGroup {
  contactRoleGroupId: string;
  name: string;
}

export interface SpecialDayType {
  specialDayTypeId: string;
  name: string;
}

export interface OfferVasLine {
  offerVasLineId?: string;
  offerId?: string;
  valueAddedServiceId: string;
  amount: number;
  price: number;
  description?: string;
  transactionCurrencyId?: string;
  linePrice?: number;
  offerVasTypeId?: string;
}

export interface OfferContact {
  id?: string;
  offerId?: string;
  contactId: string;
}

export interface OfferLine {
  offerPriceLists?: [];
  offerLineId?: string;
  offerId?: string;
  originCountryId?: string;
  originCityId?: string;
  destinationCountryId?: string;
  destinationCityId?: string;
  offerLineUnitId?: string;
  goodsTypeId?: string;
  vehicleTypeId?: string;
  originCustomsId?: string;
  originCustomsName?: string;
  destinationCustomsId?: string;
  destinationCustomsName?: string;
  transactionCurrencyId?: string;
  offerLineTypeId?: string;
  ldm?: number;
  cbm?: number;
  kg?: number;
  cw?: number;
  amount?: number;
  price?: number;
}

export interface ShipmentProfile {
  shipmentProfileId: string;
  offerPriceList: string;
  accountId: string;
  fromCountryId: string;
  fromCityId: string;
  toCountryId: string;
  toCityId: string;
  interval: ShipmentIntervalType;
  amount: number;
  account: Account;
  fromCountry: Country;
  fromCity: City;
  toCountry: Country;
  toCity: City;
  operationType: OperationType;
  createdOn: moment.Moment;
  code: string;
}

export interface ShipmentProfileDownload {
  shipmentProfileId: string;
  offerPriceList: string;
  accountId: string;
  fromCountryId: string;
  fromCityId: string;
  toCountryId: string;
  toCityId: string;
  interval: ShipmentIntervalType;
  amount: number;
  account: Account;
  fromCountry: Country;
  fromCity: City;
  toCountry: Country;
  toCity: City;
  serviceType: string;
  createdOn: moment.Moment;
  code: string;
  shipmentFrequency?: string;
  loadNumber?: string;
}

export interface ShipmentProfileRequest {
  shipmentProfileId?: string;
  accountId: string;
  fromCountryId: string;
  fromCityId: string;
  toCountryId: string;
  toCityId: string;
  interval: string;
  amount: number;
  operationTypeId: number;
}

export interface SalesSegmentation {
  salesSegmentationId?: string;
  salesOrganizationId?: string;
  name?: string;
  salesOffices?: SalesOffice[];
  salesOrganization?: SalesOrganization;
}

export interface SalesOffice {
  salesOfficeId: string;
  salesSegmentationId: string;
  name: string;
}

export interface ActivityTrendReportRequest {
  systemUser?: string;
  salesSegmentationIds?: string;
  salesOrganizationIds?: string;
  salesUserIds?: string;
  salesOfficeIds: string;
  activityTypeIds?: string;
  activityStatusIds?: string;
  beginDate: moment.Moment;
  endDate: moment.Moment;
  reportRangeType: number;
  showWeekends: boolean;
}

export interface ActivityTrendReportResult {
  tableData: ActivityTrendBase[];
  chartOptions: FusionCombinationChartData;
  summaryData: ActivityTrendSummary[];
}

export interface ActivityTrendBase {
  activityId: string;
  activityTypeId: string;
  activityStatusId: string;
  accountId: string;
  salesOrganizationId: string;
  salesOrganizationName: string;
  salesRouteId: string;
  salesOfficeId: string;
  activityPurposeId: string;
  systemUserId: string;
  activityCode: string;
  activityDate: moment.Moment;
  accountName: string;
  systemUserFullName: string;
  activityTypeName: string;
  activityPurposeDescription: string;
  activityStatusName: string;
  activityContacts: string;
  activitySystemUsers: string;
  activityDescription: string;
}

export interface ActivityTrendSummary {
  salesOrganizationId: string;
  salesSegmentationId: string;
  salesOfficeId: string;
  systemUserId: string;
  salesOrganizationName: string;
  salesSegmentationName: string;
  salesOfficeName: string;
  systemUserFullName: string;
  salesRouteCode: string;
  totalNewCustomer: string;
  totalNewOpportunity: string;
  totalSupport: string;
  total: string;
}

export interface ChartOptions {
  series: Series[];
  title: Title;
  xAxis: XAxis;
}

export interface Series {
  name: string;
  type: string;
  data: number[];
}

export interface Title {
  text: string;
  align: string;
  offsetX: string;
}

export interface XAxis {
  categories: string[];
}

export interface SystemUserSalesRoute {
  systemUserId?: string;
  salesRouteId?: string;
  salesOfficeId?: string;
  salesSegmentationId?: string;
  salesSegmentation: SalesSegmentation;
  salesRoute: SalesRoute;
  salesOffice: SalesOffice;
  systemUser: SystemUser;
}

export interface ActivityTrendReportFilter {
  salesOrganizationList?: SalesOrganization[];
  salesSegmentationList?: SalesSegmentation[];
  salesOfficeList?: SalesOffice[];
  systemUserSalesRouteList?: SystemUserSalesRoute[];
  systemUserList?: SystemUser[];
}

export interface UserGuideRequest {
  userGuideId: string;
  name: string;
  note: string;
  userGuideStatusId: string;
}

export interface UserGuideInfoRequest {
  userGuideInfoId?: string;
  userGuideId: string;
  name: string;
  note: string;
  userGuideInfoStatusId: string;
  orderBy?: number;
}

export interface UserGuideStatus {
  userGuideStatusId: string;
  name: string;
  orderBy: number;
  createdOn: string;
  code?: string;
}

export interface UserGuideInfoStatus {
  userGuideInfoStatusId: string;
  name: string;
  orderBy: number;
  createdOn: string;
  code?: string;
}

export interface UserGuide {
  userGuideId: string;
  name: string;
  note: string;
  userGuideStatusId: string;
  userGuideInfoAmount: number;
  userGuideStatus?: UserGuideStatus;
  createdOn: string;
}

export interface UserGuideInfo {
  userGuideInfoId: string;
  name: string;
  note: string;
  userGuideId: string;
  userGuideInfoStatusId: string;
  userGuide: UserGuide;
  userGuideInfoStatus: UserGuideInfoStatus;
  createdOn: string;
  orderBy?: number;
}

export class SystemRolePermission {
  id?: string;
  createdOn: moment.Moment;
  modifiedOn: moment.Moment;
  systemPermissionId: string;
  systemRoleId?: string;
}

export interface SystemRolePermissionSearchRequest extends FilterRequest {
  filter: {
    systemPermissionId?: string[];
    systemRoleId: string[];
  };
}

export interface SystemRole {
  readOnlyFlag: boolean;
  code?: string;
  createdOn?: string;
  description?: string;
  name: string;
  systemRoleId?: string;
  checked?: boolean;
  systemRolePermissions?: SystemRolePermission[];
}

export interface SystemPermission {
  actionName: string;
  controllerName: string;
  createdOn: string;
  description: string;
  permissionCode: number;
  permissionName: string;
  systemPermissionId: string;
  checked?: boolean;
}

export interface SystemUserOrganizations {
  organization: Organization;
  organizationId: string;
  systemUserId: string;
  systemUserOrganizationId: string;
}

export interface SystemUserPermissions {
  createdOn: string;
  systemPermissionId: string;
  systemUserId: string;
}

export interface SystemUserRoles {
  createdOn: string;
  systemRoleId: string;
  systemUserId: string;
}

export interface SystemUserStatus {
  createdOn: string;
  name: string;
  orderBy: string;
  systemUserStatusId: string;
}

export interface OpportunityReportFilter {
  organizationList?: Organization[];
  salesOrganizationList: SalesOrganization[];
  salesSegmentationList?: SalesSegmentation[];
  salesRouteList?: SalesRoute[];
  salesOfficeList?: SalesOffice[];
  serviceList?: OrganizationServiceModel[];
  opportunityStatusList?: OpportunityStatus[];
  transactionCurrencyList?: TransactionCurrency[];
}

export interface OpportunityReportRequest {
  systemUser?: string;
  salesOrganizationIds?: string;
  salesSegmentationIds?: string;
  salesOfficeIds?: string;
  salesRouteIds?: string;
  organizationIds?: string;
  serviceIds?: string;
  opportunityStatusIds?: string;
  minProbabilityRate?: number;
  minProbabilityGiro?: string;
  transactionCurrencyId?: string;
}

export interface OpportunityReportChartRequest {
  opportunityList?: OpportunityReportData[];
  statusIdList?: string[];
  beginDate?: moment.Moment;
  endDate?: moment.Moment;
}

export interface FusionCombinationChartData {
  cumulative: string;
  categories: FusionCombinationCategory[];
  datasetList: FusionCombinationDataSet[];
}

export interface FusionCombinationCategory {
  label: string;
}

export interface FusionCombinationDataSet {
  seriesName: string;
  showValues: string;
  parentYAxis: string;
  renderAs: string;
  color: string;
  data: FusionCombinationData[];
  id?: string;
}

export interface FusionCombinationData {
  value: string;
}

export interface FusionChartData {
  label: string;
  value: string;
  count?: number;
  statusName?: string;
  showValue?: string;
  color?: string;
  isSliced: string;
  fillColor: string;
  accountStatusId: string;
  salesOrganizationId: string;
  formattedValue?: string;
  statusId?: string;
  centerlabel?: string;
}

export interface OpportunityReportData {
  opportunityStatusId: string;
  opportunityId: string;
  customerName: string;
  opportunityName: string;
  statusName: string;
  organizationName: string;
  expectedRevenue: string;
  probabilityRate: string;
  createdOn: moment.Moment;
  transactionCurrencyId: string;
  expectedStartMonth: moment.Moment;
}

export interface OpportunityReportResult {
  opportunityReportData: OpportunityReportData[];
}

export interface LeadDraftSummaryData {
  createdByUserFullName: string;
  salesRouteName: string;
  count: number;
}

export interface LeadDraftBaseData {
  leadDraftId: string;
  salesOrganizationName: string;
  customerName: string;
  leadDraftStatusName: string;
  leadDraftInfoDueDate: moment.Moment;
  createdOn: moment.Moment;
  createdOnDate: Date;
  leadDraftInfoDueDateDate: Date;
  salesRouteDefinitionName: string;
  createdByUserFullName: string;
}

export interface LeadsDraftsReportFilter {
  salesOrganizations: SalesOrganization[];
  systemUsers: LeadsDraftsReportSystemUser[];
  services: OrganizationServiceModel[];
  leadDraftInfoStatuses: LeadDraftStatus[];
}

export interface LeadDraftStatus {
  leadDraftInfoStatusId: string;
  name: string;
}

export interface LeadsDraftsReportSystemUser {
  systemUserId: string;
  fullName: string;
  hierarchy: string;
  salesRouteDefinitions: SalesRouteDefinition[];
}

export interface LeadsDraftsReportRequest {
  salesOrganizationIds: string;
  leadDraftInfoStatusIds?: string;
  createdByUserIds?: string;
  baseTableSalesRouteDefinitionIds?: string;
  serviceIds?: string;
  beginDate: Date;
  endDate: Date;
  reportRangeType: string;
  showWeekends: boolean;
}

export interface LeadsDraftsReportResult {
  leadDraftBaseData: LeadDraftBaseData[];
  chartOptions: FusionCombinationChartData;
  leadDraftSummaryData: LeadDraftSummaryData[];
}

export interface LeadsReportFieldsCount {
  leadsCount: number;
  leadsWonCount: number;
  dateDiff: number;
}

export interface LeadsGridData {
  id: string;
  region: string;
  companyName: string;
  routeName: string;
  createDateDiff: string;
  diff: number;
  color: string;
  statusName: string;
  createdBy: string;
  routeOwner: string;
  salesOrganizationName: string;
  salesRouteStructureName: string;
  productionAccountCode: string;
  lat?: number;
  lng?: number;
  customerShortName: string;
  countryName: string;
  cityName: string;
  sectorMainName: string;
  date: moment.Moment;
  dynamicFieldValuesJson: DynamicFieldValue[];
  code: string;
  customerTypeName: string;
  reminderDate: moment.Moment;
  lastDoneVisitDate: moment.Moment;
  warnings: AccountWarning[];
  accountSource?: string;
  accountSourceName?: string;
  segment?: Segment;
}

export interface LeadsGridDataFilterRequest extends FilterRequest {
  filter?: {
    id: string;
    companyName: string;
    routeName: string;
    createDateDiff: string;
    diff: number;
    color: string;
    statusName: string;
    createdBy: string;
  };
}

export interface LeadsReportMapData {
  id: string;
  companyName: string;
  lat: number;
  lng: number;
}

export interface LeadsReportMapSearchRequest {
  accountStatusId: string;
  isLeadsWon: boolean;
  maxLat: number;
  minLat: number;
  maxLng: number;
  minLng: number;
  salesOrganizationId?: string;
}

export interface LeadsGridDataExportRequest {
  accountStatusId: string;
  isLeadsWon: boolean;
  salesOrganizationId?: string;
}

export interface OfferReportFilterResponse {
  salesOrganizations: SalesOrganization[];
  services: OrganizationServiceModel[];
  offerStatuses: OfferStatus[];
  offerTypes: OfferType[];
  countries: Country[];
  maxOfferExpireDate: moment.Moment;
}

export interface OfferReportRequest {
  salesOrganizationIds: string[];
  serviceIds: string[];
  offerStatusIds: string[];
  offerTypeIds: string[];
  destinationCountryIds: string[];
  originCountryIds: string[];
  maxOfferExpireDate: Date;
  createdOnBeginDate: Date;
  createdOnEndDate: Date;
  reportRangeType: number;
  showWeekends: boolean;
}

export interface OfferReportResult {
  offersReportBaseData: OffersReportBaseData[];
  createdByGroupedData: CreatedByGrouped[];
  chartOptions: FusionCombinationChartData;
}

export interface OffersReportBaseData {
  offerId: string;
  offerStatusName: string;
  createdBy: string;
  offerTypeId: string;
  customerName: string;
  offerTypeName: string;
  originCountryName: string;
  originCityName: string;
  destinationCountryName: string;
  destinationCityName: string;
  serviceName: string;
  createdOn: moment.Moment;
  offerExpireDate: string;
  transactionCurrencyId: string;
  price: string;
  statusLastUpdate: moment.Moment;
  createdOnDate: Date;
  statusLastUpdateDate: Date;
  segment: string;
}

export interface CreatedByGrouped {
  createdBy: string;
  acceptPercent: string;
  acceptCount: number;
  declinedCount: number;
  waitingCount: number;
  createdCount: number;
  totalCount: number;
}

export interface CustomerNoteReadRequest {
  totalCount: number;
}

export interface DateModel {
  startDate: Date;
  endDate: Date;
}

export enum ResultMessageTypes {
  SUCCESS = 'afc013f9-315a-462d-95fc-e0557aef88cb',
  ERROR = 'cfae82f4-3f38-4f09-bf1a-895c727b5ffd',
  WARNING = '229ceac1-e812-4516-9e76-bf26f87eecb2',
  INFO = '6fcc6ba4-cb7a-41a2-8fcf-4a89ad31cb18',
  NONE = '6517bb44-c0a4-4245-9d44-33bddbef14d7'
}

export interface ResultMessageType {
  resultMessageTypeId: ResultMessageTypes;
  name: string;
  createdOn?: any;
  readOnlyFlag: boolean;
}

export interface ResultMessage {
  resultMessageId: string;
  message: string;
  resultMessageTypeId: ResultMessageTypes;
  code: string;
  popupFlag: boolean;
  resultMessageType: ResultMessageType;
  createdOn?: any;
  readOnlyFlag: boolean;
}

export interface NameGuidValue {
  name?: string;
  value: string;
}

export interface OpportunityOfferLostReasonData {
  lostReasons: FusionCombinationDataSet[];
  lostPercent: number;
  lostPercentStr: string;
}

export interface OpportunityAssignment {
  createdOn: string;
  id: string;
  opportunity: Opportunity;
  opportunityId: string;
  readOnlyFlag: boolean;
}

export interface PhoneSearchRequest extends FilterRequest {
  filter: {
    phoneNumber: string;
    getCustomer: boolean;
    getContact: boolean;
  };
}

export interface PhoneSearch {
  code: string;
  contact?: Contact;
  customer?: Customer;
  countryId: string;
  createdBy: string;
  createdOn: moment.Moment;
  internalPhone: string;
  phoneId: string;
  phoneNumber: string;
  phoneTypeId: number;
  readOnlyFlag: boolean;
}

export interface OpportunityCurrentCardResponse {
  customerName?: string;
  phone?: string;
  addressLine?: string;
  address?: Address;
  taxName?: string;
  taxNo?: string;
}

export interface EmailSearchRequest extends FilterRequest {
  filter: {
    mailName: string;
    emailTypeId?: number;
    customerId?: string;
    getCustomer: boolean;
    getContact: boolean;
  };
}

export interface EmailSearch {
  code: string;
  contact?: Contact;
  customer?: Customer;
  createdBy: string;
  createdOn: moment.Moment;
  emailId: string;
  mailName: string;
  emailTypeId?: number;
  readOnlyFlag: boolean;
}
