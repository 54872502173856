import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// import { CardDetailPanel } from '../main-layout/card-detail-panel.model';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CardDetailPanel } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private _sidenavOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavOpen$ = this._sidenavOpenSubject.asObservable();

  private _sidenavCollapsedSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsed$ = this._sidenavCollapsedSubject.asObservable();

  private _sidenavCollapsedOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsedOpen$ = this._sidenavCollapsedOpenSubject.asObservable();

  private _searchOpen = new BehaviorSubject<boolean>(false);
  searchOpen$ = this._searchOpen.asObservable();

  private _opportunityCommunicationSubject = new BehaviorSubject<string>(null);
  opportunityCommunication$ = this._opportunityCommunicationSubject.asObservable();

  private _accountCommunicationSubject = new BehaviorSubject<string[]>([]);
  accountCommunication$ = this._accountCommunicationSubject.asObservable();

  private _customReportsCommunicationSubject = new BehaviorSubject<string>(null);
  customReportsCommunication$ = this._customReportsCommunicationSubject.asObservable();

  private _cardDetailSubject = new BehaviorSubject<CardDetailPanel>(null);
  cardDetail$ = this._cardDetailSubject.asObservable();

  private _openedSidenav = new BehaviorSubject<string>(null);
  openedSidenav$ = this._openedSidenav.asObservable();

  isDesktop$ = this.breakpointObserver.observe(`(min-width: 1280px)`).pipe(
    map(state => state.matches)
  );

  ltLg$ = this.breakpointObserver.observe(`(max-width: 1279px)`).pipe(
    map(state => state.matches)
  );

  gtMd$ = this.breakpointObserver.observe(`(min-width: 960px)`).pipe(
    map(state => state.matches)
  );

  ltMd$ = this.breakpointObserver.observe(`(max-width: 959px)`).pipe(
    map(state => state.matches)
  );

  gtSm$ = this.breakpointObserver.observe(`(min-width: 600px)`).pipe(
    map(state => state.matches)
  );

  isMobile$ = this.breakpointObserver.observe(`(max-width: 599px)`).pipe(
    map(state => state.matches)
  );

  isLtLg = () => this.breakpointObserver.isMatched(`(max-width: 1279px)`);

  isMobile = () => this.breakpointObserver.isMatched(`(max-width: 599px)`);

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute
  ) { }

  openSidenav() {
    this._sidenavOpenSubject.next(true);
  }

  closeSidenav() {
    this._sidenavOpenSubject.next(false);
  }

  collapseSidenav() {
    this._sidenavCollapsedSubject.next(true);
  }

  expandSidenav() {
    this._sidenavCollapsedSubject.next(false);
  }

  collapseOpenSidenav() {
    this._sidenavCollapsedOpenSubject.next(true);
  }

  collapseCloseSidenav() {
    this._sidenavCollapsedOpenSubject.next(false);
  }

  openSearch() {
    this._searchOpen.next(true);
  }

  closeSearch() {
    this._searchOpen.next(false);
  }

  openOpportunityCommunication(opportunityId: string) {
    this._openedSidenav.next('opportunity');
    this._opportunityCommunicationSubject.next(opportunityId);
  }

  closeOpportunityCommunication() {
    this._openedSidenav.next(null);
    this._opportunityCommunicationSubject.next(null);
  }

  openAccountCommunication(accountComm: string[]) {
    this._openedSidenav.next('account');
    this._accountCommunicationSubject.next(accountComm);
  }

  closeAccountCommunication() {
    this._openedSidenav.next(null);
    this._accountCommunicationSubject.next(null);
  }

  openCustomReportsCommunication(customDashboardId: string) {
    this._openedSidenav.next('customReports');
    this._customReportsCommunicationSubject.next(customDashboardId);
  }

  closeCustomReportsCommunication() {
    this._openedSidenav.next(null);
    this._customReportsCommunicationSubject.next(null);
  }

  openCardDetail(card: CardDetailPanel) {
    this._cardDetailSubject.next(card);
  }

  closeCardDetail() {
    this.route.queryParams.subscribe((params) => {
      if (params.cardId) {
        this.router.navigate([], { relativeTo: this.route });
      }
      this._cardDetailSubject.next(null);
    });
  }

  enableRTL() {
    this.router.navigate([], {
      queryParams: {
        rtl: 'true'
      }
    }).then(() => {
      if (window) {
        window.location.reload();
      }
    });
  }

  disableRTL() {
    this.router.navigate([], {
      queryParams: {
        rtl: 'false'
      }
    }).then(() => {
      if (window) {
        window.location.reload();
      }
    });
  }
}
