<div>
  <div class="dropdown">
    <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <div class="dropdown-heading">{{'GENERAL.RECENT_PAGES' | translate}}</div>
      </div>
    </div>
    <div @stagger class="dropdown-content" *ngIf="recentPage?.length > 0">
      <ng-container *ngFor="let page of recentPage; let last = last">
        <a [ngClass]="last ? 'last-recent-page' : ''" (click)="goToPage(page)" class="recent-page" fxLayout="row"
          fxLayoutAlign="start center" matRipple>
          <mat-icon mat-icon class="item-icon mr-2" style="width: 42px; height: 42px;">
            <net-iconify-icon [icon]="page.icon" fxFlex="none" size="24" color="#5c78fd"></net-iconify-icon>
          </mat-icon>
          <span class="recent-page-label" fxFlex="auto">{{ getTranslation(page.label) }}</span>
        </a>
      </ng-container>
    </div>
    <div @stagger class="dropdown-content" *ngIf="recentPage?.length === 0">
      <a class="recent-page" style="margin-bottom: 10px;" fxLayout="row" fxLayoutAlign="center center" matRipple>
        <span style="font-size: 14px;">{{'GENERAL.THERE_ARE_NO_RECENT_PAGES' | translate}}</span>
      </a>
    </div>
    <div>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="42" class="mx-auto my-6">
      </mat-progress-spinner>
    </div>
  </div>
</div>