<mat-form-field *ngIf="field" class="w-full filter-select" floatLabel="always">
  <mat-label>{{ (entityName + 'DynamicField.' + field.name) | translate }}</mat-label>
  <mat-select #select (infiniteScroll)="picklistValuesSearchWithScroll(field)"
    [complete]="field.currentPage === field.pageCount" msInfiniteScroll [formControl]="control" multiple
    panelClass="custom-panel-class" (closed)="onClosedSelectPicklist(field)">
    <mat-select-trigger *ngIf="field.selectedDynamicFieldPicklistValues?.length > 2">
      {{ 'GENERAL.COUNT_VALUES_SELECTED' | translate:
      {count: field.selectedDynamicFieldPicklistValues.length} }}
    </mat-select-trigger>
    <div class="block py-2 px-4">
      <input matInput class="block w-full" (keydown.control.a)="$event.stopPropagation()"
        placeholder="{{'GENERAL.SEARCH' | translate }}" [formControl]="field.picklistValuesKeywordFormControl"
        (keydown)="checkKey($event)">
    </div>
    <div class="mat-option-button-group">
      <button mat-raised-button [disabled]="field?.picklistValuesLoading || field?.picklistValues?.length === 1"
        (click)="onSelectAllPicklistValues(field)">{{"GENERAL.SELECT_ALL" | translate}}</button>
      <button mat-raised-button [disabled]="field?.picklistValuesLoading"
        (click)="onDeselectAllPicklistValues(field)">{{"GENERAL.REMOVE_ALL" | translate}}</button>
    </div>
    <mat-option [value]="item.dynamicFieldPickListId" [class.mat-option-disabled]="!item.activeFlag"
      (click)="onSelectedPickList(item.dynamicFieldPickListId, field)"
      *ngFor="let item of getPicklistValuesFiltered(field)">
      {{ (entityName +'_'+ field.name +'.'+item.name) | translate }}
    </mat-option>
    <mat-option *ngIf="field.picklistValues?.length === 0" disabled>{{'GENERAL.NO_RESULT_FOUND' | translate
      }}</mat-option>
  </mat-select>
  <mat-progress-spinner *ngIf="field.picklistValuesLoading" class="custom-spinner" diameter="16"
    mode="indeterminate"></mat-progress-spinner>
</mat-form-field>

<mat-form-field *ngIf="!field" class="w-full filter-select" floatLabel="always">
  <mat-label></mat-label>
  <mat-select #select>
    <div class="mat-option-button-group">
      <button mat-raised-button>{{"GENERAL.SELECT_ALL" | translate}}</button>
      <button mat-raised-button>{{"GENERAL.REMOVE_ALL" | translate}}</button>
    </div>
    <mat-option>
    </mat-option>
    <mat-option>{{'GENERAL.NO_RESULT_FOUND' | translate }}</mat-option>
  </mat-select>
  <mat-progress-spinner class="custom-spinner" diameter="16" mode="indeterminate"></mat-progress-spinner>
</mat-form-field>