import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import {
  LoginInfo, MailLink, MailLinkDataSource, MailLinkSearchRequest,
  MailLinkType, MailService, MatHeaderFilterType, User
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { getUser, hasUserPermission } from '@core/store';
import { UserConfigService } from '@core/user-config/user-config.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fadeInRight400ms, fadeInUp400ms, scaleIn400ms, stagger40ms } from '@shared/animations';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import hasClassParents from '@shared/utils/has-class-parents';
import { of } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { MailPreviewComponentService } from 'src/app/layouts/components/mail-preview/mail-preview.component.service';
import { getMailLinkTypeName } from 'src/app/mail/mail.helper';
import Swal from 'sweetalert2';
import { TableColumn } from '../../interfaces/table-column.interface';
import { updateColumnOrder, updateUserColumnVisibility, watchUserTableSort } from '../../utils/mat-table-user-config';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { DocumentUploadType } from '../document-upload/document-upload.component.enum';
import { DocumentUploadOptions } from '../document-upload/document-upload.component.model';
import { TableColumnFilterConfig } from '../mat-table-column-filter/mat-table-column-filter.component';
import { MatTablePaginatorComponent } from '../mat-table-paginator/mat-table-paginator.component';

@Component({
  selector: 'net-mail-link-list[type][objectId][showIcon]',
  templateUrl: './mail-link-list.component.html',
  styleUrls: ['./mail-link-list.component.scss'],
  animations: [
    stagger40ms,
    scaleIn400ms,
    fadeInUp400ms,
    fadeInRight400ms,
  ],
  standalone: true,
  imports: [
    SharedModule,
    DocumentUploadComponent
  ]
})
export class MailLinkListComponent implements OnInit, AfterViewInit, OnDestroy {
  initiated = false;

  searchCtrl = new FormControl();
  dataSource: MailLinkDataSource;

  pageSize = 10;
  pageSizeOptions = [5, 10, 20, 50];

  hasPreviews = false;
  matHeaderFilterType = MatHeaderFilterType;

  user: User;
  columns: TableColumn<MailLink>[] = [];
  tableColumnFilterConfig: TableColumnFilterConfig;

  icSearch = Icon.IC_SEARCH;
  icMoreHoriz = Icon.IC_MORE_HORIZ;
  icFilterList = Icon.IC_FILTER_LIST;
  icLayersClear = Icon.IC_TWOTONE_LAYERS_CLEAR;
  icKeyboardArrowUp = Icon.IC_KEYBOARD_ARROW_UP;
  icKeyboardArrowDown = Icon.IC_KEYBOARD_ARROW_DOWN;
  icOpenLink = Icon.IC_OPEN_IN_NEW;
  icRemoveLink = Icon.IC_OUTLINE_LINK_OFF;
  icMail = Icon.MDI_EMAIL;

  isDeleteMailLinkPermitted: boolean;
  hasMailMenu: boolean;

  loginInfo: LoginInfo;

  @Input() type: MailLinkType;
  @Input() objectId: string;
  @Input() accountId: string;
  @Input() showIcon: boolean;
  @Input() salesOrganizationIds: string[];

  @Input() collapse = true;
  @Input() collapsible = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTablePaginatorComponent, { static: true }) matTablePaginatorComponent: MatTablePaginatorComponent;

  documentType = DocumentUploadType.MAIL_LIST;
  documentOptions: DocumentUploadOptions = {
    single: true,
    allowedExtensions: ['.msg', '.eml'],
    autoUpload: true,
    queryString: ''
  };
  uploadUrl = `${environment.apiUrl}/api/MailLink/Upload`;

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    private userConfig: UserConfigService,
    private mailService: MailService,
    private mailPreviewService: MailPreviewComponentService,
    private cdr: ChangeDetectorRef
  ) {
    // Define columns
    this.columns = [
      {
        type: 'text',
        label: this.translate.instant('GENERAL.MAIL_LIST_FROM'),
        property: 'from',
        visible: true,
        filterable: false
      },
      {
        type: 'text',
        label: this.translate.instant('GENERAL.MAIL_LIST_SUBJECT'),
        property: 'subject',
        visible: true,
        filterable: false
      },
      {
        key: 'mailLinkTypeIds',
        type: 'uuid',
        label: this.translate.instant('GENERAL.MAIL_LIST_TYPE'),
        property: 'mailLinkTypeId',
        visible: true,
        filterable: true
      },
      {
        type: 'date',
        label: this.translate.instant('GENERAL.MAIL_LIST_MAIL_DATE'),
        property: 'mailDate',
        visible: true,
        filterable: false
      },
      {
        type: 'createdUser',
        label: this.translate.instant('GENERAL.MAIL_LIST_ADDED_BY'),
        property: 'createdUser',
        visible: true,
        filterable: false
      },
      {
        label: this.translate.instant('GENERAL.ACTIONS'),
        property: 'actions',
        type: 'button',
        visible: true,
        filterable: false
      }
    ];

    // Subscribe user updates
    this.store
      .select(getUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => this.user = user);

    // Subscribe link delete permission
    this.store
      .select(hasUserPermission(Permission.MAIL_LINK_DELETE))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.isDeleteMailLinkPermitted = response);

    this.store.select(hasUserPermission(Permission.MAIL_MAIN_MENU))
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => this.hasMailMenu = response);

    // Subscribe has preview updates
    this.mailPreviewService.hasPreview$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hasPreviews => this.hasPreviews = hasPreviews);
  }

  ngOnInit(): void {
    const initialFilter: MailLinkSearchRequest['filter'] = {
      salesOrganizationIds: this.salesOrganizationIds,
      accountId: this.accountId
    };

    // Apply customer type account filter when exists
    // Otherwise apply mail link type filter directly
    if (MailLinkType.CUSTOMER === this.type) {
      initialFilter.accountId = this.objectId;
    } else {
      initialFilter.mailLinkTypeIds = [this.type];
    }

    // Apply object id filter by type
    switch (this.type) {
      case MailLinkType.CARD: initialFilter.cardId = this.objectId; break;
      case MailLinkType.CONTACT: initialFilter.contactId = this.objectId; break;
      case MailLinkType.ACTIVITY: initialFilter.activityId = this.objectId; break;
      case MailLinkType.CUSTOMER: initialFilter.accountId = this.objectId; break;
      case MailLinkType.ASSIGNMENT: initialFilter.assignmentId = this.objectId; break;
      case MailLinkType.LEAD_DRAFT: initialFilter.leadDraftId = this.objectId; break;
      case MailLinkType.OPPORTUNITY: initialFilter.opportunityId = this.objectId; break;
    }

    // Create mail link data source
    this.dataSource = new MailLinkDataSource(this.translate, this.mailService, initialFilter);

    // Create table column filter config
    this.tableColumnFilterConfig = {
      sortId: `mail-list-${this.type}-${this.objectId}`,
      orderId: `mailList-${this.type}-${this.objectId}`,
      visibilityId: `mail-list-${this.type}-${this.objectId}`,
    };

    // Update type column visibility
    this.columns = this.columns.filter((column) => {
      // Show mail link type column if type is customer
      if ('mailLinkTypeId' === column.property) {
        return MailLinkType.CUSTOMER === this.type;
      }

      return true;
    });

    // Listen loading event for determine data source is initiated
    this.dataSource.loading$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(value => false === value), take(1))
      .subscribe(() => this.initiated = true);

    // Listen data source keyword change and sync with search control
    this.dataSource.keyword$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(keyword => keyword !== this.searchCtrl.value))
      .subscribe((keyword) => this.searchCtrl.setValue(keyword, { emitEvent: false }));

    // Listen for search
    this.searchCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(150),
        filter((keyword) => keyword?.length > 2 || keyword?.length === 0)
      )
      .subscribe((keyword) => this.dataSource.keyword$.next(keyword));

    const queryParams = new URLSearchParams({
      mailLinkTypeId: this.type
    });
    switch (this.type) {
      case MailLinkType.CARD:
        this.documentOptions.queryString = `?mailLinkTypeId=${this.type}&cardId=${this.objectId}`;
        break;
      case MailLinkType.CONTACT:
        queryParams.append('contactId', this.objectId);
        this.documentOptions.salesOrganizationIds = this.salesOrganizationIds;
        this.documentOptions.salesOrganizationSelection = this.salesOrganizationIds?.length > 1;
        if (this.salesOrganizationIds?.length > 0) {
          queryParams.append('salesOrganizationId', this.salesOrganizationIds[0]);
        }
        if (this.accountId) {
          queryParams.append('accountId', this.accountId);
        }
        this.documentOptions.queryString = `?${queryParams.toString()}`;
        break;
      case MailLinkType.ACTIVITY:
        queryParams.append('activityId', this.objectId);
        if (this.accountId) {
          queryParams.append('accountId', this.accountId);
        }
        this.documentOptions.queryString = `?${queryParams.toString()}`;
        break;
      case MailLinkType.CUSTOMER:
        queryParams.append('accountId', this.objectId);
        this.documentOptions.queryString = `?${queryParams.toString()}`;
        break;
      case MailLinkType.ASSIGNMENT:
        queryParams.append('assignmentId', this.objectId);
        if (this.accountId) {
          queryParams.append('accountId', this.accountId);
        }
        this.documentOptions.queryString = `?${queryParams.toString()}`;
        break;
      case MailLinkType.LEAD_DRAFT:
        queryParams.append('leadDraftId', this.objectId);
        this.documentOptions.salesOrganizationIds = this.salesOrganizationIds;
        this.documentOptions.salesOrganizationSelection = this.salesOrganizationIds?.length > 1;
        if (this.salesOrganizationIds?.length > 0) {
          queryParams.append('salesOrganizationId', this.salesOrganizationIds[0]);
        }
        if (this.accountId) {
          queryParams.append('accountId', this.accountId);
        }
        this.documentOptions.queryString = `?${queryParams.toString()}`;
        break;
      case MailLinkType.OPPORTUNITY:
        queryParams.append('opportunityId', this.objectId);
        if (this.accountId) {
          queryParams.append('accountId', this.accountId);
        }
        this.documentOptions.queryString = `?${queryParams.toString()}`;
        break;
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  mailLinkTypeName(typeId: string) {
    return getMailLinkTypeName(typeId, this.translate);
  }

  deleteLink(link: MailLink) {

    const type = Object.keys(MailLinkType).find(k => MailLinkType[k] === link.mailLinkTypeId);

    Swal.fire({
      html: this.translate.instant('MAIL.MAIL_LINK_REMOVE_CONFIRM_FROM_OBJECT', {
        subject: link.subject,
        objectName: this.translate.instant('GENERAL.MAIL_LINK_TYPE_' + type)
      }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (!result.value) {
        return;
      }

      this.mailService.link.delete(link.mailLinkId).subscribe(() => this.dataSource.refresh());
    });
  }

  openMailBox(link: MailLink) {
    this.router.navigate(['/mail', link?.folderId ?? 'inbox', link.mailId], {
      queryParams: {
        reset: true
      }
    }
    );
  }

  openPreview(link: MailLink) {
    if (!link.body) {
      console.warn('Mail link body is empty, preview is failed.', link);
      return;
    }

    this.mailPreviewService.addLink(link);
  }

  openLink(link: MailLink) {

    if (this.type !== MailLinkType.CUSTOMER) {
      return false;
    }

    let detailId = '';

    switch (link.mailLinkTypeId) {
      case MailLinkType.ACTIVITY:
        detailId = '/leads/' + link.accountId + '/activities/' + link.activityId;
        break;
      case MailLinkType.ASSIGNMENT:
        detailId = '/leads/' + link.accountId + '/assignment/' + link.assignmentId;
        break;
      case MailLinkType.CARD:
        detailId = '/board/' + link.card?.boardInfo.boardId + '/scrum?cardId=' + link.cardId;
        break;
      case MailLinkType.CONTACT:
        detailId = '/leads/' + link.accountId + '/contacts/' + link.contactId;
        break;
      case MailLinkType.CUSTOMER:
        detailId = '/leads/' + link.accountId + '/home';
        break;
      case MailLinkType.LEAD_DRAFT:
        break;
      case MailLinkType.OPPORTUNITY:
        detailId = '/leads/' + link.accountId + '/opportunities/' + link.opportunityId;
        break;
    }

    const url = this.router.serializeUrl(
      this.router.createUrlTree([detailId])
    );
    window.open(detailId, '_blank');

  }

  checkVisibility(link: MailLink) {

    if (!link.mailId) {
      return of(false);
    }

    if (this.type !== MailLinkType.CUSTOMER) {
      return of(false);
    }

    switch (link.mailLinkTypeId) {
      case MailLinkType.ACTIVITY:
        return this.store.select(hasUserPermission(Permission.ACTIVITY_GET));
      case MailLinkType.ASSIGNMENT:
        return this.store.select(hasUserPermission(Permission.ASSIGNMENT_GET));
      case MailLinkType.CARD:
        return this.store.select(hasUserPermission(Permission.CARD_GET));
      case MailLinkType.CONTACT:
        return this.store.select(hasUserPermission(Permission.CONTACT_GET));
      case MailLinkType.CUSTOMER:
        return this.store.select(hasUserPermission(Permission.ACCOUNT_GET));
      case MailLinkType.LEAD_DRAFT:
        return this.store.select(hasUserPermission(Permission.LEAD_DRAFT_GET));
      case MailLinkType.OPPORTUNITY:
        return this.store.select(hasUserPermission(Permission.OPPORTUNITY_GET));
      default:
        return of(false);
    }

  }

  clearPreviews() {
    this.mailPreviewService.clear();
  }

  toggleCollapse(event) {
    if (!this.collapsible) {
      return;
    }

    if (hasClassParents(event.target, 'ignore-toggle-click')) {
      return;
    }

    this.collapse = !this.collapse;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.matTablePaginatorComponent.paginator;

    // Watch table sort changes for user config
    watchUserTableSort(this.tableColumnFilterConfig.sortId, this.sort, this.userConfig, this.columns);

    // Update column visibility by user config
    updateUserColumnVisibility(this.tableColumnFilterConfig.visibilityId, this.columns, this.userConfig);

    updateColumnOrder(this.tableColumnFilterConfig.orderId, this.columns, this.userConfig);
    this.cdr.detectChanges();
  }

  onUploadDone() {
    this.dataSource.refresh();
  }

  ngOnDestroy(): void { }
}
