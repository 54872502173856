import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHTML',
  standalone: true
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: any, limit?: number): string {
    if (!value) {
      return '';
    }

    const document = new DOMParser().parseFromString(value, 'text/html');
    let text = document.body.textContent.trim() || '';

    if (limit && text.length > limit) {
      text = text.slice(0, limit) + '...';
    }

    return text;
  }

}
