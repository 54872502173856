import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { SharedModule } from '@shared/modules';
import { TaxOffice, TaxOfficeService } from '@core/api';

@Component({
  selector: 'net-select-tax-office',
  templateUrl: './select-tax-office.component.html',
  styleUrls: ['./select-tax-office.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectTaxOfficeComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectTaxOfficeComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<TaxOffice | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-tax-office';
  describedBy = '';
  stateChanges = new Subject<void>();

  taxOffices: TaxOffice[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TaxOffice | null { return this._value; }
  set value(value: TaxOffice | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: TaxOffice;

  @HostBinding('id') id = `select-taxt-office-${SelectTaxOfficeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.taxOfficeService.search({ filter: {}, pageSize: 100, orderBy: 'name', orderType: 'ASC' })
        .toPromise()
        .then((response) => {
          this.taxOffices = response.data.results;
          this.changeDetectorRef.detectChanges();
        })
        .finally(() => resolve());
    });
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private taxOfficeService: TaxOfficeService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initDefaultOptions();

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.taxOffices = [];
        // this.value = null;
      }),
      switchMap(value => this.taxOfficeService.search({
        filter: { name: value },
        pageSize: 50,
        orderBy: 'name',
        orderType: 'ASC',
      })),
      tap(() => this.loading = false)
    ).subscribe((response) => {
      this.taxOffices = response.data.results;
      this.changeDetectorRef.detectChanges();
    });
  }

  displayName = (taxOffice: TaxOffice) => taxOffice ? taxOffice.name : '';

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.taxOffices = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.initDefaultOptions();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: TaxOffice) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
