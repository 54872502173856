import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, finalize, map, tap } from 'rxjs/operators';

import {
  ContractTypeService,
  CurrencyService,
  Opportunity,
  OpportunityService,
  OpportunityStatusService,
  OpportunityTypeService,
  OrganizationService,
  SalesOrganizationService,
  SalesRouteDefinitionService,
  ServiceServiceInj,
  SystemUserService,
  User,
  OpportunityApprovalsService
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';

export class ContractDataSource extends ApiDataSource<Opportunity> {

  public isOpenedNewTab = false;

  public $salesOrganizations = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(statuses => statuses.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  public $organizations = this.organizationService.list().pipe(
    map(response => response.data.results),
    map(statuses => statuses.sort((a, b) => (a.name.toCapitalize() > b.name.toCapitalize()) ? 1 : -1).map(item => {
      return {
        key: item.organizationId,
        value: item.name
      };
    }))
  );

  public $user = this.systemUserService
    .userSearch({ filter: { salesOrganizationIds: this.user.salesOrganizations.map(item => item) } }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $opportunityTypes = this.opportunityTypeService.list().pipe(
    map(response => response.data.results),
    map(statuses => statuses.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.opportunityTypeId,
        value: this.translate.instant('OpportunityType.' + item.name)
      };
    }))
  );

  public $salesRoutes = this.salesRouteDefinitionService.userRoutes().pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(route => route.map(item => {
      return {
        key: item.salesRouteDefinitionId,
        value: item.name
      };
    }))
  );

  public $opportunityStatuses = this.opportunityStatusService.filterSearch({ filter: { isContract: true } }).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      const translatedStatus = this.translate.instant('OpportunityStatus.' + item.name);
      return {
        key: {
          opportunityStatusId: item.opportunityStatusId,
          salesOrganizationId: item.salesOrganization?.salesOrganizationId
        },
        value: item.salesOrganization &&
          item.salesOrganization?.shortName ? `${translatedStatus} - (${item.salesOrganization.shortName})` : translatedStatus,
      };
    }))
  );

  public $services = this.serviceService.filterSearch({ filter: {}, pageSize: 100 }).pipe(
    map(response => response.data),
    map(services => services.map(service => {
      return {
        key: service.serviceId,
        value: service.name
      };
    }))
  );

  public $contractTypes = this.contractTypeService.list({ filter: {}, pageSize: 100 }).pipe(
    map(response => response.data),
    map(contractTypes => contractTypes.map(contractType => {
      const translatedContractTypes = this.translate.instant('ContractType.' + contractType.typeName);
      return {
        key: contractType.contractTypeId,
        value: `${translatedContractTypes}`
      };
    }))
  );

  public $renewal = of([
    { key: true, value: this.translate.instant('GENERAL.YES') },
    { key: false, value: this.translate.instant('GENERAL.NO') },
  ]);

  public $lostReasonTypes = this.opportunityService.getLostReasonType().pipe(
    map(response => response.data),
    map(lostReasonTypes => lostReasonTypes.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1).map(lostReasonType => {
      return {
        key: lostReasonType.opportunityLostReasonTypeId,
        value: this.translate.instant('OpportunityLostReasonType.' + lostReasonType.name)
      };
    }))
  );

  public $transactionCurrencies = this.currencyService.search({ filter: { enabled: true } }).pipe(
    map(response => response.data.results),
    map(currencies => currencies.map(item => {
      return {
        key: item.transactionCurrencyId,
        value: this.translate.instant('TransactionCurrency.' + item.currencyName) + ' - ' + item.isoCurrencyCode
      };
    }))
  );

  public $approvalTypes = this.approvalService.search({ filter: { salesOrganizationIds: this.user.salesOrganizations } }).pipe(
    map(response => response.data.results),
    map(types => types.filter(type => type.isActive)),
    map(types => types.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1).map(item => {
      return {
        key: item.opportunityApprovalDefaultTypeId || item.opportunityApprovalTypeId,
        value: this.translate.instant('OpportunityApprovalType.' + item.typeName)
      };
    })),
    map(types => this.getDistinctObjects(types))
  );

  public request$ = new BehaviorSubject<{}>(null);

  constructor(
    private serviceService: ServiceServiceInj,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    private systemUserService: SystemUserService,
    private user: User,
    private opportunityService: OpportunityService,
    private organizationService: OrganizationService,
    private opportunityStatusService: OpportunityStatusService,
    private translate: TranslateService,
    private currencyService: CurrencyService,
    private salesOrganizationService: SalesOrganizationService,
    private approvalService: OpportunityApprovalsService,
    private contractTypeService: ContractTypeService,
    protected initialFilter?: any,
    private opportunityTypeService?: OpportunityTypeService
  ) {
    super(initialFilter);
  }

  private getDistinctObjects(types: Array<{ key: string, value: string }>): Array<{ key: string, value: string }> {
    const seenKeys = new Set<string>();
    return types.filter(item => {
      if (!seenKeys.has(item.key)) {
        seenKeys.add(item.key);
        return true;
      }
      return false;
    });
  }

  load(): void {
    if (!this.isOpenedNewTab) {
      // Init filter with data source's default filter
      const filter: any = { ...this.initialFilter, ...this.filter };

      // If filter keyword exists, filter data
      if (this.keyword) {
        filter.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request = this.getRequest();

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }

      // Add filters to request
      request.filter = filter;

      // Fetch data
      this.opportunityService.search(request)
        .pipe(
          tap(() => this.request$.next(request)),
          debounceTime(400),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe((response) => {
          // Update count and data subjects
          this.dataSubject.next(response.data.results);
          this.dataCountSubject.next(response.data.rowCount);
          this.rowCount$.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });

      return this.loadingSubject.next(false);
    }
  }
}
