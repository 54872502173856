import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { SystemSettingType } from '@core/api';
import { getSystemSettingValue } from '@core/store';
import { Store } from '@ngrx/store';
import { Style } from '@shared/enums';
import { StyleService } from '@shared/services';

@Component({
  selector: 'net-guest-layout',
  templateUrl: './guest-layout.component.html',
  styleUrls: ['./guest-layout.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    FlexLayoutModule
  ]
})
export class GuestLayoutComponent implements OnInit, OnDestroy {
  templateFlag = true;
  bgVideo: any;
  bgImage: any;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private style: StyleService,
    private store: Store
  ) {
    this.store.select(getSystemSettingValue(SystemSettingType.LOGIN_VIDEO)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(result => {
      this.bgVideo = result;
    });

    this.store.select(getSystemSettingValue(SystemSettingType.LOGIN_IMAGE)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(result => {
      this.bgImage = result;
    });
  }

  ngOnInit() {
    this.style.setStyle(this.templateFlag ? Style.light : Style.dark);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
