import { APP_INITIALIZER, EnvironmentProviders, Injector, Provider } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationInitializerFactory } from "@shared/configs";

export function provideTranslation(): Array<Provider | EnvironmentProviders> {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
  ];
}
