import { finalize } from 'rxjs/operators';

import { TaxOffice, TaxOfficeService } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';

export class TaxOfficeDataSource extends ApiDataSource<TaxOffice> {

  constructor(
    private taxOfficeService: TaxOfficeService,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.taxOfficeService
      .search(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
