import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { getSystemSettingValue, hasUserPermission } from '@core/store';
import { SystemSettingType } from '@core/api';
import { Permission } from '@core/auth/auth.enum';

import {
  ChangeDetectorRef, Component
  , DestroyRef, ElementRef, HostBinding, inject, Input, OnDestroy, ViewChild
} from '@angular/core';
import { Icon } from '@shared/enums';
import { NavigationItem } from '@core/navigation/navigation-item.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VexConfigService } from '@vex/config/vex-config.service';
import { LayoutService } from '@shared/services';
import { NavigationLoaderService } from '@core/navigation/navigation-loader.service';
import { PopoverService } from 'src/app/shared/components/popover/popover.service';
import { RecentPagesDropdownComponent } from './recent-pages-dropdown/recent-pages-dropdown.component';
import { SharedModule } from '@shared/modules';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { ToolbarUserComponent } from './toolbar-user/toolbar-user.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'net-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ToolbarNotificationsComponent,
    ToolbarUserComponent,
    BreadcrumbsComponent
  ]
})
export class ToolbarComponent implements OnDestroy {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  userGuideControl: boolean;

  navigationItems: NavigationItem[];

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = Icon.IC_SEARCH;
  icHelp = Icon.IC_HELP;
  icBookmarks = Icon.IC_BOOKMARKS;
  icMenu = Icon.IC_MENU;
  icRecent = Icon.MDI_RECENT;

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private popover: PopoverService,
    private layoutService: LayoutService,
    private configService: VexConfigService,
    private navigationService: NavigationLoaderService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.navigationService.items$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.navigationItems = response;
    });

    combineLatest([
      this.store.select(hasUserPermission(Permission.USER_GUIDE_INFO_SEARCH)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(getSystemSettingValue(SystemSettingType.USER_GUIDE)).pipe(takeUntilDestroyed(this.destroyRef))
    ]).subscribe(response => {
      this.userGuideControl = response.every(Boolean);
    });
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  showPopover() {
    this.changeDetectorRef.markForCheck();

    const popoverRef = this.popover.open({
      content: RecentPagesDropdownComponent,
      origin: this.originRef,
      hasBackdrop: true,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }
}
