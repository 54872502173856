<div class="box-container" [class.disabled]="disabled">
  <div class="box card flex flex-col justify-center items-center" [class.active]="this.value === service.LAND"
    [class.disabled]="isDisabled(service.LAND)" (click)="onClick(service.LAND)">
    <net-iconify-icon [icon]="icLocalShipping"></net-iconify-icon>
    <p>{{'OFFER.LAND' | translate}}</p>
  </div>

  <div class="box card flex flex-col justify-center items-center" [class.active]="this.value === service.SEA"
    [class.disabled]="isDisabled(service.SEA)" (click)="onClick(service.SEA)">
    <net-iconify-icon [icon]="icDirectionsBoat"></net-iconify-icon>
    <p>{{'OFFER.SEA' | translate}}</p>
  </div>

  <div class="box card flex flex-col justify-center items-center" [class.active]="this.value === service.AIR"
    [class.disabled]="isDisabled(service.AIR)" (click)="onClick(service.AIR)">
    <net-iconify-icon [icon]="icLocalAirport"></net-iconify-icon>
    <p>{{'OFFER.AIR' | translate}}</p>
  </div>
</div>