<mat-chip-grid #chipList #origin="matAutocompleteOrigin" matAutocompleteOrigin [disabled]="disabled">
  <mat-chip-row *ngFor="let competitor of value" [removable]="true" (removed)="onRemoveChip(competitor)">
    {{ competitor.name }}
    <net-iconify-icon [icon]="icCancel" inline="true" matChipRemove></net-iconify-icon>
  </mat-chip-row>
  <input #input [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="origin" [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" [placeholder]="placeholder"
    (matChipInputTokenEnd)="onAddToken($event)" (focus)="reset()">
</mat-chip-grid>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" [autoActiveFirstOption]="true">
  <ng-container *ngIf="this.competitors">
    <mat-option *ngFor="let competitor of competitors" [value]="competitor">{{ competitor.name }}</mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>