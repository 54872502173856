<mat-select #accountStatusSelect [placeholder]="'MAIL_LIST.ACCOUNT_STATUS' | translate" multiple
    (openedChange)="openedChange($event)" [formControl]="selectCtrl" (selectionChange)="onSelectionChange()"
    [compareWith]="compareWith">

    <mat-select-trigger *ngIf="value.length > 1" [matTooltipDisabled]="!(value.length > 1 && disabled)"
        [matTooltip]="getToolTip()" [matTooltipClass]="'account-status-tooltip'" [matTooltipPosition]="'below'">
        {{'GENERAL.ACCOUNT_STATUS_VALUE_SELECTED' | translate: {value:
        value.length} }}
    </mat-select-trigger>

    <div class="mat-option-button-group">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate }}
        </button>
    </div>

    <mat-option
        *ngFor="let item of accountStatuses | npsAccountStatusBySalesOrganization: salesOrganizationId  | sortBy:'asc':'orderBy'"
        [value]="item.accountStatusId">
        {{'AccountStatus.'+ item.name |translate}}
    </mat-option>

</mat-select>