export enum CustomFilterSet {
    WAITING_FOR_MY_APPROVAL = 'waiting_for_my_approval',
    CLOSE_DATE_EXPIRED = 'close_date_expired',
    MY_OPEN_TASKS = 'my_open_tasks',
    CREATED_BY_ME = 'created_by_me',
    ASSIGNED_TO_ME = 'assigned_to_me',
    TODAY_SPECIAL_DAYS = 'today_special_days',
    WON_OPPORTUNITIES = 'won_opportunities',
    EXPIRED_ACTIVE_CONTRACTS = 'expired_active_contracts',
    WON_LAST_30_DAYS = 'won_last_30_days',
    WARNINGS_ADDED_TODAY = 'warnings_added_today',
    EXPIRED_FUTURE_LEADS = 'expired_future_leads'
}
