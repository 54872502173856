import { ClassicEditor } from "ckeditor5";

export function MentionCustomization(editor: ClassicEditor) {
    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for('downcast').attributeToElement({
        model: 'mention',
        view: (modelAttributeValue, { writer }) => {
            // Do not convert empty attributes (lack of value means no mention).
            if (!modelAttributeValue || !modelAttributeValue.value) {
                return;
            }

            return writer.createAttributeElement('span', {
                class: 'mention',
                'data-mention': modelAttributeValue.id,
                id: modelAttributeValue.value
            }, {
                // Make mention attribute to be wrapped by other attribute elements.
                priority: 20,
                // Prevent merging mentions together.
                id: modelAttributeValue.value
            });
        },
        converterPriority: 'high'
    });
}
