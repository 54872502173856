<mat-select #channelTypeSelect [tabIndex]="tabIndex" [formControl]="selectCtrl" [compareWith]="compareWith"
    (selectionChange)="onSelectionChange()" [multiple]="multiple" [placeholder]="'CX.CHANNEL_TYPE' | translate"
    (openedChange)="openedChange($event)" (closed)="searchText = ''; filteredChannelTypes = channelTypes">

    <mat-select-trigger *ngIf="selectCtrl?.value?.length > 2 && multiple">
        {{'CX.CHANNEL_TYPES_SELECTED' | translate: {value:
        selectCtrl?.value?.length} }}
    </mat-select-trigger>

    <input *ngIf="multiple" (keydown)="$event.stopPropagation()" [(ngModel)]="searchText" (keyup)="onKey($event)"
        style="width: 95%;" class="my-2 ml-4 border-0 outline-none w-full bg-card" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <div *ngIf="multiple" class="mat-option-button-group">
        <button mat-raised-button [disabled]="filteredChannelTypes.length === 0 || disabled" (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button [disabled]="filteredChannelTypes.length === 0 || disabled" (click)="deselectAll()">
            {{'GENERAL.REMOVE_ALL' | translate }}
        </button>
    </div>

    <mat-option *ngFor="let channelType of filteredChannelTypes" [value]="channelType.cxChannelTypeId">
        {{ channelType.name }}
    </mat-option>
</mat-select>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>