<form class="form-wrapper" [formGroup]="form" [class.page]="isPage" [class.dialog]="isDialog">
  <ng-template #content>
    <div fxLayout="column">
      <ng-container *ngIf="contact !== undefined">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <!-- <mat-form-field fxFlex>
            <mat-label>{{'CONTACT.SALUTATION' | translate}}</mat-label>
            <net-select-salutation formControlName="salutation"></net-select-salutation>
          </mat-form-field> -->

          <mat-form-field fxFlex>
            <mat-label>{{'CONTACT.NAME' | translate}}</mat-label>
            <input formControlName="firstName" netUpperCase matInput>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{'CONTACT.SURNAME' | translate}}</mat-label>
            <input formControlName="lastName" netUpperCase matInput>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <!-- <mat-form-field fxFlex>
            <mat-label>{{'CONTACT.CUSTOMER' | translate}}</mat-label>
            <input matInput disabled [value]="customer.name" *ngIf="form.get('customer').disabled">

            <net-select-account formControlName="account"
              *ngIf="(form.get('customer').enabled && !contact) || (form.get('customer').enabled && form.get('account').value && contact?.contactId)">
            </net-select-account>
            <input matInput formControlName="account" *ngIf="!form.get('account').value && contact?.contactId" style="display: none" >
          </mat-form-field> -->

          <mat-form-field fxFlex>
            <mat-label>{{'CONTACT.CUSTOMER' | translate}}</mat-label>
            <net-select-account formControlName="account" [type]="contact ? 'contact': ''" [contact]="contact"
              class="customer-name-zone" [isClickable]="!!contact?.contactId"></net-select-account>
          </mat-form-field>

          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.JOB_TITLE)">
            <mat-label>{{'CONTACT.TITLE' | translate}}</mat-label>
            <input formControlName="jobTitle" matInput
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.JOB_TITLE)">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.CLEARANCE_LEVEL)">
            <mat-label>{{'CONTACT.CLEARANCE_LEVEL' | translate}}</mat-label>
            <net-select-clearance *ngIf="hasClearance" formControlName="clearanceLevel"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.CLEARANCE_LEVEL)"></net-select-clearance>
            <input *ngIf="!hasClearance" matInput type="text" [disabled]="true" readonly
              [value]="contact.clearenceLevel">
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.CONTACT_ROLE_GROUP_ID)">
            <mat-label>{{'CONTACT.CONTACT_TYPE' | translate}}</mat-label>
            <net-select-contact-role-group formControlName="contactRoleGroup"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.CONTACT_ROLE_GROUP_ID)"></net-select-contact-role-group>
          </mat-form-field>
        </div>

        <mat-form-field *ngIf="getStaticFieldActiveFlag(staticFieldEnum.ADDRESS_ID)">
          <mat-label>{{'CONTACT.ADDRESS' | translate}}</mat-label>
          <net-input-customer-address formControlName="address" [checkPermissions]="permissionControl"
            [customer]="form.get('account').value" [isDisabled]="!form.get('account').value"
            [required]="getStaticFieldRequiredFlag(staticFieldEnum.ADDRESS_ID)">
          </net-input-customer-address>
        </mat-form-field>

        <div *ngIf="hasClearance" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.EMAIL_CONTACT)" class="h-fit"
            floatLabel="always">
            <mat-label>{{'CONTACT.EMAIL' | translate}}</mat-label>
            <net-input-customer-emails formControlName="emails" [customer]="form.get('customer').value"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.EMAIL_CONTACT)">
            </net-input-customer-emails>
          </mat-form-field>

          <mat-form-field fxFlex *ngIf="getStaticFieldActiveFlag(staticFieldEnum.PHONE_CONTACT)" class="h-fit"
            floatLabel="always">
            <mat-label>{{'CONTACT.PHONE' | translate}}</mat-label>
            <net-input-customer-phones type="contact" formControlName="phones" [customer]="form.get('customer').value"
              [required]="getStaticFieldRequiredFlag(staticFieldEnum.PHONE_CONTACT)">
            </net-input-customer-phones>
          </mat-form-field>
        </div>

        <div *ngIf="getStaticFieldActiveFlag(staticFieldEnum.CONTACT_SPECIAL_DAY)">
          <mat-label class="special-day-label">{{'CONTACT.SPECIAL_DAYS' | translate}}</mat-label>
          <net-input-special-day formControlName="specialDays" (invalidForm)="updateSpecialDaysValidator($event)"
            [required]="getStaticFieldRequiredFlag(staticFieldEnum.CONTACT_SPECIAL_DAY)"></net-input-special-day>
        </div>

        <mat-form-field *ngIf="getStaticFieldActiveFlag(staticFieldEnum.CONTACT_HASHTAG)">
          <mat-label>{{'CONTACT.HASHTAGS' | translate}}</mat-label>
          <net-input-hashtag formControlName="hashtags" [type]="inputHashtagType.CONTACT"
            [required]="getStaticFieldRequiredFlag(staticFieldEnum.CONTACT_HASHTAG)"></net-input-hashtag>
        </mat-form-field>

        <net-input-dynamic-field-values [columns]="3" [entityId]="dynamicFieldEntityIds.CONTACT"
          [entityName]="DynamicFieldEntityAsString.CONTACT" formControlName="dynamicFieldValues"
          [mode]="!contact ? 'add' : 'edit'" [openSeparator]="openSeparator"
          [uploadType]="'Account'"></net-input-dynamic-field-values>

        <mat-form-field *ngIf="getStaticFieldActiveFlag(staticFieldEnum.DESCRIPTION_CONTACT)"
          class="ck-editor-overflow">
          <mat-label>{{'CONTACT.DESCRIPTION' | translate}}</mat-label>
          <net-input-editor-ck formControlName="description" [showToolbar]="hasClearance" [type]="'Account'"
            class="w-full"
            [required]="getStaticFieldRequiredFlag(staticFieldEnum.DESCRIPTION_CONTACT)"></net-input-editor-ck>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="contact === undefined">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex class="textarea" appearance="line"></ngx-skeleton-loader>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #action>
    <p class="text-hint text-xs">{{'GENERAL.FIELDS_MARKED_WITH_*_ARE_REQUIRED' | translate}}</p>
    <p class="alert alert-icon alert-warning alert-rejection-info mb-4 ml-auto" *ngIf="!hasClearance">
      <net-iconify-icon [icon]="icWarning"></net-iconify-icon>
      <span>{{'CONTACT.NOT_AUTHORIZED_TO_UPDATE_CONTACT' | translate}}</span>
    </p>
    <net-progress-button class="ml-auto" *ngIf="contact !== undefined && hasClearance" [icon]="icSave"
      [active]="loading" (btnClick)="onSubmit()" text="{{'GENERAL.SAVE' | translate}}"></net-progress-button>
  </ng-template>

  <ng-template [ngIf]="isDialog">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0" fxFlex="auto">
        <span *ngIf="contact">{{ contact.firstName }} {{ contact.lastName }}</span>
        <span *ngIf="!contact">{{'CONTACT.NEW_CONTACT' | translate}}</span>
      </h2>

      <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </mat-dialog-actions>
  </ng-template>
  <ng-template [ngIf]="isPage">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <div fxLayout="row" fxLayoutAlign="start center" class="w-full">
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </div>
  </ng-template>
</form>