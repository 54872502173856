import moment from 'moment';

export function dateFormat(format: string, input: Date | string, locale = null): string {
  // Create moment instance from input
  const date = moment(input);

  // Make sure the date is valid
  if (!date.isValid()) {
    return 'Invalid Date';
  }

  // If locale is set, update locale
  if (locale) {
    date.locale(locale);
  }

  // If format is long, update format
  if ('long' === format) {
    format = 'DD MMMM YYYY HH:mm:ss';
  }

  return date.format(format);
}

export function getDurationString(seconds: number, translations: { days: string, hours: string, minutes: string }, roundMinute = false) {
  const duration = moment.duration(seconds, 'seconds');

  const d = duration.days();
  const h = duration.hours();
  const m = duration.minutes();

  if (d === 0 && h === 0 && m === 0) {
    return `${roundMinute ? '1' : '0'}${translations.minutes}`;
  }

  // Components array to hold different parts of the formatted string
  const components = [];

  // Add days component if days are more than 0
  if (d > 0) {
    components.push(`${d}${translations.days}`);
  }

  // Add hours component if hours are more than 0 or days are more than 0 (to ensure "0h" is included if there are days but no hours)
  if (h > 0 || d > 0) {
    components.push(`${h}${translations.hours}`);
  }
  if (m > 0) {
    // Always add minutes component as we always want to show minutes
    components.push(`${m}${translations.minutes}`);
  }

  // Join the components with a space and return
  return components.join(' ');
}

// example inputs: 00:50:39.1297187, 00:00:00, 133:45:36
export function timespanToSeconds(timespanString: string) {
  const [hours, minutes, seconds] = timespanString.split(':');

  const h = parseInt(hours, 10);
  const m = parseInt(minutes, 10);
  const s = parseFloat(seconds);

  const totalSeconds = (h * 3600) + (m * 60) + s;

  return totalSeconds;
}
