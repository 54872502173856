<div fxLayout="row" fxLayoutGap="24px">
  <mat-form-field class="grouped-zone">
    <div class="select-purpose">
      <mat-select (selectionChange)="onSelectPurpose($event)" [compareWith]="compareWith" [disabled]="isDisabled"
        [formControl]="purposeCtrl" [placeholder]="'ACTIVITY.SELECT_ACTIVITY_PURPOSE' | translate">
        <mat-option *ngFor="let activityPurpose of activityPurposes"
          [disabled]="(activityPurposeType.NEW_CUSTOMER === activityPurpose.activityPurposeId && hasActiveOpportunity) ||
                    (type === activityDialogTypeEnum.LEAD_DRAFT && activityPurposeType.NEW_OPPORTUNITY === activityPurpose.activityPurposeId)" [value]="activityPurpose">
          {{ ("ActivityPurpose." + activityPurpose.name) | translate }}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>

  <div fxFlex [class.disabled]="type === activityDialogTypeEnum.LEAD_DRAFT">
    <mat-chip-grid #chipList #origin="matAutocompleteOrigin"
      [disabled]="isDisabled || type === activityDialogTypeEnum.LEAD_DRAFT" matAutocompleteOrigin>
      <mat-chip-row (removed)="onRemoveOpportunity(opportunity)" *ngFor="let opportunity of value.opportunities"
        [removable]="true">
        {{ opportunity.name }}
        <net-iconify-icon [icon]="icCancel" matChipRemove width="1.45em" height="1.45em"></net-iconify-icon>
      </mat-chip-row>
      <input #input (focus)="reset()" (matChipInputTokenEnd)="onAddToken($event)" [formControl]="opportunityCtrl"
        [matAutocompleteConnectedTo]="origin" [matAutocomplete]="auto" [matChipInputAddOnBlur]="true"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matTooltipDisabled]="!!value.activityPurpose"
        [matTooltip]="'ACTIVITY.PLEASE_SELECT_ACTIVITY_PURPOSE_FIRST' | translate"
        [placeholder]="value.opportunities.length === 0 ? ('ACTIVITY.YOU_CAN_SELECT_RELATED_OPPORTUNITIES' | translate) : ''"
        [readOnly]="!value.activityPurpose || type === activityDialogTypeEnum.LEAD_DRAFT">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectOpportunity($event)"
      [autoActiveFirstOption]="filteredOpportunities.length > 0">
      <mat-option *ngIf="filteredOpportunities.length === 0" disabled="true">
        {{"OPPORTUNITY.OPPORTUNITY_NOT_FOUND" | translate}}
      </mat-option>
      <ng-container *ngIf="!loading">
        <mat-option *ngFor="let opportunity of filteredOpportunities"
          [disabled]="isDisabled || type === activityDialogTypeEnum.LEAD_DRAFT" [value]="opportunity">{{
          opportunity.name }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </div>
</div>

<button (click)="add()" *ngIf="!disabled && !!value.activityPurpose && type !== activityDialogTypeEnum.LEAD_DRAFT"
  [disabled]="isDisabled" [hidden]="this.loading" class="add-opportunity" mat-icon-button type="button">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>

<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>