import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CustomerExperienceChannel } from '@core/api';

@Pipe({
    name: 'channelItems',
    standalone: true
})
export class SelectCustomerExperienceChannelItemsPipe implements PipeTransform {
    transform(list: CustomerExperienceChannel[], formControl: UntypedFormControl, mode: 'add' | 'edit', multiple: boolean) {
        if (list.length === 0 || !list) {
            return [];
        }

        return list.filter(item => {
            if (mode === 'add') {
                return item.activeFlag;
            } else if (mode === 'edit') {
                return (multiple ? formControl.value?.includes(item.cxChannelId) : formControl.value === item.cxChannelId) || item.activeFlag;
            }
        });

    }
}
