@if (showTitle) {

<button (click)="$event.stopPropagation();" [matMenuTriggerFor]="columnFilterMenu" fxFlex="none" mat-button
  [matTooltip]="'GENERAL.FILTER_COLUMNS' | translate" type="button">
  <net-iconify-icon [icon]="icFilterList"></net-iconify-icon>
  <span class="ml-2 font-normal">{{'GENERAL.FILTER_COLUMNS' | translate}}</span>
</button>

} @else {

<button (click)="$event.stopPropagation();" [matMenuTriggerFor]="columnFilterMenu" fxFlex="none" mat-icon-button
  [matTooltip]="'GENERAL.FILTER_COLUMNS' | translate" type="button">
  <net-iconify-icon [icon]="icFilterList"></net-iconify-icon>
</button>

}

<mat-menu class="table-filter" #columnFilterMenu="matMenu" xPosition="before" yPosition="below"
  (close)="onFilterMenuClose()">
  <div cdkDropList class="table-filter-list" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let column of columns; let last = last">
      <button *ngIf="!column.hiddenColumnFilterList" cdkDrag>
        <div class="table-filter-box">
          <net-iconify-icon color="#6f7782" customClass="drag-icon mr-2" size="16" [icon]="icDrag"
            *ngIf="isOrderActive">
          </net-iconify-icon>
          <mat-checkbox class="table-filter-checkbox cursor-pointer" [class.border-none]="last"
            [matTooltip]="'GENERAL.TO_CHANGE_THE_VISIBILITY_OF_THE_COLUMN_THE_FILTER_MUST_BE_REMOVED' | translate"
            [matTooltipDisabled]="!column.isFilterActive || column.isSwitchActive" (click)="$event.stopPropagation()"
            [disabled]="!!column.isFilterActive" [(ngModel)]="column.visible" (ngModelChange)="visibleChanged = true"
            color="primary">
            {{ column.label }}
          </mat-checkbox>
        </div>
      </button>
    </ng-container>
  </div>
</mat-menu>