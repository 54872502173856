

import { Directive, Input, OnDestroy } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Directive({
  selector: '[netAutocompletePosition]',
  standalone: true
})
export class AutocompletePositionDirective implements OnDestroy {
  private matAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() set netAutocompletePosition(value: MatAutocompleteTrigger) {
    this.matAutocompleteTrigger = value;
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  private scrollEvent = (): void => {
    if (this.matAutocompleteTrigger == null) {
      return;
    }
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }
}
