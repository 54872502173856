import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { UpdatePasswordRequest } from '@core/auth/auth.model';
import { AuthService } from '@core/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-toolbar-user-change-password',
  templateUrl: './toolbar-user-change-password.component.html',
  styleUrls: ['./toolbar-user-change-password.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class ToolbarUserChangePasswordComponent {
  form: UntypedFormGroup;
  loading = false;
  icSave = Icon.IC_SAVE;
  icHelp = Icon.IC_HELP;
  matcher = new RepeatPasswordEStateMatcher();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.initForm();
  }

  initForm() {
    const passwordPattern = '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}';

    this.form = this.formBuilder.group({
      oldPassword: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.pattern(passwordPattern)]],
      confirmPassword: [null],
    }, { validators: this.checkPasswords });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.loading = true;

      const request: UpdatePasswordRequest = {
        oldPassword: this.form.get('oldPassword').value,
        password: this.form.get('password').value
      };
      this.auth.updatePassword(request).subscribe(
        (response) => {
          this.loading = false;
          this.dialog.closeAll();
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }
  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notSame: true };
  }
}
export class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.parent.get('password').value !== control.parent.get('confirmPassword').value && control.dirty);
  }
}
