import { BoardInfoSignalRService, BoardDetailFilterService, BoardGroupType, BoardViewType } from '@core/api';
import * as signalR from '@microsoft/signalr';

export class BoardReportSignalR {

    groupName: string;
    viewName: string;

    hubConnection: signalR.HubConnection;

    constructor(
        private boardInfoSignalRService: BoardInfoSignalRService,
        private boardDetailFilterService: BoardDetailFilterService,
        protected boardId: string
    ) {
        this.groupName = BoardGroupType.Report;
        this.viewName = BoardViewType.ReportView;
        this.hubConnection = this.boardInfoSignalRService.getHubConnection();
        boardInfoSignalRService.listenJoinGroup();
        this.boardDetailFilterService.getViewNameSubject.next(this.viewName);

    }

    joinGroup() {

        this.boardInfoSignalRService.connection$.subscribe(connection => {

            if (this.hubConnection.state === signalR.HubConnectionState.Connected && connection) {
                this.hubConnection.invoke(BoardGroupType.JoinGroup, this.groupName, this.boardId);
            }

        });
    }

    removeFromGroup() {

        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            this.hubConnection.invoke(BoardGroupType.RemoveFromGroup, this.groupName, null);
            this.removeJoinedGroupList();
        }
    }

    removeJoinedGroupList() {
        this.boardInfoSignalRService.joinedGroupList = [];
    }
}
