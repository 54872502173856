import { Board, BoardResponse, BoardActionType } from '@core/api';
import { Store } from '@ngrx/store';
import { SetBoardDetailHashtag, SetBoardDetailSystemUser } from '@core/store';

export function setBoardProperties(boardsResponse: BoardResponse<Board>[], board: Board, store: Store) {

  if (Array.isArray(boardsResponse)) {
    boardsResponse.map(boardResponse => {
      setBoardPropertiesLogic(boardResponse, board, store);
    });
  } else {

    setBoardPropertiesLogic(boardsResponse, board, store);
  }
  return board;
}

export function setBoardPropertiesLogic(boardResponse: BoardResponse<Board>, board: Board, store: Store) {

  switch (boardResponse.enumBoardActionType) {
    case BoardActionType.BoardNameUpdate:
      nameUpdate(boardResponse, board);
      break;
    case BoardActionType.BoardUserEdit:
      userEdit(boardResponse, board, store);
      break;
    case BoardActionType.BoardCustomFieldAdd:
      customFieldAdd(boardResponse, board);
      break;
    case BoardActionType.BoardCustomFieldDelete:
      customFieldDelete(boardResponse, board);
      break;
    case BoardActionType.BoardCustomFieldSetActiveFlag:
      customFieldSetActiveFlag(boardResponse, board);
      break;
    case BoardActionType.BoardHashtagAdd:
      hashtagAdd(boardResponse, board, store);
      break;
    case BoardActionType.BoardHashtagUpdate:
      hashtagUpdate(boardResponse, board, store);
      break;
    case BoardActionType.BoardCardAddRange:
      cardAddRange(boardResponse, board);
      break;
    case BoardActionType.BoardSetAdmin:
      setAdmin(boardResponse, board, store);
      break;
    case BoardActionType.BoardGetCardCount:
      setCardCount(boardResponse, board);
      break;
  }
}

export function nameUpdate(boardResponse: BoardResponse<Board>, board: Board) {
  boardResponse.data.map(_board => {
    board.name = _board.name;
    board.description = _board.description;
  });
}

export function userEdit(boardResponse: BoardResponse<Board>, board: Board, store: Store) {
  boardResponse.data.map(_board => store.dispatch(new SetBoardDetailSystemUser(_board.boardSystemUsers)));
}

export function customFieldAdd(boardResponse: BoardResponse<Board>, board: Board) {

}

export function customFieldDelete(boardResponse: BoardResponse<Board>, board: Board) {

}

export function customFieldSetActiveFlag(boardResponse: BoardResponse<Board>, board: Board) {

}

export function hashtagAdd(boardResponse: BoardResponse<Board>, board: Board, store: Store) {
  boardResponse.data.map(_board => store.dispatch(new SetBoardDetailHashtag(_board.boardHashtags)));
}

export function hashtagUpdate(boardResponse: BoardResponse<Board>, board: Board, store: Store) {
  boardResponse.data.map(_board => store.dispatch(new SetBoardDetailHashtag(_board.boardHashtags)));

  // TODO: update all hashtags related board and to sort them
  // boardResponse.data.map(_board => {
  //     console.log("Board")
  //     // Board Hashtags are central to the board
  //     const boardHashTags = _board.boardHashtags;
  //     const section = board.boardInfos;
  //     console.log(section);

  //     // _boardInfo.cards.map(card => {
  //     //     section.cards.push(card);
  //     //     ++section.cardCount;
  //     // });

  //     section.forEach(info => {
  // info.cards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
  //         info.cards.forEach(card => {
  //             card.cardHashtags.forEach(cardHashtag => {

  //             })
  //         })
  //     });
  // });
}

export function cardAddRange(boardResponse: BoardResponse<Board>, board: Board) {
  boardResponse.data.map(_board => {
    _board.boardInfos.map(_boardInfo => {
      const section = board.boardInfos.find(boardInfo => boardInfo.boardInfoId === _boardInfo.boardInfoId);
      _boardInfo.cards.map(card => {
        section.cards.push(card);
        ++section.cardCount;
        section.totalCardCount++;
      });

      section.cards.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
    });
  });
}

export function setAdmin(boardResponse: BoardResponse<Board>, board: Board, store: Store) {
  boardResponse.data.map(_board => {
    _board.boardSystemUsers.map(user => {
      const _user = board.boardSystemUsers.find(u => u.systemUserId === user.systemUserId);

      if (_user) {
        board.boardSystemUsers.find(u => u.systemUserId === user.systemUserId).isAdmin = user.isAdmin;
      } else {
        board.boardSystemUsers.push(user);
      }
    });
  });
  store.dispatch(new SetBoardDetailSystemUser(board.boardSystemUsers));
}

export function setCardCount(boardResponse: BoardResponse<Board>, board: Board) {
  boardResponse.data.map(_board => {
    board.totalCardCount = _board.totalCardCount;
    board.completedCardCount = _board.completedCardCount;
  });
}
