<input #input (blur)="onBlur()" (keydown)="onKeydown($event)" [formControl]="ctrl"
  [matAutocompleteDisabled]="isDisabled" [matAutocomplete]="auto" [matTooltipDisabled]="isDisabled"
  [matTooltip]="tooltip" [placeholder]="placeholder" [readonly]="readOnly || isDisabled" [tabIndex]="tabIndex"
  class="cursor-pointer" matInput netNumbersOnly type="text">
<net-iconify-icon (click)="!readOnly ? openPanel($event) : null" [icon]="icArrowDropDown" class="dropdown-icon"
  matSuffix></net-iconify-icon>
<mat-autocomplete #auto="matAutocomplete" (closed)="onClosed()" (optionSelected)="onSelect($event)"
  [panelWidth]="'auto'">
  <mat-option *ngFor="let item of options" [disabled]="isDisabled" [value]="item">
    {{ item}}
  </mat-option>
</mat-autocomplete>