import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ApiService, SystemRole } from '@core/api';
import { Subject } from 'rxjs';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';
import { SharedModule } from '@shared/modules';


@Component({
  selector: 'net-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectRoleComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectRoleComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SystemRole[]> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'multi-select-role';
  describedBy = '';
  stateChanges = new Subject<void>();
  isOpened = false;
  searchText = '';
  tooltip: string;
  filteredRoles: SystemRole[];
  filteredSystemRoles: SystemRole[];
  listRoles: SystemRole[];

  systemRoles: SystemRole[] = [];
  selectCtrl = new UntypedFormControl();
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  @Input() tabIndex;
  @Input() selectionWithId = false;
  @ViewChild('roleSelect') roleSelect: MatSelect;
  @HostBinding('id') id = `multi-select-role-${SelectRoleComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): SystemRole[] {
    return this.selectCtrl.value;
  }
  set value(value: SystemRole[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initDefaultOptions();
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.loading = true;
      this.api.systemRole.list({ pageSize: 1000 })
        .toPromise()
        .then((response) => {
          this.systemRoles = response.data.results.sort((a, b) => a.name.localeCompare(b.name));
          this.listRoles = this.systemRoles;
          this.filteredSystemRoles = this.systemRoles;
          if (this.selectionWithId) {
            const validIds: any = new Set(this.value);
            const filteredRoles = this.listRoles.filter(permission => validIds.has(permission.systemRoleId));
            this.value = filteredRoles;
          }
          this.loading = false;
        })
        .finally(() => resolve());
    });
  }


  onSelectionChange() {
    this.selectCtrl.patchValue(this.value);
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();
  }

  compareFn(first: SystemRole, second: SystemRole) {
    return first && second ? first.systemRoleId === second.systemRoleId : first === second;
  }

  selectAll() {
    this.selectCtrl.patchValue(this.systemRoles);
    this.onSelectionChange();
  }

  deselectAll() {
    this.selectCtrl.patchValue([]);
    this.onSelectionChange();
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.roleSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
    this.isOpened = isOpened;
    const value = '';
    this.onKey(value);
  }

  onKey(value) {
    this.searchText = value;
    this.filteredRoles = this.search(value);
    this.systemRoles = this.filteredRoles;
    this.filteredSystemRoles = this.systemRoles;
  }

  search(value: string) {
    const filter = replaceTurkishCharacters(value).toLocaleUpperCase();
    if (!filter) {
      return this.listRoles;
    }
    if (this.systemRoles.length < 1) {
      this.systemRoles = this.listRoles;
    }
    const roles = this.listRoles.filter((option) => option?.name &&
      replaceTurkishCharacters(option?.name?.toUpperCase()).includes(filter)
    );

    if (roles) {
      const selectedRoles = (this.value || []).map(item => {
        const isHave = roles.find(key => key.systemRoleId === item?.systemRoleId);
        if (!isHave) {
          return item;
        }

      });
      this.systemRoles = [...new Set([...roles, ...selectedRoles.filter(e => !!e)])];
    }

    return this.systemRoles;
  }

  onChange = (_: any) => {
  }

  selectionChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SystemRole[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }
  ngOnDestroy = () => this.stateChanges.complete();

}
