import {
    BoardInfoSignalRService, CardHubRequest, BoardHubRequest, BoardGroupType, BoardViewType,
    BoardDetailSignalR, BoardDetailFilterService, SocketSearchRequest
} from '@core/api';

export class BoardScrumSignalR extends BoardDetailSignalR {

    constructor(
        protected boardInfoSignalRService: BoardInfoSignalRService,
        protected boardDetailFilterService: BoardDetailFilterService
    ) {
        super(boardInfoSignalRService, boardDetailFilterService);
        this.groupName = BoardGroupType.Kanban;
        this.viewName = BoardViewType.KanbanView;

        this.boardDetailFilterService.getViewNameSubject.next(this.viewName);
    }

    loadBoardInfo(request: SocketSearchRequest) {
        return this.boardInfoSignalRService.invokeBoardInfos(this.viewName, request);
    }

    loadCard(request: SocketSearchRequest) {
        return this.boardInfoSignalRService.invokeCards(request);
    }

    boardDataInvoke(methodName: string, request: CardHubRequest | BoardHubRequest) {
        return this.boardInfoSignalRService.getHubConnection().invoke(methodName, request);
    }
}
