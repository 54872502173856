export enum DocumentPageType {
  ACTIVITY = 1,
  OPPORTUNITY = 2,
  ACCOUNT = 3,
  CUSTOMER = 4,
  PRICE_LIST = 5,
}

export enum DocumentTypeOption {
  PRICE_LIST = '383729b6-fe5e-4eef-8329-34367679d339',
  ADDITIONAL = 'cf685ed7-952a-4f50-8451-d2e83256f5f8',
  OPPORTUNITY = '2ba4557e-62b7-4727-ab8b-1ba9901fb135',
  CONTRACT = 'aa6dbc05-765f-48f3-93c0-0c01a1405ccd',
  SPECIAL_CONTRACT = '5c5c1bd6-e2d5-4477-8b06-dbc098ac7aeb',
  CARD = 'e7f63f82-569c-444c-9050-b3cb9c510c68',
}

export enum DocumentTypeEnum {
  CommercialDocuments = '8a1f9003-4088-4270-b9ca-93e7460a1397',
  SignatureCircus = '7cc2a94c-7e31-4bca-9f69-842ca96ec9e7',
  TaxPlate = '77c718dd-0591-489b-9f55-1093f9a63f69',
  OfficialNewspaper = '53893541-67d5-496c-a79d-91ccd4bd2b88',
  CommercialRegistration = '58bf1e72-0d8c-4ea9-a83c-509873fafda5',

  ContractAndProposalDocuments = '8633f975-7b76-404a-8778-68c8c6d89efa',
  FinancialInquiryRegistration = '2efa6d68-b651-43a7-8980-f35651103ee4',
  CurrentCardForm = '82292b36-fef8-4dda-be4a-9141ae4aaa83',
  Offer = '9833fe90-86e5-47d9-9292-84767a07bcce',
  Contract = '7ad29ef4-e556-4e12-a6ef-06691bb57923',

  Other = 'fd415884-fc8d-4f23-a38a-7deb853ea4ba',
  SIGNED_CONTRACT = 'ae394ec2-cd95-428f-a2b8-13ed553c5e36',
  SIGNED_PRICE_LIST = '15f20c2d-58cc-4c2b-85a3-92bb0e309b9e',
  ASSIGNMENT = '8ab2a94c-7e31-4bca-9f69-842ca96ec9e8',
  ADDITIONAL_DOCUMENT = 'cf685ed7-952a-4f50-8451-d2e83256f5f8',
  CUSTOMER_DOCUMENT = '65a9d502-9874-48a4-a7cd-194476816523',

  // Custom Documents
  OPP_DOC_ONE = '28d31fea-444a-4548-b805-2574f57bac97',
  OPP_DOC_TWO = '65a9d502-9874-48a4-a7cd-194476816523',
  OPP_DOC_THREE = '63b2102e-20ac-4209-a187-17a4df269cf9',
  OPP_DOC_FOUR = 'e2f49f0c-6b7d-4086-9eb6-66b23f34fdd9',

  CUS_DOC_ONE = '2e7ad35a-90d6-4fc5-80ca-75d18e2e9776',
  CUS_DOC_TWO = '575bb4b3-1a01-4c32-9237-2469d0f73f1e',
  CUS_DOC_THREE = '80f6797a-a735-4ff4-ba18-ee12eae5df08',
  CUS_DOC_FOUR = '72cade46-320a-46ea-b026-86241c16f215',
  CUS_DOC_FIVE = '2047da1b-367b-447b-b871-e2c85c3ec8cf'
}

export enum DocumentSettingDetailsType {
  ACTIVITY = 'Activity',
  OPPORTUNITY = 'Opportunity',
  ACCOUNT = 'Account',
}

export enum DocumentHistoryTpye {
  Insert,
  Delete,
}
