import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentIntervalType } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'shipmentProfileInterval',
  standalone: true
})
export class ShipmentProfileIntervalPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(type: ShipmentIntervalType | string): string {
    switch (type) {
      case ShipmentIntervalType.WEEK: return this.translate.instant('Interval.' + ShipmentIntervalType.WEEK);
      case ShipmentIntervalType.MONTH: return this.translate.instant('Interval.' + ShipmentIntervalType.MONTH);
      case ShipmentIntervalType.YEAR: return this.translate.instant('Interval.' + ShipmentIntervalType.YEAR);
    }

    return null;
  }

}
