import { Component, OnDestroy, OnInit } from '@angular/core';
import { Icon } from '@shared/enums';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SharedModule } from '@shared/modules';
import { PpBreadcrumbsService } from 'pp-breadcrumbs';

@Component({
  selector: 'net-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    SharedModule
  ]
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  icHome = Icon.IC_TWOTONE_HOME;

  constructor(public ppBreadcrumbs: PpBreadcrumbsService) {
  }

  ngOnInit() {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
