import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import { AttachmentService, CardAttachmentService, User } from '@core/api';
import { AuthService } from '@core/auth/auth.service';
import { CustomUploadAdapter } from './custom-upload-adapter';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { ImagePipe } from '../../pipes/image-pipe';
import {
  Bold, Essentials, ClassicEditor, Italic, Paragraph, Undo, BlockQuote, Alignment, Strikethrough, Heading, ListProperties, FontColor,
  FontBackgroundColor, FontSize, RemoveFormat, Link, Table, Image, ImageInsert, Underline,
  WordCount, TableCellProperties, TableProperties, TableToolbar, ImageResizeEditing, ImageResizeHandles,
  ImageToolbar
} from 'ckeditor5';
import { MentionCustomization } from '../input-user-mention/mention-customization';

@Component({
  selector: 'net-input-editor-ck',
  templateUrl: './input-editor-ck.component.html',
  styleUrls: ['./input-editor-ck.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputEditorCkComponent
    }
  ]
})
export class InputEditorCkComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | null> {
  static nextId = 0;

  public Editor = ClassicEditor;

  editor: ClassicEditor;
  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-editor';
  describedBy = '';
  stateChanges = new Subject<void>();
  user: User = JSON.parse(AuthService.getUser());

  writer: any;
  insertPosition: any;

  @Input() minHeight = null;
  @Input() isDisabled = false;
  loadingClass = '';
  @Input() cardId = '';
  @Input() type: string;
  @Input() autoHeight = false;
  @Input() debug: 'warn' | 'log' | 'error' | false = environment.production ? false : 'error';
  @Input() styles: any;
  @Input() customClass: string;
  @Input() showToolbar = true;
  @Input() limit = { active: false, textLimit: 99999 };
  @Output() editorLength = new EventEmitter<number>();
  @Input() config = {
    extraPlugins: [MentionCustomization],
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'bold', 'italic', 'underline', 'strikethrough', 'blockQuote', 'alignment', '|',
        'heading', '|',
        'numberedList', 'bulletedList', '|',
        'fontColor', 'fontBackgroundColor', 'fontSize', 'removeFormat', '|',
        'insertTable', '|',
        'link', 'ImageUpload', '|',
        'undo', 'redo'
      ]
    },
    plugins: [
      Bold, Essentials, Italic, Paragraph, Undo, BlockQuote, Alignment, Strikethrough, Image, Underline, TableCellProperties, TableProperties,
      Heading, ListProperties, FontColor, FontBackgroundColor, FontSize, RemoveFormat, Table, Link, ImageInsert, WordCount, TableToolbar, ImageResizeEditing, ImageResizeHandles, ImageToolbar
    ], table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties']
    },
    wordCount: {
      onUpdate: stats => {
        if (this.limit.active && stats.characters > this.limit.textLimit) {
          // this.value = this.value.slice(0, this.limit.textLimit);
          // event.editor.deleteText(this.limit.textLimit, event.editor.getLength());
          // this.editorComponent.editorInstance.setData(this.editorComponent.editorInstance.getData().slice(0, this.limit.textLimit));
        }
        this.editorLength.emit(stats.characters);
      }
    }
  };

  @Input() set selectedVariable(value: string) {
    if (!value) {
      return;
    }

    if (!this.writer) {
      this.change(` ${value}`);
    }

    this.insertPosition = this.editor.model.document.selection.getFirstPosition();


    this.editor.model.insertContent(this.writer.createText(` ${value}`), this.insertPosition);

  }

  @Output() editorck = new EventEmitter<ClassicEditor>();
  @HostBinding('id') id = `input-editor-${InputEditorCkComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;
  @ViewChild('ckEditor') editorComponent: CKEditorComponent;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private attachmentService: AttachmentService,
    private cardAttachmentService: CardAttachmentService,
    private imagePipe: ImagePipe,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    // tslint:disable-next-line:only-arrow-functions
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _tag: string;

  @Input()
  get tag(): string {
    return this._tag;
  }

  set tag(value: string) {
    this._tag = value;
  }

  private _placeholder = '';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }

  private _value: string | null;

  @Input()
  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {

    if (value === this.value) {
      return;
    }
    const newValue = value ?? ' ';
    this._value = newValue;
    this.onChange(newValue);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return true;
  }

  get empty() {
    return !this.value;
  }

  onReady(editor: ClassicEditor) {
    this.editor = editor;
    this.editorck.emit(this.editor);
    if (!this.showToolbar) {
      return;
    }
    const fileRepository = editor.plugins.get('FileRepository');

    fileRepository.createUploadAdapter = loader => {
      return new CustomUploadAdapter(this.attachmentService, this.cardAttachmentService, loader, this.type, this.cardId);
    };

    const element = document.createElement('div');
    element.innerHTML = this.value;
    const imageElements = Array.from(element.querySelectorAll('img'));

    if (!imageElements || imageElements.length === 0) {
      return;
    }

    this.loadingClass = 'skeleton';
    this.value = element.innerHTML;
    Array.from(element.querySelectorAll('img')).forEach(img => {
      if (img.src.startsWith('http')) {
        this.loadingClass = '';
        this.value = element.innerHTML;
        return;

      }
      const attachmentId = img.alt;
      if (attachmentId) {
        this.imagePipe.transform(environment.apiUrl + `/api/Attachment/GetPicMedia?attachmentId=${attachmentId}`).subscribe(value => {
          img.src = value;
          this.loadingClass = '';
          this.value = element.innerHTML;
        });
      }
    });
  }

  change(event: string) {

    if (!event) {
      this._value = '';
      this.onChange(this._value);
      this.stateChanges.next();
      return;
    }

    this._value = event;
    this.onChange(this._value);
    this.stateChanges.next();

    this.editor.model.change(writer => {
      this.writer = writer;
    });

    const element = document.createElement('div');
    element.innerHTML = event;
    const imageElements = Array.from(element.querySelectorAll('img'));

    if (!imageElements || imageElements.length === 0) {
      return;
    }

    imageElements.forEach(img => {
      if (!img.src.includes('attachment/')) {
        return;
      }

      this.loadingClass = 'skeleton';
      this.value = element.innerHTML;
      const attachmentId = img.src.split('attachment/')[1];

      if (!attachmentId) {
        return;
      }

      this.imagePipe.transform(environment.apiUrl + `/api/Attachment/GetPicMedia?attachmentId=${attachmentId}`).subscribe(value => {
        img.src = value;
        img.alt = attachmentId;
        this.loadingClass = '';

        if (!this.value?.endsWith('&nbsp')) {
          this._value = element.innerHTML + ' &nbsp';
        }

        this.editor.setData(this.value);
        this.editor.model.change(writer => {
          writer.setSelection(writer.createPositionAt(this.editor.model.document.getRoot(), 'end'));
        });
        document.getElementsByClassName('ck-content')[0].scrollTo(0, document.getElementsByClassName('ck-content')[0].scrollHeight);
      });
    });
  }


  textChanged(event) {
    if (this.limit.active && event.editor.getLength() - 1 > this.limit.textLimit) {
      event.editor.deleteText(this.limit.textLimit, event.editor.getLength());
    }
    this.editorLength.emit(event.editor.getLength() - 1);
  }

  focusEditor() {
    if (this.minHeight === '20px') {
      this.minHeight = '125px';
    }

    this.customClass = `${this.customClass} min-h-[${this.minHeight}]`;

  }

  onChange = (_: any) => {
  }
  onTouched = () => {
  }
  onContainerClick = () => {
  }
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: string | null) {
    if (value === this.value) {
      return;
    }
    this._value = value;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
