import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { ChartType, CustomerExperienceChartService } from '@core/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'net-select-customer-experience-chart',
  templateUrl: './select-customer-experience-chart.component.html',
  styleUrls: ['./select-customer-experience-chart.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCustomerExperienceChartComponent,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule
  ]
})
export class SelectCustomerExperienceChartComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-chart';
  describedBy = '';
  stateChanges = new Subject<void>();

  charts: { key: string, value: string }[] = [
    { key: ChartType.RESOLUTION_SLA, value: this.translate.instant('CX.ResolutionSLA') },
    { key: ChartType.RESOLUTION_SLA_OVERALL, value: this.translate.instant('CX.ResolutionSLAOverall') },
    { key: ChartType.FIRST_RESPONSE_SLA, value: this.translate.instant('CX.FirstResponseSLA') },
    { key: ChartType.FIRST_RESPONSE_SLA_OVERALL, value: this.translate.instant('CX.FirstResponseSLAOverall') },
    { key: ChartType.SLA, value: this.translate.instant('CX.SLA') }
  ].sort((a, b) => a.value.localeCompare(b.value));

  selectCtrl = new UntypedFormControl();
  searchText = '';

  @Input() multiple: boolean;
  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null { return this.selectCtrl.value; }
  set value(value: string | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: string;

  @ViewChild('chartSelect') categoriesSelect: MatSelect;

  @HostBinding('id') id = `select-chart-${SelectCustomerExperienceChartComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  // private initDefaultOptions(): Promise<void> {
  //   return new Promise<void>((resolve) => {
  //     this.chartService.search({
  //       page: 1,
  //       pageSize: 1000,
  //       filter: {
  //         defaultFlag: true
  //       }
  //     })
  //       .toPromise()
  //       .then((response) => {
  //         this.charts = response.data.results;

  //       })
  //       .finally(() => resolve());
  //   });
  // }

  constructor(
    private chartService: CustomerExperienceChartService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    // this.initDefaultOptions();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.categoriesSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
    this.selectCtrl.setValue(this.value);
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: string, two: string) => one && two && one === two;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: string) {
    this.value = value;
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
