import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { ActivityStatus, ActivityStatusService } from '@core/api';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-activity-status',
  templateUrl: './select-activity-status.component.html',
  styleUrls: ['./select-activity-status.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectActivityStatusComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectActivityStatusComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | null> {

  static nextId = 0;

  loading = true;
  focused = false;
  errorState = false;
  controlType = 'select-activity-status';
  describedBy = '';
  stateChanges = new Subject<void>();

  activityStatuses: ActivityStatus[] = [];
  mainActivityStatuses: ActivityStatus[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;
  @Input() isDisabled = false;
  @HostBinding('id') id = `select-activity-status-${SelectActivityStatusComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private activityStatusService: ActivityStatusService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _isCreate = false;

  @Input()
  get isCreate(): boolean {
    return this._isCreate;
  }

  set isCreate(value: boolean) {
    this._isCreate = coerceBooleanProperty(value);
    if (this._isCreate) {
      this.mainActivityStatuses = this.activityStatuses;
    } else {
      this.activityStatuses = this.mainActivityStatuses;
    }
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this.selectCtrl.value;
  }

  set value(value: string | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  ngOnInit() {
    this.fetchList();
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  compareWith = (one: string, two: string) => one && two && one === two;

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue = (value: string) => this.value = value;

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  private fetchList() {
    this.activityStatusService.list({}).subscribe(response => {
      if (response.success) {
        this.activityStatuses = response.data.results.sort((a, b) => (a.orderBy > b.orderBy) ? 1 : -1);
      }
    }).add(() => this.loading = false);
  }
}
