import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogData } from './alert-dialog.component.model';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    SharedModule
  ]
})
export class AlertDialogComponent {

  icClose = Icon.IC_ROUND_CLOSE;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData, public dialogRef: MatDialogRef<AlertDialogComponent>) {
    this.dialogRef.addPanelClass('alert-dialog-pane');
  }

}
