<button type="button" class="add-day" mat-icon-button (click)="add()" *ngIf="!disabled">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>
<ng-container [formGroup]="form">
  <div fxLayout="column" formArrayName="days" class="pt-[10px]">
    <div class="relative" fxLayout="row" fxLayoutGap="16px" fxFlex fxLayoutAlign="start center" [formGroupName]="index"
      *ngFor="let control of getDaysForm().controls; let index = index">

      <!-- <mat-form-field fxFlex="25" class="mr-6">
        <mat-select formControlName="type" placeholder="{{'GENERAL.TYPE' | translate}}" [compareWith]="compareWithType">
          <mat-option *ngFor="let type of types" [value]="type">{{ ("SpecialDayType." + type.name) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field fxFlex="20">
        <mat-label>{{'GENERAL.DATE' | translate}}</mat-label>
        <input matInput [placeholder]="'GENERAL.DATE' | translate" readonly formControlName="date"
          [matDatepicker]="datePicker" (click)="datePicker.open()">
        <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
      </mat-form-field>

      <mat-form-field fxFlex="1 1 calc(80% - 36px)">
        <mat-label>{{'GENERAL.DESCRIPTION' | translate}}</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>

      <button type="button" class="remove-day" fxFlex="20px" mat-icon-button (click)="remove(index)">
        <net-iconify-icon [icon]="icMinus" size="16"></net-iconify-icon>
      </button>
    </div>
  </div>
</ng-container>