import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountWarning, AccountWarningSearchFilter } from './account-warning.model';
import { Observable } from 'rxjs';
import { PagedResponse } from '../api.model';
import { environment } from '@env/environment';
@Injectable({
    providedIn: 'root',
})
export class AccountWarningService {
    constructor(
        private http: HttpClient
    ) {}

    search(request: AccountWarningSearchFilter): Observable<PagedResponse<AccountWarning>> {
        return this.http.post<PagedResponse<AccountWarning>>(environment.apiUrl + '/api/AccountWarning/Search', request);
    }

    accountWarningTrendChartData(uuid: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post(environment.apiUrl + '/api/AccountWarning/AccountWarningTrendChartData', JSON.stringify(uuid), {headers});
    }

    accountWarningTrendDoughnutChartData(uuid: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post(environment.apiUrl + '/api/AccountWarning/AccountWarningTrendDoughnutChartData', JSON.stringify(uuid), {headers});
    }

    getAccountWarningCount(uuid: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post(environment.apiUrl + '/api/AccountWarning/AccountWarningCount', JSON.stringify(uuid), {headers});
    }
}
