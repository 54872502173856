
import {
    BoardInfoSignalRService, CardHubRequest, BoardHubRequest, BoardGroupType, BoardViewType,
    BoardDetailSignalR, BoardDetailFilterService, SocketSearchRequest, CardPaginationType
} from '@core/api';

export class BoardTimeLineSignalR extends BoardDetailSignalR {

    constructor(
        protected boardInfoSignalRService: BoardInfoSignalRService,
        protected boardDetailFilterService: BoardDetailFilterService
    ) {
        super(boardInfoSignalRService, boardDetailFilterService);
        this.groupName = BoardGroupType.Timeline;
        this.viewName = BoardViewType.TimelineView;

        this.boardDetailFilterService.getViewNameSubject.next(this.viewName);
    }

    loadBoardInfo(request: SocketSearchRequest) {
        this.boardInfoSignalRService.invokeBoardInfos(this.viewName, request);
    }

    loadCard(request: SocketSearchRequest) {
        this.boardInfoSignalRService.invokeCards(request);
    }

    boardDataInvoke(methodName: string, request: CardHubRequest | BoardHubRequest) {
        this.boardInfoSignalRService.getHubConnection().invoke(methodName, request);
    }

    loadTimelineCards(request: SocketSearchRequest) {
        this.boardInfoSignalRService.invokeListCards(request, CardPaginationType.TimelineCardPagination);
    }
}
