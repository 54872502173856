<button (click)="onClickAdd()" [disabled]="disabled || isDisabled" [matTooltip]="'GENERAL.ADD' | translate"
  class="add-button" mat-icon-button type="button">
  <net-iconify-icon [icon]="icAdd" size="16"></net-iconify-icon>
</button>
<ng-container [formGroup]="form">
  <div class="input" formArrayName="activityAssignment" fxLayout="column">
    <div *ngFor="let control of getActivityAssignmentForm().controls; let index = index" @fadeInUp
      [formGroupName]="index" class="input-item" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="20" class="mr-16">
        <button [disabled]="isDisabled" fxFlex="5" [style.color]="getStatusColor(control.value)" class="activity-icon"
          color="primary" mat-icon-button>
          <net-iconify-icon [icon]="icCheckCircle" size="24"></net-iconify-icon>
        </button>

        <mat-form-field [fxFlex]="((100 / 3)*2) - 5">
          <mat-label>{{'GENERAL.TASK' | translate}}</mat-label>
          <input [readonly]="isDisabled" formControlName="description" matInput matInput>
        </mat-form-field>

        <mat-form-field fxFlex="auto">
          <mat-label>{{'GENERAL.DATE' | translate}}</mat-label>
          <input (click)="datePicker.open()" [disabled]="isDisabled" [matDatepicker]="datePicker" [min]="today"
            formControlName="dueDate" matInput readonly>
          <mat-datepicker #datePicker></mat-datepicker>
          <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20" class="mr-16">
        <mat-form-field fxFlex="auto">
          <mat-label>{{ 'TASK.PRIORITY' | translate}}</mat-label>
          <net-select-assignment-priority [isDisabled]="isDisabled" formControlName="assignmentPriorityId">
          </net-select-assignment-priority>
        </mat-form-field>

        <mat-form-field fxFlex="auto">
          <mat-label>{{'GENERAL.ASSIGNEE' | translate}}</mat-label>
          <net-select-user [isDisabled]="isDisabled" [required]="true"
            [salesOrganizationIds]="[account?.salesOrganizationId ?? leadDraftInfo.salesOrganizationId]"
            formControlName="assignedUser"></net-select-user>
        </mat-form-field>

        <mat-form-field fxFlex="auto">
          <mat-label>{{'GENERAL.STATUS' | translate}}</mat-label>
          <mat-select (selectionChange)="openDialog(control, index)" [disabled]="isDisabled"
            [placeholder]="'GENERAL.STATUS' | translate" formControlName="assignmentStatusId">
            <ng-container *ngIf="!control.value.id">
              <mat-option [value]="assignmentStatus.ASSIGNED">{{"AssignmentStatus.Assigned" | translate}}</mat-option>
            </ng-container>
            <ng-container *ngIf="control.value.id">
              <ng-container *ngFor="let status of assignmentStatuses">
                <mat-option *ngIf="checkStatusVisibility(status.key, index, control)" [value]="status.key">
                  {{ status.value }}
                </mat-option>
              </ng-container>
            </ng-container>
            <!-- <ng-container *ngIf="control.value.id">
              <mat-option [value]="control.value?.assignmentStatus?.assignmentStatusId"
                *ngIf="control?.value?.assignmentStatus">
                {{("AssignmentStatus." + control.value.assignmentStatus.name) | translate}}</mat-option>
              <ng-container *ngIf="control.value.assignmentStatus.assignmentStatusId === assignmentStatus.ASSIGNED">
                <mat-option
                  *ngIf="value && value.length && value[index].assignedUser && activeUser.userId === value[index].assignedUser.systemUserId || (activeUser.userId === value[index].assignedUser.systemUserId && activeUser.userId === control.value.actionUser.systemUserId)"
                  [value]="assignmentStatus.COMPLETE">{{"AssignmentStatus.Completed" | translate}}</mat-option>
                <mat-option
                  *ngIf="value && value.length && value[index].assignedUser && activeUser.userId === value[index].assignedUser.systemUserId && activeUser.userId !== control.value.actionUser.systemUserId"
                  [value]="assignmentStatus.REJECTED">{{"AssignmentStatus.Rejected" | translate}}</mat-option>
                <mat-option
                  *ngIf="value && value.length && value[index].assignedUser && activeUser.userId === control.value.actionUser.systemUserId"
                  [value]="assignmentStatus.CANCEL">{{"AssignmentStatus.Cancelled" | translate}}</mat-option>
              </ng-container>
            </ng-container> -->
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20" class="mr-16">
        <net-input-dynamic-field-values fxFlex="auto" [assignmentStatusId]="control.value.assignmentStatusId"
          [entityName]="DynamicFieldEntityAsString.TASK" [entityId]="DynamicFieldEntityId.TASK"
          [updateValidations]="control.get('updateValidations').value" [isDisabled]="isDisabled" [columns]="2"
          formControlName="dynamicFieldValues" mode="edit"></net-input-dynamic-field-values>
      </div>

      <div *ngIf="control.value.explanation" class="explation" fxLayoutAlign="start center">
        <button [disabled]="isDisabled" mat-icon-button>
          <net-iconify-icon [icon]="icArrowRight" size="24"></net-iconify-icon>
        </button>
        <span>{{control.value.explanation}}</span>

        <button (click)="removeExplation(control)" *ngIf="!control.value.id" [disabled]="isDisabled"
          [matTooltip]="'GENERAL.REMOVE' | translate" class="remove-explanation-button" mat-icon-button>
          <net-iconify-icon [icon]="icRemove" size="14"></net-iconify-icon>
        </button>
      </div>

      <!-- Add / Remove Buttons -->

      <button (click)="onClickRemove(index)" *ngIf="!control.value.id" [disabled]="isDisabled" [disabled]="disabled"
        [matTooltip]="'GENERAL.REMOVE' | translate" class="remove-button add-remove-buttons" mat-icon-button
        type="button">
        <net-iconify-icon [icon]="icMinus" size="16"></net-iconify-icon>
      </button>

    </div>
  </div>
</ng-container>
<mat-progress-spinner *ngIf="loading" diameter="16" mode="indeterminate"></mat-progress-spinner>

<ng-template #assignmentDetailDialog>
  <h2 mat-dialog-title>{{"ACTIVITY.ASSIGNMENT_ADDITIONAL_INFORMATION" | translate}}</h2>
  <mat-dialog-content [formGroup]="assignmentDetailForm" class="assignment-detail-form pb-4" fxLayout="column">
    <mat-form-field>
      <mat-label>{{"GENERAL.DETAIL" | translate}}</mat-label>
      <textarea formControlName="explanation" matInput maxlength="180"></textarea>
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions class="items-start">
    <button (click)="closeDialog()" class="ml-auto mr-2" color="secondary" mat-button>{{"GENERAL.CANCEL" |
      translate}}</button>
    <net-progress-button (btnClick)="onSubmitDetail()" [active]="assignmentLoading" [icon]="icSave"
      [text]="'GENERAL.SAVE' | translate">
    </net-progress-button>
  </mat-dialog-actions>
</ng-template>