import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Component, DestroyRef, ElementRef, HostListener, OnDestroy, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventType, NavigationEnd, Router, Scroll } from '@angular/router';
import { Permission } from '@core/auth/auth.enum';
import { getSystemSettingValue, hasUserPermission } from '@core/store';
import { SystemSettingType } from '@core/api';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
  ActivityFormDialogComponent
} from '../activity-form-dialog/activity-form-dialog.component';
import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import {
  CustomerSearchDialogComponent
} from '../customer-search-dialog/customer-search-dialog.component';
import {
  CustomerSearchDialogResult
} from '../customer-search-dialog/customer-search-dialog.component.model';
import {
  OpportunityFormDialogComponent
} from '../opportunity-form-dialog/opportunity-form-dialog.component';
import { QuickActionButton } from './quick-action.component.model';
import { QuickActionComponentAnimations } from '@shared/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.scss'],
  animations: [QuickActionComponentAnimations],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class QuickActionComponent implements OnDestroy {

  icAdd = Icon.IC_TWOTONE_ADD;
  display = false;
  isActive = true;

  buttons: QuickActionButton[] = [];
  activeButtons: QuickActionButton[] = [];

  @HostListener('document:click', ['$event']) documentClick(event) {
    if (false === this.elementRef.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
    private translate: TranslateService
  ) {
    combineLatest([
      this.store.select(hasUserPermission(Permission.ACCOUNT_INSERT)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(hasUserPermission(Permission.ACTIVITY_INSERT)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(hasUserPermission(Permission.OPPORTUNITY_INSERT)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(hasUserPermission(Permission.CONTACT_INSERT)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(hasUserPermission(Permission.LEAD_DRAFT_INSERT)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(getSystemSettingValue(SystemSettingType.ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT)).pipe(takeUntilDestroyed(this.destroyRef))
    ]).subscribe(([account, activity, opportunity, contact, leadDraft, leadlessUsage]) => {
      this.buttons = [];

      if (activity) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.ACTIVITY'),
          icon: Icon.IC_TWOTONE_DATE_RANGE,
          action: () => this.openDialog(ActivityFormDialogComponent)
        });
      }

      if (opportunity) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.OPPORTUNITY'),
          icon: Icon.IC_TWOTONE_BUSINESS_CENTER,
          action: () => this.openDialog(OpportunityFormDialogComponent)
        });
      }

      if (contact) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.CONTACT'),
          icon: Icon.IC_TWOTONE_PERSON_PIN,
          action: () => this.openDialog(ContactFormDialogComponent)
        });
      }

      // If leadlessUsage is active, then show Customer field instead of Lead field
      if (leadlessUsage as boolean) {
        if (account) {
          this.buttons.push({
            label: this.translate.instant('GENERAL.CUSTOMER'),
            icon: Icon.IC_TWOTONE_SUPERVISED_USER_CIRCLE,
            action: () => this.openDialog(CustomerSearchDialogComponent)
          });
        }
      } else if (leadDraft) {
        this.buttons.push({
          label: this.translate.instant('GENERAL.LEAD'),
          icon: Icon.IC_TWOTONE_SUPERVISED_USER_CIRCLE,
          action: () => this.openLeadDraftInfoDialog()
        });
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
      map((event) => event instanceof Scroll ? event.routerEvent as NavigationEnd : event as NavigationEnd),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((result: NavigationEnd) => {
      this.isActive = result.url.includes('board/') ? false : true;
    });
  }

  private openDialog(dialog) {
    this.dialog
      .open(dialog, { autoFocus: false })
      .addPanelClass('cdk-overlay-full');
  }

  private openLeadDraftInfoDialog() {
    this.dialog.open(CustomerSearchDialogComponent)
      .afterClosed()
      .subscribe((result: CustomerSearchDialogResult) => {
        if (result) {
          let route = ['/leads-drafts/new'];

          if (result.found) {
            route = ['/leads-drafts', result.customer.leadDraft.leadDraftId];
          }

          this.router.navigate(route, { queryParams: { keyword: result.keyword } });
        }
      });
  }

  show() {
    this.display = true;
    this.activeButtons = this.buttons;
  }

  hide() {
    this.display = false;
    this.activeButtons = [];
  }

  toggle() {
    if (this.display) {
      this.hide();
    } else {
      this.show();
    }
  }

  onClick(button: QuickActionButton) {
    this.hide();
    button.action.call([]);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() { }
}
