<input [matTooltip]="tooltip" #input matInput [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
    [placeholder]="placeholder" autocomplete="off" (blur)="onBlur()">
<net-iconify-icon class="dropdown-icon" [ngClass]="{'disabled': disabled}" [icon]="icArrowDropDown" matSuffix
    (click)="openPanel($event)"></net-iconify-icon>
<mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [autoActiveFirstOption]="true"
    [displayWith]="displayName" (optionSelected)="onSelect($event)" (closed)="onClosed()">
    <mat-option *ngIf="offers && offers.length === 0" disabled>{{'GENERAL.NO_OFFER_FOUND' | translate}}</mat-option>
    <mat-option class="autocomplete-option" *ngFor="let offer of filteredOffers" [value]="offer">
        <div>{{ offer.code }} {{ ('Country.' + offer.originCountry.name) | translate}} - {{ ('Country.' +
            offer.destinationCountry.name) | translate}}</div>
        <div class="description">{{ offer.service?.name }}
            <span *ngIf="offer.offerTotalPrices"> -
                <ng-container *ngFor="let item of offer.offerTotalPrices;let last = last">
                    <span>
                        {{(item.price | currency: offer.transactionCurrency?.isoCurrencyCode:'symbol-narrow') |
                        currencyReplace }}
                    </span>
                    <span *ngIf="!last"> + </span>
                </ng-container>
            </span>
        </div>
    </mat-option>
</mat-autocomplete>
<mat-progress-spinner *ngIf="!!loading" diameter="16" mode="indeterminate"></mat-progress-spinner>