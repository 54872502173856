
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  PagedResponse,
} from '@core/api';
import { NoteLogs } from './note-logs.model';

@Injectable({
  providedIn: 'root'
})
export class NoteLogsService {

  constructor(private http: HttpClient) { }

  search(request: any): Observable<PagedResponse<NoteLogs>> {
    return this.http.post<PagedResponse<NoteLogs>>(environment.apiUrl + '/api/CustomerNoteLog/Search', request);
  }
}
