import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { OpportunityType, OpportunityTypeService } from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-opportunity-type',
  templateUrl: './select-opportunity-type.component.html',
  styleUrls: ['./select-opportunity-type.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectOpportunityTypeComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectOpportunityTypeComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-opportunity-type';
  describedBy = '';
  stateChanges = new Subject<void>();

  opportunityTypes: OpportunityType[] = [];
  selectCtrl = new UntypedFormControl();

  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null { return this.selectCtrl.value; }
  set value(value: string | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input()
  get accountId(): string { return this._accountId; }
  set accountId(value: string) {
    this._accountId = value;
  }
  private _accountId: string;

  @Input()
  get organizationId(): string { return this._organizationId; }
  set organizationId(value: string) {
    this._organizationId = value;
    if (value) {
      const filter = {
        filter: {
          accountId: this.accountId,
          organizationId: value
        }
      };

      this.opportunityTypeService.search(filter).subscribe((response) => {
        if (response.success) {
          this.opportunityTypes = response.data.results.map(type => {
            return { ...type, name: this.translate.instant('OpportunityType.' + type.name) };
          });

          const hasValue = this.opportunityTypes?.find(oppType => oppType.opportunityTypeId === this.value);
          if (!hasValue) {
            this.value = null;
          }

          if (response.data.results.length === 1) {
            this.value = response.data.results[0].opportunityTypeId;
          }
        }
      });
    }
  }
  private _organizationId: string;

  @HostBinding('id') id = `select-opportunity-type-${SelectOpportunityTypeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private translate: TranslateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private opportunityTypeService: OpportunityTypeService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
