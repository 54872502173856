<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" netContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <net-iconify-icon [icon]="icMenu"></net-iconify-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block" fxLayout="row"
    fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/image/layout/logo-icon.png">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">XOOI</h1>
  </a>

  <div class="-mx-1 flex">
    <net-breadcrumbs class="flex-auto"></net-breadcrumbs>
  </div>

  <span fxFlex></span>

  <div class="-mx-1 flex items-center">

    <button (click)="openSearch()" mat-icon-button type="button">
      <net-iconify-icon [icon]="icSearch" color="#5c78fd"></net-iconify-icon>
    </button>

    <!-- <button *ngIf="userGuideControl" routerLink="/user-guide" mat-icon-button type="button">
      <net-iconify-icon [icon]="icHelp" color="#5c78fd"></net-iconify-icon>
    </button> -->


    <div class="px-1">
      <div (click)="showPopover()">
        <button #originRef class="button" mat-icon-button type="button">
          <net-iconify-icon style="z-index:-1" color="#5c78fd" [icon]="icRecent"></net-iconify-icon>
        </button>
      </div>
    </div>

    <div class="px-1 notification">
      <net-toolbar-notifications></net-toolbar-notifications>
    </div>

    <div class="px-1">
      <net-toolbar-user></net-toolbar-user>
    </div>

  </div>
</div>

<net-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery">
</net-navigation>