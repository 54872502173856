import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';
import { provideIcons } from './core/icons/icons.provider';
import { provideLuxon } from './core/luxon/luxon.provider';
import { provideVex } from '@vex/vex.provider';
import { provideNavigation } from './core/navigation/navigation.provider';
import { vexConfigs } from '@vex/config/vex-configs';
import { provideQuillConfig } from 'ngx-quill';
import { provideMat, provideNet, provideTranslation } from '@shared/providers';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppMissingTranslationHandler, NotTranslatedService, TranslationService } from '@shared/services';
import { provideEffects } from '@ngrx/effects';
import { appEffects, appReducer } from '@core/store';
import { provideStore } from '@ngrx/store';
import { environment } from '@env/environment';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideToastr } from 'ngx-toastr';
import { GoogleMapsModule } from '@angular/google-maps';
import { registerLocaleData } from '@angular/common';

import localeTr from '@angular/common/locales/tr';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';
import { provideServiceWorker, ServiceWorkerModule } from '@angular/service-worker';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#2fbfcb',
  fgsPosition: 'center-center',
  fgsType: 'pulse',
  hasProgressBar: false
};

let googleTagManager = [
  GoogleTagManagerModule.forRoot({
    id: localStorage.getItem('googleTagManagerId')
  })
];

if (localStorage.getItem('googleTagManagerId') === '@TODO') {
  googleTagManager = [];
}

registerLocaleData(localeTr);
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeNl);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      MatBottomSheetModule,
      MatNativeDateModule,
      GoogleMapsModule,
      ...googleTagManager,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: TranslationService,
          deps: [HttpClient]
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: AppMissingTranslationHandler,
          deps: [NotTranslatedService]
        },
        isolate: false,
        extend: true
      }),
      NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
      NgxUiLoaderHttpModule.forRoot({
        showForeground: true,
        delay: 2000,
        maxTime: 20000,
        excludeRegexp: [
          '/api/Notification/GetActionNeededCounts',
          '/api/Board/SocketSearch',
          '/api/Report/ActivityReportTableData',
          '/api/Report/ActivityReportPurposeSummaryTableData',
          '/api/Report/ActivityReportSummaryTableData',
          '/api/Report/LeadsDraftsReportSummaryTableData',
          '/api/Report/LeadsDraftsReportTableData',
          '/api/Report/OffersReportSummaryTableData',
          '/api/Report/OffersReportTableData',
          '/api/Report/ActivityTrendDoughnutChartData',
          '/api/Report/OpportunityReportGroupedByStatusTrendChart',
          '/api/Report/OpportunityReportExpectedGiroChart',
          '/api/Report/OpportunityReportExpectedGiroByStatusChart',
          '/api/Report/OpportunityReportStatusChangeChart',
          '/api/Report/OpportunityReportTableData',
          '/api/Report/GainReportTrendChartData',
          '/api/Report/GainReportTableData',
          '/api/Report/OffersDoughnutChartData',
          '/api/AccountRevenue/GetWithProductionAccountCode',
          '/api/SystemUserPicture/GetPicture',
          '/api/MailIntegration/UploadFile',
          '/api/Attachment/DownloadMailIntegration'
        ]
      })),
    provideRouter(appRoutes,
      // TODO: Add preloading withPreloading(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })),
    provideNet(),
    provideMat(),
    provideAnimations(),
    // provideServiceWorker('ngsw-worker.js', { enabled: environment.production }),
    provideHttpClient(withInterceptorsFromDi()),
    provideVex({
      /**
       * The config that will be used by default.
       * This can be changed at runtime via the config panel or using the VexConfigService.
       */
      config: vexConfigs.zeus,
      /**
       * Only themes that are available in the config in tailwind.config.ts should be listed here.
       * Any theme not listed here will not be available in the config panel.
       */
      availableThemes: [
        {
          name: 'Default',
          className: 'vex-theme-default'
        },
        {
          name: 'Teal',
          className: 'vex-theme-teal'
        },
        {
          name: 'Green',
          className: 'vex-theme-green'
        },
        {
          name: 'Purple',
          className: 'vex-theme-purple'
        },
        {
          name: 'Red',
          className: 'vex-theme-red'
        },
        {
          name: 'Orange',
          className: 'vex-theme-orange'
        }
      ]
    }),
    provideNavigation(),
    provideTranslation(),
    provideToastr(),
    provideIcons(),
    provideLuxon(),
    provideQuillConfig({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['clean'],
          ['link', 'image']
        ]
      }
    }),
    provideEffects(appEffects),
    provideStore(appReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    !environment.production ? provideStoreDevtools() : []
  ]
};
