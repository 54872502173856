<mat-select #warningSelect [placeholder]="placeholder" [formControl]="selectCtrl" multiple
    (selectionChange)="onSelectionChange()" [compareWith]="compareWith" (openedChange)="openedChange($event)"
    (closed)="searchText = ''; filteredWarnings = warnings">

    <mat-select-trigger *ngIf="value?.length > 1" [matTooltipDisabled]="!(value.length > 1 && disabled)"
        [matTooltip]="getToolTip()" [matTooltipClass]="'warning-tooltip'" [matTooltipPosition]="'before'">
        {{'MAIL_LIST.WARNINGS_VALUE_SELECTED' | translate: {value:
        value?.length} }}
    </mat-select-trigger>

    <input (keydown)="$event.stopPropagation()" [(ngModel)]="searchText" (keyup)="onKey($event)"
        class="my-2 ml-4 border-0 outline-none w-full bg-card" maxlength="10"
        placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <div class="mat-option-button-group">
        <button mat-raised-button (click)="selectAll()">
            {{'GENERAL.SELECT_ALL' | translate }}
        </button>
        <button mat-raised-button (click)="deselectAll()">{{'GENERAL.REMOVE_ALL' |
            translate
            }}</button>
    </div>

    <mat-option *ngFor="let item of filteredWarnings" [value]="item.warningId">{{item.name}}</mat-option>

</mat-select>