import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { getUser } from '@core/store';
import { Store } from '@ngrx/store';


export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const auth = inject(AuthService);
  const store = inject(Store);
  const router = inject(Router);

  if (!auth.isAuthenticated()) {
    if (state.url !== '/auth/login' && state.url !== '/error-403') {
      localStorage.setItem('returnUrl', state.url);
    }

    router.navigate(['auth/login']);
    return false;
  }

  if (state.url !== '/auth/update-password') {
    store.select(getUser).subscribe(user => {
      if (user && (user.isPasswordExpired)) {
        router.navigate(['/auth/update-password']);
      }
    });
  }

  return true;
}
