import { ChangeDetectorRef, DestroyRef, Directive, HostBinding, inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VexConfigService } from '@vex/config/vex-config.service';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Directive({
  selector: '[netContainer]',
  standalone: true
})
export class ContainerDirective implements OnDestroy {

  @HostBinding('class.container') enabled: boolean;

  readonly destroyRef = inject(DestroyRef);

  constructor(private configService: VexConfigService,
    private cd: ChangeDetectorRef) {
    this.configService.config$.pipe(
      map(config => config.boxed),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(boxed => {
      this.enabled = boxed;
      this.cd.markForCheck();
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }
}
