import { Pipe, PipeTransform } from '@angular/core';
import { BoardDynamicFieldValue, Card, DynamicFieldTypeId } from '@core/api';
import moment from 'moment';

import { NormalizeDecimalSeparatorPipe } from './normalize-decimal-seperator.pipe';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dynamicFieldValue',
  standalone: true
})
export class DynamicFieldValuePipe implements PipeTransform {

  constructor(
    private normalizeDecimalSeparatorPipe: NormalizeDecimalSeparatorPipe,
    private translateService: TranslateService,
  ) { }

  transform(card: Card, dynamicFieldId: string, isTooltip = false) {
    const customField = card.dynamicFieldValues?.find(field => field.dynamicFieldId === dynamicFieldId);

    if (!isTooltip) {
      return this.fieldValueToString(customField);
    }

    if (isTooltip && customField) {
      return this.fieldValueToTooltip(customField);
    }

    return null;
  }

  private fieldValueToString(customField: BoardDynamicFieldValue): string {
    if (customField) {
      switch (customField.dynamicFieldTypeId) {
        case DynamicFieldTypeId.URL:
        case DynamicFieldTypeId.EMAIL:
        case DynamicFieldTypeId.PHONE:
        case DynamicFieldTypeId.RICH_TEXT:
        case DynamicFieldTypeId.MULTI_LINE_TEXT:
        case DynamicFieldTypeId.SINGLE_LINE_TEXT:
          return customField.textValue.length > 15 ? customField.textValue.substring(0, 15) + '..' : customField.textValue;

        case DynamicFieldTypeId.NUMBER:
        case DynamicFieldTypeId.DECIMAL:
        case DynamicFieldTypeId.CURRENCY:
          return this.formatNumberValue(customField.numberValue);

        case DynamicFieldTypeId.PERCENTAGE:
          return `${this.formatNumberValue(customField.numberValue)}%`;

        case DynamicFieldTypeId.DATE:
          return moment(customField.dateValue).format('DD.MM.YYYY');

        case DynamicFieldTypeId.DATETIME:
          return moment(customField.dateValue).format('DD.MM.YYYY HH:mm');

        case DynamicFieldTypeId.PICKLIST:
          if (customField.multipleValueFlag) {
            const joinedValues = customField.nameValues.map(item => this.translateService.instant(`Board_${customField.displayName}.${item.name}`)).join('; ');
            return joinedValues.length > 20 ? joinedValues.substring(0, 20) + '..' : joinedValues;
          }
          return customField.nameValue.name.length > 18 ? customField.nameValue.name.substring(0, 18) + '..' : customField.nameValue.name;
      }
    }
  }

  private fieldValueToTooltip(customField: BoardDynamicFieldValue): string {
    if (customField) {
      switch (customField.dynamicFieldTypeId) {
        case DynamicFieldTypeId.URL:
        case DynamicFieldTypeId.EMAIL:
        case DynamicFieldTypeId.PHONE:
        case DynamicFieldTypeId.SINGLE_LINE_TEXT:
          return customField.textValue;

        case DynamicFieldTypeId.NUMBER:
        case DynamicFieldTypeId.DECIMAL:
        case DynamicFieldTypeId.CURRENCY:
          return this.formatNumberValue(customField.numberValue);

        case DynamicFieldTypeId.PERCENTAGE:
          return `${this.formatNumberValue(customField.numberValue)}%`;

        case DynamicFieldTypeId.DATE:
          return moment(customField.dateValue).format('DD.MM.YYYY');

        case DynamicFieldTypeId.DATETIME:
          return moment(customField.dateValue).format('DD.MM.YYYY HH:mm');

        case DynamicFieldTypeId.PICKLIST:
          if (customField.multipleValueFlag) {
            return customField.nameValues.map(item => {
              return this.translateService.instant(`Board_${customField.displayName}.${item.name}`);
            }).join('\n');
          }
          return customField.nameValue.name;
      }
    }
  }

  private formatNumberValue(value: number | null): string {
    if (value === null) {
      return '';
    }

    return this.normalizeDecimalSeparatorPipe.transform(value);
  }
}
