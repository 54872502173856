import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';

@Pipe({
  name: 'customNumber',
  standalone: true
})
export class CustomNumberPipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }
  transform(value: any, options?: Intl.NumberFormatOptions): any {
    if (value === 0) {
      return 0;
    }
    if (!value) {
      return;
    }
    const numberLanguage = this.authService.numberLanguage;
    if (numberLanguage) {
      return new Intl.NumberFormat(numberLanguage, options).format(value);
    }
    return null;
  }

}
