import {
    BoardInfoSignalRService, CardActionType, BoardGroupType, CardCommentHubRequest, CardLinkRequest,
    CardAttachmentAddHubRequest, CardBoardInfoUpdateRequest, CardCopyRequest,
    CardDeleteRequest, CardDescriptionUpdateRequest, CardDueDateUpdateRequest, CardLabelsUpdateRequest, CardNameUpdateRequest,
    CardObjectsUpdateRequest, CardParentCardUpdateRequest, CardPropertiesUpdateRequest, CardRemainderDateUpdateRequest,
    CardStatusUpdateRequest, CardSystemUsersUpdateRequest, GetChildCardsRequest, SubCardOrderRequest
} from '@core/api';
import * as signalR from '@microsoft/signalr';

export class CardSignalR {

    private hubConnection: signalR.HubConnection;
    constructor(
        private boardInfoSignalRService: BoardInfoSignalRService
    ) {
        this.hubConnection = this.boardInfoSignalRService.getHubConnection();
    }

    JoinGroup(cardId: string) {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            this.hubConnection.invoke(BoardGroupType.JoinGroup, BoardGroupType.Card, cardId);
        }
    }

    removeFromGroup(cardId: string) {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            this.hubConnection.invoke(BoardGroupType.RemoveFromGroup, BoardGroupType.Card, cardId);
            this.removeJoinedGroupList();
        }
    }

    add(request: CardNameUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardAdd, request);
    }

    childCardAdded(request: CardNameUpdateRequest) {
        this.hubConnection.invoke(CardActionType.ChildCardAdd, request);
    }

    childCardSetOrder(request: SubCardOrderRequest[]) {
        this.hubConnection.invoke(CardActionType.ChildCardSetOrder, request);
    }

    updateName(request: CardNameUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardNameUpdate, request);
    }

    updateAssignedUser(request: CardSystemUsersUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardAssigneeUserUpdate, request);
    }

    convertToParentCard(request: CardParentCardUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardSubcardConvertToParentCard, request);
    }

    convertToSubcard(request: CardParentCardUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardParentCardConvertToSubcard, request);
    }

    updateStatus(request: CardStatusUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardSetComplete, request);
    }

    getCard(request: any) {
        this.hubConnection.invoke(CardActionType.CardGet, request);
    }

    getChildCards(request: GetChildCardsRequest) {
        this.hubConnection.invoke(CardActionType.GetChildCards, request);
    }

    updateOrder(request: SubCardOrderRequest[]) {
        this.hubConnection.invoke(CardActionType.CardSetOrder, request);
    }

    updateDueDate(request: CardDueDateUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardDueDateUpdate, request);
    }

    delete(request: CardDeleteRequest) {
        this.hubConnection.invoke(CardActionType.CardDelete, request);
    }

    updateDescription(request: CardDescriptionUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardDescriptionUpdate, request);
    }

    updateReminderDate(request: CardRemainderDateUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardReminderDateUpdate, request);
    }

    updateBoardInfo(request: CardBoardInfoUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardSectionChange, request);
    }

    updateHashtag(request: CardLabelsUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardHashtagUpdate, request);
    }

    updateCRMLink(request: CardObjectsUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardCRMLinkUpdate, request);
    }

    attachmentAdd(request: CardAttachmentAddHubRequest) {
        this.hubConnection.invoke(CardActionType.CardAttachmentAdd, request);
    }

    attachmentUpdate(request) {
        this.hubConnection.invoke(CardActionType.CardAttachmentUpdate, request);
    }

    attachmentDelete(request: CardAttachmentAddHubRequest) {
        this.hubConnection.invoke(CardActionType.CardAttachmentDelete, request);
    }

    linkAdd(request: CardLinkRequest) {
        this.hubConnection.invoke(CardActionType.CardLinkAdd, request);
    }

    linkDelete(request) {
        this.hubConnection.invoke(CardActionType.CardLinkDelete, request);
    }

    commentAdd(request: CardCommentHubRequest) {
        this.hubConnection.invoke(CardActionType.CardCommentAdd, request);
    }

    commentUpdate(request: CardCommentHubRequest) {
        this.hubConnection.invoke(CardActionType.CardCommentUpdate, request);
    }

    getAllComments(request: { boardInfoId: string, cardId: string }) {
        this.hubConnection.invoke(CardActionType.CardCommentGetAll, request);
    }

    commentDelete(request: CardCommentHubRequest) {
        this.hubConnection.invoke(CardActionType.CardCommentDelete, request);
    }

    getHistory(request: { cardId: string }) {
        this.hubConnection.invoke(CardActionType.CardHistoryGet, request);
    }

    updateDynamicfields(request: CardPropertiesUpdateRequest) {
        this.hubConnection.invoke(CardActionType.CardDynamicFieldUpdate, request);
    }

    updateDependencies(request: any) {
        this.hubConnection.invoke(CardActionType.CardDependencyUpdate, request);
    }

    removeJoinedGroupList() {
        this.boardInfoSignalRService.joinedGroupList = this.boardInfoSignalRService.joinedGroupList.filter(x => !x.startsWith(BoardGroupType.Card));
    }

    copyCard(request: CardCopyRequest) {
        this.hubConnection.invoke(CardActionType.CardCopy, request);
    }
}
