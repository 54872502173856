import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Response, ResultMessageTypes } from '@core/api/api.model';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

import { ToastService } from '../../shared/services/toast.service';
import { MessagesService } from '@shared/services';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  restrictedApis = ['Card/', 'SurveyAnswer/Unsubscribe'];

  constructor(
    private toastService: ToastService,
    private router: Router,
    private messageService: MessagesService,
    private translate: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Do not intercept API's auth requests
    if (request.url.match(AuthService.AUTH_REGEX)) {
      return next.handle(request);
    }

    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 0:
                this.toastService.error(this.translate.instant('GENERAL.PLEASE_CHECK_YOUR_INTERNET_CONNECTION'));
                break;

              case 401:
                // Do nothing on 401 request, its handled by auth interceptor
                break;

              case 400:
                this.toastService.error(this.translate.instant('GENERAL.AN_ERROR_OCCURRED_WHILE_PERFORMING_THE_REQUEST'));
                break;

              case 403:
                this.router.navigate(['error-403']);
                break;

              case 408:
              case 409:
                // Do nothing on 408 request, its handled by auth interceptor
                break;

              case 500:
                this.toastService.error(this.translate.instant('GENERAL.AN_UNEXPECTED_ERROR_OCCURRED_THE_SERVER_NOT_RESPONDING_PLEASE_TRY_AGAIN'));
                break;
              case 404:
                this.toastService.error(this.translate.instant('GENERAL.NOT_FOUND'));
                break;
              default:
                this.toastService.error(this.translate.instant('GENERAL.AN_UNEXPECTED_ERROR_OCCURRED_PLEASE_TRY_AGAIN'));
                break;
            }
          }

          return throwError({ error: { error, request } });
        }),
        switchMap(event => {
          if (event instanceof HttpResponse) {
            const response: Response<any> = event.body;

            if (!response) {
              this.toastService.error(this.translate.instant('GENERAL.ERROR_OCCURRED_IN_SERVER_COMMUNICATION_PLEASE_TRY_AGAIN'));
              console.error('API request fail: ', response);
              return throwError({ error: { event, request } });
            }

            const isRestrictedUrl = this.restrictedApis.some((api) => event.url.includes('api/' + api));
            if (response.resultMessage && ResultMessageTypes.NONE !== response.resultMessage.resultMessageTypeId && !isRestrictedUrl) {
              this.messageService.showMessage(response.resultMessage);
            }

            if (false === response.success) {
              if (!environment.production) {
                console.error('API request fail: ', response);
              }

              if (response?.errorCode && Number(response.errorCode) >= 0) {
                return of(event);
              }

              return throwError({ error: { event, request } });
            }
          }

          return of(event);
        })
      );
  }
}
