import { ApiDataSource } from '@core/api/api.data-source';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomerExperienceWorkingHoursService } from './customer-experience-working-hours.service';
import { WorkingHour } from './customer-experience-working-hours.model';
import { SystemUserService } from '../system-user/system-user.service';

export class CustomerExperienceWorkingHoursDataSource extends ApiDataSource<WorkingHour> {

    public isOpenedNewTab = false;

    public $status = of([
        { key: true, value: this.translate.instant('GENERAL.ACTIVE') },
        { key: false, value: this.translate.instant('GENERAL.PASSIVE') }
    ]);

    public $userList = this.systemUserService
        .userSearch({ filter: {} }).pipe(
            map(response => response.data.results),
            map(statuses => statuses.map(item => {
                return {
                    key: item.systemUserId,
                    value: [item.firstName, item.lastName].join(' '),
                };
            }))
        );

    constructor(
        private workingHoursService: CustomerExperienceWorkingHoursService,
        private translate: TranslateService,
        private systemUserService: SystemUserService,
        protected initialFilter?: any,
    ) {
        super(initialFilter);
    }

    load(): void {
        if (!this.isOpenedNewTab) {

            // Init filter with data source's default filter
            const filterValue: any = { ...this.initialFilter, ...this.filter };

            // If filter keyword exists, filter data
            if (this.keyword) {
                filterValue.searchText = this.keyword;
            }

            // Update loading state
            this.loadingSubject.next(true);

            // Create request parameters
            const request = this.getRequest();
            if (!request.orderType) {
                request.orderType = 'ASC';
                request.orderBy = 'createdOn';
            }

            if (this.paginator?.pageSize) {
                request.pageSize = this.paginator.pageSize;
            }

            // Add filters to request
            request.filter = filterValue;

            // Fetch data
            this.workingHoursService
                .search(request)
                .pipe(
                    debounceTime(400),
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe(response => {
                    // Update count and data subjects
                    this.dataSubject.next(response.data.results);
                    this.dataCountSubject.next(response.data.rowCount);

                    // Update data source's empty based row count
                    this.empty = response.data.rowCount === 0;
                });

        }
        return this.loadingSubject.next(false);
    }

}
