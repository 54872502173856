import { NgModule } from '@angular/core';
import { Error403Component } from './error-403.component';
import { SharedModule } from '@shared/modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';



@NgModule({
  imports: [
    SharedModule,
    TranslateModule
  ],
  exports: [
    SharedModule,
    TranslateModule,
  ]
})
export class Error403Module { }
