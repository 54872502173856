import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ApiService, SystemPermission } from '@core/api';
import { Subject } from 'rxjs';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-permission',
  templateUrl: './select-permission.component.html',
  styleUrls: ['./select-permission.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectPermissionComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectPermissionComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SystemPermission[]> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'multi-select-permission';
  describedBy = '';
  stateChanges = new Subject<void>();
  isOpened = false;
  searchText = '';
  tooltip: string;
  filteredPermissions: SystemPermission[];
  filteredSystemPermissions: SystemPermission[];
  listPermissions: SystemPermission[];

  systemPermissions: SystemPermission[] = [];
  selectCtrl = new UntypedFormControl();
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  @Input() tabIndex;
  @Input() selectionWithId = false;
  @ViewChild('permissionSelect') permissionSelect: MatSelect;
  @HostBinding('id') id = `multi-select-permission-${SelectPermissionComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): SystemPermission[] {
    return this.selectCtrl.value;
  }
  set value(value: SystemPermission[]) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private api: ApiService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initDefaultOptions();
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.loading = true;
      this.api.systemPermission.search({ filter: {} })
        .toPromise()
        .then((response) => {
          this.systemPermissions = response.data.results.sort((a, b) => a.permissionName.localeCompare(b.permissionName));
          this.listPermissions = this.systemPermissions;
          this.filteredSystemPermissions = this.systemPermissions;
          if (this.selectionWithId) {
            const validIds: any = new Set(this.value);
            const filteredPermissions = this.listPermissions.filter(permission => validIds.has(permission.systemPermissionId));
            this.value = filteredPermissions;
          }
          this.loading = false;
        })
        .finally(() => resolve());
    });
  }


  onSelectionChange() {
    this.selectCtrl.patchValue(this.value);
    this.onChange(this.value);
    this.selectionChange(this.value);
    this.stateChanges.next();
  }

  compareFn(first: SystemPermission, second: SystemPermission) {
    return first && second ? first.systemPermissionId === second.systemPermissionId : first === second;
  }

  selectAll() {
    this.selectCtrl.patchValue(this.systemPermissions);
    this.onSelectionChange();
  }

  deselectAll() {
    this.selectCtrl.patchValue([]);
    this.onSelectionChange();
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.permissionSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
    this.isOpened = isOpened;
    const value = '';
    this.onKey(value);
  }

  onKey(value) {
    this.searchText = value;
    this.filteredPermissions = this.search(value);
    this.systemPermissions = this.filteredPermissions;
    this.filteredSystemPermissions = this.systemPermissions;
  }

  search(value: string) {
    const filter = replaceTurkishCharacters(value).toLocaleUpperCase();
    if (!filter) {
      return this.listPermissions;
    }
    if (this.systemPermissions.length < 1) {
      this.systemPermissions = this.listPermissions;
    }
    const permissions = this.listPermissions.filter((option) => option?.permissionName &&
      replaceTurkishCharacters(option?.permissionName?.toUpperCase()).includes(filter)
    );

    if (permissions) {
      const selectedPermissions = (this.value || []).map(item => {
        const isHave = permissions.find(key => key.systemPermissionId === item?.systemPermissionId);
        if (!isHave) {
          return item;
        }

      });
      this.systemPermissions = [...new Set([...permissions, ...selectedPermissions.filter(e => !!e)])];
    }

    return this.systemPermissions;
  }

  onChange = (_: any) => {
  }

  selectionChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: SystemPermission[]) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }
  ngOnDestroy = () => this.stateChanges.complete();

}
