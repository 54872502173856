import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Account, ActivityRequest, ActivityService, ApiService, AssignmentBulkInsert, CardRequest, CardService,
  Contact, ContactRequest, CustomerExperienceLinkInsertRequest, CustomerExperienceLinkType,
  CustomerExperienceTicket, CustomerExperienceTicketLinksService, OpportunityRequest, OpportunityService
} from '@core/api';
import { ActivityFormDialogComponent } from 'src/app/shared/components/activity-form-dialog/activity-form-dialog.component';

import { filter, switchMap } from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'net-customer-experience-new-object-dialog',
  templateUrl: './customer-experience-new-object-dialog.component.html',
  styleUrls: ['./customer-experience-new-object-dialog.component.scss'],
})
export class CustomerExperienceNewObjectDialogComponent implements OnInit {
  loading = false;

  ticket: CustomerExperienceTicket;
  account: Account;
  contact: Contact;
  type: CustomerExperienceLinkType;
  types = CustomerExperienceLinkType;

  ticketDetail: CustomerExperienceTicket;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {
      type?: CustomerExperienceLinkType, ticket?: CustomerExperienceTicket,
      account?: Account, contact?: Contact, ticketDetail?: CustomerExperienceTicket;
    },
    private dialogRef: MatDialogRef<ActivityFormDialogComponent>,
    private apiService: ApiService,
    private cardService: CardService,
    private activityService: ActivityService,
    private opportunityService: OpportunityService,
    private customerExperienceLinksService: CustomerExperienceTicketLinksService
  ) {
    if (data && data.type) {
      this.type = data.type;
    }

    if (data && data.ticket) {
      this.ticket = data.ticket;
    }

    if (data && data.account) {
      this.account = data.account;
    }

    if (data && data.contact) {
      this.contact = data.contact;
    }

    if (data && data.ticketDetail) {
      this.ticketDetail = data.ticketDetail;
    }

    this.dialogRef.addPanelClass('cdk-overlay-full');
  }

  ngOnInit(): void {
  }

  private insertLink(request: Omit<CustomerExperienceLinkInsertRequest, 'ticketId' | 'ticketLinkTypeId'>) {
    return this.customerExperienceLinksService.link.insert({
      ...request,
      ticketId: this.ticket?.ticketId ?? this.ticketDetail.ticketId,
      ticketLinkTypeId: this.type
    });
  }

  onCardSave(request: CardRequest) {
    this.loading = true;
    this.cardService
      .insert(request)
      .pipe(
        filter((response) => response.success),
        switchMap((response) => this.insertLink({ cardId: response.data.cardId }))
      )
      .subscribe((response) => this.dialogRef.close(response.data))
      .add(() => this.loading = false);
  }

  onContactSave(request: ContactRequest) {
    this.loading = true;
    this.apiService
      .contact
      .save(request)
      .pipe(
        filter((response) => response.success),
        switchMap((response) => this.insertLink({
          contactId: response.data.contactId,
          salesOrganizationId: response.data.customer.accounts.find(x => x.accountId === request.accountIds[0]).salesOrganizationId,
          accountId: request.accountIds[0]
        }))
      )
      .subscribe((response) => this.dialogRef.close(response.data))
      .add(() => this.loading = false);
  }

  onActivitySave(request: ActivityRequest) {

    request.activityAssignments.map(item => {
      item.assignment.dueDate = moment(item.assignment.dueDate).format('YYYY/MM/DD').toString();
    });

    this.loading = true;
    this.activityService
      .insert(request)
      .pipe(
        filter((response) => response.success),
        switchMap((response) => this.insertLink({
          accountId: response.data.accountId,
          activityId: response.data.activityId,
        }))
      )
      .subscribe((response) => this.dialogRef.close(response.data))
      .add(() => this.loading = false);
  }

  onAssignmentSave(assignments: AssignmentBulkInsert[]) {
    this.insertLink({
      accountId: assignments[0].accountId,
      assignmentId: assignments[0].assignmentId,
    })
      .subscribe((response) => this.dialogRef.close(response.data))
      .add(() => this.loading = false);
  }

  onOpportunitySave(request: OpportunityRequest) {
    this.loading = true;
    this.opportunityService
      .save(request)
      .pipe(
        filter((response) => response.success),
        switchMap((response) => this.insertLink({
          accountId: response.data.accountId,
          opportunityId: response.data.opportunityId,
        }))
      )
      .subscribe((response) => this.dialogRef.close(response.data))
      .add(() => this.loading = false);
  }

}
