import { Component, inject, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'net-iconify-icon',
  template: `
  @if (svg) {
    <span [innerHTML]="svg" [class]="customClass +' flex items-center justify-center text-icon'" [style.width]="size + 'px'" [style.height]="size + 'px'"></span>
  } @else {
    <span [class]="icon + ' ' + customClass +' flex items-center justify-center text-icon'" [style.width]="size + 'px'" [style.height]="size + 'px'" [style.color]="color"
    [style.background-color]="backgroundColor"></span>
  }
  `,
})
export class IconifyIconComponent {

  @Input({ required: false }) set icon(value: string) {
    this._icon = `iconify ${value}`;
  }

  get icon(): string {
    return this._icon;
  }

  private _icon: string = '';

  @Input() set customClass(value: string) {
    this._customClass = value;
  }

  get customClass(): string {
    return this._customClass;
  }

  private _customClass: string = '';
  private _svg: SafeHtml = '';

  @Input() size: string = '24';

  @Input() width: string = `${this.size}px`;

  @Input() height: string = `${this.size}px`;

  @Input() color: string = 'currentColor';

  @Input() backgroundColor: string = 'currentColor';

  @Input() set svg(value: string) {
    if (value) {
      this._svg = this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
  get svg(): SafeHtml {
    return this._svg;
  }

  private sanitizer = inject(DomSanitizer);

}
