import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';

const secretKey = environment.secretKey;

export function encrypt(value: string): string{
  return CryptoJS.AES.encrypt(value, secretKey).toString();
}

export function decrypt(textToDecrypt: string){
  return CryptoJS.AES.decrypt(textToDecrypt, secretKey).toString(CryptoJS.enc.Utf8);
}


