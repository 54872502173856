<div class="h-full w-full flex justify-center items-center p-4">
  <div class="p-4 shadow bg-card rounded-full select-none">
    <span *ngIf="!loading && !icon" style="font-size: 20px">{{ extension }}</span>
    <net-iconify-icon *ngIf="!loading && !!icon" [icon]="icon" class="flex-none text-primary"
      size="30"></net-iconify-icon>
    <mat-progress-spinner *ngIf="loading === true" diameter="30" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<div class="vex-mail-attachment-overlay" [matTooltipDisabled]="!(scanFlag && loading === true)"
  [matTooltip]="'MAIL.SCANNING' |translate">
  <p class="truncate">{{ file.fileName }}</p>
</div>