import { Subject } from 'rxjs';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, NgControl, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { SelectTime } from '../select-time/select-time.component.model';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

/* eslint-disable @angular-eslint/no-conflicting-lifecycle */

// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
  selector: 'net-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputTimeComponent
    }
  ]
})
export class InputTimeComponent implements OnInit, DoCheck, OnChanges {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-time';
  describedBy = '';
  stateChanges = new Subject<void>();

  ctrl = new UntypedFormControl(null, Validators.minLength(5));

  options: string[];

  icArrowDropDown = Icon.IC_TWOTONE_ARROW_DROP_DOWN;

  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input() start = 7;
  @Input() end = 22;
  @Input() min: SelectTime;
  @Input() max: SelectTime;
  @Input() tooltip: string;
  @Input() readOnly: boolean;

  @Input() set defaultValue(value: string) {
    if (!value) {
      return;
    }
    const defaultTime: SelectTime = {
      hour: Number(value.slice(0, 2)),
      minute: Number(value.slice(3, 5))
    };
    this.value = defaultTime;
    this.stateChanges.next();
  }
  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplit: MatAutocompleteTrigger;
  @Output() selectionChange: EventEmitter<SelectTime> = new EventEmitter<SelectTime>();
  @HostBinding('id') id = `select-time-${InputTimeComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Generate time options
    this.options = this.generateSelectTimes();
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get value(): SelectTime | null {
    return this.ctrl.value?.length > 4 ? {
      hour: Number(this.ctrl.value.slice(0, 2)),
      minute: Number(this.ctrl.value.slice(3, 5))
    } as SelectTime : null;
  }

  set value(value: SelectTime | null) {
    const valueString = value ? String(value.hour).padStart(2, '0') + ':' + String(value.minute).padStart(2, '0') : value;
    this.ctrl.setValue(valueString);
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  ngOnInit() {
    this.ctrl.valueChanges.subscribe((response) => {
      this.onChange(response?.length > 4 ? {
        hour: Number(response.slice(0, 2)),
        minute: Number(response.slice(3, 5))
      } as SelectTime : null);
      this.stateChanges.next();
    });
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Backspace' && this.input.nativeElement.selectionStart === this.input.nativeElement.value.length) {
      return true;
    }

    if (this.input.nativeElement.value.length > 1 && this.input.nativeElement.value.indexOf(':') !== 2) {
      this.input.nativeElement.value += ':';
    }

    // Check time input rules
    if (this.input.nativeElement.value.length === 0 && Number(event.key) > 2) {
      event.preventDefault();
    } else if (this.input.nativeElement.value.length === 3 && Number(event.key) > 5) {
      event.preventDefault();
    } else if (this.input.nativeElement.value.length > 4) {
      event.preventDefault();
    }

    // Check max time
    if (this.max) {
      const maxHourString = this.max.hour.toString().padStart(2, '0');
      const maxMinuteString = this.max.minute.toString().padStart(2, '0');

      if (this.input.nativeElement.value.length === 0 && (Number(event.key) > Number(maxHourString[0]))) {
        event.preventDefault();
      } else if (this.input.nativeElement.value.length === 1 && (Number(this.input.nativeElement.value[0]) === Number(maxHourString[0])) &&
        (Number(event.key) > Number(maxHourString[1]))) {
        event.preventDefault();
      } else if (maxHourString === this.input.nativeElement.value.slice(0, 2)) {
        if (this.input.nativeElement.value.length === 3 && (Number(event.key) > Number(maxMinuteString[0]))) {
          event.preventDefault();
        } else if (this.input.nativeElement.value.length === 4 && (Number(this.input.nativeElement.value[3]) === Number(maxMinuteString[0])) &&
          (Number(event.key) > Number(maxMinuteString[1]))) {
          event.preventDefault();
        }
      }
    }

    // Check min time
    if (this.min) {
      const minHourString = this.min.hour.toString().padStart(2, '0');
      const minMinuteString = this.min.minute.toString().padStart(2, '0');

      if (this.input.nativeElement.value.length === 0 && (Number(event.key) < Number(minHourString[0]))) {
        event.preventDefault();
      } else if (this.input.nativeElement.value.length === 1 && (Number(this.input.nativeElement.value[0]) === Number(minHourString[0])) &&
        (Number(event.key) < Number(minHourString[1]))) {
        event.preventDefault();
      } else if (minHourString === this.input.nativeElement.value.slice(0, 2)) {
        if (this.input.nativeElement.value.length === 3 && (Number(event.key) < Number(minMinuteString[0]))) {
          event.preventDefault();
        } else if (this.input.nativeElement.value.length === 4 && (Number(this.input.nativeElement.value[3]) === Number(minMinuteString[0])) &&
          (Number(event.key) < Number(minMinuteString[1]))) {
          event.preventDefault();
        }
      }
    }
  }

  reset() {
    if (this.ctrl.pristine) {
      return;
    }

    this.value = null;
    this.ctrl.markAsPristine();
  }

  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    // this.ctrl.setValue(event.option.value);
    this.input.nativeElement?.blur();

    this.selectionChange.emit(this.value);
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  compareWith = (one: SelectTime, two: SelectTime) => one && two && one.hour === two.hour && one.minute === two.minute;

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue = (value: SelectTime) => this.value = value;

  ngOnDestroy = () => this.stateChanges.complete();

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.min && changes.min.currentValue) || (changes.max && changes.max.currentValue) ||
      (changes.start && changes.start.currentValue) || (changes.end && changes.end.currentValue)) {
      this.options = this.generateSelectTimes();
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = (this.ngControl.invalid || this.ctrl.invalid) && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  private generateSelectTimes(): string[] {
    const options: string[] = [];

    for (let hour = this.start; hour <= this.end; hour++) {
      if (this.min && hour < this.min.hour) {
        continue;
      }

      if (this.max && hour > this.max.hour) {
        continue;
      }

      if (this.min && this.min.hour !== this.start && hour === this.min.hour && new Date().getMinutes() > 30) {
        continue;
      }

      if (!this.min || this.min?.hour === this.start || ((this.min && hour !== this.min.hour) || 0 === this.min.hour)) {
        options.push(`${String(hour).padStart(2, '0')}:00`);
      }

      options.push(`${String(hour).padStart(2, '0')}:30`);
    }

    return options;
  }
}
