import { KeyValue } from '@angular/common';
import { NavigationItem } from '@core/navigation/navigation-item.interface';

export function trackByRoute(index: number, item: NavigationItem): string {
  if (item.type === 'link') {
    return item.route;
  }

  return item.label;
}

export function trackById<T extends { id: string | number }>(id: string, index: number, item: T) {
  return item.id;
}

export function trackByKey(index: number, item: KeyValue<any, any>) {
  return item.key;
}

export function trackByValue(index: number, value: string) {
  return value;
}

export function trackByLabel<T extends { label: string }>(index: number, value: T) {
  return value.label;
}
