import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewRef
} from '@angular/core';
import {
  ToolbarNotificationsDropdownComponent
} from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { Store } from '@ngrx/store';
import { GetNotification, getNotificationCount, getUser } from '@core/store';
import { User } from '@core/api';
import { take } from 'rxjs/operators';
import { Icon } from '@shared/enums';
import { PopoverService } from '@shared/components/popover/popover.service';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {
  interval: NodeJS.Timeout;

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  notificationCount = 0;
  dropdownOpen: boolean;
  icNotificationsActive = Icon.IC_NOTIFICATIONS_ACTIVE;

  constructor(
    private popover: PopoverService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store
  ) {

    this.store.select(getUser).pipe(take(1)).subscribe((user: User) => {
      if ((this.interval && user) || !user) {
        clearInterval(this.interval);
      }

      if (user) {
        const userId = user ? user.userId + '-' + user.userName : null;

        this.notificationListener();

        // this.interval = setInterval(() => {
        // this.notificationListener();
        // }, 60 * 1000);

        // Get mail unread count
        // @TODO [mail-disable] disabled for release
        // this.store.dispatch(new GetMailUnreadCount());
      }
    });
  }

  ngOnInit() {

    this.store.select(getNotificationCount).subscribe(data => {
      this.notificationCount = data;
      if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  notificationListener() {
    this.store.dispatch(new GetNotification());
  }

  showPopover() {
    this.dropdownOpen = true;
    this.changeDetectorRef.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      hasBackdrop: true,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.changeDetectorRef.markForCheck();
    });
  }
}
