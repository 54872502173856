import { ReplaySubject, Subject } from 'rxjs';
import { filter, map, pairwise, startWith, take } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { AfterViewInit, Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  Account,
  AccountDocumentService,
  AttachmentService,
  DocumentDetail,
  DocumentFilterRequest,
  DocumentService,
  DocumentTypeEnum,
  MatHeaderFilterType,
  SystemUserService,
  User
} from '@core/api';
import { Permission } from '@core/auth/auth.enum';
import { UserConfigService } from '@core/user-config/user-config.service';
import { TranslateService } from '@ngx-translate/core';

import { fadeInRight400ms, fadeInUp400ms, scaleIn400ms, stagger40ms } from '@shared/animations';
import { TableColumn } from '../../interfaces/table-column.interface';
import { ToastService } from '../../services/toast.service';
import { updateUserColumnVisibility, watchUserTableSort } from '../../utils/mat-table-user-config';
import { TableColumnFilterConfig } from '../mat-table-column-filter/mat-table-column-filter.component';
import { DocumentListComponentType } from './document-list.component.enum';
import { getAccount, getUser, hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentDataSource } from '@core/api/document/document.data-source';
import { MatTablePaginatorComponent } from '../mat-table-paginator/mat-table-paginator.component';
import { Icon } from '@shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/modules';
import { FormatFileSizePipe } from '@shared/pipes';
import { LeadDetailDocumentDialogComponent } from 'src/app/lead/lead-detail/lead-detail-documents/lead-detail-document-dialog/lead-detail-document-dialog.component';

@Component({
  selector: 'net-document-list[type]',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    stagger40ms,
    scaleIn400ms
  ],
  standalone: true,
  imports: [
    SharedModule,
    FormatFileSizePipe
  ]
})
export class DocumentListComponent implements OnInit, AfterViewInit, OnDestroy {

  user: User;
  hasDownloadPermission = false;
  hasUploadPermission = false;
  hasDeletePermission = false;
  columns: TableColumn<DocumentDetail>[] = [];
  loading = true;
  initiated = false;
  items: DocumentDetail[];
  refresh: Subject<DocumentFilterRequest> = new Subject();
  tableSubject: ReplaySubject<DocumentDetail[]> = new ReplaySubject<[]>(1);
  dataSource: DocumentDataSource;
  matHeaderFilterType = MatHeaderFilterType;
  searchCtrl = new UntypedFormControl();
  tableColumnFilterConfig: TableColumnFilterConfig;
  icEdit = Icon.IC_EDIT;
  icCheck = Icon.IC_CHECK;
  icCancel = Icon.IC_CANCEL;
  icDelete = Icon.IC_TWOTONE_DELETE;
  icRemove = Icon.SOLID_TRASH_ALT;
  icOpenLink = Icon.IC_OPEN_IN_NEW;
  icSearch = Icon.IC_SEARCH;
  icMoreHoriz = Icon.IC_MORE_HORIZ;
  icFilterList = Icon.IC_FILTER_LIST;
  icDownload = Icon.MDI_DOWNLOAD;
  icAdd = Icon.IC_TWOTONE_ADD;
  translations: any;
  account: Account;

  @Input() type: DocumentListComponentType;
  @Input() documentSettingDetailId: string;
  @Input() isAccount = false;
  @Input() accountInput: Account;
  @Output() delete: EventEmitter<DocumentDetail> = new EventEmitter<DocumentDetail>();
  @Output() download: EventEmitter<DocumentDetail> = new EventEmitter<DocumentDetail>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTablePaginatorComponent, { static: true }) matTablePaginatorComponent: MatTablePaginatorComponent;

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private userConfig: UserConfigService,
    private systemUserService: SystemUserService,
    private store: Store,
    private toastService: ToastService,
    private documentService: DocumentService,
    private attachmentService: AttachmentService,
    private accountDocumentService: AccountDocumentService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    translate.get(['DOCUMENT', 'GENERAL']).subscribe(translations => {
      this.translations = translations;
      this.initColumns();
    });
    this.store.select(getAccount).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(account => {
      if (account) {
        this.account = account;
      }
    });

    this.refresh
      .asObservable()
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(response => {
        if ((response.filter.accountId && response.filter.opportunityId) || response.filter.activityId || response.filter.assignmentId) {
          this.dataSource.refresh();
        }
      });

    // Fetch current user
    this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => this.user = user);

    // Fetch user permissions
    this.store.select(hasUserPermission(Permission.DOCUMENT_UPLOAD)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => this.hasUploadPermission = response);
    this.store.select(hasUserPermission(Permission.DOCUMENT_DELETE)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => this.hasDeletePermission = response);
    this.store.select(hasUserPermission(Permission.DOCUMENT_DOWNLOAD)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => this.hasDownloadPermission = response);

  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  getDataSource() {
    // Subscribe table data subject
    this.tableSubject
      .asObservable()
      .pipe(
        map(items => items.filter(item => DocumentTypeEnum.Other === item.documentTypeId))
      )
      .subscribe(items => {
        items.map(value => {
          value.sourceName = value.documentSettingDetail?.name;
        });
        this.items = items;
        this.dataSource.data = items;
      });
  }

  ngOnInit(): void {
    let initialFilter = {
      accountId: this.account?.accountId ?? this.accountInput?.accountId,
      additionalFlag: false,
      ...this.type === DocumentListComponentType.activity ? { activityId: this.documentSettingDetailId } : {},
      ...this.type === DocumentListComponentType.opportunity ? { opportunityId: this.documentSettingDetailId } : {},
      ...this.type === DocumentListComponentType.assignment ? { assignmentId: this.documentSettingDetailId } : {}
    };
    if (this.isAccount) {
      this.columns.splice((this.columns.length - 1), 0, {
        label: this.translations?.DOCUMENT.SOURCE,
        property: 'documentSource',
        type: 'text',
        visible: true,
        filterable: true,
        key: 'documentSources'
      });
      initialFilter = {
        ...initialFilter,
        additionalFlag: true
      };
    }
    if (DocumentListComponentType.lead === this.type) {
      this.columns.splice(3, 0, {
        label: this.translations?.DOCUMENT.INSERTION_LOCATION,
        property: 'location',
        type: 'text',
        visible: true,
        filterable: false
      });
    }

    this.dataSource = new DocumentDataSource(
      this.translate,
      this.accountDocumentService,
      this.systemUserService,
      this.user,
      initialFilter
    );

    this.tableColumnFilterConfig = {
      visibilityId: `document-list-${this.type || 'default'}`,
      sortId: `document-list-${this.type || 'default'}`,
      orderId: `document-list-${this.type || 'default'}`
    };

    // Listen loading event for determine data source is initiated
    this.dataSource.loading$.pipe(filter(value => value === false), take(1)).subscribe(() => this.initiated = true);

    this.dataSource.keyword$.pipe(filter(keyword => keyword !== this.searchCtrl.value))
      .subscribe((keyword) => this.searchCtrl.setValue(keyword, { emitEvent: false }));
    // Listen for search
    this.searchCtrl.valueChanges
      .pipe(
        filter(value => value?.length > 2 || value?.length === 0),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(value => {
        if (this.type !== DocumentListComponentType.assignment) {
          this.router.navigate([], {
            queryParams: {
              search: value
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        } else {
          this.dataSource.keyword$.next(value ?? '');
        }

      });

    this.route.queryParams.pipe(
      startWith(null as string),
      pairwise(),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(([prev, next]) => {
        if (prev?.search === next.search) {
          return;
        }
        const searchValue = this.type === DocumentListComponentType.assignment ? '' : next.search;
        this.searchCtrl.setValue(searchValue ?? '');
        this.dataSource.keyword$.next(searchValue ?? '');
      });

    // Update column visibility by user config
    updateUserColumnVisibility(this.tableColumnFilterConfig.visibilityId, this.columns, this.userConfig);

    // Watch table sort changes for user config
    watchUserTableSort(this.tableColumnFilterConfig.sortId, this.sort, this.userConfig, this.columns);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.matTablePaginatorComponent.paginator;
  }

  clickDelete(document: DocumentDetail, event: MouseEvent) {
    Swal.fire({
      icon: 'warning',
      html: `
        <strong>${document.name}</strong> ${this.translations?.DOCUMENT.DOCUMENT_WILL_BE_DELETED}
        <br><br>
        ${this.translations?.GENERAL.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE} `,
      focusCancel: true,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#7a7a7a',
      confirmButtonText: this.translations?.GENERAL.REMOVE,
      cancelButtonText: this.translations?.GENERAL.CANCEL,
    }).then((result) => {
      if (result.value) {
        this.documentService
          .delete(document.documentId)
          .subscribe(response => {
            if (response.success) {
              this.delete.emit(document);
              this.dataSource.refresh();
              // this.items.splice(this.items.findIndex(item => item.documentId === document.documentId), 1);
              this.tableSubject.next(this.items?.filter(item => item.documentId !== document.documentId));
              // this.toastService.info(`${document.name} ${this.translations?.DOCUMENT.DOCUMENT_WAS_DELETED}`);
            } else {
              this.toastService.error(`${document.name} ${this.translations?.DOCUMENT.DOCUMENT_COULD_NOT_BE_DELETED_PLEASE_TRY_AGAIN}`);
            }
          });
      }
    });

    event.stopPropagation();
  }

  clickDownload(document: DocumentDetail, event: MouseEvent) {
    this.download.emit(document);
    if (document.documentSettingDetail.name === 'Card') {
      this.attachmentService.download(document.documentId, document.name + document.documentExtensionType.name);
    } else {
      this.documentService.download(document.documentId, document.name + document.documentExtensionType.name);
    }
    event.stopPropagation();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  checkDeletePermission(document: DocumentDetail): boolean {
    if ((document.opportunityId && document.opportunityPriceListId) || (document.opportunityId && document.opportunityContractId)) {
      return false;
    }
    if (DocumentListComponentType.assignment === this.type) {
      return this.user.userId === document.createdBy && this.hasDeletePermission;
    } else {
      return this.hasDeletePermission;
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  openFormDialog() {

    this.dialog
      .open(LeadDetailDocumentDialogComponent, { autoFocus: false, data: this.account })
      .addPanelClass('cdk-full-overlay').afterClosed().subscribe(response => {
        if (response) {

          this.dataSource.refresh();
        }
      });

  }

  onContextMenuAction(item: any) {
    if (item?.source === 'Customer' || !this.isAccount) {
      return;
    }
    const detailId = this.switchSettingDetail(item);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([detailId])
    );
    window.open(detailId, '_blank');
  }

  switchSettingDetail(item: any) {
    let detailId: string;
    switch (item?.source) {
      case 'Activity':
        detailId = '/activities/' + item.activity.activityId;
        break;
      case 'Card':
        detailId = `/board/${item.boardId}/scrum?cardId=${item.cardId}`;
        break;
      case 'Opportunity':
        detailId = '/opportunities/' + item.opportunity.opportunityId;
        break;
      case 'Assignment':
        detailId = '/leads/' + item.accountId + '/assignment/' + item.assignmentId;
        break;
    }
    return detailId;
  }

  private initColumns() {
    this.columns = [
      {
        label: this.translations?.DOCUMENT.FILE_NAME,
        property: 'name',
        type: 'text',
        visible: true,
        filterable: false
      },
      {
        label: this.translations?.DOCUMENT.SIZE,
        property: 'size',
        type: 'number',
        visible: true,
        filterable: false
      },
      {
        label: this.translations?.GENERAL.DATE,
        property: 'createdOn',
        type: 'date',
        visible: true,
        filterable: true,
        key: 'createDate'
      },
      {
        label: this.translations?.DOCUMENT.ADDEDBY,
        property: 'createdBy',
        type: 'text',
        visible: true,
        filterable: true,
        key: 'createdByIds'
      },
      {
        label: this.translations?.GENERAL.ACTIONS,
        property: 'actions',
        type: 'button',
        visible: true,
        filterable: true
      }
    ];
  }

}
