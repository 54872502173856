import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardComment, CardCommentRequest, Response } from '@core/api';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardCommentService {

  constructor(private http: HttpClient) { }

  insert(request: CardCommentRequest): Observable<Response<CardComment>> {
    return this.http.post<Response<CardComment>>(environment.apiUrl + '/api/CardComment/Insert', request);
  }

  update(request: CardCommentRequest): Observable<Response<CardComment>> {
    return this.http.post<Response<CardComment>>(environment.apiUrl + '/api/CardComment/Update', request);
  }

  delete(request: CardCommentRequest): Observable<Response<CardComment>> {
    return this.http.post<Response<CardComment>>(environment.apiUrl + '/api/CardComment/Delete', request);
  }

  get(uuid: string): Observable<Response<CardComment>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<CardComment>>(environment.apiUrl + '/api/CardComment/Get', JSON.stringify(uuid), { headers });
  }
}
