import { DestroyRef, Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Style } from '@shared/enums';
import { VexConfigService } from '@vex/config/vex-config.service';
import { UserConfigService } from '@core/user-config/user-config.service';
import { VexColorScheme, VexConfigName } from '@vex/config/vex-config.interface';
import { UserConfigType } from '@core/user-config/user-config.service.enum';


@Injectable({
  providedIn: 'root'
})
export class StyleService implements OnDestroy {

  defaultStyle = Style.default;

  private _styleSubject = new BehaviorSubject<Style>(this.defaultStyle);
  style$ = this._styleSubject.asObservable();

  private readonly destroyRef = inject(DestroyRef);

  private readonly configService: VexConfigService = inject(VexConfigService);
  private userConfig: UserConfigService = inject(UserConfigService);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.style$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(style => this._updateStyle(style));
  }

  setStyle(style: Style) {
    this._styleSubject.next(style);

    switch (style) {
      case Style.default:
        this.disableDefaultMode();
        break;

      case Style.light:
        this.enableLightMode();
        break;

      case Style.dark:
        this.enableDarkMode();
        break;
    }
  }

  enableLightMode(): void {
    this.configService.setConfig(VexConfigName.ares);
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.DARK
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  disableDefaultMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }

  private _updateStyle(style: Style) {
    const body = this.document.body;

    Object.values(Style).filter(s => s !== style).forEach(value => {
      if (body.classList.contains(value)) {
        body.classList.remove(value);
      }
    });

    body.classList.add(style);
  }
}
