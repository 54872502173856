import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { MailService } from '@core/api';


export const mailGuard = (): Promise<boolean> => {

  const router = inject(Router);
  const mailService = inject(MailService);

  return new Promise((resolve) => {
    mailService.isLoggedIn().subscribe(
      (isLogged) => {
        if (isLogged) {
          resolve(true);
          return;
        }

        router.navigate(['/mail/login']);
        resolve(false);
      },
      (error) => {
        console.error('Mail login check failed.', error);
        router.navigate(['/mail/login']);
        resolve(false);
      }
    );
  });
}
