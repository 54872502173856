<ng-container *ngIf="!loading">
  <ng-container *ngIf="multiple || options.single">
    <div class="drop-container" ngFileDrop [options]="options" [uploadInput]="uploadInput" [class.drag-over]="dragOver"
      (uploadOutput)="onUploadOutput($event)">
      <input #documentInput id="document-file-input{{id}}" type="file" ngFileSelect [options]="options"
        [multiple]="multiple" [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)"
        (click)="$event.target['value'] = null" />
      <label for="document-file-input{{id}}">
        <strong class="display-contents">{{ (type !== documentUploadTypes.MAIL_LIST ? "DOCUMENT.UPLOAD_FILE" :
          "DOCUMENT.UPLOAD_MAIL") | translate}}</strong>:
        {{ (type !== documentUploadTypes.MAIL_LIST ?
        "DOCUMENT.YOU_CAN_DRAG_YOUR_FILES_TO_THIS_AREA_OR_SELECT_THEM_BY_CLICKING" :
        "DOCUMENT.YOU_CAN_DRAG_YOUR_MAILS_TO_THIS_AREA_OR_SELECT_THEM_BY_CLICKING") | translate}}
      </label>
    </div>
    <div class="file-container" *ngIf="files.length > 0">
      <ul>
        <li *ngFor="let file of files; let i = index;" [class.removable]="file.progress?.data?.percentage < 100">
          <div class="file-info">
            <net-iconify-icon inline="true" size="14" color="#5c77ff" [icon]="icFile"></net-iconify-icon>
            <p class="file-name">{{ file.name }}</p>
            <p class="progress-text">
              <span *ngIf="file.progress?.data?.percentage !== 0 && file.progress?.data?.percentage !== 100">
                %{{ file.progress?.data?.percentage }}
              </span>
              <span *ngIf="file.progress?.data?.percentage === 0"> {{"DOCUMENT.READY_TO_UPLOAD" | translate}} </span>
              <span *ngIf="file.progress?.data?.percentage === 100"> {{"DOCUMENT.UPLOADED" | translate}}</span>
            </p>
            <button type="button" *ngIf="file.progress?.data?.percentage < 100" mat-icon-button matSuffix
              (click)="removeUpload(file.id)">
              <net-iconify-icon [icon]="icRemove" size="16"></net-iconify-icon>
            </button>
          </div>
          <div class="file-progress">
            <mat-progress-bar *ngIf="file.progress?.data?.percentage > 0"
              [mode]="file.progress?.data?.percentage ? 'determinate' : 'buffer'"
              [value]="file.progress?.data?.percentage || 0"></mat-progress-bar>
            <p class="speed-and-eta"
              *ngIf="file.progress?.data?.percentage !== 0 && file.progress?.data?.percentage !== 100">
              {{ file.progress?.data?.speedHuman }} | {{ file.progress?.data?.etaHuman }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="!multiple && !options.single">
    <div class="single-file-container"
      [ngClass]="{'is-file-loaded': document, 'cursor-pointer': hasDownloadPermission}">
      <div [hidden]="loading">
        <div fxLayout="column" fxLayoutAlign="center center" class="content"
          [matTooltip]="'GENERAL.PREVIEW' | translate" (click)="clickDownload(true)">
          <div class="icon">
            <net-iconify-icon fxFlex="none" [icon]="icon" size="30"></net-iconify-icon>
          </div>
          <div class="text text-center pt-3">
            {{ description }}
          </div>
        </div>

        <ng-container *ngIf="!document && hasUploadPermission">
          <input class="hidden" id="document-single-file-input{{id}}" type="file" ngFileSelect [options]="options"
            [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)" />
          <label for="document-single-file-input{{id}}" class="upload"
            [matTooltip]="'DOCUMENT.UPLOAD_FILE' | translate">
            <net-iconify-icon [icon]="icUpload" fxFlex="none" size="20"></net-iconify-icon>
          </label>
        </ng-container>

        <ng-container *ngIf="document">
          <label *ngIf="hasDownloadPermission" class="download">
            <a [matTooltip]="'GENERAL.DOWNLOAD' | translate" (click)="clickDownload()">
              <net-iconify-icon [icon]="icDownload" fxFlex="none" size="20"></net-iconify-icon>
            </a>
          </label>

          <label *ngIf="getOpportunityCommercialDocumentDeletePermissionControl()" class="remove">
            <a [matTooltip]="'GENERAL.REMOVE' | translate" (click)="clickDelete()">
              <net-iconify-icon [icon]="icRemove" fxFlex="none" size="20"></net-iconify-icon>
            </a>
          </label>
        </ng-container>
      </div>
      <net-full-spinner [active]="singleLoading"></net-full-spinner>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="loading">
  <div class="multiple-loading">
    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
  </div>
</ng-container>