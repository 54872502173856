import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import { SelectSalesOrganizationComponent } from '../select-sales-organization/select-sales-organization.component';

@Component({
  selector: 'net-select-sales-organization-dialog',
  templateUrl: './select-sales-organization-dialog.component.html',
  styleUrls: ['./select-sales-organization-dialog.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    SelectSalesOrganizationComponent
  ]
})
export class SelectSalesOrganizationDialogComponent {

  loading = false;

  icSave = Icon.IC_SAVE;
  icClose = Icon.IC_TWOTONE_CLOSE;

  form: UntypedFormGroup;
  userId: string;
  salesOrganizations: string[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SelectSalesOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {userId: string, salesOrganizations: string[]}
  ) {
    this.initForm();

    this.userId = this.data?.userId;
    this.salesOrganizations = this.data?.salesOrganizations;
  }

  initForm() {
    this.form = this.formBuilder.group({
      salesOrganization: [null, Validators.required],
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.dialogRef.close(this.form.get('salesOrganization').value);
    }
  }

}
