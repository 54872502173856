<div [formGroup]="form" [ngClass]="{'pointer-events-none': isReadOnly}">

  <net-select-country class="pr-5 mr-3" [panelWidth]="'auto'" fxFlex="37" formControlName="country"
    [value]="value?.country" [placeholder]="'GENERAL.COUNTRY' | translate" (optionSelected)="onChangeCountry($event)">
  </net-select-country>

  <input class="hide-input-number-arrow"
    [type]="form.get('country').value ? typeControl(form.get('country').value?.sortName) ? 'tel' : 'number' : 'tel'"
    formControlName="phone" matInput (blur)="removeValidators()" [placeholder]="placeholder" (keyup)="onKeyup($event)"
    [mask]="form.get('phoneMask').value" [prefix]="form.get('prefix').value" [showMaskTyped]="true"  [dropSpecialCharacters]="false">

  <net-input-number *ngIf="hasInternal" class="ml-4" fxFlex="100px" formControlName="internalPhone"
    [placeholder]="'GENERAL.INTERNAL_NUMBER' | translate" [removeMask]="true" [allowDecimal]="false"></net-input-number>

</div>