<mat-dialog-content>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="p-6 flex flex-col gap-4">
      <div fxFlex="auto" fxLayout="column">
        <h2 mat-dialog-title>{{'TOOLBAR.PASSWORD_UPDATE' | translate}}</h2>
        <mat-form-field fxFlex="grow">
          <mat-label>{{'TOOLBAR.OLD_PASSWORD' | translate}}</mat-label>
          <input type="password" formControlName="oldPassword" matInput type="password">
          <mat-error *ngIf="form.get('oldPassword').hasError('required')">
            {{'TOOLBAR.PLEASE_TYPE_YOUR_OLD_PASSWORD' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>{{'TOOLBAR.NEW_PASSWORD' | translate}}</mat-label>
          <input matInput formControlName="password" autocorrect="off" autocomplete="current-password"
            autocapitalize="off" spellcheck="false" type="password">
          <net-iconify-icon [icon]="icHelp" size="21" color="#5c78fd"
            [matTooltip]="'TOOLBAR.PASSWORD_MUST_BE_AT_LEAST_CHARACTERS_AND_CONTAIN_AT_LEAST_ONE_LOWERCASE_LETTER' | translate"
            matSuffix></net-iconify-icon>
          <mat-error *ngIf="form.get('password').hasError('required')">
            {{'TOOLBAR.PLEASE_TYPE_YOUR_NEW_PASSWORD' | translate}}
          </mat-error>
          <mat-error *ngIf="form.get('password').hasError('pattern')">
            {{'TOOLBAR.PLEASE_CREATE_A_STRONG_PASSWORD' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>{{'TOOLBAR.CONFIRM_NEW_PASSWORD' | translate}}</mat-label>
          <input matInput formControlName="confirmPassword" autocorrect="off" autocomplete="current-password"
            autocapitalize="off" spellcheck="false" type="password" [errorStateMatcher]="matcher">
          <mat-error *ngIf="form.hasError('notSame')">
            {{'TOOLBAR.PASSWORD_NOT_MATCH' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-dialog-actions class="items-start">
        <button class="ml-auto" color="secondary" style="border-radius: 5px" mat-button
          [mat-dialog-close]="false">{{'GENERAL.CANCEL' |
          translate}}</button>
        <net-progress-button [icon]="icSave" [block]="true" [active]="loading"
          text="{{'GENERAL.SAVE' | translate}}"></net-progress-button>
      </mat-dialog-actions>
    </div>
  </form>
</mat-dialog-content>