import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  constructor(private http: HttpClient) { }

  transform(url): Observable<string> {
    return this.http
      .get(url, { responseType: 'blob' }).pipe(
        map(val => URL.createObjectURL(val)
        ));
  }

}
