import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountCommunication } from './account-communication.model';
import { environment } from '@env/environment';
import { PagedResponse, Response } from '@core/api';
@Injectable({
    providedIn: 'root'
})
export class AccountCommunicationService {

    constructor(
        private http: HttpClient
    ) {}

    search(request: any): Observable<PagedResponse<any>> {
        return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/AccountCommunication/Search', request);
    }

    insert(request: any): Observable<Response<AccountCommunication>> {
        return this.http.post<Response<AccountCommunication>>(environment.apiUrl + '/api/AccountCommunication/Insert', request);
    }

    getAccountCommunication(accountId: string): Observable<Response<AccountCommunication[]>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<AccountCommunication[]>>( environment.apiUrl + '/api/AccountCommunication/List', JSON.stringify(accountId), { headers});
    }
}
