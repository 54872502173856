import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  signal,
  SimpleChanges
} from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink
} from '../../../../core/navigation/navigation-item.interface';
import { dropdownAnimation } from '@shared/animations/dropdown.animation';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  Scroll
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { NavigationService } from '../../../../core/navigation/navigation.service';

import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import { Store } from '@ngrx/store';
import { getAssignmentCount, getMailUnreadCount, getNotificationCount } from '@core/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatRippleModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    NgFor,
    SharedModule
  ]
})
export class SidenavItemComponent implements OnInit, OnChanges {
  @Input({ required: true }) item!: NavigationItem;
  @Input({ required: true }) level!: number;
  @Input({ required: true }) collapsed!: boolean;
  isOpen = false;
  isActive = false;

  icKeyboardArrowRight = Icon.IC_TWOTONE_KEYBOARD_ARROW_RIGHT;

  assignmentCount: number;
  mailUnreadCount: number;
  notificationCount: number;

  badgeValue = signal(0);

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private store: Store,
    private translate: TranslateService
  ) { }

  @HostBinding('class')
  get levelClass() {
    return `item-level-${this.level}`;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
        map((event) => event instanceof Scroll ? event.routerEvent as NavigationEnd : event as NavigationEnd),
        filter(() => this.isDropdown(this.item)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.onRouteChange());

    this.navigationService.openChange$
      .pipe(
        filter(() => this.isDropdown(this.item)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((item) => this.onOpenChange(item));

    this.store.select(getNotificationCount).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(notificationCount => {
      this.notificationCount = notificationCount;
      this.updateBadge();
    });

    this.store.select(getAssignmentCount).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(assignmentCount => {
      this.assignmentCount = assignmentCount;
      this.updateBadge();
    });

    this.store.select(getMailUnreadCount).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(mailUnreadCount => {
      this.mailUnreadCount = mailUnreadCount;
      this.updateBadge();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.hasOwnProperty('item') &&
      this.isDropdown(this.item)
    ) {
      this.onRouteChange();
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cdr.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cdr.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cdr.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cdr.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown): boolean {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter((child) => this.isDropdown(child))
      .some((child) => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown): boolean {
    return parent.children.some((child) => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']): boolean {
    return prop instanceof Function;
  }

  updateBadge() {
    if (this.item?.label === this.translate.instant('GENERAL.TASKS') && this.item.badge.value !== this.assignmentCount) {
      this.item.badge = {
        value: this.assignmentCount > 0 ? this.assignmentCount.toString() : '',
        background: '#ff4946',
        color: '#ffeeee'
      };
      this.badgeValue.set(this.assignmentCount);
    }

    if (this.item?.label === this.translate.instant('GENERAL.NOTIFICATIONS') && this.item.badge.value !== this.notificationCount) {
      this.item.badge = {
        value: this.notificationCount > 0 ? this.notificationCount.toString() : '',
        background: '#ff4946',
        color: '#ffeeee'
      };
      this.badgeValue.set(this.notificationCount);
    }

    if (this.item?.label === this.translate.instant('MENU.MAIL') && this.item.badge.value !== this.mailUnreadCount) {
      this.item.badge = {
        value: this.mailUnreadCount > 0 ? this.mailUnreadCount.toString() : '',
        background: '#ff4946',
        color: '#ffeeee'
      };
      this.badgeValue.set(this.mailUnreadCount);
    }
  }
}
