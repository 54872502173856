import { finalize, map } from 'rxjs/operators';

import { OrganizationService, SystemUser, SystemUserService, SystemUserStatusType } from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class SystemUserDataSource extends ApiDataSource<SystemUser> {

  public $status = of([
    { key: SystemUserStatusType.ACTIVE, value: this.translate.instant('GENERAL.ACTIVE') },
    { key: SystemUserStatusType.PASSIVE, value: this.translate.instant('GENERAL.PASSIVE') }
  ]);

  public $organizations = this.organizationService.list().pipe(
    map(response => response.data.results),
    map(statuses => statuses.sort((a, b) => (a.name.toCapitalize() > b.name.toCapitalize()) ? 1 : -1).map(item => {
      return {
        key: item.organizationId,
        value: item.name
      };
    }))
  );

  public $users = this.systemUserService
    .userSearch({ filter: {} }).pipe(
      map(response => response.data.results),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  constructor(
    private systemUserService: SystemUserService,
    private translate: TranslateService,
    private organizationService: OrganizationService,
    protected initialFilter?: any,
  ) {
    super(initialFilter);
  }

  load(): void {
    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();
    if (!request.orderType) {
      request.orderType = 'ASC';
    }

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.systemUserService
      .search(request)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  }
}
