import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, delay, finalize, map, tap } from 'rxjs/operators';

import {
    Offer, OfferRejectionReasonService, OfferService, OfferServiceType, OfferStatusService, OfferTypeService
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { TranslateService } from '@ngx-translate/core';

export class OfferDataSource extends ApiDataSource<Offer> {

  public isOpenNewTab = false;

  public $offerTypes = this.offerTypeService.list({}).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.offerTypeId,
        value: this.translate.instant('OfferType.' + item.name)
      };
    }))
  );

  public $createdBys = this.offerService
    .listCreatedUsers(this.initialFilter ? { accountId: this.initialFilter.accountId } : null).pipe(
      map(response => response.data),
      map(statuses => statuses.map(item => {
        return {
          key: item.systemUserId,
          value: [item.firstName, item.lastName].join(' '),
        };
      }))
    );

  public $originCountries = this.offerService.listCountries(true, this.initialFilter ? { accountId: this.initialFilter.accountId } : null).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.countryId,
        value: this.translate.instant('Country.' + item.name),
      };
    }))
  );

  public $destinationCountries = this.offerService.listCountries(false, this.initialFilter ? { accountId: this.initialFilter.accountId } : null).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.countryId,
        value: this.translate.instant('Country.' + item.name),
      };
    }))
  );

  public $services = of([
    { key: OfferServiceType.LAND, value: 'Yurtdışı Kara' },
    { key: OfferServiceType.AIR, value: 'Yurtdışı Hava' },
    { key: OfferServiceType.SEA, value: 'Yurtdışı Deniz' },
  ]).pipe(delay(1000));

  public $offerStatuses = this.offerStatusService.list({}).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.offerStatusId,
        value: this.translate.instant('OfferStatus.' + item.name)
      };
    }))
  );

  public $offerRejectionReasons = this.offerRejectionReasonService.list({}).pipe(
    map(response => response.data),
    map(reasons => reasons.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1).map(item => {
      return {
        key: item.offerRejectionReasonId,
        value: this.translate.instant('OfferRejectionReason.' + item.name)
      };
    }))
  );

  public request$ = new BehaviorSubject<{}>(null);

  constructor(
    private offerService: OfferService,
    private offerTypeService: OfferTypeService,
    private offerStatusService: OfferStatusService,
    private offerRejectionReasonService: OfferRejectionReasonService,
    private translate: TranslateService,
    protected initialFilter?: any
  ) {
    super(initialFilter);
  }

  load(): void {
    if (!this.isOpenNewTab){

    // Init filter with data source's default filter
    const filter: any = { ...this.initialFilter, ...this.filter };

    // If filter keyword exists, filter data
    if (this.keyword) {
      filter.searchText = this.keyword;
    }

    // Update loading state
    this.loadingSubject.next(true);

    // Create request parameters
    const request = this.getRequest();

    if (this.paginator?.pageSize) {
      request.pageSize = this.paginator.pageSize;
    }

    // Add filters to request
    request.filter = filter;

    // Fetch data
    this.offerService
      .search(request)
      .pipe(
        tap(() => this.request$.next(request)),
        debounceTime(400),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(response => {
        // Update count and data subjects
        this.dataSubject.next(response.data.results);
        this.dataCountSubject.next(response.data.rowCount);
        this.rowCount$.next(response.data.rowCount);

        // Update data source's empty based row count
        this.empty = response.data.rowCount === 0;
      });
  
    }
    return this.loadingSubject.next(false)

  }
}
