<form class="form-wrapper" [formGroup]="form" [class.page]="isPage" [class.dialog]="isDialog">
  <ng-template #content>
    <div fxLayout="column">
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
        <mat-form-field fxFlex fxFlex.gt-xs="50">
          <mat-label>{{ 'BOARD.BOARD_NAME' | translate }}</mat-label>
          <net-select-board formControlName="board"></net-select-board>
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.gt-xs="50">
          <mat-label>{{ 'BOARD.SECTION_TITLE' | translate }}</mat-label>
          <net-select-board-section [board]="form.get('board').value"
            formControlName="section"></net-select-board-section>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'BOARD.CARD_NAME' | translate }}</mat-label>
          <input matInput formControlName="name" maxlength="250" />
        </mat-form-field>
      </div>
    </div>
  </ng-template>

  <ng-template #action>
    <net-progress-button class="ml-auto mt-3" [icon]="icSave" [active]="loading" (btnClick)="onSubmit()"
      text="{{ 'GENERAL.SAVE' | translate }}"></net-progress-button>
  </ng-template>

  <ng-template [ngIf]="isDialog">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0" fxFlex="auto">
        <ngx-skeleton-loader *ngIf="loading" fxFlex appearance="line"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{ 'BOARD.NEW_CARD' | translate }}</span>
      </h2>

      <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </mat-dialog-actions>
  </ng-template>

  <ng-template [ngIf]="isPage">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <div fxLayout="row" fxLayoutAlign="start center" class="w-full">
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </div>
  </ng-template>
</form>