import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {
  url = 'https://translation.googleapis.com/language/translate/v2?key=' + environment.googleTranslateKey;
  httpClient: HttpClient;
  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  async translate(text: string, target: string, source?: string, format: string = 'text') {
    const requestBody = {
      q: text,
      source,
      target,
      format
    };
    return this.httpClient.post(this.url, requestBody).pipe(map((response: any) => response.data.translations[0].translatedText)).toPromise();
  }
}

export class GoogleObj {
  q: string;
  readonly source: string = 'en';
  readonly target: string = 'es';
  readonly format: string = 'text';

  constructor() { }
}
