import { debounceTime, finalize, map } from 'rxjs/operators';
import { ApiDataSource } from '../api.data-source';
import { OrderType } from '../api.type';
import { DynamicField } from '../dynamic-field/dynamic-field.model';
import { BoardCustomFieldService } from './board-custom-field.service';
import { BoardCustomFieldSearchRequest } from './board-custom-field.model';

export class BoardCustomFieldDataSource extends ApiDataSource<DynamicField> {
    orderBy = 'orderBy';
    orderType = 'ASC' as OrderType;

    constructor(public ready: boolean, private boardCustomFieldService: BoardCustomFieldService, public initialFilter?: any) {
        super(initialFilter);
    }

    load(): void {
        // Load dynamic fields when ready
        if (!this.ready) {
            return;
        }

        // Update loading state
        this.loadingSubject.next(true);

        // Create request parameters
        const request: BoardCustomFieldSearchRequest = {
            filter: { ...this.initialFilter, ...this.filter },
            ...this.getRequest()
        };

        if (this.paginator?.pageSize) {
            request.pageSize = this.paginator.pageSize;
        }

        // Fetch data
        this.boardCustomFieldService.search(request).pipe(
            debounceTime(400),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(response => {
            // Update count and data subjects
            this.dataSubject.next(response.data.results);
            this.dataCountSubject.next(response.data.rowCount);

            // Update data source's empty based row count
            this.empty = response.data.rowCount === 0;
        });
    }
}
