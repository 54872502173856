import {
    BoardDetailSignalR, BoardInfoSignalRService, BoardDetailFilterService, SocketSearchRequest,
    BoardGroupType, BoardViewType, CardPaginationType, CardHubRequest, BoardHubRequest
} from '@core/api';

export class BoardListviewSignalR extends BoardDetailSignalR {

    constructor(
        protected boardInfoSignalRService: BoardInfoSignalRService,
        protected boardDetailFilterService: BoardDetailFilterService
    ) {
        super(boardInfoSignalRService, boardDetailFilterService);
        this.groupName = BoardGroupType.Listview;
        this.viewName = BoardViewType.ListviewView;

        this.boardDetailFilterService.getViewNameSubject.next(this.viewName);
    }

    loadCard(request: SocketSearchRequest) {
        this.boardInfoSignalRService.invokeCards(request);
    }

    loadListViewCards(request: SocketSearchRequest) {
        this.boardInfoSignalRService.invokeListCards(request, CardPaginationType.ListviewCardPagination);
    }

    boardDataInvoke(methodName: string, request: CardHubRequest | BoardHubRequest) {
        return this.boardInfoSignalRService.getHubConnection().invoke(methodName, request);
    }
}
