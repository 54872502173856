import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { MailService, SystemSettingType } from '@core/api';
import { getSystemSettingValue } from '@core/store';
import { Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { fileIcon } from 'src/app/mail/mail.helper';
import { humanizeFileSize } from 'src/app/shared/utils/humanize-file-size';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-customer-experience-mail-attachment',
  templateUrl: './customer-experience-mail-attachment.component.html',
  styleUrls: ['./customer-experience-mail-attachment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class CustomerExperienceMailAttachmentComponent implements OnInit {

  icDownloadFill = Icon.MINGCUTE_DOWNLOAD_FILL;
  icDocument = Icon.IC_BASELINE_ASSIGNMENT;

  loading = false;
  scanFlag = false;
  fileName: string;

  downloadedAttachmentId: string;

  @Input() file: {
    attachmentId: string;
    fileName: string;
    fileSize: number;
    extension: string;
  };
  @Input() directDownload = false;
  @Input() mode: 'chatView' | 'mailView' = 'mailView';

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private fileSaver: FileSaverService,
    private mailService: MailService,
    private store: Store,
  ) {
    this.store.select(getSystemSettingValue(SystemSettingType.MAIL_SCAN)).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(scanFlag => {
        this.scanFlag = scanFlag as boolean;
      });
  }

  ngOnInit() {
    this.fileName = [this.file.fileName, this.file.extension.toLowerCase()].join('');
  }

  download() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (this.directDownload) {
      this.downloadMailIntegration();
      return;
    }
  }

  get icon() {
    return fileIcon(this.file.extension) ?? this.icDocument;
  }

  get extension(): string {
    return this.file.extension.toUpperCase();
  }

  downloadMailIntegration() {
    this.mailService.downloadMailIntegration(this.file['attachmentId'])
      .subscribe(
        (response) => {
          if (response.status !== 200) {
            console.error(`Downloading file '${this.fileName}' failed.`);
            this.loading = false;
            return;
          }

          this.fileSaver.save(response.body, this.fileName);
          this.loading = false;

        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );

  }

  get fileSize(): string {
    return humanizeFileSize(this.file.fileSize, 0);
  }
}

