<div class="py-3 pt-2 max-w-full">
  <div fxLayout="row" fxLayoutGap="16px" class="px-3 py-2 rounded-t title bg-app-bar cursor-pointer"
    [ngClass]="{'rounded-b': !showList}" (click)="toggleList()">
    <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div class="rounded-full h-9 w-9 flex items-center justify-center bg-white">
        <net-iconify-icon class="opacity-50 text-gray" size="20" [icon]="icFolderOpen"></net-iconify-icon>
      </div>
      <h2 class="title">{{'GENERAL.DOCUMENTS' | translate}}</h2>
      <button type="button" mat-icon-button matSuffix>
        <net-iconify-icon [icon]="showList ? icKeyboardArrowUp : icKeyboardArrowDown"></net-iconify-icon>
      </button>
    </div>
    <div fxFlex="60" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <span *ngIf="systemUserParameter" class="net-label p-2 text-sm">
        {{ systemUserParameter.contentCurrentUploadSize | formatFileSize}}/{{ systemUserParameter.contentUploadSize |
        formatFileSize }}
        {{'GENERAL.DOCUMENT_USAGE_PERCANTAGE' | translate: {usage:
        ((systemUserParameter.contentCurrentUploadSize * 100) / systemUserParameter.contentUploadSize).toFixed(0)} }}
      </span>
      <button type="button" color="primary" mat-icon-button *ngIf="documentSetting && systemUserParameter" [matTooltip]="'GENERAL.MAXIMUM_FILE_SIZE_INFO' | translate : {
        value1: documentSetting.perFileMaxSize | formatFileSize, 
        value2: systemUserParameter.contentUploadSize | formatFileSize}">
        <net-iconify-icon class="text-gray-500" [icon]="icHelpOutline"></net-iconify-icon>
      </button>
    </div>
  </div>
  <div class="card overflow-auto" *ngIf="showList">
    <div fxLayout="column" fxLayout.lt-md="column">
      <div fxFlex="100" fxLayout="column">
        <div class="overflow-auto px-3 py-2">
          <div class="my-4" *ngIf="hasUploadPermission">
            <net-document-upload [type]="uploadType.ASSIGNMENT" [options]="uploaderOptions" [uploadData]="uploadData"
              [systemUserParameter]="systemUserParameter" (done)="onUploadDone()"></net-document-upload>
          </div>
          <div class="mt-2 -mb-2 -ml-3 -mr-3">
            <net-document-list [type]="listType.assignment" [documentSettingDetailId]="assignment.assignmentId"
              (delete)="onDeleteDone()"></net-document-list>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3 py-2 overflow-auto mt-4">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="16px" class="cursor-pointer" (click)="toggleHistory()">
          <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div class="rounded-full h-8 w-8 flex items-center justify-center bg-app-bar">
              <net-iconify-icon class="opacity-75 text-gray" size="20px" [icon]="icHistory"></net-iconify-icon>
            </div>
            <h4>{{'GENERAL.DOCUMENT_HISTORY' | translate}}</h4>
          </div>
          <div fxFlex="60" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button type="button" mat-icon-button matSuffix>
              <net-iconify-icon [icon]="showHistory ? icKeyboardArrowUp : icKeyboardArrowDown"></net-iconify-icon>
            </button>
          </div>
        </div>
        <net-document-history *ngIf="showHistory"></net-document-history>
      </div>
    </div>
  </div>
</div>